import {Skeleton, Text, View} from 'native-base';
import {
  DISPLAY_SLASH_DATE_FORMAT,
  GROUP_MEMBER_TYPE,
  QUALITY_MEASURES_PATIENT_TYPE,
  SORT_ORDER,
} from '../../../../constants';
import {Colors} from '../../../../styles';
import {DisplayCardAvatar} from '../../../common/DisplayCard/DisplayCardAvatar';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import GoalSvg from '../../../common/Svg/GoalSvg';
import GroupSvg from '../../../common/Svg/GroupSvg';
import './styles.css';
import {CARE_TEAM_MEMBER_TYPE} from '../../../../constants/MlovConst';
import {extractNumber, getCodeFromCriteria, getCodeSystemFromCriteria, getCriteriaTitle, getReasonText, getResourceTypeForDate} from './utils';
import {Tooltip} from 'antd';
import {useIntl} from 'react-intl';
import InfoSvg from '../../../common/Svg/InfoSvg';
import LoopSvg from '../../../common/Svg/LoopSvg';
import AntDesign from 'react-native-vector-icons/AntDesign';
import {testID} from '../../../../testUtils';
import {IQualityMeasuresList} from './interface';
import {Pressable} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import SortIcon from '../../../common/Svg/SortIcon';
import {measureTableStyles} from './style';
import {ICustomToast} from '../../Contacts/CustomField/interface';
import {useToast} from '../../../Toast/ToastProvider';
import {CUSTOM_FIELD_TOAST_ERROR_DURATION, CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG} from '../../Contacts/CustomField/CustomFieldConst';
import {ToastType} from '../../../../utils/commonViewUtils';
import CriteriaMatchSvg from '../../../common/Svg/MeasureCriteriaSvg/CriteriaMatchSvg';
import CriteriaNotMatchSvg from '../../../common/Svg/MeasureCriteriaSvg/CriteriaNotMatchSvg';
import CriteriaExclusionSvg from '../../../common/Svg/MeasureCriteriaSvg/CriteriaExclutionSvg';
import CriteriaExceptionSvg from '../../../common/Svg/MeasureCriteriaSvg/CriteriaExceptionSvg';
import ProgressBar from './ProgressBar';
import {getDateStrFromFormat} from '../../../../utils/DateUtils';
import {NAME_MAX_LENGTH} from './const';

export const getContractsListColumns = (
  {hideProjectedRevenueInContract}: {hideProjectedRevenueInContract: boolean},
  onActionPerformed: (code: string, data?: any) => void
) => {
  return [
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="contractName"
        />
      ),
      key: 'name',
      width: '30%',
      render: (contract: any) => {
        return (
          <Text size="smSemibold" color={Colors.FoldPixel.GRAY400}>
            {contract.name || ''}
          </Text>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="contractType"
        />
      ),
      key: 'contractType',
      width: '20%',
      render: (contract: any) => {
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text size="smMedium" color={Colors.FoldPixel.GRAY200}>
              {contract.contractType?.value || ''}
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="riskLevel"
        />
      ),
      key: 'riskLevel',
      width: '12.5%',
      render: (contract: any) => {
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text size="smMedium" color={Colors.FoldPixel.GRAY200}>
              {contract.riskLevel}
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="livesCovered"
        />
      ),
      key: 'livesCovered',
      width: '12.5%',
      render: (contract: any) => {
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <GroupSvg
              customStrokeColor={Colors.FoldPixel.GRAY200}
              width={16}
              height={16}
            />
            <Text size="smMedium" ml={1} color={Colors.FoldPixel.GRAY200}>
              {contract.livesCovered || ''}
            </Text>
          </View>
        );
      },
    },
    ...(!hideProjectedRevenueInContract ? [{
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="projectedRevenue"
        />
      ),
      key: 'projectedRevenue',
      width: '12.5%',
      render: (contract: any) => {
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text
              size="smMedium"
              color={ contract.projectedRevenue ? Colors.FoldPixel.STATUS_DARK_SUCCESS : Colors.FoldPixel.GRAY400 }
            >
            {contract.projectedRevenue ? `$${contract.projectedRevenue}` : 0}
            </Text>
          </View>
        );
      },
    }] : []),
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="performancePeriod"
        />
      ),
      key: 'startDate',
      width: '12.5%',
      render: (contract: any) => {
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text size="smMedium"  color={Colors.FoldPixel.GRAY200}>
              {contract.startDate} - {contract.endDate}
            </Text>
          </View>
        );
      },
    },

  ];
};

export const getQualityMeasuresListColumns = (args: {
  onActionPerformed: (code: string, data?: any) => void,
  loading: boolean,
  expandedRowKeys: string[],
  handleExpand: (expanded: boolean, record: IQualityMeasuresList) => void,
  expandLoading: boolean,
  nameSortOrder: string,
  hoveredData: string | null,
  setHoveredData: React.Dispatch<React.SetStateAction<string | null>>,
  isSmallScreen: boolean,
}) => {
  const {isSmallScreen, loading, nameSortOrder, onActionPerformed, expandedRowKeys, handleExpand, expandLoading, hoveredData, setHoveredData} = args;
  const intl = useIntl();
  const toast = useToast();
  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }
  const maxLength = isSmallScreen ? NAME_MAX_LENGTH.MEASURE_NAME_SMALL_SCREEN : NAME_MAX_LENGTH.MEASURE_NAME;
  return [
    {
      title: (
        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <DisplayText
            size={'smMedium'}
            extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
            textLocalId="qualityMeasureName"
          />
          <Pressable
            disabled={loading}
            style={[
              loading && measureTableStyles.disabled,
            ]}
            onPress={() => {
              const currentOrder = nameSortOrder;
              let order;
              if (!currentOrder) {
                order = SORT_ORDER.ASC;
              } else if (currentOrder === SORT_ORDER.ASC) {
                order = SORT_ORDER.DESC;
              } else if (currentOrder === SORT_ORDER.DESC) {
                order = null;
              }
              onActionPerformed(COMMON_ACTION_CODES.SORT_CHANGED ,{order});
            }}>
            <SortIcon sortMode={nameSortOrder} />
          </Pressable>
        </View>
      ),
      key: 'name',
      width: isSmallScreen ? '35%' : '41%',
      render: (qualityMeasures: any) => {
        const isStratification = qualityMeasures?.hasSinglePerformance;
        const showExpand = isStratification ? qualityMeasures?.qmMeasureStratification?.length > 1 : qualityMeasures?.qmMeasureGroup?.length > 1;
        const qualityMeasuresList = isStratification ? qualityMeasures?.qmMeasureStratification : qualityMeasures?.qmMeasureGroup;
        const totalGroup = qualityMeasuresList?.length;
        const key = `quality_measures_list_${qualityMeasures.id}`
        return (
          <View style={{
            flexDirection: 'row',
            alignItems: 'center'
          }}>
            <View width={showExpand ? '76%' : '100%'}>
              <Tooltip overlayClassName='measure-tooltip' title={qualityMeasures?.name?.length > maxLength ? qualityMeasures?.name : ''} placement={'top'}>
                <Text noOfLines={2} isTruncated size="smSemibold" color={Colors.FoldPixel.GRAY400}>
                  {qualityMeasures?.name}
                </Text>
              </Tooltip>
            </View>
            <View>
              {showExpand && <span
                onClick={(e) => {
                  e.stopPropagation();
                  if (loading) {
                    return;
                  }
                  handleExpand(!expandedRowKeys.includes(key),qualityMeasures);
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: Colors.FoldPixel.GRAY300,
                  marginLeft: 10,
                }}
              >
                {expandedRowKeys.includes(key) ? (
                  <>
                    <Text style={{
                      marginRight: 3,
                      color: Colors.FoldPixel.GRAY300,
                      fontSize: 14,
                      fontWeight: '500',
                      lineHeight: 16.8
                    }}>
                      {`(${totalGroup} strata)` }</Text>
                    <AntDesign
                      size={6}
                      name={'caretup'}
                      color={Colors.FoldPixel.GRAY300}
                      {...testID('image')}
                    />
                  </>
                ):(
                  <>
                    <Text style={{
                      marginRight: 3,
                      color: Colors.FoldPixel.GRAY300,
                      fontSize: 14,
                      fontWeight: '500',
                      lineHeight: 16.8
                    }}>
                      {`(${totalGroup} strata)` }</Text>
                    <AntDesign
                      size={6}
                      name={'caretdown'}
                      color={Colors.FoldPixel.GRAY300}
                      {...testID('image')}
                    />
                  </>
                )}
              </span>}
            </View>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="performanceRate"
        />
      ),
      key: 'performanceScore',
      width: isSmallScreen ? '25%' : '30%',
      render: (qualityMeasures: any) => {
        const goal = extractNumber(qualityMeasures.goal) || 0;
        const performanceScore = extractNumber(qualityMeasures.performanceScore) || 0;
        return (
          <>
            {loading && (
              <Skeleton.Text lines={1}/>
            )}
            {(!loading) && <View>
              <View style={{flexDirection: 'row', alignItems: 'center', width: '100%'}}>
                <ProgressBar percentage={performanceScore} goal={goal} inInverse={qualityMeasures?.inverse} showProgressInfo={true}/>
              </View>
            </View>}
          </>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="measureScore"
        />
      ),
      key: 'score',
      width: isSmallScreen ? '8%' : '5%',
      render: (qualityMeasures: any) => {
        const performanceScore = extractNumber(qualityMeasures.performanceScore) || 0;
        const key = `quality_measures_list_${qualityMeasures.id}`;
        return (
          <>
            {loading && (
              <Skeleton.Text lines={1} width={12}/>
            )}
            {(!loading) && <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <Text size="smMedium" ml={0.5} color={expandedRowKeys?.includes(key) ? Colors.FoldPixel.GRAY400 : Colors.FoldPixel.GRAY200}>
                {performanceScore}%
              </Text>
            </View>}
          </>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="goal"
        />
      ),
      key: 'goal',
      width: isSmallScreen ? '7%' : '5%',
      render: (qualityMeasures: any) => {
        const key = `quality_measures_list_${qualityMeasures?.id}`;
        return (
          <>
            {loading && (
              <Skeleton.Text lines={1} width={12}/>
            )}
            {(!loading) && <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <Text size="smMedium" ml={0.5} color={expandedRowKeys?.includes(key) ? Colors.FoldPixel.GRAY400 : Colors.FoldPixel.GRAY200}>
                {qualityMeasures.goal}
              </Text>
            </View>}
          </>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="measureNotMet"
        />
      ),
      key: 'careGap',
      width: isSmallScreen ? '13%' : '10%',
      render: (qualityMeasures: any) => {
        const key = `quality_measures_list_${qualityMeasures?.id}`;
        const isStratification = qualityMeasures?.hasSinglePerformance;
        const showExpand = isStratification ? qualityMeasures?.qmMeasureStratification?.length > 1 : qualityMeasures?.qmMeasureGroup?.length > 1;
        return (
          <>
            {loading && (
              <Skeleton.Text lines={1} width={20}/>
            )}
            <div
              onMouseEnter={()=>setHoveredData(key)}
              onMouseLeave={()=> setHoveredData(null)}
            >
              <Pressable
                style={[
                  (!qualityMeasures?.careGap || showExpand) && measureTableStyles.disabled,
                  {
                    width: 200,
                  }
                ]}
                onPress={(e)=> {
                  if (showExpand) {
                    return;
                  }
                  if (qualityMeasures?.careGap) {
                    onActionPerformed(COMMON_ACTION_CODES.MEASURE_NOT_MET, qualityMeasures);
                    e.stopPropagation();
                  } else {
                    showToast({
                      toastType: ToastType.info,
                      message: intl.formatMessage({id: 'noMemberFoundForMeasure'}),
                      duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
                      closeAllPrevToast: true,
                    })
                    e.stopPropagation();
                  }
                }}
              >
              {(!loading && qualityMeasures.careGap !== 0) && <View
                style={{flexDirection: 'row', alignItems: 'center'}}>
                  <GroupSvg
                    customStrokeColor={expandedRowKeys?.includes(key) ? Colors.FoldPixel.GRAY400 : Colors.FoldPixel.GRAY200}
                    width={16}
                    height={16}
                  />
                  <Text size="smMedium" ml={0.5} color={expandedRowKeys?.includes(key) ? Colors.FoldPixel.GRAY400 : Colors.FoldPixel.GRAY200}>
                    {qualityMeasures.careGap || ''}
                  </Text>
                  {(hoveredData === key && !showExpand) && <View>
                    <AntDesign
                      size={6}
                      style={{
                        marginLeft: 4
                      }}
                      name={'caretright'}
                      color={Colors.FoldPixel.GRAY200}
                      {...testID('image')}
                    />
                  </View>}
              </View>}
              {(!qualityMeasures?.careGap && !loading) && <Text size="smMedium" ml={0.5} color={expandedRowKeys?.includes(key) ? Colors.FoldPixel.GRAY400 : Colors.FoldPixel.GRAY200}>
                {"-"}
              </Text>}
              </Pressable>
            </div>
          </>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="totalMembers"
        />
      ),
      key: 'totalPatients',
      width: isSmallScreen ? '12%' : '9%',
      render: (qualityMeasures: any) => {
        const key = `quality_measures_list_${qualityMeasures?.id}`;
        return (
          <>
            {loading && (
              <Skeleton.Text lines={1} width={20}/>
            )}
            {(!loading && qualityMeasures.totalPatients !== 0) && <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <GroupSvg
                customStrokeColor={expandedRowKeys?.includes(key) ? Colors.FoldPixel.GRAY400 : Colors.FoldPixel.GRAY200}
                width={16}
                height={16}
              />
              <Text size="smMedium" ml={0.5} color={expandedRowKeys?.includes(key) ? Colors.FoldPixel.GRAY400 : Colors.FoldPixel.GRAY200}>
                {qualityMeasures.totalPatients || ''}
              </Text>
            </View>}
            {(!qualityMeasures.totalPatients && !loading) && <Text size="smMedium" ml={0.5} color={expandedRowKeys?.includes(key) ? Colors.FoldPixel.GRAY400 : Colors.FoldPixel.GRAY200}>
              {"-"}
            </Text>}
          </>
        );
      },
    },
  ];
};

export const getQualityMeasuresListExpendColumns = (
  record: any,
  expandedRowData: any,
  onActionPerformed: (code: string, data?: any, measureGroup?: any)=> void,
  hoveredIndex: number | null,
  setHoveredIndex: React.Dispatch<React.SetStateAction<number | null>>,
  hoveredData: string | null,
  setHoveredData: React.Dispatch<React.SetStateAction<string | null>>,
  isSmallScreen: boolean,
) => {
  const toast = useToast();
  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }

  const selectedExpandedRowData = expandedRowData?.find((row: any) => row?.id === record?.id);
  const intl = useIntl();
  const goal = extractNumber(record.goal) || 0;
  const isStratification = record?.hasSinglePerformance;
  const measureGroup = selectedExpandedRowData?.groupData;
  const maxLength = isSmallScreen ? NAME_MAX_LENGTH.MEASURE_NAME_SMALL_SCREEN : NAME_MAX_LENGTH.MEASURE_NAME;
  return (
    <>
      <View style={{
        flexDirection: 'row',
        backgroundColor: Colors.FoldPixel.GRAY50,
      }}>
        <View style={{
          width: 2,
          backgroundColor: Colors.FoldPixel.GRAY300,
          borderRadius: 8,
          marginVertical: 4,
          marginHorizontal: 3,
        }}>
        </View>
        <View style={{
          backgroundColor: 'white',
          borderRadius: 6,
          paddingVertical: 4,
          paddingHorizontal: 1,
          borderColor: Colors.FoldPixel.GRAY150,
          borderWidth: 0.5,
          width: '99.55%'
        }}>
          {measureGroup?.map((group: any,index: any) => {
            const name = isStratification ? group?.description : group?.name || ''
            const performanceScore = extractNumber(group?.performanceScore || '') || 0;
            return (
              <div
                onMouseEnter={()=>setHoveredIndex(index)}
                onMouseLeave={()=> setHoveredIndex(null)}
                style={{
                  backgroundColor:  hoveredIndex === index ? Colors.FoldPixel.GRAY50 : '',
                }}
              >
                <Pressable
                  style={[
                    (selectedExpandedRowData?.loading || !group.totalPatients) && measureTableStyles.disabled,
                    {
                      backgroundColor:  hoveredIndex === index ? Colors.FoldPixel.GRAY50 : '',
                    }
                  ]}
                  onPress={() => {
                    if (selectedExpandedRowData?.loading) {
                      return;
                    }
                    if (!group.totalPatients) {
                      showToast({
                        toastType: ToastType.info,
                        message: intl.formatMessage({id: 'noMemberFoundForMeasure'}),
                        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
                        closeAllPrevToast: true,
                      })
                      return;
                    }
                    onActionPerformed(COMMON_ACTION_CODES.DETAIL_VIEW, record, group)
                  }}
                >
                  <View style={{
                    flexDirection: 'row',
                    marginLeft: 1,
                    paddingHorizontal: 6,
                    paddingVertical: 16,
                    borderBottomWidth: index<(measureGroup?.length-1)? 0.5:0,
                    borderBottomColor: Colors.FoldPixel.GRAY100,
                  }}>
                    <View style={{
                      width: isSmallScreen ? '36%' : '42%',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}>
                      <View style={{
                        width: 30,
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <View style={{
                          borderWidth: 1,
                          borderColor: Colors.FoldPixel.GRAY100,
                          backgroundColor: Colors.FoldPixel.GRAY50,
                          borderRadius: 4,
                          alignItems: 'center',
                          justifyContent: 'center',
                          paddingHorizontal: 5,
                          marginRight: 6,
                          marginVertical: 2
                        }}>
                            <Text style={{
                              color: Colors.FoldPixel.GRAY300,
                              fontSize: 10,
                              fontWeight: '400',
                            }}>
                              {index + 1}
                            </Text>
                        </View>
                      </View>
                      <Tooltip overlayClassName='measure-tooltip' title={name?.length > maxLength ? name : ''} placement={'top'}>
                        <Text marginRight={8} noOfLines={2} isTruncated size="smSemibold" color={Colors.FoldPixel.GRAY400}>
                          {name}
                        </Text>
                      </Tooltip>
                    </View>

                    <View style={{flexDirection: 'row',alignItems: 'center',width: isSmallScreen ? '25.6%' : '30.7%'}}>
                      <>
                        {selectedExpandedRowData?.loading ? <View style={{
                          width: isSmallScreen ? '88%' : '91.3%'
                        }}>
                          <Skeleton.Text lines={1}/>
                        </View> : <View style={{
                          width: isSmallScreen ? '88%' : '91.3%'
                        }}>
                          <ProgressBar percentage={performanceScore} goal={goal} inInverse={record?.inverse} showProgressInfo={true}/>
                        </View>
                        }
                      </>
                    </View>

                    <View style={{flexDirection: 'row',alignItems: 'center',width: isSmallScreen ? '8.2%' : '5.05%'}}>
                      {selectedExpandedRowData?.loading ? <View style={{
                        width: '70%'
                      }}>
                        <Skeleton.Text lines={1} width={10}/>
                      </View> :
                      <Text size="smMedium" ml={0.5} color={Colors.FoldPixel.GRAY400}>
                        {performanceScore}%
                      </Text>}
                    </View>

                    <View style={{flexDirection: 'row',alignItems: 'center',width: isSmallScreen ? '7.2%' : '5%'}}>
                      {selectedExpandedRowData?.loading ? <View style={{
                        width: '70%'
                      }}>
                        <Skeleton.Text lines={1} width={10}/>
                      </View> :
                      <Text size="smMedium" ml={0.5} color={Colors.FoldPixel.GRAY400}>
                        {record.goal}
                      </Text>}
                    </View>

                    <View style={{flexDirection: 'row',alignItems: 'center',width: isSmallScreen ? '13.2%' : '10.25%'}}>
                      <>
                      {selectedExpandedRowData?.loading ? <View style={{
                        width: '70%'
                      }}>
                        <Skeleton.Text lines={1} width={20}/>
                      </View> : <div
                        onMouseEnter={()=>setHoveredData(index)}
                        onMouseLeave={()=> setHoveredData(null)}
                      >
                        <Pressable
                          style={[
                            !group.careGap && measureTableStyles.disabled,
                            {
                              alignItems: 'center',
                              flexDirection: 'row'
                            }
                          ]}
                          onPress={(e)=> {
                            if (group?.careGap) {
                              onActionPerformed(COMMON_ACTION_CODES.MEASURE_NOT_MET, record, group);
                              e.stopPropagation();
                            } else {
                              showToast({
                                toastType: ToastType.info,
                                message: intl.formatMessage({id: 'noMemberFoundForMeasure'}),
                                duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
                                closeAllPrevToast: true,
                              })
                              e.stopPropagation();
                            }
                          }}>
                          {group.careGap !==0 && <>
                            <GroupSvg
                              customStrokeColor={Colors.FoldPixel.GRAY400}
                              width={16}
                              height={16}
                            />
                            <Text size="smMedium" ml={0.5} color={Colors.FoldPixel.GRAY400}>
                              {group.careGap||''}
                            </Text>
                            {hoveredData === index && <View>
                              <AntDesign
                                size={6}
                                style={{
                                  marginLeft: 4
                                }}
                                name={'caretright'}
                                color={Colors.FoldPixel.GRAY400}
                                {...testID('image')}
                              />
                            </View>}
                          </>}
                          {(!group.careGap && !selectedExpandedRowData?.loading) && <Text size="smMedium" ml={0.5} color={Colors.FoldPixel.GRAY400}>
                            {"-"}
                          </Text>}
                        </Pressable>
                      </div>}
                      </>
                    </View>

                    <View style={{flexDirection: 'row',alignItems: 'center', width: isSmallScreen ? '12%' : '7%'}}>
                      <>
                        {selectedExpandedRowData?.loading ? <View style={{
                        width: '70%'
                      }}>
                        <Skeleton.Text lines={1} width={20}/>
                      </View> : <>
                        { group.totalPatients !==0 &&  <>
                          <GroupSvg
                            customStrokeColor={Colors.FoldPixel.GRAY400}
                            width={16}
                            height={16}
                          />
                          <Text size="smMedium" ml={0.5} color={Colors.FoldPixel.GRAY400}>
                            {group.totalPatients||''}
                          </Text>
                        </>}
                        {(!group.totalPatients && !selectedExpandedRowData?.loading) && <Text size="smMedium" ml={0.5} color={Colors.FoldPixel.GRAY400}>
                          {"-"}
                        </Text>}
                      </>
                        }
                      </>
                    </View>

                  </View>
                </Pressable>
              </div>
            )
          })}
        </View>
      </View>
    </>
  )
}

const getReasonTitle = (tab: string)=> {
  switch(tab) {
    case QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED:
      return 'reasonForMeetingCriteria';
    case QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED:
      return 'reasonForNotMeetingCriteria';
    case QUALITY_MEASURES_PATIENT_TYPE.DENOMINATOR_EXCEPTION:
    case QUALITY_MEASURES_PATIENT_TYPE.NUMERATOR_EXCEPTION:
      return 'reasonForTheException';
    case QUALITY_MEASURES_PATIENT_TYPE.DENOMINATOR_EXCLUSION:
    case QUALITY_MEASURES_PATIENT_TYPE.NUMERATOR_EXCLUSION:
      return 'reasonForTheExclusion';
    default:
      return '';
  }
}

export const getQualifiedPatientList = (args: {memberType: string}, onActionPerformed: (actionCode: string, actionData?: any) => void) => {
  const {memberType} = args;
  const array = [
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      key: 'name',
      width: '25%',
      render: (data: any) => {
        return (
          <Pressable onPress={(()=> {
            onActionPerformed(COMMON_ACTION_CODES.OPEN,{contactData: data?.contactData})
          })} style={{flexDirection: 'row', alignItems: 'center'}}>
            <View style={{marginRight: 8}}>
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: '12',
                  width: 48,
                  height: 48,
                }}
                isLetterAvatarShow
                userData={{
                  userId: data?.contactData?.id || '',
                  userType: GROUP_MEMBER_TYPE.CONTACT,
                  contactType: data?.contactData?.memberType || '',
                  name: data?.contactData?.name,
                  userName: data?.contactData?.name || '',
                }}
              />
            </View>
            <View>
              <Text size="smSemibold" color={Colors.FoldPixel.GRAY400}>
                {data?.contactData?.name || ''}
              </Text>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Text size="smMedium" color={Colors.FoldPixel.GRAY200}>
                  {data?.contactData?.memberType
                    ? `${data?.contactData?.memberType} • `
                    : ''}
                </Text>
                <Text size="smMedium" color={Colors.FoldPixel.GRAY200}>
                  {data?.contactData?.gender
                    ? `${data?.contactData?.gender} • `
                    : ''}
                </Text>
                <Text size="smMedium" color={Colors.FoldPixel.GRAY200}>
                  {data?.contactData?.dob || ''}
                  {data?.contactData?.age
                    ? ` (${data?.contactData?.age}) `
                    : ''}
                </Text>
              </View>
            </View>
          </Pressable>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="pcp"
        />
      ),
      key: 'pcp',
      width: '10%',
      render: (data: any) => {
        const pcpPractitioner = (
          data?.pcp?.careTeamMemberType || []
        ).find((careTeamMember: any) => {
          return careTeamMember?.memberType?.code === CARE_TEAM_MEMBER_TYPE.PCP;
        });
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text size="smMedium" ml={0.5} color={Colors.FoldPixel.GRAY200}>
              {pcpPractitioner?.userName || '--'}
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="payer"
        />
      ),
      key: 'payer',
      width: '10%',
      render: (data: any) => {
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text size="smMedium" ml={0.5} color={Colors.FoldPixel.GRAY200}>
              {data?.contractPayer?.name || ''}
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId={getReasonTitle(memberType)}
        />
      ),
      key: 'reason',
      width: '20%',
      render: (data: any) => {
        const patientQalifiedType = data?.patientType === QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED;
        let color =  data?.reason && patientQalifiedType ? Colors.FoldPixel.STATUS_DARK_SUCCESS
        : Colors.FoldPixel.STATUS_ERROR
        if (data?.inverse) {
          color =  data?.reason && !patientQalifiedType ? Colors.FoldPixel.STATUS_DARK_SUCCESS
          : Colors.FoldPixel.STATUS_ERROR
        }
        if ((data?.patientType !== QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED) && data?.patientType !== (QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED)) {
          color = data?.reason ? Colors.FoldPixel.GRAY300 : Colors.FoldPixel.STATUS_ERROR
        }
        return (
          <Pressable onPress={()=> {
            onActionPerformed(COMMON_ACTION_CODES.SHOW_REASON, data);
          }}>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <Text noOfLines={1} isTruncated style={{
                fontSize: 14,
                marginLeft: 0.5,
                color: color,
                maxWidth: '94%'
              }}>
                {getReasonText(memberType, data?.reason) || 'No screening evidence found'}
              </Text>
              <View ml={1} mt={0.5}>
                <InfoSvg customDimension={'14'} customColor={color}/>
              </View>
            </View>
          </Pressable>
        );
      },
    },
  ];
  return array;
};

export const getProgressBarColor = (value: number, goal: number, inverse: boolean) => {
  if (inverse) {
    if (value >= goal) {
      return Colors.FoldPixel.STATUS_ERROR;
    }
    return Colors.FoldPixel.STATUS_DARK_SUCCESS;
  } else {
    if (value <= 30) {
      return Colors.FoldPixel.STATUS_ERROR;
    }else if (value >= goal) {
      return Colors.FoldPixel.STATUS_DARK_SUCCESS;
    }
    return Colors.FoldPixel.STATUS_IN_PROGRESS;
  }
};

export const getCriteriaIcon = (criteria: string, isInverse?: boolean)=> {
  switch(criteria) {
    case QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED:
      if (isInverse) {
        return <CriteriaNotMatchSvg/>
      }
      return <CriteriaMatchSvg/>
    case QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED:
      if (isInverse) {
        return <CriteriaMatchSvg/>
      }
      return <CriteriaNotMatchSvg/>
    case QUALITY_MEASURES_PATIENT_TYPE.NUMERATOR_EXCLUSION:
    case QUALITY_MEASURES_PATIENT_TYPE.DENOMINATOR_EXCLUSION:
      return <CriteriaExclusionSvg/>
      case QUALITY_MEASURES_PATIENT_TYPE.NUMERATOR_EXCEPTION:
    case QUALITY_MEASURES_PATIENT_TYPE.DENOMINATOR_EXCEPTION:
      return <CriteriaExceptionSvg/>
    default:
      return <CriteriaMatchSvg/>
  }
}

export const getMeasureCriteriaList=(criteriaType: string, isInverse?: boolean) => {
  const array = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY250, fontWeight: '400', fontSize: 12, lineHeight: 14.4}}
          textLocalId="met"
        />
      ),
      key: 'met',
      width: '6%',
      render: (data: any) => {
        return (
          <View>
            {getCriteriaIcon(criteriaType, isInverse)}
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY250, fontWeight: '400', fontSize: 12, lineHeight: 14.4}}
          textLocalId="criteria"
        />
      ),
      key: 'criteria',
      width: '38%',
      render: (data: any) => {
        return (
          <View style={{flexDirection: 'column'}}>
            <Text style={{
              fontWeight: '400',
              color: Colors.FoldPixel.GRAY400,
              fontSize: 14,
              lineHeight: 16.8
            }}>
              {data?.criteriaTitle || '--'}
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY250, fontWeight: '400', fontSize: 12, lineHeight: 14.4}}
          textLocalId="status"
        />
      ),
      key: 'status',
      width: '20%',
      render: (data: any) => {
        return (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text
              style={{
                fontWeight: '400',
                color: Colors.FoldPixel.GRAY200,
                fontSize: 14,
                lineHeight: 16.8,
              }}
            >
              {data?.status || '--'}
            </Text>
          </View>
        );
      },
    }
  ];
  return array;
};


export const measureCriteriaViewDetail = (
  record: any,
  expandedCriteriaKeys: string[],
  onActionPerformed: (actionCode: string, actionData: any)=> void,
  criteriaType: string,
  isInverse?: boolean,
) => {
  const criteriaList = record?.qualifyingResources as any;
  const isExpanded = expandedCriteriaKeys.includes(record?.id);
  return (
    <>
      <View style={{
        backgroundColor: 'white',
        borderTopWidth: 0.5,
        borderBottomWidth: isExpanded ? 0: 0.5,
        borderColor: Colors.FoldPixel.GRAY100,
      }}>
        <Pressable style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: 12
        }}
          onPress={()=> {
            onActionPerformed(
              COMMON_ACTION_CODES.ITEM_SELECT,
              {
                id: record?.id,
                expanded: !isExpanded,
              }
            )
          }}
        >
          <Text style={{
            color: Colors.FoldPixel.GRAY300,
            fontSize: 14,
            fontWeight: '400',
            lineHeight: 16.8,
            padding: 4
          }}>
            {'View Detail'}
          </Text>
          {isExpanded? (
            <>
              <AntDesign
                size={6}
                name={'caretup'}
                color={Colors.FoldPixel.GRAY300}
                {...testID('image')}
              />
            </>
          ):(
            <>
              <AntDesign
                size={6}
                name={'caretdown'}
                color={Colors.FoldPixel.GRAY300}
                {...testID('image')}
              />
            </>
          )}
        </Pressable>
        {isExpanded && <View style={{
          flexDirection: 'row',
          paddingVertical: 6,
          paddingRight: 6,
          backgroundColor: Colors.FoldPixel.GRAY50
        }}>
          <View style={{
            width: 2,
            backgroundColor: Colors.FoldPixel.GRAY200,
            borderRadius: 8,
            marginVertical: 3,
            marginHorizontal: 3,
          }}>
          </View>
          <View style={{
            backgroundColor: 'white',
            borderRadius: 6,
            paddingVertical: 4,
            paddingHorizontal: 8,
            borderColor: Colors.FoldPixel.GRAY150,
            borderWidth: 0.5,
            width: '98.55%'
          }}>
            <View style={{
              flexDirection: 'row',
              borderBottomWidth: 0.5,
              borderBottomColor: Colors.FoldPixel.GRAY150,
              paddingBottom: 4,
            }}>
              <View style={{
                width: '10%'
              }}>
                <DisplayText
                  size={'xsMedium'}
                  extraStyles={{color: Colors.FoldPixel.GRAY250, fontWeight: '400', fontSize: 12, lineHeight: 14.4}}
                  textLocalId="met"
                />
              </View>
              <View style={{
                width: '54%'
              }}>
                <DisplayText
                  size={'xsMedium'}
                  extraStyles={{color: Colors.FoldPixel.GRAY250, fontWeight: '400', fontSize: 12, lineHeight: 14.4}}
                  textLocalId="criteria"
                />
              </View>
              <View style={{
                width: '20%'
              }}>
                <DisplayText
                  size={'xsMedium'}
                  extraStyles={{color: Colors.FoldPixel.GRAY250, fontWeight: '400', fontSize: 12, lineHeight: 14.4}}
                  textLocalId="code"
                />
              </View>
              <View style={{
                width: '18%'
              }}>
                <DisplayText
                  size={'xsMedium'}
                  extraStyles={{color: Colors.FoldPixel.GRAY250, fontWeight: '400', fontSize: 12, lineHeight: 14.4}}
                  textLocalId="codeSystem"
                />
              </View>
            </View>
            {criteriaList?.map((criteria: any,index: any) => {
              const codeCodeSystemAndName = getCodeSystemFromCriteria(criteria);
              const codeSystem = codeCodeSystemAndName?.matchedSystem;
              const code = codeCodeSystemAndName?.code || "-";
              const criteriaTitle = codeCodeSystemAndName?.display || "-";
              const resourceType = getResourceTypeForDate(criteria?.resourceType);
              const relevantDate = criteria?.relevantDate ? getDateStrFromFormat(criteria?.relevantDate, DISPLAY_SLASH_DATE_FORMAT) : "--"
              return (
                <View style={{
                  flexDirection: 'row',
                  paddingVertical: 16,
                  borderBottomWidth: index<(criteriaList?.length-1)? 0.5 : 0,
                  borderBottomColor: Colors.FoldPixel.GRAY100,
                  alignItems: 'center'
                }}>
                  <View style={{width: '10%'}}>
                    {getCriteriaIcon((criteriaType === QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED || criteriaType === QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED) ? criteriaType : '', isInverse)}
                  </View>

                  <View style={{flexDirection: 'column', width: '54%', paddingRight: 14}}>
                    <Text style={{
                      fontWeight: '400',
                      color: Colors.FoldPixel.GRAY400,
                      fontSize: 14,
                      lineHeight: 16.8
                    }}>
                      {criteriaTitle || '--'}
                    </Text>
                    <View style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                      <Text style={{
                        fontWeight: '400',
                        color: Colors.FoldPixel.GRAY200,
                        fontSize: 12,
                        lineHeight: 14.4
                      }}>
                        {resourceType || '--'}{": "}
                      </Text>
                      <Text style={{
                        fontWeight: '400',
                        color: Colors.FoldPixel.GRAY200,
                        fontSize: 12,
                        lineHeight: 14.4
                      }}>
                        {relevantDate || '--'}
                      </Text>
                    </View>
                  </View>

                  <View style={{flexDirection: 'row', alignItems: 'center', width: '20%', paddingRight: 14}}>
                    <Text noOfLines={2} style={{
                        fontWeight: '400',
                        color: Colors.FoldPixel.GRAY200,
                        fontSize: 14,
                        lineHeight: 16.8,
                      }}>
                      {code || '--'}
                    </Text>
                  </View>

                  <View style={{flexDirection: 'row', alignItems: 'center', width: '18%'}}>
                    <Text style={{
                        fontWeight: '400',
                        color: Colors.FoldPixel.GRAY200,
                        fontSize: 14,
                        lineHeight: 16.8
                      }}>
                      {codeSystem || '--'}
                    </Text>
                  </View>

                </View>
              )
            })}
          </View>
        </View>}
      </View>
    </>
  )
}
