import {useLazyQuery, useQuery} from '@apollo/client';
import {Popover, Select} from 'antd';
import {HStack, Pressable, Skeleton, Text, View, Spacer} from 'native-base';
import {useContext, useEffect, useRef, useState, useMemo} from 'react';
import {Dimensions, useWindowDimensions, StyleSheet} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import TagQueries from '../../../../services/Tags/TagQueries';
import {Colors} from '../../../../styles';
import {AddActionView} from '../../../common/Buttons/AddActionView';
import {ILabelDataView} from '../../../common/FilterView/interfaces';
import AddTagIcon from '../../../common/Svg/AddTagIcon';
import PlusIcon from '../../../common/Svg/PlusSvg';
import {AddTagView, DeleteTagView} from '../../../common/TagViews';
import {useContainerDimensions} from '../../../CustomHooks/ContainerDimensionHook';
import {formatGetMLovfromCategory} from '../../../RightSideContainer/Contacts/Tags/Helper/formatTagsData';
import {IContact} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {styles} from './OtherDetailsStyles';
import {TooltipPlacement} from 'antd/lib/tooltip';
import { MemberStatusList } from './MemberStatusList';

interface TagDataViewProps {
  contactData?: IContact;
  taggableType: string;
  contactId?: number;
  style?: any;
  conversationData?: any;
  sideCarDashboardEnabled?: boolean;
  showMemberStatus?: boolean;
  hideAddMoreButton?: boolean;
  tagList?: any;
  popOverConfig?: {
    placement?: TooltipPlacement;
    trigger?: 'click' | 'hover';
    offset?: [number, number];
  }
}

const antdStyles: Record<string, React.CSSProperties> = {
  popover: {
    padding: 0,
    borderRadius: 12,
  },
  popoverContent: {
    padding: 0
  },
};

export const TagDataListView = (props: TagDataViewProps) => {
  // const {style} = props;
  const [currentScreen, setCurrentScreen] = useState('');
  const [stateData, setStateData] = useState({
    moreActionPopup: false,
    loading: false
  })
  const componentRef = useRef();
  const {width, resetDimension} = useContainerDimensions(componentRef);
  let cId: any;
  props.taggableType === 'Conversation'
    ? (cId = props.conversationData?.id)
    : (cId = props?.contactData?.id);
  const mlovData = useContext(CommonDataContext);
  const categoryID = formatGetMLovfromCategory(props.taggableType, mlovData);
  const [labelData, setLabelData] = useState<ILabelDataView[]>([]);
  const [selectedDeleteData, setSelectedDeleteData] = useState<any>({});

  const {loading, refetch} = useQuery(TagQueries.GetConversationContactTags, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      taggableId: cId,
      taggableTypeId: categoryID,
    },
    skip: props.hideAddMoreButton ? true : false,
    onCompleted: (data) => {
      getMaxWidth(data.labels)
      setLabelData(data.labels);
    },
  });

  const handleMoreActionVisibleChange = (visible: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        moreActionPopup: visible,
      };
    });
  };
  const [maxTagsToShow, setLabelToShow] = useState<ILabelDataView[]>([]);

  const getMaxWidth = (labels: ILabelDataView[]) => {
    const maxTagsToShow: ILabelDataView[] = [];
    let currentWidth = 0;
    const maxWidth = width / 17;
    if (labels?.length === 1) {
      setLabelToShow(labels)
      return;
    } else {
      for (const label of labels) {
        if (currentWidth + label?.title?.length <= maxWidth) {
          currentWidth = currentWidth + label?.title?.length
          maxTagsToShow.push(label)
        } else {
          if (maxTagsToShow?.length === 0) {
            maxTagsToShow.push(label)
          }
          break;
        }
      }
      setLabelToShow(maxTagsToShow)
    }
  }

  const renderTags = (index: number, tagItem: ILabelDataView) => {
    return (
      <HStack
        key={index}
        justifyContent={'space-between'}
        alignItems="center"
        backgroundColor={tagItem?.color + '20'}
        style={tagStyles.tagContainer}
      >
        <Text
          wordBreak={'break-word'}
          color={tagItem?.color}
          size={'smRegular'}
          style={[tagStyles.tagText, {maxWidth: props.hideAddMoreButton ? (width - 25) : (width / 2.1)}]}
        >
          {tagItem?.title}
        </Text>
        {!props.hideAddMoreButton ?
          <Pressable
            onPress={() => {
              setCurrentScreen(COMMON_ACTION_CODES.DELETE);
              setSelectedDeleteData(tagItem);
            }}
          >
            <AntIcon color={Colors.Custom.Gray500} name="close" />
          </Pressable> : <></>}
      </HStack>
    )
  }
  const GetConversationContactTags = () => {
    setStateData(prev => {
      return {
        ...prev,
        loading: true
      }
    })
    if (props.hideAddMoreButton && props.tagList?.length) {
      const mappedTagObjList: any = []
      props.tagList?.forEach((item: any) => {
        if (item?.labels) {
          mappedTagObjList.push(item?.labels)
        }
      })
      getMaxWidth(mappedTagObjList)
      setLabelData(mappedTagObjList);
    } else {
      getMaxWidth([])
      setLabelData([]);
    }
    setStateData(prev => {
      return {
        ...prev,
        loading: false
      }
    })
  }
  useEffect(() => {
    GetConversationContactTags();
  }, [props.tagList, cId])

  const containerStyle = useMemo(() => {
    return [
      styles.listContainer,
      {
        // backgroundColor: props.hideAddMoreButton ? 'transparent' : '#fff',
        borderColor: Colors.Custom.Gray200,
        padding: props.hideAddMoreButton ? 0 : props?.sideCarDashboardEnabled ? 6 : 16,
        paddingVertical: props.hideAddMoreButton ? 0 : 10,
        width: '100%',
        flex: 1
      },
    ];
  }, [props.hideAddMoreButton]);

  return (
    <>
      <View ref={componentRef} style={containerStyle} backgroundColor={props.hideAddMoreButton ? 'transparent' : '#fff'}>
        {loading || stateData.loading ? (
          <Skeleton.Text lines={3} />
        ) : (
          <>
            <View style={tagStyles.headerContainer}>
              <View
                style={tagStyles.tagListContainer}
                maxWidth={props.hideAddMoreButton ? 'max-content' : '90%'}
              >
                <HStack alignItems={'center'} maxWidth={props.hideAddMoreButton ? width : '100%'} flexWrap={'wrap'} overflow={'auto'}>
                  {props?.showMemberStatus && 
                    <MemberStatusList 
                      contactData={props?.contactData} 
                      contactId={props?.contactId} 
                      showMemberStatus={props?.showMemberStatus}
                      /> } 
                  <View style={tagStyles.tagListInnerContainer}>
                    {maxTagsToShow && maxTagsToShow.length ? (
                      maxTagsToShow.map((tagItem: ILabelDataView, index: number) => {
                        return tagItem?.id ? (
                          <>
                            {renderTags(index, tagItem)}
                          </>
                        ) : <></>;
                      })
                    ) : (
                      <>
                        {labelData?.length ? <></> : <Text>{props.hideAddMoreButton ? '' : 'No Tag'}</Text>}
                      </>
                    )}
                  </View>
                  {(labelData?.length - maxTagsToShow?.length) > 0 && <Popover
                    overlayInnerStyle={antdStyles.popover}
                    overlayClassName='patient-search-select-styles'
                    className='patient-search-select-styles'
                    overlayStyle={antdStyles.popoverContent}
                    overlay={antdStyles.popoverContent}
                    style={antdStyles.popover}
                    placement={props?.popOverConfig?.placement || 'bottomLeft'}
                    trigger={props?.popOverConfig?.trigger || 'click'}
                    visible={stateData.moreActionPopup}
                    onVisibleChange={handleMoreActionVisibleChange}
                    content={
                      <View style={tagStyles.popoverContent}>
                        <View 
                          style={tagStyles.popoverInnerContent}
                          overflow={'auto'}
                        >
                          {labelData && labelData.length && (
                            labelData.map((tagItem: ILabelDataView, index: number) => {
                              return tagItem?.id ? (
                                <>
                                  {renderTags(index, tagItem)}
                                </>
                              ) : <></>;
                            })
                          )}

                        </View>
                      </View>
                    }
                  >
                    <Pressable
                      onPress={() => {
                        setStateData((prev) => {
                          return {
                            ...prev,
                            moreActionPopup: true,
                          };
                        });
                      }}
                    >
                      <Text fontWeight={500} marginTop={1} marginLeft={1} color={'#825AC7'} size={'smRegular'}>
                        {`+${labelData?.length - maxTagsToShow?.length} more`}
                      </Text>
                    </Pressable>
                  </Popover>}
                </HStack>
              </View>
              {!props.hideAddMoreButton
                ? <Pressable
                  onPress={() => {
                    setCurrentScreen(COMMON_ACTION_CODES.ADD);
                  }}
                >
                  <PlusIcon defaultColor={Colors.Custom.foldIconColor} />
                  {/* <AddActionView
              extraStyles={{width: 28, height: 28, marginLeft: 4, marginTop:5}}
              iconSize={15}
            /> */}
                </Pressable> : <></>
              }
            </View>
          </>
        )}
      </View>
      <>
        {currentScreen == COMMON_ACTION_CODES.ADD && (
          <AddTagView
            tagType={props.taggableType || 'Conversation'}
            onFormCompleteAction={() => {
              setCurrentScreen('');
              refetch();
            }}
            selectedLabelIds={labelData.map((label: ILabelDataView) => label.id)}
            contactTags={labelData}
            contactId={props?.contactData?.id}
          />
        )}
        {currentScreen == COMMON_ACTION_CODES.DELETE && (
          <DeleteTagView
            deleteTagData={selectedDeleteData}
            onFormCompleteAction={(actionCode?: string) => {
              setCurrentScreen('');
              setSelectedDeleteData({});
              if (actionCode === COMMON_ACTION_CODES.COMPLETED) {
                refetch();
              }
            }}
          />
        )}
      </>
    </>
  );
};

const tagStyles = StyleSheet.create({
  tagContainer: {
    paddingHorizontal: 8,
    marginHorizontal: 2,
    borderRadius: 16,
    marginTop: 5,
  },
  tagText: {
    marginRight: 5,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tagListContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  tagListInnerContainer: {
    maxWidth: '100%',
    flexDirection: 'row',
    alignItems: 'center'
  },
  popoverContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 330
  },
  popoverInnerContent: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    maxWidth: '90%'
  },
  moreText: {
    fontWeight: '500',
    marginTop: 1,
    marginLeft: 1,
    color: '#825AC7'
  }
});
