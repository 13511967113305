import React, {useEffect, useState} from 'react';
import {Pressable, Text, View} from 'react-native';
import {ITaskAlert} from './SideBarCustomHooks/useFetchAlerts';
import BackArrowSvg from '../common/Svg/SideCarSvg/BackArrowSvg';
import {useIntl} from 'react-intl';
import {Colors} from '../../styles';
import {Divider, Skeleton} from 'native-base';
import {getDateStrFromFormat} from '../../utils/DateUtils';
import {
  CARE_GAP_CONDITION_TYPE,
  DATE_FORMATS,
  MLOV_CATEGORY,
  QUALITY_MEASURES_PATIENT_TYPE,
} from '../../constants';
import {getMlovListFromCategory} from '../../utils/mlovUtils';
import {useContext} from 'react';
import {CommonDataContext} from '../../context/CommonDataContext';
import {Spin, Table} from 'antd';
import Stack from '../common/LayoutComponents/Stack';
import {
  SatisfiedConditionEvalution,
  UnSatisfiedConditionEvalution,
} from './dummyData';
import {TASK_STATUS} from '../../constants/MlovConst';
import {useApolloClient, useLazyQuery} from '@apollo/client';
import QualityMeasuresService from '../../services/Contracts/QualityMeasures.service';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../constants/Configs';
import {getMeasureCriteriaList} from '../RightSideContainer/Contracts/HelperFiles/TablesColumnsView';
import {PopulationCriteriaCode, getFormattedCriteriaListData} from '../RightSideContainer/Contracts/HelperFiles/utils';
import {ICriteriaList} from '../RightSideContainer/Contracts/HelperFiles/interface';
interface ICareGapDetailView {
  onBackClick: () => void;
  careGapDetail: ITaskAlert;
  contactUuid: string;
  style?: {
    paddingHorizontal: number
    paddingVertical: number
  }
}
interface IReason {
  name: string;
  date: string;
}
const commonStyle = {
    fontSize: 14,
    fontWight: 400,
  };
const {Column} = Table;
const CareGapDetailView = (props: ICareGapDetailView) => {
  const {onBackClick, careGapDetail,contactUuid} = props;
  const mlovData = useContext(CommonDataContext);
  const client = useApolloClient();
  const intl = useIntl();
  const [stateData, setStateData] = useState({
    satisfiedCriteriaReason: [] as IReason[],
    unsatisfiedCriteriaReason: [] as IReason[],
    unsatisfiedCriteriaList: [] as ICriteriaList[],
    error: '',
    loading: false
  });
  const taskStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];
  const status = taskStatusMlov?.find(
    (status) => status.id === careGapDetail.statusId
  );
  const renderStatus = status?.value;
  const evalutionDate = careGapDetail.startDateTime
    ? getDateStrFromFormat(
        careGapDetail.startDateTime,
        DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
      )
    : '';
  const [getQualityMeasuresIdByName] = useLazyQuery(
    QualityMeasuresService.getQualityMeasuresIdByName,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );
  const [getMesaureReportCriteriaReason] = useLazyQuery(
    QualityMeasuresService.getMesaureReportCriteraReasons,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );
  const getCriteriaReason = async () => {
    setStateData((prevState) => ({
      ...prevState,
      loading: true,
    }));
    try {
      const qualityMeasuresResp = await getQualityMeasuresIdByName({
        variables: {
          name: props.careGapDetail.title,
        },
      });

      if (qualityMeasuresResp.data?.qmQualityMeasures.length) {
        const qualityMeasureId =
          qualityMeasuresResp.data.qmQualityMeasures[0].id;
        const filter = {
          measureId: qualityMeasureId,
          contactId: contactUuid,
          denominatorFlag: true,
          numeratorFlag: false
        }
        const criteriaReasonResp = await getMesaureReportCriteriaReason({
          variables: {...filter}
        });

        if (criteriaReasonResp.data?.qmMeasureReports.length) {
          const qmMeasureReports = criteriaReasonResp.data?.qmMeasureReports[0]
          const criteriaReasonsEvidence = qmMeasureReports.evidence?.numerator;
          const satisfiedCriteriaReason: IReason[] = []
          const unsatisfiedCriteriaReason: IReason[] = []
          Object.values(criteriaReasonsEvidence).forEach((evidence: any) => {
            if (evidence.actual === evidence.expected) {
              satisfiedCriteriaReason.push({
                name: evidence.name,
                date: ''
              });
            } else {
              unsatisfiedCriteriaReason.push({
                name: evidence.name,
                date: ''
              });
            }
          });
          setStateData((prevState) => ({
            ...prevState,
            satisfiedCriteriaReason: satisfiedCriteriaReason,
            unsatisfiedCriteriaReason: unsatisfiedCriteriaReason,
            error: '',
            loading: false,
          }));
        } else {
          setStateData((prevState) => ({
            ...prevState,
            error: 'No measure reports found.',
            loading: false,
          }));
        }
      } else {
        setStateData((prevState) => ({
          ...prevState,
          error: 'No quality measures found.',
          loading: false,
        }));
      }
    } catch {
      setStateData((prevState) => ({
        ...prevState,
        error: `An error occurred`,
        loading: false,
      }));
    }
  };
  const fetchCriteria = async () => {
    await getCriteriaReason();
  };
  useEffect(() => {
    fetchCriteria();
  }, [props.careGapDetail.title]);

  const fetchQualityMeasureReports = async (measureId: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true
      }
    })
    try {
      const response = await client.query({
        query: QualityMeasuresService.getEvidenceList,
        variables: {
          evaluationId: measureId,
          populationCriteria: PopulationCriteriaCode.numerator,
        },
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        fetchPolicy: 'no-cache',
      });
      if (response?.data?.qmMeasureEvidences?.length) {
        const formattedCriteriaListData = getFormattedCriteriaListData(response?.data?.qmMeasureEvidences, QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED);
        setStateData((prev)=> {
          return {
            ...prev,
            unsatisfiedCriteriaList: formattedCriteriaListData,
            loading: false
          }
        })
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false
          }
        })
      }
    } catch (err) {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false
        }
      })
    }
  };


  useEffect(() => {
    fetchQualityMeasureReports(careGapDetail?.referenceData?.executionId);
  },[careGapDetail?.referenceData?.executionId])

  const renderCareGapActionTable = (data: any, caregapContionType: string) => {
    return (
      <Table
        className="alertsTable care-gap"
        dataSource={data}
        rowKey="id"
        pagination={false}
        loading={{
          spinning: false,
          indicator: (
            <View style={{marginTop: 10}}>
              <Spin size={'default'} />
            </View>
          ),
        }}
        locale={{
          emptyText: intl.formatMessage({id: 'noAlerts'}),
        }}
      >
        <Column
          title={
            <Stack direction={'row'}>
              <Text
                style={{
                  color: Colors?.FoldPixel?.GRAY300,
                  fontWeight: '500',
                  fontSize: 14,
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 10,
                }}
              >
                {caregapContionType === CARE_GAP_CONDITION_TYPE.SATISFIED
                  ? 'Completed Action'
                  : 'Pending Action'}
              </Text>
            </Stack>
          }
          dataIndex="name"
          key="name"
          width={'55%'}
          render={(
            text: string,
            record: {name: string; date: string; resultLink: string}
          ) => {
            return (
              <Text style={[commonStyle, {color: Colors.Custom.Gray400}]}>
                {record.name}
              </Text>
            );
          }}
        />
        {/* <Column
          title={
            <Stack direction={'row'}>
              <Text
                style={{
                  color: Colors?.FoldPixel?.GRAY300,
                  fontWeight: '500',
                  fontSize: 14,
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 10,
                }}
              >
                {caregapContionType === CARE_GAP_CONDITION_TYPE.UN_SATISFIED ? 'Due Date' : 'Date'}
              </Text>
            </Stack>
          }
          dataIndex="date"
          key="date"
          width={'20%'}
          render={(
            text: string,
            record: {name: string; date: string; resultLink: string}
          ) => {
            const evalutionDate = record.date
              ? getDateStrFromFormat(
                  record.date,
                  DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
                )
              : '';
            return (
              <Text style={[commonStyle, {color: Colors.Custom.Gray400}]}>
                {evalutionDate}
              </Text>
            );
          }}
        /> */}
        {CARE_GAP_CONDITION_TYPE.UN_SATISFIED !== caregapContionType && <Column
          title={
            <Stack direction={'row'}>
              <Text
                style={{
                  color: Colors?.FoldPixel?.GRAY300,
                  fontWeight: '500',
                  fontSize: 14,
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 10,
                }}
              >
                {caregapContionType === CARE_GAP_CONDITION_TYPE.UN_SATISFIED
                  ? 'Last Action'
                  : ''}
              </Text>
            </Stack>
          }
          width={'20%'}
          render={(
            text: string,
            record: {
              name: string;
              date: string;
              resultLink: string;
              lastAction: string;
            }
          ) => {
            return (
              <Pressable>
                <Text
                  style={{
                    color:
                      caregapContionType ===
                      CARE_GAP_CONDITION_TYPE.UN_SATISFIED
                        ? Colors.FoldPixel?.GRAY300
                        : Colors.FoldPixel?.PRIMARY300,
                    fontWeight: '500',
                    fontSize: 12,
                    paddingHorizontal: 4,
                    paddingVertical: 2,
                    textDecorationLine:
                      caregapContionType ===
                      CARE_GAP_CONDITION_TYPE.UN_SATISFIED
                        ? 'none'
                        : 'underline',
                  }}
                >
                  {caregapContionType === CARE_GAP_CONDITION_TYPE.UN_SATISFIED
                    ? record.lastAction
                    : 'View result'}
                </Text>
              </Pressable>
            );
          }}
        />}
      </Table>
    );
  };

  const renderUnsatisfiedCriteriaTable = (unsatisfiedCriteriaList: ICriteriaList[]) => {
    return (
      <View style={{
        marginTop: 8,
        borderWidth: 0.5,
        borderColor: Colors.FoldPixel.GRAY150,
        borderRadius: 8,
        padding: 2,
        backgroundColor: '#ffffff',
      }}>
        <Table
          columns={getMeasureCriteriaList(QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED)}
          rowClassName={(record, index) => {
            const isLastRow = index === unsatisfiedCriteriaList?.length - 1;
            return isLastRow ? 'measure-criteria-detail-last-row' : 'measure-criteria-detail';
          }}
          loading={{
            spinning: false,
            indicator: (
              <View style={{marginTop: 10}}>
                <Spin size={'default'} />
              </View>
            ),
          }}
          locale={{
            emptyText: intl.formatMessage({id: 'noDataFound'}),
          }}
          className="measure-criteria-detail-table"
          dataSource={unsatisfiedCriteriaList}
          pagination={false}
          rowSelection={undefined}
          rowKey={(record: any) => {
            return record?.id;
          }}
        />
      </View>
    )
  }
  const getTitleView = (title: string) => {
    return (
      <Text
        style={{
          color: Colors.FoldPixel.GRAY300,
          fontSize: 14,
          fontWeight: '500',
          marginLeft: 8,
          marginBottom: 8,
        }}
      >
        {title}
      </Text>
    );
  };
  return (
    <View
      style={{
        height: '100%',
        backgroundColor: Colors.FoldPixel.GRAY50,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: props.style?.paddingHorizontal
            ? props.style?.paddingHorizontal
            : 12,
          paddingVertical: props.style?.paddingVertical
            ? props.style?.paddingVertical
            : 6,
          backgroundColor: '#ffffff',
        }}
      >
        <Pressable
          onPress={() => {
            onBackClick();
          }}
          style={{
            width: 28,
            height: 28,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <BackArrowSvg />
        </Pressable>
        <Text style={{marginLeft: 6}}>
          {intl.formatMessage({id: 'careGapDetails'})}
        </Text>
      </View>
      <View
        style={{
          borderWidth: 1,
          borderColor: Colors.Custom.Gray200,
          borderStyle: 'solid',
          backgroundColor: '#ffffff',
          borderRadius: 8,
          margin: 16,
        }}
      >
        <View
          style={{
            paddingHorizontal: 12,
            paddingVertical: 8,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <View style={{width: '55%'}}>
            <Text style={[commonStyle, {color: Colors.FoldPixel.GRAY400}]}>
              {careGapDetail?.title}
            </Text>
          </View>
          <View>
           <Text
              style={[
                commonStyle,
                {
                  color:
                    status?.code === TASK_STATUS.MISSED
                      ? Colors.FoldPixel.STATUS_ERROR
                      : Colors.FoldPixel.GRAY200,
                },
              ]}
            >
              {renderStatus}
            </Text>
          </View>
        </View>
        <Divider />
        <View style={{paddingHorizontal: 12, paddingVertical: 8}}>
          <Text
            style={[
              commonStyle,
              {color: Colors.FoldPixel.GRAY400, marginBottom: 4},
            ]}
          >
            {intl.formatMessage({id: 'description'})}
          </Text>
          <Text style={[commonStyle, {color: Colors.FoldPixel.GRAY300}]}>
            {careGapDetail?.description}
          </Text>
        </View>
        <Divider />
        <View style={{paddingHorizontal: 12, paddingVertical: 8}}>
          <Text
            style={[
              commonStyle,
              {color: Colors.FoldPixel.GRAY400, marginBottom: 4},
            ]}
          >
            {intl.formatMessage({id: 'evaluationDate'})}
          </Text>
          <Text style={[commonStyle, {color: Colors.FoldPixel.GRAY300}]}>
            {evalutionDate}
          </Text>
        </View>
      </View>
      <View style={{marginHorizontal: 16, marginBottom: 16, display: 'none'}}>
        {getTitleView('Satisfied Criteria')}
        <View
          style={{
            backgroundColor: '#ffffff',
            borderRadius: 8,
          }}
        >
          {stateData.loading && !stateData.unsatisfiedCriteriaReason.length ? (
            <Skeleton.Text
              alignContent={'center'}
              width={'auto'}
              justifyContent={'center'}
              alignItems={'center'}
              lines={4}
              marginTop={1}
              padding={4}
            ></Skeleton.Text>
          ) : (
            renderCareGapActionTable(
              stateData.satisfiedCriteriaReason,
              CARE_GAP_CONDITION_TYPE.SATISFIED
            )
          )}
        </View>
      </View>
      <View style={{marginHorizontal: 16, marginBottom: 16}}>
        {getTitleView('Unsatisfied Criteria')}
        <View>
          {stateData.loading && !stateData.unsatisfiedCriteriaList.length ? (
            <Skeleton.Text
              alignContent={'center'}
              width={'auto'}
              justifyContent={'center'}
              alignItems={'center'}
              lines={4}
              marginTop={1}
              padding={4}
            ></Skeleton.Text>
          ) : (
            renderUnsatisfiedCriteriaTable(stateData?.unsatisfiedCriteriaList)
          )}
        </View>
      </View>
    </View>
  );
};

export default CareGapDetailView;
