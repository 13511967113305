import React, { useState, useMemo } from 'react';
import { HStack, Pressable, Divider, Text as NativeText, VStack, Input, View } from 'native-base';
import { Colors } from '../../../../../../styles';
import EyeOpenSvg from '../../../../Svg/WidgetsSvg/EyeOpenSvg';
import DraggableIconSvg2024 from '../../../../Svg/DraggableIconSvg2024';
import CloseSvg from '../../../../Svg/CloseSvg';
import NewSearchIconSvg from '../../../../../../assets/svg/NewSearchIconSvg';
import { DragDropContext, Droppable, Draggable, DroppableProvided, DroppableStateSnapshot, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import FoldButtonV2, { ButtonType } from '../../../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import RestartSvg from '../../../../../../assets/Icons/RestartSvg';
import EyeClosedSvgNew from '../../../../Svg/WidgetsSvg/EyeClosedSvgNew';
import Stack from '../../../../LayoutComponents/Stack';
import { DayOptimizerColumnChangeType, DayOptimizerColumnCode } from '../../DayOptimizerConstants';
import { useIntl } from 'react-intl';
import { InputStyle } from '../../../../../RightSideContainer/Contacts/Leads/LeadView/LeadViewStyle';

interface ColumnFilterDropdownProps {
  setPopoverVisible: (visible: boolean) => void;
  handleColumnChange: (changeType: DayOptimizerColumnChangeType, columnCode?: string, result?: any) => void;
  handleApplyForColumnFilter: () => void;
  columns: any[];
  loading: boolean;
  isColumnChanged: () => boolean;
  resetButtonEnabled: boolean;
}

const ColumnFilterDropdown = ({ setPopoverVisible, handleColumnChange, handleApplyForColumnFilter, columns, loading, isColumnChanged, resetButtonEnabled }: ColumnFilterDropdownProps) => {
  const [searchText, setSearchText] = useState('');
  const intl = useIntl();

  const filteredColumns = useMemo(() => {
    if (!searchText) return columns?.filter(column => column?.sequence !== 0 && column?.columnCode !== DayOptimizerColumnCode.NAME && column?.columnCode !== DayOptimizerColumnCode.ACTIONS);
    return columns?.filter(column => 
      column?.displayName?.toLowerCase()?.includes(searchText?.toLowerCase()) &&
      column?.sequence !== 0 &&
      column?.columnCode !== DayOptimizerColumnCode.NAME &&
      column?.columnCode !== DayOptimizerColumnCode.ACTIONS
    );
  }, [columns, searchText]);

  const handleSearchChange = (text: string) => {
    setSearchText(text);
  };

  const onDragEnd = (result: any) => {
    handleColumnChange(DayOptimizerColumnChangeType.PRIORITY, undefined, result);
  };

  const toggleVisibility = (columnCode: string) => {
    handleColumnChange(DayOptimizerColumnChangeType.VISIBILITY, columnCode);
  };

  const handleResetToDefault = () => {
    handleColumnChange(DayOptimizerColumnChangeType.RESET_DEFAULT);
  };

  return (
    <VStack padding={'12px'} space={'10px'} >
      <HStack justifyContent='space-between' alignItems='center'>
        <NativeText color={Colors.FoldPixel.GRAY400} fontSize={14} fontWeight={500}>Customize Table View</NativeText>
        <Pressable onPress={() => setPopoverVisible(false)}>
          <CloseSvg />
        </Pressable>
      </HStack>
      <Input
        value={searchText}
        width={'full'}
        borderColor={Colors.FoldPixel.GRAY200}
        placeholder='Search'
        placeholderTextColor={InputStyle.placeholderTextColor}
        fontSize={InputStyle.placeholderTextColor}
        paddingLeft={2}
        InputLeftElement={<View paddingLeft={2}><NewSearchIconSvg width={14} height={14} strokeColor={Colors.FoldPixel.GRAY300} /></View>}
        onChangeText={handleSearchChange}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='columns'>
          {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
            <VStack
              gap={'8px'}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {filteredColumns?.length > 0 ? (
              filteredColumns?.map((column, index) => (
                <Draggable key={column?.columnCode} draggableId={column?.columnCode} index={index} isDragDisabled={false}>
                  {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      style={{
                        ...(provided.draggableProps.style as any),
                        opacity: snapshot.isDragging ? 0.5 : 1
                      }}
                    >
                      <HStack
                        height={'32px'}
                        width={'232px'}
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <HStack space={4} alignItems='center'>
                        <Pressable>
                            <DraggableIconSvg2024 />
                          </Pressable>
                          <NativeText size={'smRegular'} color={Colors.FoldPixel.GRAY400}>{column?.displayName}</NativeText>
                        </HStack>
                          <Pressable onPress={() => toggleVisibility(column?.columnCode)}>
                            {!column?.isVisible ? (
                              <EyeClosedSvgNew/>
                            ) : (
                              <EyeOpenSvg width={22} height={18} customStrokeColor={Colors.FoldPixel.GRAY300} /> 
                          )}
                        </Pressable>
                      </HStack>
                    </div>
                  )}
                </Draggable>
                ))
              ) : (
                <NativeText color={Colors.FoldPixel.GRAY300} fontSize={14} fontWeight={400} textAlign='center'>
                  {intl.formatMessage({id: 'noMatchingColumnsFound'})}
                </NativeText>
              )}
              {provided?.placeholder}
            </VStack>
          )}
        </Droppable>
      </DragDropContext>
      <Divider />
      <Stack
        style={{
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        direction='row'
        space={2}
      >
        <FoldButtonV2
          leftIcon={<RestartSvg />}
          label={intl.formatMessage({id:'reset'})}
          onPress={handleResetToDefault}
          buttonType={ButtonType.secondary}
          borderColor={Colors.FoldPixel.GRAY250}
          flex={1}
          isLoading={loading}
          isDisabled={loading || !resetButtonEnabled}
          hoverBackgroundColor={Colors.FoldPixel.GRAY50}
        />
        <FoldButtonV2
          label={intl.formatMessage({id: 'apply'})}
          onPress={handleApplyForColumnFilter}
          buttonType={ButtonType.tertiary}
          borderColor={Colors.FoldPixel.PRIMARY300}
          flex={1}
          hoverBackgroundColor={Colors.FoldPixel.PRIMARY300}
          hoverTextColor={Colors.Custom.White}
          isDisabled={loading || !isColumnChanged()}
        />
      </Stack>
    </VStack>
  );
};

export default ColumnFilterDropdown;