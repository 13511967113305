import React from 'react';
import {
  Form,
  Input,
  DatePicker,
  Radio,
  Select,
  InputNumber,
  Skeleton,
} from 'antd';
import {ScrollView, View} from 'react-native';
import {Text} from 'native-base';
import Stack from '../../../../../../common/LayoutComponents/Stack';
import {DATE_FORMATS} from '../../../../../../../constants/StringConst';
import {antStyle, styles} from './DischargeDetailStyle';
import './DischargeDetailsView.css';
import {useIntl} from 'react-intl';
import {getMomentObj} from '../../../../../../../utils/DateUtils';
import {ADMISSION_TYPE} from '../../../../FormsConstants';
import {
  IDischargeDetailsFormProps, Value, dischargeDispositionOptions
} from './interface';
import { isSourceFromAdtEvent } from './DischargeDetailsFormUtils';
import { useDischargeDetailsView } from './useDischargeDeatilsView';
import { Colors } from '../../../../../../../styles/Colors';
import { ContactCareProgramReducerAction } from '../../../../../ContactCareProgram/ContactCareProgramView/reducer';
import { CARE_PROGRAM_EVENTS } from '../../../../../ContactCareProgram/useCPEventhandler';
import { CareProgramComplexityCode } from '../../../../../../../constants/MlovConst';

const DischargeDetailsView: React.FC<IDischargeDetailsFormProps> = (props) => {
  const intl = useIntl();
  const { Option } = Select;
  const {
    componentValue,
    componentState,
    updateDischargeDetails,
    validateData,
    debouncedSearchDiagnosisCodes,
    onSearchDiagnosisCodes,
    handleRadioChange,
    handleDispostionChange,
    handleComplexityChange
  } = useDischargeDetailsView({
    component: props?.component,
    onChange: props?.onChange,
    contactCareProgramId: props?.contactCareProgramId || '',
    componentSelectedValue: props?.component?.selectedValue,
    handleUpdateCareProgram: props?.handleUpdateCareProgram
  });

  props.validateRef.current = validateData;


  const getDiagnosisOptions = React.useCallback((diagnosisOptions: any[]) => {
    return (
      diagnosisOptions?.map((option) => (
        <Option key={option.value} value={`${option.label} (${option.value})`}>
          {`${option.label} (${option.value})`}
        </Option>
      )) || []
    );
  }, []);

  return (
    <ScrollView style={styles.scrollView}>
      {componentState.loadingStatus === 'loading' ? (
        <div className="flex flex-col">
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
      ) : (
        <Form layout="vertical">
          <View>
            <Text style={styles.sectionTitle}>
              {intl.formatMessage({id: 'dischargeDetails'})}
            </Text>
          </View>

          <Stack direction="row" style={styles.threeColumnGrid}>
            <Form.Item
              label={
                <Text size={'smRegular'} style={styles.label}>
                  {intl.formatMessage({id: 'admissionDate'})}
                </Text>
              }
              style={antStyle.formMultiItem}
            >
              <DatePicker
                className="adt-event-date-picker"
                style={antStyle.datePicker}
                placeholder={intl.formatMessage({ id: 'selectDatePlaceholder' })}
                disabled={isSourceFromAdtEvent(
                  componentValue.dischargeDetails.admissionDate?.source
                ) || props.disableForm}
                format={DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT}
                showTime={false}
                allowClear
                inputReadOnly={true}
                value={
                  componentValue.dischargeDetails.admissionDate?.value
                    ? getMomentObj(
                      componentValue.dischargeDetails.admissionDate.value
                    )
                    : undefined
                }
                disabledDate={(current: moment.Moment): boolean => {
                  if (!current) return false;                  
                  const today = getMomentObj(new Date());
                  const dischargeDateValue = componentValue.dischargeDetails.dischargeDate?.value;
                  const dischargeDate = dischargeDateValue
                    ? getMomentObj(dischargeDateValue)
                    : null;
                  if (current > today) return true;
                  if (dischargeDate && current > dischargeDate) return true;
                  
                  return false;
                }}
                onChange={(dates: any, formatStrings: string) => {
                  if (formatStrings && formatStrings.length > 1) {
                    updateDischargeDetails('admissionDate', formatStrings);
                  }
                }}
              />
            </Form.Item>

            <Form.Item
              label={
                <Text size={'smRegular'} style={styles.label}>
                  {intl.formatMessage({id: 'dischargeDate'})}
                </Text>
              }
              style={antStyle.formMultiItem}
            >
              <DatePicker
                className="adt-event-date-picker"
                style={antStyle.datePicker}
                placeholder={intl.formatMessage({id: 'selectDatePlaceholder'})}
                disabled={isSourceFromAdtEvent(
                  componentValue.dischargeDetails.dischargeDate?.source
                ) || props.disableForm}
                format={DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT}
                showTime={false}
                allowClear
                disabledDate={(current: moment.Moment): boolean => {
                  if (!current) return false;
                  
                  const today = getMomentObj(new Date());
                  const admissionDateValue = componentValue.dischargeDetails.admissionDate?.value;
                  const admissionDate = admissionDateValue
                    ? getMomentObj(admissionDateValue)
                    : null;

                  if (current > today) return true;
                  if (admissionDate && current < admissionDate) return true;
                  
                  return false;
                }}
                inputReadOnly={true}
                value={
                  componentValue.dischargeDetails.dischargeDate?.value
                    ? getMomentObj(
                      componentValue.dischargeDetails.dischargeDate.value
                    )
                    : undefined
                }
                onChange={(dates: any, formatStrings: string) => {
                  if (formatStrings && formatStrings.length > 1) {
                    updateDischargeDetails('dischargeDate', formatStrings);
                  }
                }}
              />
            </Form.Item>

            <Form.Item
              label={
                <Text size={'smRegular'} style={styles.label}>
                  {intl.formatMessage({id: 'lengthOfStay'})}
                </Text>
              }
              style={antStyle.formMultiItem}
            >
              <Input.Group
                compact
                className="adt-event-form-input-group"
                style={antStyle.inputGroup}
              >
                <InputNumber
                  className="adt-event-form-input-number"
                  value={componentValue.dischargeDetails.lengthOfStay?.value}
                  placeholder="Enter Length of Stay"
                  style={antStyle.inputNumber}
                  min={0}
                  precision={0}
                  disabled={true}
                />
                <Select
                  value={componentValue.dischargeDetails.lengthOfStayUnit}
                  className="adt-event-form-select"
                  style={antStyle.daysSelect}
                  disabled={true}
                  onChange={(value) => {
                    updateDischargeDetails('lengthOfStayUnit', value);
                  }}
                >
                  <Select.Option value="Hours">Hours</Select.Option>
                </Select>
              </Input.Group>
            </Form.Item>
          </Stack>
          <Form.Item
            label={
              <Text size={'smRegular'} style={styles.label}>
                {intl.formatMessage({id: 'dischargeDisposition'})}
              </Text>
            }
            style={antStyle.formItem}
          >
            <Select
              value={componentValue.dischargeDetails.selectedDischargeDispostionOption}
              onChange={handleDispostionChange}
              disabled={isSourceFromAdtEvent(
                componentValue?.dischargeDetails?.dischargeDisposition?.source || ''
              ) || props.disableForm}
              style={antStyle.selectDischargeDisposition}
              placeholder={intl.formatMessage({ id: 'selectDischargeDisposition' })}
            >
              {dischargeDispositionOptions.map((option: any) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>

            {componentValue?.dischargeDetails?.selectedDischargeDispostionOption === Value.OTHER && (
              <Input.TextArea
                className="adt-event-form-input"
                placeholder={intl.formatMessage({
                  id: 'pleaseAddAnySpecificReason',
                })}
                value={
                  componentValue.dischargeDetails.dischargeDisposition?.value || ''
                }
                onChange={(e) => {
                  updateDischargeDetails(
                    'dischargeDisposition',
                    e.target.value || ''
                  )
                }
                }
                style={antStyle.textArea}
              />
            )}
          </Form.Item>

          <Form.Item
            label={
              <Text size={'smRegular'} style={styles.label}>
                {intl.formatMessage({id: 'admissionType'})}
              </Text>
            }
            style={antStyle.formItem}
          >
            <Radio.Group
              value={
                componentValue?.dischargeDetails?.admissionType?.value || undefined
              }
              disabled={isSourceFromAdtEvent(
                componentValue?.dischargeDetails?.admissionType?.source || ''
              ) || props.disableForm}
              style={antStyle.radioGroup}
            >
              <Radio value={ADMISSION_TYPE.OBSERVATION} onClick={handleRadioChange}>
                <Text style={styles.radioLabel}>
                  {intl.formatMessage({id: 'observationStay'})}
                </Text>
              </Radio>
              <Radio value={ADMISSION_TYPE.READMISSION} onClick={handleRadioChange}>
                <Text style={styles.radioLabel}>
                  {intl.formatMessage({id: 'readmission'})}
                </Text>
              </Radio>
            </Radio.Group>
          </Form.Item>

          {/* <Form.Item
            label={
              <Text style={styles.label}>
                {intl.formatMessage({id: 'predictiveReAdmissionRiskScore'})}
              </Text>
            }
            style={antStyle.formItem}
          >
            <Input
              className="adt-event-form-input"
              type="number"
              value={
                componentValue.dischargeDetails.predictiveReAdmissionRiskScore
                  ?.value || ''
              }
              disabled={isSourceFromAdtEvent(
                componentValue?.dischargeDetails?.predictiveReAdmissionRiskScore
                  ?.source || ''
              )}
              style={{
                ...antStyle.input,
                WebkitAppearance: 'none',
                MozAppearance: 'textfield',
              }}
              placeholder={intl.formatMessage({id: 'enterRiskScore'})}
              onChange={(e) =>
                updateDischargeDetails(
                  'predictiveReAdmissionRiskScore',
                  e.target.value
                )
              }
            />
          </Form.Item> */}

          <Form.Item
            label={
              <Text size={'smRegular'} style={styles.label}>
                {intl.formatMessage({id: 'hospitalName'})}
              </Text>
            }
            style={antStyle.formItem}
          >
            <Input
              className="adt-event-form-input"
              value={componentValue.dischargeDetails.hospitalName?.value || ''}
              placeholder={intl.formatMessage({id: 'enterHospitalName'})}
              style={antStyle.input}
              disabled={isSourceFromAdtEvent(
                componentValue?.dischargeDetails?.hospitalName?.source || ''
              ) || props.disableForm}
              onChange={(e) =>
                updateDischargeDetails('hospitalName', e?.target?.value)
              }
            />
          </Form.Item>

          <Form.Item
            label={
              <Text size={'smRegular'} style={styles.label}>
                {intl.formatMessage({id: 'primaryDiagnosis'})}
              </Text>
            }
            style={antStyle.formItem}
          >
            <Select
              showSearch
              allowClear
              placeholder={intl.formatMessage({id: 'selectDiagnosis'})}
              maxTagCount="responsive"
              style={antStyle.select}
              className="custom-select-tags"
              filterOption={false}
              onSearch={(value) => {
                debouncedSearchDiagnosisCodes(value, 'primaryDiagnosisOptions');
              }}
              onBlur={() => {
                onSearchDiagnosisCodes('', 'primaryDiagnosisOptions');
              }}
              value={
                componentValue.dischargeDetails.primaryDiagnosis?.value || []
              }
              disabled={isSourceFromAdtEvent(
                componentValue?.dischargeDetails?.primaryDiagnosis?.source || ''
              ) || props.disableForm}
              onChange={(e) => updateDischargeDetails('primaryDiagnosis', e)}
              notFoundContent={
                componentState.diagnosisCodesLoading ? (
                  <Text color={Colors.Custom.Gray500}>Loading...</Text>
                ) : (
                  <Text color={Colors.Custom.Gray500}>
                    No diagnosis codes found
                  </Text>
                )
              }
            >
              {getDiagnosisOptions(componentState.primaryDiagnosisOptions)}
            </Select>
          </Form.Item>

          <Form.Item
            label={
              <Text size={'smRegular'} style={styles.label}>
                {intl.formatMessage({id: 'dischargeDiagnosis'})}
              </Text>
            }
            style={antStyle.formItem}
          >
            <Select
              mode="multiple"
              showSearch
              allowClear
              placeholder={intl.formatMessage({id: 'selectDiagnosis'})}
              maxTagCount="responsive"
              style={antStyle.select}
              className="custom-select-tags"
              filterOption={false}
              onSearch={(value) =>
                debouncedSearchDiagnosisCodes(
                  value,
                  'dischargeDiagnosisOptions'
                )
              }
              onBlur={() => {
                onSearchDiagnosisCodes('', 'dischargeDiagnosisOptions');
              }}
              value={
                componentValue.dischargeDetails.dischargeDiagnosis?.value || []
              }
              disabled={isSourceFromAdtEvent(
                componentValue?.dischargeDetails?.dischargeDiagnosis?.source ||
                  ''
              ) || props.disableForm}
              onChange={(e) => updateDischargeDetails('dischargeDiagnosis', e)}
              notFoundContent={
                componentState.diagnosisCodesLoading ? (
                  <Text color={Colors.Custom.Gray500}>Loading...</Text>
                ) : (
                  <Text color={Colors.Custom.Gray500}>
                    No diagnosis codes found
                  </Text>
                )
              }
            >
              {getDiagnosisOptions(componentState.dischargeDiagnosisOptions)}
            </Select>
          </Form.Item>

          <Form.Item
            label={
              <Text style={styles.label}>
                {intl.formatMessage({ id: 'typeOfCare' })}
              </Text>
            }
            style={antStyle.formItem}
          >
            <Radio.Group
              value={
                componentState.complexity
              }
              disabled={isSourceFromAdtEvent(
                componentValue?.dischargeDetails?.admissionType?.source || ''
              ) || props.disableForm}
              style={antStyle.radioGroup}
              onChange={(e) => {
                handleComplexityChange(e.target.value)
              }}
            >
              <Radio value={CareProgramComplexityCode.COMPLEX}>
                <Text style={styles.radioLabel}>
                  {intl.formatMessage({ id: 'complex' })}
                </Text>
              </Radio>
              <Radio value={CareProgramComplexityCode.SIMPLE}>
                <Text style={styles.radioLabel}>
                {intl.formatMessage({ id: 'moderate' })}
                </Text>
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      )}
    </ScrollView>
  );
};

export default React.memo(DischargeDetailsView);
