import {AxiosResponse} from 'axios';
import BaseService from '../../../../services/CommonService/BaseService';
import {MEMBER_STATUS_PATH} from './MemberStatusApiPath';
import {
  ICreateMemberStatusHistoryParams,
  ICreateMemberStatusParams,
  IGetMemberStatusParams,
  IGetMemberStatusResponse,
  IMemberStatusResponse,
} from './interface';

export const getMemberStatusData = async (
  params: IGetMemberStatusParams
): Promise<AxiosResponse<IGetMemberStatusResponse>> => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.get(MEMBER_STATUS_PATH.MEMBER_STATUS, {
    params: params,
  });
  return response;
};

export const createMemberStatus = async (
  createMemberStatusPayload: ICreateMemberStatusParams
): Promise<AxiosResponse<IMemberStatusResponse>> => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.post(
    MEMBER_STATUS_PATH.MEMBER_STATUS,
    createMemberStatusPayload?.body
  );
  return response;
};

export const updateMemberStatus = async (
  updateMemberStatusPayload: ICreateMemberStatusParams
): Promise<AxiosResponse<IMemberStatusResponse>> => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.put(
    MEMBER_STATUS_PATH.MEMBER_STATUS + '/' + updateMemberStatusPayload?.id,
    updateMemberStatusPayload?.body
  );
  return response;
};

export const deleteMemberStatus = async (
  id: string
): Promise<AxiosResponse<IMemberStatusResponse>> => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.delete(
    MEMBER_STATUS_PATH.MEMBER_STATUS + '/' + id
  );
  return response;
};

export const updateContactStatus = async (
  createMemberStatusPayload: ICreateMemberStatusHistoryParams
): Promise<AxiosResponse<IMemberStatusResponse>> => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.put(
    MEMBER_STATUS_PATH.CONTACT_STATUS +
      '/' +
      createMemberStatusPayload.body?.contactUuid +
      '/status',
    createMemberStatusPayload?.body
  );
  return response;
};
