import { IMlov, IUser } from '../../../../Interfaces';
import {DATE_FORMATS, MLOV_CATEGORY} from '../../../../constants';
import { CARE_PROGRAM_OUTREACH_MODE_CODES } from '../../../../constants/MlovConst';
import {getFormattedDate} from '../../../../utils/DateUtils';
import {
  getMlovListFromCategory,
  getMlovObjectFromId,
} from '../../../../utils/mlovUtils';
import { OUTREACH_MODE_DISPLAY_NAME } from '../../../common/MemebersView/constant';
import {
  IContactOutreachActivity,
  IFormattedContactOutreachActivity,
} from './interfaces';

export const getUserName = (
  typeId: string,
  outreachModeMlov: IMlov[],
  user?: IUser
): string => {
  const outreachMode = outreachModeMlov.find(mlov => mlov.id === typeId);
  if (outreachMode?.code === CARE_PROGRAM_OUTREACH_MODE_CODES.MANUAL) {
    return user?.name || OUTREACH_MODE_DISPLAY_NAME.AUTOMATION;
  }
  else if (outreachMode?.code === CARE_PROGRAM_OUTREACH_MODE_CODES.AUTOMATION) {
    return OUTREACH_MODE_DISPLAY_NAME.AUTOMATION;
  }
  else {
    return OUTREACH_MODE_DISPLAY_NAME.AUTOMATION;
  }
};

export const formatOutreachData = (
  mlovData: any,
  outreachData: IContactOutreachActivity[],
  userMap: Record<string, IUser>
) => {

  const outreachModeMlov =
  getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_PROGRAM_OUTREACH_MODE
  ) || [];
  const outreachCommunicationModes =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CONTACT_OUTREACH_COMMUNICATION_MODE
    ) || [];

  const outreachOutComes = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.OUT_REACH_STEP_OUTCOMES
  );


  const formattedOutreachActiities: IFormattedContactOutreachActivity[] = [];

  outreachData?.forEach((outreachData) => {
    const user = userMap[outreachData.createdBy]
    const outreachMode = outreachModeMlov.find(mlov => mlov.id === outreachData.typeId);
    const isAutomatedOutreach = outreachMode?.code === CARE_PROGRAM_OUTREACH_MODE_CODES.AUTOMATION;

    const outreachCommunicationMlov = getMlovObjectFromId(
      outreachData.communicationModeId,
      outreachCommunicationModes
    );

    const outreachOutcomeMlov = getMlovObjectFromId(
      outreachData.outcomeId,
      outreachOutComes
    ) as IMlov;

    const formattedDate = getFormattedDate(
      outreachData.outreachDateTime,
      DATE_FORMATS.BILLING_ACTIVITY_DATE
    );

    const formattedOutreachData: IFormattedContactOutreachActivity = {
      communicationMode: outreachCommunicationMlov?.code || '',
      outcomeText: outreachOutcomeMlov?.value || '',
      note: outreachData.note,
      formattedDate: formattedDate,
      userName: getUserName(outreachData.typeId, outreachModeMlov, user),
      outcomeMlov: outreachOutcomeMlov,
      outreachData: outreachData,
      communicationModeText: outreachCommunicationMlov?.value || '',
      id: outreachData.id,
      resourceId: outreachData.resourceId,
      isAutomatedOutreach: isAutomatedOutreach
    };

    formattedOutreachActiities.push(formattedOutreachData);
  });
  return formattedOutreachActiities;
};
