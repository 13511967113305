export interface IClinicalParametersFormProps {
  contactCareProgramId?: string;
  component: any;
  validateRef: any;
  disableForm: boolean;
  onChange: (componentValue: any, flag?: any) => void;
}

export interface ICareProgramAttributeValue {
  valueString: string;
}

export interface ICareProgramAdditionalAttribute {
  attributeKey: string;
  attributeValue: ICareProgramAttributeValue;
  attributeValueDataTypeCode: string;
  source: string;
}

export interface IClinicalParametersParams {
  contactCareProgramId: string;
  componentSelectedValue?: any;
  component: any;
  isDisabled: boolean;
  onChange: (componentValue: any, flag?: any) => void;
}

interface IClinicalParametersField<T = any> {
  value: T;
  source: string;
}

export interface IClinicalParametersFormState {
  chronicConditions: any;
  futureAppointment: IClinicalParametersField<string>;
  lastAdmissionDate: IClinicalParametersField<string>;
  lastDischargeDate: IClinicalParametersField<string>;
  lastOfficeVisitDate: IClinicalParametersField<string>;
  nextOfficeVisitDate: IClinicalParametersField<string>;
  lastERVisitDate: IClinicalParametersField<string>;
  totalAdmissionsForLast12Months: IClinicalParametersField<number>;
  totalERVisitsForLast12Months: IClinicalParametersField<number>;
  totalOutpatientVisitsForLast12Months: IClinicalParametersField<number>;
}

export interface IClinicalParametersComponentValue {
  clinicalParameters: IClinicalParametersFormState;
}

export interface IComponentState {
  loadingStatus: 'loading' | 'loaded' | 'error';
}

export const HIE_ENCOUNTER_TYPE = {
  ED_ER_VISITS: 'ED_ER_VISITS',
  IP_HOSPITAL_VISITS: 'IP_HOSPITAL_VISITS',
  OP_VISITS: 'OP_VISITS',
  HOME_CARE: 'HOME_CARE',
  OTHER: 'OTHER',
} as const;


export const CHRONIC_CONDITIONS_TABLE_COLUMNS = [
  {
    title: 'Condition',
    dataIndex: 'condition',
    key: 'condition',
  },
  {
    title: 'First Diagnosed Date',
    dataIndex: 'diagnosedDate',
    key: 'diagnosedDate',
  },
] 