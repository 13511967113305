import { View, Text, ScrollView, Pressable, StyleSheet } from 'react-native';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  CarePlanScreenContext,
  ICarePlanComponentState,
  ICarePlanDetail,
  ICarePlanProps,
} from './interfaces';
import { HStack, VStack } from 'native-base';
import { Colors } from '../../../../styles';
import Header from './components/Header';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import { useIntl } from 'react-intl';
import { BUTTON_TYPE, DATE_FORMATS } from '../../../../constants';
import Feather from 'react-native-vector-icons/Feather';
import AddOrUpdateCarePlanDetails from './components/AddOrUpdateCarePlanDetails';
import {useLazyQuery} from '@apollo/client';
import {GET_CARE_PLANS_BY_CONTACT_ID, GET_CARE_PLAN_LAST_PUBLISH_DATA, GET_CARE_PLAN_REVIEW_TASK} from '../../../../services/CarePlan/CarePlanQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {useToast} from '../../../Toast/ToastProvider';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {FHForm} from '../../../RightSideContainer/Forms/FHFormio';
import {getEnabledFormComponents} from '../../../../utils/capabilityUtils';
import {CARE_PLAN_COMPONENT_LIST, CARE_PLAN_GOAL_COMPONENT_LIST, componentKeys} from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {
  getAccountUUID,
  getFoldAccessToken,
  isLoginUserBusinessOwner,
  navigateToOtherScreen,
} from '../../../../utils/commonUtils';
import { FormViewType } from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import Stack from '../../../common/LayoutComponents/Stack';
import { GET_FORM_NAME_AND_ID, GET_FORM_RESPONSE_BY_FORM_LOG_ID } from '../../../../services/Forms/FormsQueries';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../utils/mlovUtils';
import {
  CARE_PLAN_SOURCE_TYPE_CODES,
  CARE_PLAN_STATUS_CODES,
  FORM_STATUS_CODE,
  MLOV_CATEGORY,
} from '../../../../constants/MlovConst';
import TimeLineSkeletonLoader from '../../../common/TimeLineSkeletonLoader/TimeLineSkeletonLoader';
import { forEachExtensiveFormComponent } from '../../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import { GoalViewType, IGoal } from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/Goals/AddOrUpdateGoals/interface';
import { removeDeletedFormValues } from './components/utils';
import { cloneDeep } from 'lodash';
import CarePlanAudit from './CarePlanAudit/CarePlanAudit';
import CrossIconSvg from '../../../common/Svg/CrossIconSvg';
import WriteInfoBackBanner from '../CareTimeline/components/WriteInfoBackBanner';
import { TaskQueries, UserQueries } from '../../../../services';
import {LeftOutlined} from '@ant-design/icons';
import {Button, Tooltip} from 'antd';
import ReadMsgSvg from '../../../common/Svg/ReadMsgSvg';
import ReviewChatSquareAddSvg from '../../../common/Svg/CarePlanSvgs/ReviewChatSquareAddSvg';
import PenSvg from '../../../common/Svg/PenSvg';
import ClockHistorySvg from '../../../common/Svg/ClockHistorySvg';
import { testID, TestIdentifiers } from '../../../../testUtils';
import { NoCarePlanCreatedFallback } from './components/NoCarePlanCreatedFallback';
import useEHRCapabilities from '../../../../screens/BusinessStudio/useEHRCapabilities';
import { ITask } from '../../../common/CareDashboard/CareDashboardInterfaces';
import { getActiveWorkflowNameByMasterId, getWorkflowNameByMasterIds } from '../../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { isNotPermittedToEdit } from '../../../RightSideContainer/Workflow/workflowUtils';
import { useNavigate, useParams } from 'react-router-dom';
import { FlowType } from '../../../../context/WorkflowContext';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import { navigationPathMap } from '../../../RightSideContainer/Workflow/Workflow/WorkflowTableView/NewWorkflowTableView/WorkflowConst';
import { getFormattedDate } from '../../../../utils/DateUtils';

export interface IAutomationDetails {
  name: string;
  flowType: string;
  isDeleted: boolean;
  workflowMasterId: string;
  workflowLocations: { locationId: string }[];
}

const CarePlan = (props: ICarePlanProps) => {
  const intl = useIntl();
  const isCarePlanGoalsDrawerView = props.isCarePlanGoalsDrawerView || false;
  const tenantId = getAccountUUID();
  const navigate = useNavigate();
  const {careProgramId} = useParams();
  const [componentState, setComponentState] = useState<ICarePlanComponentState>(
    {
      carePlanScreenContext: CarePlanScreenContext.ADD_CARE_PLAN,
      formResponseComponents: [],
      carePlanLoading: true,
      publishData: {
        publishedOn: '',
        publishedBy: '',
      },
      enabledCarePlanComponents: isCarePlanGoalsDrawerView ?
        CARE_PLAN_GOAL_COMPONENT_LIST :
        CARE_PLAN_COMPONENT_LIST,
      taskId: '',
      isCreatingCarePlan: false,
    }
  );
  const [carePlanSourceName, setCarePlanSourceName] = useState('');
  const [loadingCarePlanSourceName, setLoadingCarePlanSourceName] = useState(false);
  const [automationDetails, setAutomationDetails] = useState<IAutomationDetails>();
  const contextData = useContext(CommonDataContext);
  const userPracticeLocationIds = contextData?.userPracticeLocations?.map((object: any) => {
    return object?.accountLocations[0]?.uuid
  })
  const isSidecar = contextData.sidecarContext?.isSidecar;
  const ehrCapabilities = useEHRCapabilities({locationId: props.accountLocationUuid});
  const patientId =
    props.personData?.patientId || props.personData?.patientUuid;
  const foldAccessToken = getFoldAccessToken();
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const isBusinessOwner = isLoginUserBusinessOwner()
  const accountUUID = getAccountUUID();
  const [intakeOptions, setIntakeOptions] = useState({
    foldAccessToken,
    patientId,
    contactUuid: props.contactUuid,
    accountUUID,
    contactId: props.contactUuid,
    foldContactId: props.contactId,
    backgroundSaveEnabled: true,
    componentView: FormViewType.widget,
    ehrCapabilities: ehrCapabilities,
    isPreviewMode: true,
    MLOV: contextData.MLOV,
    CARE_STUDIO_MLOV: contextData.CARE_STUDIO_MLOV,
    CLOUD_TELEPHONY_MLOV: contextData.CLOUD_TELEPHONY_MLOV,
    userSettings: contextData.userSettings,
    userData: contextData.userData,
    sidecarContext: contextData.sidecarContext,
    accountLocationUuid: props.accountLocationUuid,
  });

  const toast = useToast();

  const [getCarePlan] = useLazyQuery(GET_CARE_PLANS_BY_CONTACT_ID, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  const [ getTaskById ] = useLazyQuery(TaskQueries.GET_TASK_BY_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [getFormLogNameAndId, {loading: loadingCarePlanAssessmentFormName}] = useLazyQuery(GET_FORM_NAME_AND_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onError: (error: any) => {
      toast({
        message: intl.formatMessage({
          id: 'carePlanAssessmentFormFetchError',
        }),
        toastType: ToastType.error,
      });
    },
  });


  const [getCarePlanLastPublishData] = useLazyQuery(GET_CARE_PLAN_LAST_PUBLISH_DATA, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });
  const [getCarePlanTask] = useLazyQuery(GET_CARE_PLAN_REVIEW_TASK, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const [getFormResponse] = useLazyQuery(GET_FORM_RESPONSE_BY_FORM_LOG_ID, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });
  
  const [getUsersByUuid] = useLazyQuery(UserQueries.GET_USER_BY_UUID_LIST, {
    fetchPolicy: 'no-cache',
  });
  
  const carePlanStatusMlovs =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV || {},
      MLOV_CATEGORY.CARE_PLAN_STATUS
    ) || [];

  const carePlanSourceTypeMlovs = getMlovListFromCategory(
    contextData.CARE_STUDIO_MLOV || {},
    MLOV_CATEGORY.CARE_PLAN_SOURCE
  ) || [];

  const draftCarePlanStatusId = getMlovIdFromCode(
    carePlanStatusMlovs,
    CARE_PLAN_STATUS_CODES.DRAFT
  );

  const activeCarePlanStatusId = getMlovIdFromCode(
    carePlanStatusMlovs,
    CARE_PLAN_STATUS_CODES.ACTIVE,
  );

  const formStatusList =
  getMlovListFromCategory(
    contextData.CARE_STUDIO_MLOV || {},
    MLOV_CATEGORY.FORM_STATUS
  ) || [];

  const submittedformStatusId = getMlovIdFromCode(
    formStatusList,
    FORM_STATUS_CODE.SUBMITTED
  );

  const inReviewCarePlanStatusId = getMlovIdFromCode(
    carePlanStatusMlovs,
    CARE_PLAN_STATUS_CODES.IN_REVIEW,
  );

  const automationSourceTypeId = getMlovIdFromCode(
    carePlanSourceTypeMlovs,
    CARE_PLAN_SOURCE_TYPE_CODES.AUTOMATION
  );

  useEffect(() => {
    getCarePlanDetails();
  }, []);


  const fetchPublishData = async () => {
    try {
      if (componentState?.carePlanDetails?.statusId !== activeCarePlanStatusId) {
        return;
      }

      const response = await getCarePlanLastPublishData({
        variables: {
          carePlanId: componentState?.carePlanDetails?.id
        }
      });

      const publishInfo = response?.data?.activityLogs?.[0];
      if (!publishInfo) {
        return;
      }

      const usersResponse = await getUsersByUuid({
        variables: {
          uuidList: [publishInfo.performedById]
        }
      });

      setComponentState((prev) => ({
        ...prev,
        publishData: {
          publishedOn: publishInfo.timestamp,
          publishedBy: publishInfo.performedByTypeCode === 'USER' 
            ? usersResponse?.data?.users?.[0]?.name : 'Automation'
        }
      }));
    } catch (error) {
    }
  };

  useEffect(() => { 
    fetchPublishData();
  }, [
    componentState?.carePlanDetails?.statusId,
    componentState?.carePlanDetails?.id,
  ]);

  const getCarePlanDetails = async () => {
    setComponentState((prev) => ({
      ...prev,
      carePlanLoading: true,
    }));

    try {
      const carePlanResponsePromise = getCarePlan({
        variables: {
          contactId: props.contactUuid,
          contactCareProgramId: careProgramId,
        },
      });

      const variables = getVariablesForCarePlanAssessmentForm();
      const formLogNameAndIdPromise = getFormLogNameAndId({
        variables: variables,
      });

      const [carePlanResponse, formLogNameAndIdResponse] = await Promise.all([
        carePlanResponsePromise,
        formLogNameAndIdPromise,
      ]);

      const carePlans = carePlanResponse.data?.getContactCarePlans?.carePlans;
      if (carePlans?.length > 0) {
        getFormResponseData(carePlans[0]);
        let carePlanRevTaskId = '';
        if (carePlans[0].statusId === inReviewCarePlanStatusId) {
          const carePlanTaskResponse = await getCarePlanTask({
            variables: {
              carePlanId: carePlans[0].id,
            },
          });
          carePlanRevTaskId = carePlanTaskResponse.data?.resourceMappings?.[0]?.resourceId as string;
        }
        let carePlanTask: ITask = {} as ITask;
        if (carePlanRevTaskId) {
          const carePlanTaskbyIdResponse = await getTaskById({
            variables: {
              id: carePlanRevTaskId,
            },
          });
          carePlanTask = carePlanTaskbyIdResponse.data?.getTasks?.tasks?.[0];
        }
        setComponentState((prev) => ({
          ...prev,
          carePlanDetails: carePlans[0],
          taskId: carePlanRevTaskId,
          submittedCarePlanAssessmentForm: {
            submittedCarePlanAssessmentFormId:
              formLogNameAndIdResponse.data?.formResponses?.[0]?.formId,
            submittedCarePlanAssessmentFormName:
              formLogNameAndIdResponse.data?.formResponses?.[0]?.form?.name,
          },
          ...(carePlanTask?.id && {carePlanTask: carePlanTask}),
        }));
      } else {
        setComponentState((prev) => ({
          ...prev,
          carePlanLoading: false,
          submittedCarePlanAssessmentForm: {
            submittedCarePlanAssessmentFormId:
              formLogNameAndIdResponse.data?.formResponses?.[0]?.formId,
            submittedCarePlanAssessmentFormName:
              formLogNameAndIdResponse.data?.formResponses?.[0]?.form?.name,
          },
        }));
      }
    } catch (error) {
      setComponentState((prev) => ({
        ...prev,
        carePlanLoading: false,
      }));
      toast({
        message: intl.formatMessage({
          id: 'apiErrorMsg',
        }),
        toastType: ToastType.error,
      });
    }
  };

  const getFormResponseData = async (carePlanDetails: ICarePlanDetail) => {
    try {
      const response = await getFormResponse({
        variables: {
          formLogId: carePlanDetails.formLogId,
        },
      });
      const formResponses =
        response.data.formLogs?.[0].formResponse?.formResponse;

      const carePlanScreenContext =
        [draftCarePlanStatusId, inReviewCarePlanStatusId].includes(carePlanDetails.statusId)
          ? CarePlanScreenContext.EDIT_CARE_PLAN
          : CarePlanScreenContext.CARE_PLAN_LISTING;

      const enabledCarePlanComponents = componentState?.enabledCarePlanComponents;

      enabledCarePlanComponents.forEach((enabledCarePlanComponent) => {
        if (enabledCarePlanComponent.key === componentKeys.GOALS) {
          enabledCarePlanComponent.selectedValue = {
            goals: carePlanDetails.goals?.map((goal) => {
              goal.viewType = GoalViewType.list;
              return goal
            }) || [],
            total: carePlanDetails.goals?.length || 0
          }
        }

        if (enabledCarePlanComponent.key === componentKeys.BARRIERS) {
          enabledCarePlanComponent.selectedValue = {
            barriers: carePlanDetails.barriers || [],
          }
        }

        if (enabledCarePlanComponent.key === componentKeys.INTERVENTION) {
          const intervention = carePlanDetails.interventions || [];
          const updatedInterventions = intervention.map((item: any) => {
            return {
              ...item,
              ...item.intervention,
              carePlanInterventionId: item?.id,
              startDateTime: carePlanDetails?.startDateTime
            };
          })
          enabledCarePlanComponent.selectedValue = {
            interventions: updatedInterventions || [],
          }
        }
      })

      const intervention = isCarePlanGoalsDrawerView ? [] : carePlanDetails?.interventions || [];

      const newInterventions = intervention.map((item: any) => {
        return {
          ...item,
          intervention: {
            ...item.intervention,
            status: item.status,
            carePlanInterventionId: item?.id
          },
          carePlanInterventionId: item?.id,
          startDateTime: carePlanDetails.startDateTime
        };
      })
      const data: any = {};
      const goalsFromResponse: IGoal[] = [];
      // status updated in form response
      forEachExtensiveFormComponent(formResponses, (item: any) => {
        data[item.key] = item.selectedValue;
        const shouldUpdateFormResponseForIntervention = !isCarePlanGoalsDrawerView &&
          ((item.type === componentKeys.INTERVENTION && item.selectedValue.interventions.length > 0) ||
          (item.type !== componentKeys.INTERVENTION && item?.components?.length > 0))
        if (shouldUpdateFormResponseForIntervention) {
                if (item.type === componentKeys.INTERVENTION && item.selectedValue.interventions.length > 0) {
                  item.selectedValue.interventions.forEach((oldInt: any) => {
                        const matchedIntervention = newInterventions.find((newInt: any) => newInt.intervention.id === oldInt.id);
                        if (matchedIntervention) {
                            oldInt.status = matchedIntervention.status;
                            oldInt.carePlanInterventionId = matchedIntervention.carePlanInterventionId;
                            oldInt.startDateTime = matchedIntervention.startDateTime;
                        }
                    });
                }
        } else if (item.type === componentKeys.GOALS && item.selectedValue?.goals?.length > 0) {
          item.selectedValue.goals.forEach((goal: IGoal) => {
            if (!goal.isDeleted) {
              goalsFromResponse.push(goal);
            }
          });
        }
    });

    enabledCarePlanComponents.forEach((enabledCarePlanComponent) => {
      if (enabledCarePlanComponent.key === componentKeys.GOALS) {
        enabledCarePlanComponent.selectedValue?.goals?.forEach((goal: IGoal) => {
          const goalResponse = goalsFromResponse.find((item: IGoal) => item.id === goal.id);
          if ((goalResponse?.contactRules?.length || 0) > 0) {
            goal.contactRules = goalResponse?.contactRules || [];
          }
        });
      }
    })

      setComponentState((prev) => ({
        ...prev,
        formResponseComponents: removeDeletedFormValues(formResponses) || [],
        carePlanScreenContext: carePlanScreenContext,
        enabledCarePlanComponents: enabledCarePlanComponents,
        carePlanLoading: false,
        submittedResponse: { data },
        isCreatingCarePlan: false,
      }));
    } catch (error) {
      setComponentState((prev) => ({
        ...prev,
        carePlanLoading: false,
        isCreatingCarePlan: false,
      }));
      toast({
        message: intl.formatMessage({
          id: 'apiErrorMsg',
        }),
        toastType: ToastType.error,
      });
    }
  };

  const getCarePlanSourceName = async () => {
    const sourceId = componentState?.carePlanDetails?.sourceId;
    const sourceTypeId = componentState?.carePlanDetails?.sourceTypeId;
    if (sourceTypeId === automationSourceTypeId && sourceId) {
      setLoadingCarePlanSourceName(true);
      const workflowList = await getActiveWorkflowNameByMasterId(
        sourceId,
        tenantId
      );
      setCarePlanSourceName(workflowList?.data?.workflows?.[0]?.name || '');
      setAutomationDetails(workflowList?.data?.workflows?.[0]);
      setLoadingCarePlanSourceName(false);
    } else {
      setLoadingCarePlanSourceName(true);
      const usersResponse = await getUsersByUuid({
        variables: {
          uuidList: [componentState?.carePlanDetails?.createdBy]
        }
      })
      setCarePlanSourceName(usersResponse.data?.users?.[0]?.name || '');
      setLoadingCarePlanSourceName(false);
    }
  }
  
  const onAutomationNavigation = () => {
    if (!automationDetails) {
      return;
    }
    const navigationPath = navigationPathMap[automationDetails.flowType] || navigationPathMap[FlowType.form];
    let isNotPermitted = false
    if (isMsoEnabled && !isBusinessOwner) {
      isNotPermitted = isNotPermittedToEdit(automationDetails?.workflowLocations, userPracticeLocationIds || [])
    }
    if (isNotPermitted) {
      showToast(
        toast,
        intl.formatMessage({ id: 'isNotPermittedEditMessage' }),
        ToastType.error,
        3000
      );
    }
    navigateToOtherScreen(navigate, navigationPath +
      '/update' +
      '/' +
      automationDetails.workflowMasterId +
      '?flowType=' +
      automationDetails.flowType,
      true
    )
  }

  const getVariablesForCarePlanAssessmentForm = () => {
    return {
      where: {
        isDeleted: {
          _eq: false,
        },
        contactId: {
          _eq: props?.contactUuid,
        },
        form: {
          formCategory: {
            code: {
              _eq: 'CARE_PLAN_ASSESSMENT',
            },
          },
        },
        formLog: {
          statusId: {
            _eq: submittedformStatusId,
          },
          isDeleted: {
            _eq: false,
          },
          contactId: {
            _eq: props?.contactUuid,
          },
        },
      },
    };
  }

  const getCarePlanTaskDetails = async (taskId: string) => {
    const taskDetails = await getTaskById({
      variables: {
        id: taskId,
      },
    });
    setComponentState((prev) => ({
      ...prev,
      ...(taskDetails?.data?.getTasks?.tasks?.[0] && {
        carePlanTask: taskDetails?.data?.getTasks?.tasks?.[0],
      }),
    }));
  };

  const getHeaderActions = () => {
    if (
      componentState.carePlanScreenContext ===
      CarePlanScreenContext.ADD_CARE_PLAN
    ) {
      return (
        <FoldButton
          customProps={{
            btnText: intl.formatMessage({ id: 'createCarePlan' }),
            textColor: Colors.Custom.Primary300,
          }}
          nativeProps={{
            leftIcon: (
              <Feather
                name="plus"
                color={Colors.Custom.mainPrimaryPurple}
                size={16}
              />
            ),
            backgroundColor: Colors.Custom.PrimaryColor100,
            borderColor: Colors.Custom.Primary200,
            variant: BUTTON_TYPE.PRIMARY,
            height: '32px',
            borderRadius: 4,
            borderWidth: 0.5,
            fontSize: 14,
            fontWeight: '500',
            onPress: () => {
              setComponentState((prev) => ({
                ...prev,
                isCreatingCarePlan: true,
                carePlanScreenContext: CarePlanScreenContext.EDIT_CARE_PLAN,
              }));
            },
          }}
        />
      );
    } else if (componentState.carePlanScreenContext === CarePlanScreenContext.CARE_PLAN_LISTING) {
      return (
        <Stack direction="row" space={16}>
          <Tooltip title={intl.formatMessage({id: 'editCarePlan' })}>
            <Pressable
              onPress={() => {
                setComponentState((prev) => ({
                  ...prev,
                  isCreatingCarePlan: false,
                  carePlanScreenContext: CarePlanScreenContext.EDIT_CARE_PLAN,
                }));
              }}
              {...testID('CarePlanEditIcon')}
            >
              <PenSvg customStrokeColor={Colors.Custom.GRAY350} size={18} />
            </Pressable>
          </Tooltip>
          <View style={styles.separator} />
          <Tooltip title={intl.formatMessage({id: 'history' })}>
            <Pressable
              onPress={() => {
                setComponentState((prev) => ({
                  ...prev,
                  carePlanScreenContext: CarePlanScreenContext.CARE_PLAN_AUDIT,
                  carePlanDiff: {
                    old: [],
                    new: [],
                  }
                }));
              }}
              {...testID('CarePlanHistoryIcon')}
            >
              <ClockHistorySvg size={18}/>
            </Pressable>
          </Tooltip>
        </Stack>
      );
    } else if (componentState.carePlanScreenContext === CarePlanScreenContext.CARE_PLAN_AUDIT) {
      return (
        <Pressable onPress={() => {
          setComponentState((prev) => ({
            ...prev,
            carePlanScreenContext:
              CarePlanScreenContext.CARE_PLAN_LISTING,
          }));
        }}>
          <CrossIconSvg
            size={18}
          />
        </Pressable>
      );
    }


  };
  const isInReviewed = componentState.carePlanDetails?.statusId === inReviewCarePlanStatusId;
  const getCarePlanTitleBasedOnStatus = (statusId?: string) => {
    if (isInReviewed)  {
      return (
        <HStack alignItems={'center'}>
          <ReviewChatSquareAddSvg size={14} customStrokeColor={Colors.Custom.orangeMessageTopBar} />
          <Text style={styles.inReviewText}>
            {intl.formatMessage({id: 'inReview'})}
          </Text>
          {componentState?.carePlanTask?.assigneeUser?.name ? (
              <Text style={styles.assigneeText}>
                • {componentState?.carePlanTask?.assigneeUser?.name}
              </Text>
            ) : (
              <></>
            )
          }
        </HStack>
      );
    }
    return (
      <HStack alignItems={'center'}>
        <ReadMsgSvg />
        <Text style={styles.assignedToPatientText}>
          {intl.formatMessage({id: 'assignedToPatient'})}
        </Text>
        {componentState?.publishData && (
          <Text  style={styles.assignedOnText}>{` • ${componentState?.publishData?.publishedBy} • Assigned on: ${getFormattedDate(componentState?.publishData?.publishedOn, DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT)}`}</Text>
        )}
      </HStack>
    )
  }

  const getCarePlanListingView = () => {
    return (
      <Stack direction="column" style={styles.fullFlex}>
        {!isCarePlanGoalsDrawerView && (
          <Header
            prefixAction={
              isSidecar || props?.isCompactView ? (
                <Button onClick={() =>  props?.onClose?.(false)} type="text" icon={<LeftOutlined />} {...(testID("carePlanDetailsCloseBtn"))} />
              ) : undefined
            }
            context={componentState.carePlanScreenContext}
            headerAction={getHeaderActions()}
            title={intl.formatMessage({id: 'carePlanDetails'})}
            titleFontSize={16}
            subTitle={getCarePlanTitleBasedOnStatus(
              componentState?.carePlanDetails?.statusId
            )}
            subTitleStyle={{
              color: isInReviewed
                ? Colors.Custom.orangeMessageTopBar
                : Colors.Custom.Gray500,
            }}
            carePlanSourceName={carePlanSourceName}
            disableAutomationNavigation={automationDetails?.isDeleted ?? false}
            loadingCarePlanSourceName={loadingCarePlanSourceName}
            getCarePlanSourceName={getCarePlanSourceName}
            carePlanCreatedOn={componentState?.carePlanDetails?.createdOn || ''}
            carePlanSourceTypeId={componentState?.carePlanDetails?.sourceTypeId || ''}
            automationSourceTypeId={automationSourceTypeId}
            sourceId={componentState?.carePlanDetails?.sourceId}
            onAutomationNavigation={onAutomationNavigation}
          />
        )}
        <ScrollView
          style={{
            padding: isCarePlanGoalsDrawerView ? 0 : 16,
            marginTop: isCarePlanGoalsDrawerView ? 16 : 0,
            height: props?.isCompactView ? 600 : undefined
          }}
        >
        {!!componentState?.submittedCarePlanAssessmentForm
            ?.submittedCarePlanAssessmentFormId &&
            !isCarePlanGoalsDrawerView && (
            <WriteInfoBackBanner
              message={intl.formatMessage({
                id: 'assessmentFormSubmittedInstruction',
              })}
            />
          )}
          <FHForm
            optionData={intakeOptions}
            components={componentState.enabledCarePlanComponents}
            readOnly={true}
          />
        </ScrollView>
      </Stack>
    );
  }

  const renderMainViewByScreenContext = () => {
    if (isCarePlanGoalsDrawerView) {
      return getCarePlanListingView();
    }
    switch (componentState.carePlanScreenContext) {
      case CarePlanScreenContext.ADD_CARE_PLAN:
        return (
          <Stack direction="column" style={styles.fullFlex}>
            <Header
              prefixAction={
                isSidecar || props?.isCompactView ? (
                  <Button
                    onClick={() => props?.onClose?.(false)}
                    type="text"
                    icon={<LeftOutlined />}
                    {...testID(TestIdentifiers.leftOutlinedBtn)}
                  />
                ) : undefined
              }
              context={componentState.carePlanScreenContext}
              headerAction={getHeaderActions()}
              title={intl.formatMessage({id: 'carePlan'})}
              carePlanSourceName={carePlanSourceName}
              loadingCarePlanSourceName={loadingCarePlanSourceName}
              getCarePlanSourceName={getCarePlanSourceName}
              carePlanCreatedOn={componentState?.carePlanDetails?.createdOn || ''}
              carePlanSourceTypeId={componentState?.carePlanDetails?.sourceTypeId || ''}
              automationSourceTypeId={automationSourceTypeId}
              sourceId={componentState?.carePlanDetails?.sourceId}
              onAutomationNavigation={onAutomationNavigation}
              disableAutomationNavigation={automationDetails?.isDeleted ?? false}
            />
            {!!componentState?.submittedCarePlanAssessmentForm
              ?.submittedCarePlanAssessmentFormId && (
              <WriteInfoBackBanner
                message={intl.formatMessage({
                  id: 'assessmentFormSubmittedInstruction',
                })}
              />
            )}
            <NoCarePlanCreatedFallback
              handleCreateCarePlanClick={() => {
                setComponentState((prev) => ({
                  ...prev,
                  isCreatingCarePlan: true,
                  carePlanScreenContext: CarePlanScreenContext.EDIT_CARE_PLAN,
                }));
              }}
            />
          </Stack>
        );
      case CarePlanScreenContext.CARE_PLAN_LISTING:
        return (
          getCarePlanListingView()
        );
      case CarePlanScreenContext.EDIT_CARE_PLAN:
        return (
          <AddOrUpdateCarePlanDetails
            carePlanReviewTaskId={componentState?.taskId}
            carePlanSourceName={carePlanSourceName}
            getCarePlanSourceName={getCarePlanSourceName}
            loadingCarePlanSourceName={loadingCarePlanSourceName}
            onCarePlanAdded={()=> {
              if (isSidecar || props?.isCompactView) {
                props?.onClose?.(true);
                return;
              }
              getCarePlanDetails();
            }}
            onCancelClick={() => {
              if (isSidecar || props?.isCompactView) {
                props?.onClose?.(false);
                return
              }
              if (componentState?.carePlanDetails?.statusId === inReviewCarePlanStatusId) {
                setComponentState((prev) => ({
                  ...prev,
                  isCreatingCarePlan: false,
                  carePlanScreenContext: CarePlanScreenContext.EDIT_CARE_PLAN,
                }));
              } else if (componentState?.carePlanDetails?.statusId === activeCarePlanStatusId) {
                setComponentState((prev) => ({
                  ...prev,
                  carePlanScreenContext: CarePlanScreenContext.CARE_PLAN_LISTING,
                }));
              } else {
                setComponentState((prev) => ({
                  ...prev,
                  carePlanScreenContext: CarePlanScreenContext.ADD_CARE_PLAN,
                }));
              }
            }}
            carePlanDetails={componentState?.carePlanDetails}
            formResponseComponents={cloneDeep(componentState?.formResponseComponents)}
            submittedCarePlanAssessmentForm={componentState?.submittedCarePlanAssessmentForm}
            loadingCarePlanAssessmentFormName={loadingCarePlanAssessmentFormName}
            submittedResponse={componentState.submittedResponse}
            {...props}
            carePlanTask={componentState?.carePlanTask}
            isCreatingCarePlan={componentState?.isCreatingCarePlan}
            isCompactView={props?.isCompactView}
            onAutomationNavigation={onAutomationNavigation}
            disableAutomationNavigation={automationDetails?.isDeleted ?? false}
            onTaskUpdate={(taskId?: string) => {
              if (taskId) {
               getCarePlanTaskDetails(taskId);
              }
            }}
          />
        );

      case CarePlanScreenContext.CARE_PLAN_AUDIT:
        if (componentState.carePlanDetails) {
          return (
            <Stack direction="column" style={styles.fullFlex}>
              <Header
                context={componentState.carePlanScreenContext}
                headerAction={getHeaderActions()}
                title={intl.formatMessage({ id: 'carePlanHistory' })}
                carePlanSourceName={carePlanSourceName}
                loadingCarePlanSourceName={loadingCarePlanSourceName}
                carePlanCreatedOn={componentState?.carePlanDetails?.createdOn || ''}
                getCarePlanSourceName={getCarePlanSourceName}
                carePlanSourceTypeId={componentState?.carePlanDetails?.sourceTypeId || ''}
                automationSourceTypeId={automationSourceTypeId}
                sourceId={componentState?.carePlanDetails?.sourceId}
                onAutomationNavigation={onAutomationNavigation}
                disableAutomationNavigation={automationDetails?.isDeleted ?? false}
              />
              <ScrollView 
                style={{
                  height: props?.isCompactView ? 400 : undefined,
                  padding: 16
                }}
              >
              {!!componentState?.submittedCarePlanAssessmentForm
                  ?.submittedCarePlanAssessmentFormId && (
                  <WriteInfoBackBanner
                    message={intl.formatMessage({
                      id: 'assessmentFormSubmittedInstruction',
                    })}
                  />
                )}
                {![draftCarePlanStatusId, inReviewCarePlanStatusId].includes(componentState.carePlanDetails.statusId) && (
                  <CarePlanAudit
                    carePlanDetails={componentState.carePlanDetails}
                    components={cloneDeep(componentState?.formResponseComponents)}
                    locationId={props?.accountLocationUuid}
                  />
                )}
              </ScrollView>
            </Stack>
          );
        }
        break;
      default:
        return <View />;
    }
  };

  return (
    <VStack
      backgroundColor={Colors.Custom.Gray50}
      flex={1}
    >
      {componentState?.carePlanLoading && !isCarePlanGoalsDrawerView && <TimeLineSkeletonLoader />}
      {!componentState?.carePlanLoading && renderMainViewByScreenContext()}
    </VStack>
  );
};

const styles = StyleSheet.create({
  separator: {
    backgroundColor: Colors.Custom.Gray500,
    width: 0.2,
  },
  inReviewText: {
    color: Colors.Custom.orangeMessageTopBar,
    marginLeft: 2,
    fontSize: 12,
  },
  assigneeText: {
    color: Colors.Custom.Gray400,
  },
  assignedOnText:{
    color: Colors.Custom.Gray500,
    fontSize: 12,
  },
  assignedToPatientText: {
    color: Colors.Custom.CompletedTaskFontColor,
    marginLeft: 2,
    fontSize: 12,
  },
  fullFlex: {
    flex: 1,
  },
  scrollView: {
    padding: 16,
  },
});

export default CarePlan;
