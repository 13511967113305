import {
  IComponentState,
  IClinicalParametersComponentValue, IClinicalParametersParams, HIE_ENCOUNTER_TYPE
} from './interface';
import {
  CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE,
} from '../../../../FormsConstants';
import { useContext, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { IFormCommonData } from '../../CustomWrapper/CustomWrapper';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';
import { getPatientChronicConditions } from '../../../../../../../services/CommonService/AidBoxService';
import { getPatientEncounterDetailsWithParams } from '../../../../../../../services/CommonService/IntegrationService';
import { useCareProgramAdditionalData } from '../../../../../CareManagementView/CareManagementViewHooks/useCareProgramAdditionalData';
import { ADDITIONAL_DATA_CODE } from '../../../../../CareManagementBilling/CareManagementConstants';
import { buildEncounterParams, getPreviousTime } from './ClinicalParametersFormUtils';


export const useClinicalParametersView = (params: IClinicalParametersParams) => {
  const {
    contactCareProgramId,
    component,
    onChange,
    isDisabled
  } = params;

  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const contactId = contextData?.contactId || ''
  const patientId = contextData?.contactDetails?.patientId || ''
  const locationId = contextData?.locationId
  const { getPatientAdditionalData } = useCareProgramAdditionalData()

  const [componentValue, setComponentValue] =
    useState<IClinicalParametersComponentValue>(
      component?.selectedValue || {
        clinicalParameters: {}
      }
    );

  const [componentState, setComponentState] = useState<IComponentState>({
    loadingStatus: 'loading',
  });

  const fetchCareProgramData = async () => {
    const previousTime = getPreviousTime(new Date().toISOString(), 12);
    const promiseMap = {
      chronicConditions: getPatientChronicConditions(
        [{
          location: locationId,
          patientId: patientId,
        }],
        true
      ),
      encounterDetails: getPatientEncounterDetailsWithParams(
        buildEncounterParams(patientId)
      ),
      officeVisits: getPatientEncounterDetailsWithParams(
        buildEncounterParams(patientId, { type: HIE_ENCOUNTER_TYPE.OP_VISITS })
      ),
      erVisits: getPatientEncounterDetailsWithParams(
        buildEncounterParams(patientId, { type: HIE_ENCOUNTER_TYPE.ED_ER_VISITS })
      ),
      last12MonthsAdmissions: getPatientEncounterDetailsWithParams(
        buildEncounterParams(patientId, { startDate: previousTime })
      ),
      last12MonthsOpVisits: getPatientEncounterDetailsWithParams(
        buildEncounterParams(patientId, {
          type: HIE_ENCOUNTER_TYPE.OP_VISITS,
          startDate: previousTime
        })
      ),
      last12MonthsErVisits: getPatientEncounterDetailsWithParams(
        buildEncounterParams(patientId, {
          type: HIE_ENCOUNTER_TYPE.ED_ER_VISITS,
          startDate: previousTime
        })
      ),
      futureAppointment: getPatientAdditionalData(
        {
          contactUuid: contactId,
          additionalDataCodes: [
            ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT
          ]
        }
      )
    };

    await Promise.all(Object.values(promiseMap))
      .then((results: any) => {
        const resolvedResults = Object.keys(promiseMap).reduce<Record<string, any>>((acc, key, index) => {
          acc[key] = results[index] ?? null;
          return acc;
        }, {});

        setComponentValue((prev) => ({
          ...prev,
          clinicalParameters: {
            ...prev.clinicalParameters,
            chronicConditions: resolvedResults.chronicConditions || [],
            lastAdmissionDate: {
              value:
                resolvedResults.encounterDetails?.entry?.[0]?.resource?.period?.start || '',
              source: CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            lastDischargeDate: {
              value:
                resolvedResults.encounterDetails?.entry?.[0]?.resource?.period?.end || '',
              source: CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            lastOfficeVisitDate: {
              value:
                resolvedResults.officeVisits?.entry?.[0]?.resource?.period?.start || '',
              source: CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            lastERVisitDate: {
              value:
                resolvedResults.erVisits?.entry?.[0]?.resource?.period?.start || '',
              source: CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            totalAdmissionsForLast12Months: {
              value: resolvedResults.last12MonthsAdmissions?.total || 0,
              source: CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            totalOutpatientVisitsForLast12Months: {
              value: resolvedResults.last12MonthsOpVisits?.total || 0,
              source: CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            totalERVisitsForLast12Months: {
              value: resolvedResults.last12MonthsErVisits?.total || 0,
              source: CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            futureAppointment: {
              value: resolvedResults.futureAppointment?.[0]?.referenceData?.startDateTime || [],
              source: CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            }
          },
        }));
        setComponentState((prev) => ({
          ...prev,
          loadingStatus: 'loaded',
        }));
      });
  };

  const validateData = () => {
    return {
      isValid: true,
      message: '',
    };
  };

  useEffect(() => {
    if (!isDisabled) {
      fetchCareProgramData()
    }
    else {
      setComponentState((prev) => ({
        ...prev,
        loadingStatus: 'loaded',
      }));
    }
  }, [contactCareProgramId]);


  useEffect(() => {
    if (componentState.loadingStatus === 'loaded') {
      const list = componentValue.clinicalParameters;
      onChange(cloneDeep({ clinicalParameters: list }));
    }
  }, [componentValue, componentState.loadingStatus]);

  return {
    componentValue,
    componentState,
    validateData
  };
};
