import React from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from '@foldhealth/formio-react';
import { Formio } from '@foldhealth/formiojs';
import { CustomTable } from './CustomTable';
import { ColumnType, ITableColumn } from './interfaces';
import CustomWrapper from '../CustomWrapper/CustomWrapper';
import CustomClassComponent from '../CustomWrapper/CustomClassComponent';

export default class CustomTableComponent extends CustomClassComponent {
  static schema() {
    return ReactComponent.schema({
      type: 'customTable',
      label: 'Custom Table',
      key: 'customTable',
      tableColumns: [],
    });
  }

  static get builderInfo() {
    return {
      title: 'Custom Table',
      group: 'custom',
      icon: 'table',
      weight: 70,
      documentation: '',
      schema: CustomTableComponent.schema(),
    };
  }

  attachReact(element: HTMLElement) {
    return ReactDOM.render(
      <CustomWrapper formOptionData={this.options}>
        <CustomTable
          component={this.component}
          tableColumns={this.component.tableColumns || []}
          value={this.component.selectedValue || this.dataValue || []}
          onChange={(value) => this.updateValue(value)}
          isPreview={this.options.preview}
          disabled={this.disabled}
        />
      </CustomWrapper>,
      element
    );
  }

  static editForm = function() {
    return {
      components: [
        {
          type: 'textfield',
          key: 'label',
          label: 'Label',
          input: true,
        },
        {
          type: 'datagrid',
          key: 'tableColumns',
          label: 'Columns',
          input: true,
          components: [
            {
              type: 'oldtextfield',
              key: 'name',
              label: 'Column Name',
              input: true,
              validate: {
                required: true,
              },
            },
            {
              type: 'select',
              key: 'type',
              label: 'Column Type',
              input: true,
              data: {
                values: [
                  { label: 'Text', value: ColumnType.TEXT },
                  { label: 'Date', value: ColumnType.DATE },
                ],
              },
              validate: {
                required: true,
              },
            },
          ],
        },
      ],
    };
  };
}

// Register the component with Formio
Formio.Components.addComponent('customTable', CustomTableComponent);
