import React, {
  LegacyRef,
  useCallback,
  useMemo,
  useState
} from 'react';
import {useContactCareProgramContext} from '../ContactCareProgram.context';
import {getContentView} from './getContentView';
import ContentHeader from './components/ContentHeader';
import {
  IMainContentContextState,
  MainContentProvider,
} from './MainContentView.context';
import useCareProgramStepStatus from '../hooks/useCareProgramStepStatus';
import {CustomCareProgramStatus} from '../reducer';
import ProgramCompletionView from './components/Completion/ProgramCompletionView';
import {IContactCareProgramStep} from '../../interface';
import {useSelectedStepInCareProgram} from './components/hooks/useSelectedStepInCareProgram';
import {ScrollView} from 'react-native';
import { OUTREACH_STEP_TYPES } from '../../../Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';
import CarePlan from '../../../../PersonOmniView/MiddleContainer/CarePlan/CarePlan';
import {CARE_PROGRAM_STEP_TYPE} from '../../../../common/MemebersView/constant';

const MainContent = React.forwardRef<ScrollView, any>(
  (props, ref: LegacyRef<ScrollView>) => {
    const {state} = useContactCareProgramContext();
    const [outreachSubtitle, setOutreachSubtitle] = useState('');
    const showCompletedView =
      state.selectedStepId === CustomCareProgramStatus.COMPLETED;
    const {careProgramStepStatusList} = useCareProgramStepStatus();
    const [mainContentState, setMainContentState] =
      useState<IMainContentContextState>({
        headerSavingIndicator: false,
        formResponseForAssessmentStep: undefined,
      });

    const updateMainContentState = useCallback(
      (newState: Partial<IMainContentContextState>) => {
        setMainContentState((prevState) => ({...prevState, ...newState}));
      },
      [setMainContentState]
    );

    const {selectedStepId, contactCareProgramDetails} = state;
    const {selectedStep, accountLocationUuid, personData, contactName} = useSelectedStepInCareProgram();

    const isOutReachview = selectedStep?.careProgramStepType?.code && OUTREACH_STEP_TYPES.includes(selectedStep?.careProgramStepType?.code);

    const selectedStepStatus = useMemo(() => {
      const stepLog = contactCareProgramDetails?.stepsLog?.find(
        (step) => step.careProgramStepId === selectedStepId
      );
      const statusId = stepLog?.careProgramStepStatusId;
      return statusId
        ? careProgramStepStatusList.find((item) => item.id === statusId)
        : undefined;
    }, [
      contactCareProgramDetails?.stepsLog,
      selectedStepId,
      careProgramStepStatusList,
    ]);
    const handleHeaderSubtitle = (subtitle: string) => {
      setOutreachSubtitle(subtitle);
    }
    const isCarePlanView: boolean = [
      CARE_PROGRAM_STEP_TYPE.CARE_PLAN
    ].includes(selectedStep?.careProgramStepType?.code || '');

    const ContentView = showCompletedView
      ? ProgramCompletionView
      : getContentView({
          selectedStep: selectedStep as IContactCareProgramStep,
          handleHeaderSubtitle: handleHeaderSubtitle,
        });

    if (!selectedStep && !showCompletedView) {
      return null;
    }

    return (
      <MainContentProvider
        value={{
          mainContentContextState: mainContentState,
          updateMainContentContextState: updateMainContentState,
        }}
      >
        <div className="flex flex-col flex-1" key={selectedStepId}>
          {!isCarePlanView && <ContentHeader outreachSubtitle={isOutReachview ? outreachSubtitle : ''} />}
          <ScrollView style={{maxHeight: '90%', paddingBottom: 50}} ref={ref}>
            <ContentView/>
          </ScrollView>
        </div>
      </MainContentProvider>
    );
  }
);

export default MainContent;
