import React from 'react';
import {IFormComponentProps} from '../../CustomWrapper/CustomWrapper';
import ClinicalParametersView from './ClinicalParametersView';

const ClinicalParametersWrapper: React.FC<IFormComponentProps> = (props) => {
  return (
    <>
      <ClinicalParametersView
        contactCareProgramId={props?.options?.contactCareProgramId}
        onChange={props?.onChange}
        validateRef={props?.validateRef}
        component={props?.component}
        disableForm={props?.disabled}
      />
    </>
  );
};

export default ClinicalParametersWrapper;
