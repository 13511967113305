import { useLazyQuery } from '@apollo/client';
import { Modal as AntdModal, Drawer, Table, notification } from 'antd';
import {
  Button, Center,
  Divider,
  Modal, Spinner,
  Text,
  VStack,
  View,
  useMediaQuery,
  useToast
} from 'native-base';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dimensions } from 'react-native';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  BUTTON_TYPE,
  DEFAULT_PAGE_SIZE_OPTIONS,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  MEMBER_FILTER_STATUS,
  MLOV_CATEGORY,
  MODULE_PAGINATION_COUNT,
  POPULATION_GROUP_TYPES,
  SORT_TYPE
} from '../../../../constants';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { FHIR_RESOURCE, KEY_ALLOWED_OPERATIONS } from '../../../../constants/FhirConstant';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { FilterDataContext } from '../../../../context/FilterDataContext';
import ContactsQueries from '../../../../services/Contacts/ContactsQueries';
import { Colors } from '../../../../styles';
import { getFilterDataByType } from '../../../../utils/FilterUtils';
import { getAccountConfigResourceAbilities, getResourceAbilities, getSexAtBirthTransformValues } from '../../../../utils/capabilityUtils';
import {
  findIsAllowToRunAutomation,
  getAccountUUID,
  getBooleanFeatureFlag,
  getUserActionObjectFromActionCode,
  getUserUUID,
  isLeadGroupsPage,
  splitArrayIntoChunks
} from '../../../../utils/commonUtils';
import { ToastType, showToast } from '../../../../utils/commonViewUtils';
import { getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';
import { CustomMenuItem } from '../../../common/CustomMenuItem/CustomMenuItem';
import { DisplayText } from '../../../common/DisplayText/DisplayText';
import { FHAlertDialog } from '../../../common/FHAlertDialog';
import CrossIconSvg from '../../../common/Svg/CrossIconSvg';
import AutomationSvg from '../../../common/Svg/GlobalActionSvg/AutomationSvg';
import CampaignSvg from '../../../common/Svg/GlobalActionSvg/CampaignSvg';
import PlusIcon from '../../../common/Svg/PlusSvg';
import SendButtonSvg from '../../../common/Svg/SendButtonSvg';
import ThreeDotsSvg from '../../../common/Svg/ThreeDotsSvg';
import { TABLE_TOP_BAR_ACTION_CODES } from '../../../common/TableTopBar';
import { TableWrapper } from '../../../common/TableWrapper';
import { handleCreateMemberGroupAction } from '../../../common/UsageLevel/UsageLevelHandlers';
import { mapGenderDisplayCode } from '../../Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import AssignCareJourneyPopup from '../../Journeys/JourneysOfCare/AssignCareJourneyPopup/AssignCareJourneyPopup';
import { AutomationWorkflowEmployee } from '../../MembersManagement/AutomationWorkflowEmployee';
import { DeleteEmployeeModal } from '../../MembersManagement/DeleteEmployeeModal';
import { GroupMemberListDrawer } from '../../MembersManagement/GroupMemberListDrawer';
import { GroupSummary } from '../../MembersManagement/GroupSummary';
import { getPreFilterMemberStatus, patientFilterForCreateSegment } from '../../MembersManagement/Helper';
import { IGroupData, IMembersDatas } from '../../MembersManagement/RulesInterface';
import {
  IMembersGroupData,
  IMembersGroupTableColumnsArgs
} from '../../MembersManagement/interfaces';
import { isJourneyAllowed, isAutomationAllowed, USER_ACCESS_PERMISSION } from '../../UserAccess/UserAccessPermission';
import {
  createCampaignSegment,
  createOrUpdateLeadGroups,
  deleteCampaignSegmentRules,
  deleteEmployeeGroup, executeCampaignSegmentRule, getCampaignSegmentRules,
  getContactsWithinGroup,
  getGroupDetailById,
  getGroupsList,
  getLeadGroups
} from '../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import AddOrUpdateCampaign from './AddOrUpdateCampaign/AddOrUpdateCampaign';
import AssignJourneyListView from './AssignJourneyListView/AssignJourneyListView';
import { FetchContactsBy } from './AssignJourneyListView/interface';
import { CampaignSegmentForm } from './CampaignSegmentForm';
import ContactSendForm from './GroupDetails/ContactSendForm';
import { getGroupListColumns } from './Helper';
import ImportRule from './ImportRule/ImportRule';
import { PopulationGroupFilter } from './PopulationGroupFilter/PopulationGroupFilter';
import { CAMPAIGN_SEGMENT_TABLE_ACTIONS, POPULATION_FILTER_TYPE, POPULATION_GROUP_ALL_FILTER_CODE } from './constants';
import { CampaignSegmentCategories, CampaignSegmentTableContext, ICampaignErrors, ICampaignSegmentFormState, ICampaignSegmentTableProps, IPopulationGroupFilter } from './interfaces';
import { getFormattedGroupData, getFrequencyValue } from './utils';
import GroupDetailsDrawer from './GroupDetails/GroupDetailsDrawer';
import ThreeDotIconSvg from '../../../common/Svg/InterventionSvg/ThreeDotIconSvg';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import { usePermissions } from '../../../CustomHooks/usePermissions';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';
import TeamQueries from '../../../../services/Team/TeamQueries';
import {salesDataService} from '../../../../services/SalesDataService';

const {Content, CloseButton, Header, Footer, Body} = Modal;

const CampaignSegmentTable = (props: ICampaignSegmentTableProps) => {
  const isScheduleSuggestionRulesContext = props.context === CampaignSegmentTableContext.SCHEDULE_SUGGESTION_RULES;
  const isPopulationGroupContext = props.context === CampaignSegmentTableContext.POPULATION_GROUP;
  const heightDifferenceForTable = isPopulationGroupContext ? 236 : 260
  const toast = useToast();
  const intl = useIntl();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const navigate = useNavigate();
  const [editData, setEditData] = useState<ICampaignSegmentFormState>();
  const [careJourneyState, setCareJourneyState] = useState<{
    isCareJourneyInactiveMemberAlertOpen?: boolean;
    assignCareJourneyData?: { record: any, groups: { groupId: string | undefined; contactIds: any; }[]};
  }>({});
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const [confirmationModal, setConfirmationModal] = useState({
    isOpen: false,
    id: '',
    title: 'Delete Group',
    message: 'Are you sure you want to delete this group?',
    action: CAMPAIGN_SEGMENT_TABLE_ACTIONS.DELETE
  });
  const mlovData = useContext(CommonDataContext);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const { accountLocationListWithEHR } = mlovData;
  const {check} = usePermissions();
  const accessLevel = isLeadGroupsPage() ? MAIN_MENU_CODES.LEAD : MAIN_MENU_CODES.CONSUMER;
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code, accessLevel);

  const allowedAccountLocations = accountLocationListWithEHR?.filter((location) => {
    return permissionConfig?.allowedLocationIds?.includes(location?.uuid)
  })?.map((location) => {
    return {
      ...location,
      uuid: location?.uuid,
      name: location?.practiceLocation?.name,
    }
  }) || [];
  const allowedAccountLocationIds = allowedAccountLocations?.map((location) => {
    return location?.uuid
  }) || [];
  let personGenderList = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.PERSON_GENDER
  );
  const genderTransformValues = getSexAtBirthTransformValues(
    KEY_ALLOWED_OPERATIONS.GENDER,
    '',
    ''
  );
  const hintCapability = getAccountConfigResourceAbilities(FHIR_RESOURCE.HINT);
  const openInHint = hintCapability?.openInHint || false;
  personGenderList = mapGenderDisplayCode(personGenderList,genderTransformValues)
  const accountUuid = getAccountUUID();
  const filterContext = useContext(FilterDataContext);

  const filterContextData = isPopulationGroupContext && getFilterDataByType(filterContext, POPULATION_FILTER_TYPE)

  const [membersGroupTableState, setMembersGroupTableState] = useState({
    isLoading: true,
    searchString: filterContextData?.searchString ? filterContextData?.searchString : '',
    membersTableGroupData: [] as IMembersGroupData[],
    page: filterContextData?.page ? filterContextData?.page : 0,
    pageSize: filterContextData?.pageSize ? filterContextData?.pageSize : MODULE_PAGINATION_COUNT.MEMBERS_GROUP_TABLE_PAGE_SIZE,
    sort: filterContextData?.sort ? filterContextData?.sort : {
      columnKey: '',
      order: ''
    } ,
    selectedGroup: {} as IMembersGroupData,
    deleteModal: false,
    showSummary: false,
    showMemberList: false,
    selectedGroups: [] as IMembersGroupData[],
    formattedMembersGroupData: [],
    total: null,
    contactUuidsOfSelectedGroups: [],
    groupAndActiveContactIds: [] as {group?: IMembersGroupData, contactIds?: string[]}[],
    showAssignJourneyMemberListDrawer: false,
    selectedJourneyToAssign: undefined,
    filter: filterContextData?.filter ? filterContextData?.filter : {} as IPopulationGroupFilter,
    appliedFilters: filterContextData?.appliedFilters ? filterContextData?.appliedFilters : [],
    showGroupDetailDrawer: false,
    selectedRuleIdForAuditDrawer: '',
  });

  const [inactiveOrNoMemberAlert, setInactiveOrNoMemberAlert] = useState(false)
  const tenantId = getAccountUUID();
  const userUuid = getUserUUID();
  const [campaignDrawerState, setCampaignDrawerState] = useState<any>({
    isVisible: false,
    segmentId: null,
  });

  const [campaignDetailsError, setCampaignDetailsErrors] =
    useState<ICampaignErrors>({});

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const [GetAccountLocationsName] = useLazyQuery(TeamQueries.GetLocationsNameByAccountLocationUuids, {
    fetchPolicy: 'no-cache'
  })
  const createOrUpdateGroupPromise = isLeadGroupsPage()
    ? createOrUpdateLeadGroups
    : createCampaignSegment;

  const [searchParams] = useSearchParams();

  const isWalkThrough = searchParams.get('isWalkThrough') || false;

  const {width, height} = Dimensions.get('window');
  const finalWidth =
    isIPadScreen || isIPadMiniScreen ? width * 0.7 : width * 0.5;
  const finalWidthForImportRulesDrawer = isIPadScreen || isIPadMiniScreen ? width * 0.8 : width * 0.7;
  const [selectedGroupRowData, setSelectedGroupRowData] = useState<{} | any>();
  const [segmentId, setSelectedGroupId] = useState<any>();
  const [openModal, setOpenModal] = useState<{
    type: string;
    customDrawerWidth?: number;
  }>({
    type: '',
    customDrawerWidth: undefined
  });
  const [formSendModal, setFormSendModal] = useState(false);

  const userActionObject = getUserActionObjectFromActionCode(
    'CREATE_A_MEMBER_GROUP'
  );
  const level = userActionObject.level || '';

  const onCancelAuditDetailDrawer = () => {
    setMembersGroupTableState((prev) => {
      return {
        ...prev,
        showGroupDetailDrawer: false,
        selectedRuleIdForAuditDrawer: '',

      }
    })
  }

  useEffect(() => {
    loadMembersGroup(false);
    saveContextData()
  }, [
    membersGroupTableState.page,
    membersGroupTableState.pageSize,
    membersGroupTableState.searchString,
    membersGroupTableState.sort,
    membersGroupTableState.filter
  ]);

  const saveContextData = () => {
    const filterData = {
      filter: membersGroupTableState.filter,
      sort: membersGroupTableState.sort,
      type: POPULATION_FILTER_TYPE,
      page: membersGroupTableState.page,
      pageSize: membersGroupTableState.pageSize,
      appliedFilters: membersGroupTableState.appliedFilters
    }

    filterContext?.saveFilterData?.(filterData)
  }

  const addCountsToGroupData = async (groupListData: IMembersDatas[]) => {
    for (const group of groupListData) {
      if (group.type === 'STATIC') {
        continue;
      }
      const {activePatients, inactivePatients} =
        await salesDataService.getActiveAndInactiveNumberByRuleId(group?.id);
      group.inActiveCount = inactivePatients || group?.inActiveCount;
      group.count = activePatients || group?.count;
    }
  };

  useEffect(() => {
    // checking for null as if zero count comes then if condition should work
    if (membersGroupTableState.total !== null) {
      props.updatePopulationGroupCount?.(membersGroupTableState.total ?? 0);
    }
  }, [membersGroupTableState.total]);
  const setInitialGroupState = (groupListData: IMembersDatas[], total: any) => {
    const initialGroups = groupListData.map((group) => ({
      ...group,
      practionLocationNameList: [],
      locationFetched: false
    }));

    setMembersGroupTableState((prev) => ({
      ...prev,
      isLoading: false,
      total: total,
      formattedMembersGroupData:
        getFormattedGroupData(initialGroups, personGenderList) || [],
    }));

    return initialGroups;
  };
  const fetchLocationNames = async (group: IMembersDatas) => {
    if (group.ruleLocations.length > 0) {
      const accountLocationUuids = group.ruleLocations.map(
        (location) => location.locationId
      );

      const locationsData = await GetAccountLocationsName({
        variables: {
          uuids: accountLocationUuids,
        },
      });

      const locationsNameList = [
        ...new Set(
          locationsData.data.accountLocations.map(
            (location: { practiceLocation: { name: string } }) => location.practiceLocation.name
          )
        ),
      ] as string[];

      return locationsNameList || [];
    }

    return []
  }
  const fetchInputRuleDetails = async(group: IMembersDatas) => {
    if (group.inputRuleId) {
      try {
        const inputRule = await getCampaignSegmentRules(group.inputRuleId);
        return inputRule;
      } catch  {        }
    }

    return null;
  }
  const loadDetailedGroups = async(initialGroups: IMembersDatas[]) => {
    const detailedGroups = [];

    for (const group of initialGroups) {
      const updatedGroup = { ...group };
      if (isMultiTenancyEnabled) {
        updatedGroup.practionLocationNameList = await fetchLocationNames(updatedGroup);
      }

      updatedGroup.inputRuleDetails = await fetchInputRuleDetails(updatedGroup);
      updatedGroup.locationFetched =  true
      detailedGroups.push(updatedGroup);
    }

    return detailedGroups;
  }
  const loadMembersGroup = async (isReset: boolean) => {
    setMembersGroupTableState((prev) => ({
      ...prev,
      membersTableGroupData: [],
      page: isReset ? 0 : prev.page || 0,
      isLoading: true,
    }));

    let sort = ''
    let filter = ''

    if(membersGroupTableState.sort?.order && membersGroupTableState.sort?.columnKey){
        sort = membersGroupTableState.sort?.order === SORT_TYPE.DESC ? `-${membersGroupTableState.sort?.columnKey}` : membersGroupTableState.sort?.columnKey
    }

    if(membersGroupTableState?.filter?.type){
      filter = membersGroupTableState?.filter?.type || ''
      if(filter === POPULATION_GROUP_ALL_FILTER_CODE){
        filter = ''
      }
    }

    try {
      let rulesGroupListRes: any;
      if (isLeadGroupsPage()) {
        rulesGroupListRes = await getLeadGroups({
          accountUuid: accountUuid,
          name: membersGroupTableState.searchString,
          limit: membersGroupTableState.pageSize,
          offset: membersGroupTableState.pageSize * membersGroupTableState.page,
          sort,
          filter,
          selectedAccountLocationIds: isMultiTenancyEnabled ? allowedAccountLocationIds : undefined,
        });
      } else {
        rulesGroupListRes = await getGroupsList(
          accountUuid,
          membersGroupTableState.pageSize,
          membersGroupTableState.searchString,
          membersGroupTableState.pageSize * membersGroupTableState.page,
          isScheduleSuggestionRulesContext ? CampaignSegmentCategories.Appointment : undefined,
          undefined,
          undefined,
          sort,
          filter,
          isMultiTenancyEnabled ? allowedAccountLocationIds : undefined,
        );
      }
      const groupListData: IMembersDatas[] =
        rulesGroupListRes?.data?.data || [];
      await addCountsToGroupData(groupListData);
      const initialGroups = setInitialGroupState(groupListData,rulesGroupListRes?.data?.total);
      loadDetailedGroups(initialGroups).then((detailedGroups) => {
        setMembersGroupTableState((prev) => ({
          ...prev,
          isLoading: false,
          formattedMembersGroupData:
            getFormattedGroupData(detailedGroups, personGenderList) || [],
        }));
      });
    } catch (error) {
      setMembersGroupTableState((prev) => {
        return {
          ...prev,
          membersTableGroupData: [],
          isLoading: false,
        };
      });
    }
  };

  const onMembersGroupTableActionPerformed = async (
    actionCode: string,
    actionData: IMembersGroupData,
    status?: boolean
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ROW_CLICKED:
        //onRowClicked(actionData);
        break;
      case COMMON_ACTION_CODES.DELETE:
        const id = actionData.id;
        deleteEmployeeGroup({
          segmentId: id,
        }).then((resp) => {
          if (resp?.id) {
            loadMembersGroup(true);
          }
        });
        break;
      default:
    }
  };

  const MembersTableGroupColumnArgs: IMembersGroupTableColumnsArgs = {
    actionFn: onMembersGroupTableActionPerformed,
  };

  const updateGroupContact = (
    campaignSegmentFormData: ICampaignSegmentFormState,
    initialLocationIdsList?: string[],
  ) => {
    const selectedAccountLocations = campaignSegmentFormData?.selectedAccountLocations || [];
    let locationIdList = selectedAccountLocations.map((location) => {
      return { locationId: location?.uuid };
    });
    const selectedLocationIds = new Set(selectedAccountLocations.map(location => location?.uuid));
    const deletedLocations = initialLocationIdsList
      ?.filter(locationId => !selectedLocationIds.has(locationId))
      .map(locationId => ({ locationId, isDeleted: true }));

    locationIdList = locationIdList.concat(deletedLocations || []);
    createOrUpdateGroupPromise({
      id: segmentId,
      name: campaignSegmentFormData?.audienceName || '',
      description: campaignSegmentFormData?.description || '',
      metadata: {
        patientFilter: patientFilterForCreateSegment(campaignSegmentFormData),
      },
      ...(!campaignSegmentFormData.patientList?.length && !isLeadGroupsPage()) && (getPreFilterMemberStatus(campaignSegmentFormData)),
      locationIdList: locationIdList || [],
      automationToUpdate: campaignSegmentFormData?.automationToUpdate || [],
    }).then(async (resp: any) => {
      if (resp?.id) {
        let popGroupResponse = resp;
        if (resp?.inputRuleId) {
          popGroupResponse = await getCampaignSegmentRules(resp?.inputRuleId);
        }
        notification.success({
          message:
            resp.type == 'DYNAMIC' || resp.type === 'DYNAMIC_LEAD'
              ? `${intl.formatMessage({id: 'popGroupFrequencyMessage'})} ${getFrequencyValue(popGroupResponse)}.`
              : intl.formatMessage({id: 'createGroupSuccessMessage'}),
        });
        setOpenModal({
          type: '',
        });
        loadMembersGroup(true);
      }
    }).catch((error)=>{
      notification.error({
        message: error?.response?.data?.message || 'Something went wrong',
      });
    });
  };

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setMembersGroupTableState((prev) => {
          return {
            ...prev,
            membersTableGroupData: [],
            searchString: actionData?.searchString || '',
            page: 0,
            pageSize: 12,
            total: null,
          };
        });

        break;
    }
  };

  const createSegmentGroup = (
    campaignSegmentFormData: ICampaignSegmentFormState,
    initialLocationIdsList?: string[],
  ) => {
    const selectedAccountLocations = campaignSegmentFormData?.selectedAccountLocations || [];
    let locationIdList = selectedAccountLocations.map((location) => {
      return { locationId: location?.uuid };
      });
      const selectedLocationIds = new Set(selectedAccountLocations.map(location => location?.uuid));
      const deletedLocations = initialLocationIdsList
        ?.filter(locationId => !selectedLocationIds.has(locationId))
        .map(locationId => ({ locationId, isDeleted: true }));
      locationIdList = locationIdList.concat(deletedLocations || []);
    createOrUpdateGroupPromise({
      id: campaignSegmentFormData?.id || '',
      name: campaignSegmentFormData?.audienceName || '',
      tenantId: tenantId,
      description: campaignSegmentFormData?.description || '',
      metadata: {
        patientFilter: patientFilterForCreateSegment(campaignSegmentFormData),
      },
      isScheduleSuggestionRule: isScheduleSuggestionRulesContext,
      executionFrequency: campaignSegmentFormData.executionFrequency,
      popupationGroupId: campaignSegmentFormData.populationGroupId,
      ...(!campaignSegmentFormData.patientList?.length && !isLeadGroupsPage()) && (getPreFilterMemberStatus(campaignSegmentFormData)),
      ishintMembershipStatus: campaignSegmentFormData.ishintMembershipStatus,
      locationIdList: locationIdList,
      automationToUpdate: campaignSegmentFormData?.automationToUpdate || [],
    }).then(async (resp: any) => {
      if (resp?.id) {
        let popGroupResponse = resp;
        if (resp?.inputRuleId) {
          popGroupResponse = await getCampaignSegmentRules(resp?.inputRuleId);
        }
        notification.success({
          message:
            resp.type == 'DYNAMIC' || resp.type === 'DYNAMIC_LEAD'
              ? `${intl.formatMessage({id: 'popGroupFrequencyMessage'})} ${getFrequencyValue(popGroupResponse)}.`
              : intl.formatMessage({id: 'createGroupSuccessMessage'}),
        });
        setOpenModal({
          type: '',
        });
        handleCreateMemberGroupAction({
          accountUuid: tenantId,
          userUuid: userUuid,
          level,
        })
          .catch((error) => {

          });

        loadMembersGroup(true);
      }
    }).catch((error)=>{
      notification.error({
        message: error?.response?.data?.message || 'Something went wrong',
      });
    });
  };

  const onDrawerContent = (type?: string): JSX.Element => {
    let element: JSX.Element = <></>;
    switch (type) {
      case 'automationWorkflow':
        element = (
          <>
            <AutomationWorkflowEmployee
              type="group"
              groupList={membersGroupTableState.selectedGroups}
              onModalClose={(shouldTriggerModal?: boolean) => {
                setOpenModal({
                  type: '',
                });
              }}
            />
          </>
        );
        break;
      case 'CreateSegment':
        element = (
          <>
            <View style={{marginHorizontal: 0}}>
              <CampaignSegmentForm
                context={props.context}
                campaignDetailsError={campaignDetailsError}
                onModalClose={() => {
                  setOpenModal({
                    type: '',
                    customDrawerWidth: undefined,
                  });
                }}
                onSubmitForm={(
                  campaignSegmentFormData: ICampaignSegmentFormState,
                  callback?: any,
                  initialLocationIdsList?: string[],
                ) => {
                  createSegmentGroup(campaignSegmentFormData, initialLocationIdsList);
                }}
                onColumnViewChange={(columns: number) => {
                  setOpenModal((prev) => ({...prev, customDrawerWidth: columns > 1 ? width * 0.75 : undefined}));
                }}
              />
            </View>
          </>
        );
        break;
      case 'importRule':
        element = (
          <ImportRule
            onModalClose={(refreshList: boolean) => {
              refreshList && loadMembersGroup(true)
              setOpenModal({
                type: '',
              });
            }}
          />
        );
        break;
      case 'EditSegment':
        element = (
          <>
            <CampaignSegmentForm
              context={props.context}
              campaignDetailsError={campaignDetailsError}
              initData={{
                audienceName: selectedGroupRowData?.name || '',
                patientList:
                  selectedGroupRowData?.metadata?.patientFilter?.contactIds ||
                  [],

                description: selectedGroupRowData?.description,
                executionFrequency: selectedGroupRowData?.executionFrequency,
                populationGroupId: selectedGroupRowData?.populationGroupId,
                memberStatusCodeFromResponseWhenEditingGroup: selectedGroupRowData?.preFilter?.hintMembershipStatus?.params?.value,
                ruleLocations: editData?.ruleLocations,
              }}
              onModalClose={() => {
                setOpenModal({
                  type: '',
                  customDrawerWidth: undefined,
                });
              }}
              onSubmitForm={(
                campaignSegmentFormData: ICampaignSegmentFormState,
                callback: any,
                initialLocationIdsList?: string[],
              ) => {
                updateGroupContact(campaignSegmentFormData, initialLocationIdsList);
              }}
              onColumnViewChange={(columns: number) => {
                setOpenModal((prev) => ({...prev, customDrawerWidth: columns > 1 ? width * 0.75 : undefined}));
              }}
            />
          </>
        );
        break;
      case 'AddGroupMember':
        element = (
          <>
            <CampaignSegmentForm
              context={props.context}
              isAddGroupMemberOnly={true}
              initData={editData}
              onModalClose={() => {
                setOpenModal({
                  type: '',
                  customDrawerWidth: undefined,
                });
              }}
              onColumnViewChange={(columns: number) => {
                setOpenModal((prev) => ({...prev, customDrawerWidth: columns > 1 ? width * 0.75 : undefined}));
              }}
              onSubmitForm={(
                campaignSegmentFormData: ICampaignSegmentFormState,
                callback?: any,
                initialLocationIdsList?: string[],
              ) => {
                const selectedAccountLocations = campaignSegmentFormData?.selectedAccountLocations || [];
                let locationIdList = selectedAccountLocations.map((location) => {
                  return { locationId: location?.uuid };
                  });
                  const selectedLocationIds = new Set(selectedAccountLocations.map(location => location?.uuid));
                  const deletedLocations = initialLocationIdsList
                    ?.filter(locationId => !selectedLocationIds.has(locationId))
                    .map(locationId => ({ locationId, isDeleted: true }));

                locationIdList = locationIdList.concat(deletedLocations || []);
                createOrUpdateGroupPromise({
                  name: campaignSegmentFormData?.audienceName || '',
                  id: campaignSegmentFormData?.id || '',
                  description: campaignSegmentFormData?.description || '',
                  metadata: {
                    patientFilter: {
                      ...(campaignSegmentFormData.patientList?.length
                        ? {
                            contactIds: campaignSegmentFormData.patientList,
                          }
                        : {
                            jsonTree: campaignSegmentFormData?.jsonTree,
                          }),
                    },
                  },
                  ...(!campaignSegmentFormData.patientList?.length && !isLeadGroupsPage()) && (getPreFilterMemberStatus(campaignSegmentFormData)),
                  locationIdList: locationIdList,
                  automationToUpdate: campaignSegmentFormData?.automationToUpdate || [],
                })
                  .then((resp: any) => {
                    if (resp.id) {
                      showToast(toast, 'Group member updated successfully', ToastType.success);
                      setOpenModal({
                        type: '',
                        customDrawerWidth: undefined,
                      });
                      loadMembersGroup(true);
                      if(callback){
                        callback(resp)
                      }
                    }
                  }).catch((error)=>{
                    showToast(toast, (error?.response?.data?.message || 'Something went wrong'), ToastType.error);
                    if(callback){
                      callback()
                    }
                  });
              }}
            />
          </>
        );
        break;
      case 'EditCampaign':
        element = (
          <>
            <CampaignSegmentForm
              context={props.context}
              initData={editData}
              onModalClose={() => {
                setOpenModal({
                  type: '',
                  customDrawerWidth: undefined,
                });
              }}
              onColumnViewChange={(columns: number) => {
                setOpenModal((prev) => ({...prev, customDrawerWidth: columns > 1 ? width * 0.75 : undefined}));
              }}
              onSubmitForm={(
                campaignSegmentFormData: ICampaignSegmentFormState,
                callback?: any,
                initialLocationIdsList?: string[],
              ) => {
                const selectedAccountLocations = campaignSegmentFormData?.selectedAccountLocations || [];
                let locationIdList = selectedAccountLocations.map((location) => {
                  return { locationId: location?.uuid };
                  });
                  const selectedLocationIds = new Set(selectedAccountLocations.map(location => location?.uuid));
                  const deletedLocations = initialLocationIdsList
                    ?.filter(locationId => !selectedLocationIds.has(locationId))
                    .map(locationId => ({ locationId, isDeleted: true }));

                locationIdList = locationIdList.concat(deletedLocations || []);
                createOrUpdateGroupPromise({
                  name: campaignSegmentFormData?.audienceName || '',
                  id: campaignSegmentFormData?.id || '',
                  description: campaignSegmentFormData?.description || '',
                  metadata: {
                    patientFilter: {
                      ...(campaignSegmentFormData.patientList?.length
                        ? {
                            contactIds: campaignSegmentFormData.patientList,
                          }
                        : {
                            jsonTree: campaignSegmentFormData?.jsonTree,
                          }),
                    },
                  },
                  ...(!campaignSegmentFormData.patientList?.length && !isLeadGroupsPage()) && (getPreFilterMemberStatus(campaignSegmentFormData)),
                  executionFrequency: campaignSegmentFormData?.executionFrequency || '',
                  popupationGroupId: campaignSegmentFormData?.populationGroupId || '',
                  ishintMembershipStatus: campaignSegmentFormData.ishintMembershipStatus,
                  locationIdList: locationIdList,
                  automationToUpdate: campaignSegmentFormData?.automationToUpdate || [],
                })
                  .then(async (resp: any) => {
                    if (resp.id) {
                      let popGroupResponse = resp;
                      if (resp?.inputRuleId) {
                        popGroupResponse = await getCampaignSegmentRules(resp?.inputRuleId);
                      }
                      showToast(toast,   (resp.type == 'DYNAMIC' || resp.type === "DYNAMIC_LEAD") ?
                      `${intl.formatMessage({id: 'popGroupFrequencyMessage'})} ${getFrequencyValue(popGroupResponse)}.`
                      : intl.formatMessage({id: 'createGroupSuccessMessage'}), ToastType.success, undefined);
                      setOpenModal({
                        type: '',
                        customDrawerWidth: undefined,
                      });
                      loadMembersGroup(true);
                    }
                  }).catch((error)=>{
                    showToast(toast, (error?.response?.data?.message || 'Something went wrong'), ToastType.error);
                  });
              }}
              editing={true}
            />
          </>
        );

        break;
      default:
        break;
    }
    return element;
  };


  const singleGroupActions = () => {
    return (
      <VStack>
        <CustomMenuItem
          label={intl.formatMessage({id: 'sendForm'})}
          hideBottomBorder
          icon={<SendButtonSvg customStrokeColor={Colors.Custom.Gray500} />}
          onClick={async () => {
            if (membersGroupTableState?.selectedGroups?.[0]?.count > 0) {
              const isActive = true;
              let inactiveMembersPresentInSelectedGroups = false;
              const data = await getContactsWithinGroup(
                membersGroupTableState?.selectedGroups[0].id || '',
                isActive
              ).catch(() => {
                showToast(
                  toast,
                  'Error occured while fetching employee details',
                  ToastType.error
                );
              });
              if (data?.data?.contactUuids?.length > 0) {
                if (
                  data?.data?.contactUuids?.length !==
                  membersGroupTableState?.selectedGroups[0]?.count +
                    (!(
                      typeof membersGroupTableState?.selectedGroups[0]
                        ?.inActiveCount === 'string'
                    ) ??
                      membersGroupTableState?.selectedGroups[0]?.inActiveCount)
                ) {
                  inactiveMembersPresentInSelectedGroups = true;
                }
                setMembersGroupTableState((prev) => {
                  return {
                    ...prev,
                    contactUuidsOfSelectedGroups: data?.data?.contactUuids,
                    groupAndActiveContactIds: [
                      {
                        group: membersGroupTableState?.selectedGroups[0],
                        contactIds: data?.data?.contactUuids,
                      },
                    ],
                  };
                });
              } else if (data?.data?.contactUuids?.length === 0) {
                showToast(
                  toast,
                  intl.formatMessage({id: 'noActiveMemberInGroupToSendForm'}),
                  ToastType.error,
                  2000
                );
              }
              inactiveMembersPresentInSelectedGroups
                ? setInactiveOrNoMemberAlert(true)
                : setFormSendModal(true);
            } else if (
              membersGroupTableState?.selectedGroups[0]?.inActiveCount > 0
            ) {
              showToast(
                toast,
                intl.formatMessage({id: 'noActiveMemberInGroupToSendForm'}),
                ToastType.error,
                2000
              );
            } else {
              showToast(
                toast,
                intl.formatMessage({id: 'noMemberInGroupToSendForm'}),
                ToastType.error,
                2000
              );
            }
          }}
        ></CustomMenuItem>
        <CustomMenuItem
          label={intl.formatMessage({id: 'createCampaign'})}
          icon={<CampaignSvg width={18} height={18}/>}
          hideBottomBorder
          onClick={() => {
            setCampaignDrawerState({
              isVisible: true,
              segmentId: membersGroupTableState.selectedGroups[0]?.id,
            });
          }}
        ></CustomMenuItem>
      </VStack>
    );
  };


  const hasSelected = membersGroupTableState.selectedGroups.length > 0;
  const buttonList: any = [];
  const bulkButtonList: any = [];


  if (isJourneyAllowed() && membersGroupTableState.selectedGroups.length === 1 && !isLeadGroupsPage()) {
    bulkButtonList.push({
      btnClick: () => {
        // setOpenModal({
        //   type: 'automationWorkflow',
        // });
        // onClickAssignCareJourney();
      },
      id: 'assignJourneyPopGroups',
      leftIcon: <></>,
      btnText: 'assignJourney',
      isTransBtn: false,
      size: 'smBold',
      textColor: Colors.Custom.mainPrimaryPurple,
      borderColor: Colors.Custom.mainPrimaryPurple,
      backgroundColor: '#ffffff00',
      variant: 'PRIMARY',
      content: (
        <AssignCareJourneyPopup
          onComplete={(record: any) => {
              setMembersGroupTableState((prev) => ({
                ...prev,
                showAssignJourneyMemberListDrawer: true,
                selectedJourneyToAssign: record
              }));
          }}
          locations={membersGroupTableState?.selectedGroups?.[0]?.locationIds || []}

        />
      ),
    });
  }
  const isAutomationAllowedConfig = isAutomationAllowed()
  if (isAutomationAllowedConfig && findIsAllowToRunAutomation()) {
    bulkButtonList.push({
      btnClick: () => {
        setOpenModal({
          type: 'automationWorkflow',
        });
      },
      btnText: 'runAutomation',
      size: 'smBold',
      leftIcon: (
        <AutomationSvg customStrokeColor={Colors.Custom.Gray500}/>
      ),
      textColor: Colors.Custom.mainPrimaryPurple,
      borderColor: Colors.Custom.mainPrimaryPurple,
      backgroundColor: '#ffffff00',
      variant: 'PRIMARY',
    });
  }

  if (membersGroupTableState.selectedGroups?.length === 1) {
    bulkButtonList.push({
      id: 100,
      dropDownType: 'Popover',
      btnText: '',
      size: 'smBold',
      textColor: Colors.Custom.mainPrimaryPurple,
      style: {
        paddingHorizontal: 0, minWidth: 'auto',
      },
      borderColor: Colors.Custom.mainPrimaryPurple,
      backgroundColor: Colors.Custom.buttonWhiteBg,
      variant: BUTTON_TYPE.NO_BORDER,
      rightIcon: (
        <ThreeDotIconSvg style={{padding: 0, margin: 0}}></ThreeDotIconSvg>
      ),
      withRightBorder: true,
      content: singleGroupActions(),
      isRightDividerNotVisible: false
    })
  }

  if (!isPopulationGroupContext || !hasSelected) {
    buttonList.push(
      {
      btnClick: () => {
        setOpenModal({
          type: 'CreateSegment',
        });
      },
      btnText: isPopulationGroupContext ? 'createGroup' : 'createRule',
      size: 'smBold',
      textColor: Colors.Custom.mainPrimaryPurple,
      borderColor: Colors.Custom.mainPrimaryPurple,
      backgroundColor: '#ffffff00',
      variant: 'PRIMARY',
      leftIcon: <PlusIcon />,
    },
    );
  }

  if (!isLeadGroupsPage() && !isScheduleSuggestionRulesContext && (!isPopulationGroupContext || !hasSelected)) {
    buttonList.push(
      {
        btnClick: () => {
          setOpenModal({
            type: 'importRule',
          });
        },
        btnText: 'Import Rule',
        size: 'smBold',
        textColor: Colors.Custom.mainPrimaryPurple,
        borderColor: Colors.Custom.mainPrimaryPurple,
        backgroundColor: '#ffffff00',
        variant: 'PRIMARY',
      },
    )
  }

  const [getContactsByIds, contactDetailsQuery] = useLazyQuery(
    ContactsQueries.GET_ACTIVE_CONTACTS_BY_CONTACTUUIDS,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => {

      },
    }
  );

  const getActiveContactsByChunk = async (contactIds: string[]) => {
    const chunkForGetContacts = splitArrayIntoChunks(contactIds, 100)
    let contacts: any[] = []
    for (const chunk of chunkForGetContacts) {
      const contactsData = await getContactsByIds({
        variables: {
          contactIdList: chunk,
        },
      })
      if (contactsData?.data?.contacts?.length) {
        contacts = contacts.concat(contactsData?.data?.contacts);
      }
    }

    return contacts;
  }

  function assignCareJourney(record: any, groups?: { groupId: string | undefined; contactIds: any; }[]) {
    if (!record || !groups?.length) {
      showToast(toast, 'Something went wrong!', ToastType.error);
      return;
    }
    navigate(
      `/members/patient/${-1}/product/${record?.productId}/assignJourney/${record?.id}`,
      {
        state: {
          personData: {},
          isFromBulkAssign: true,
          bulkApiData: {
            groups: groups,
          },
        },
      },
    );
  }

  async function onClickAssignCareJourney(record: any, unassignedContactsUuids: string[]) {
    if (membersGroupTableState?.selectedGroups[0]?.count > 0) {
      const isActive = true
      let inactiveMembersPresentInSelectedGroups = false;
      setIsActionLoading(true);
      const data = await getContactsWithinGroup(
        membersGroupTableState?.selectedGroups[0].id || '',
        isActive
      ).catch(() => {
        showToast(
          toast,
          'Error occurred while fetching employee details',
          ToastType.error
        );
      });
      if (!data?.data?.contactUuids?.length) {
        setIsActionLoading(false);
      }
      if (data?.data?.contactUuids?.length > 0) {
        if (data?.data?.contactUuids?.length !== (membersGroupTableState?.selectedGroups[0]?.count + (!(typeof(membersGroupTableState?.selectedGroups[0]?.inActiveCount) === 'string') ?? membersGroupTableState?.selectedGroups[0]?.inActiveCount))) {
          inactiveMembersPresentInSelectedGroups = true
        }
        let contactUuids = data?.data?.contactUuids;
        if (inactiveMembersPresentInSelectedGroups) {
          const contacts = await getActiveContactsByChunk(contactUuids);
          contactUuids = contacts?.map?.(contact => contact?.uuid)?.filter(cond => !!cond) || [];
        }
        setIsActionLoading(false);
        if (!contactUuids?.length) {
          showToast(
            toast,
            intl.formatMessage({ id: 'noActiveMemberInGroupToAssignJourney' }),
            ToastType.error,
            2000,
          );
          return;
        }
        const groupAndActiveContactIds = [{ group: membersGroupTableState?.selectedGroups[0], contactIds: unassignedContactsUuids}];
        const groups = groupAndActiveContactIds?.map(item => ({ groupId: item?.group?.id, contactIds: item?.contactIds }));

        if (inactiveMembersPresentInSelectedGroups) {
          setCareJourneyState(prev => ({ ...prev, isCareJourneyInactiveMemberAlertOpen: true, assignCareJourneyData: { record, groups }}));
        } else {
          assignCareJourney(record, groups);
        }
      } else if (data?.data?.contactUuids?.length === 0) {
        showToast(
          toast,
          intl.formatMessage({ id: 'noActiveMemberInGroupToAssignJourney' }),
          ToastType.error,
          2000,
        );
      }
    } else if (membersGroupTableState?.selectedGroups[0]?.inActiveCount > 0) {
      showToast(
        toast,
        intl.formatMessage({ id: 'noActiveMemberInGroupToAssignJourney' }),
        ToastType.error,
        2000
      );
    } else {
      showToast(
        toast,
        intl.formatMessage({ id: 'noMemberInGroupToAssignJourney' }),
        ToastType.error,
        2000
      );
    }
  }


  const onSingleGroupBoxActionPerformed = (
    actionCode: string,
    actionData: IMembersGroupData
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ITEM_CLICKED:
        setMembersGroupTableState((prev) => {
          return {
            ...prev,
            showSummary: true,
            selectedGroup: actionData,
          };
        });
        break;

      case COMMON_ACTION_CODES.EDIT:
        setSelectedGroupId(actionData.id);
        setSelectedGroupRowData(actionData);
        setOpenModal({
          type: 'EditSegment',
        });
        break;

      case COMMON_ACTION_CODES.DELETE:
        setMembersGroupTableState((prev) => {
          return {
            ...prev,
            deleteModal: true,
            selectedGroup: actionData,
          };
        });
        break;

      case COMMON_ACTION_CODES.LIST_VIEW:
        setMembersGroupTableState((prev) => {
          return {
            ...prev,
            showMemberList: true,
            selectedGroup: actionData,
          };
        });
        break;
    }
  };

  const handleSelection = (
    groupData: IMembersGroupData,
    isSelected: boolean
  ) => {
    let newSelectedRowKeys = [...selectedRowKeys]
    if (isSelected) {
      const array = membersGroupTableState.selectedGroups || [];
      array.push(groupData);
      setMembersGroupTableState((prev) => {
        return {
          ...prev,
          selectedGroups: array,
        };
      });
      if (groupData.id && (newSelectedRowKeys.indexOf(groupData.id) == -1)) {
        newSelectedRowKeys.push(groupData.id);
      }
    } else {
      const array = membersGroupTableState.selectedGroups.filter(
        (item) => item.id != groupData.id
      );
      setMembersGroupTableState((prev) => {
        return {
          ...prev,
          selectedGroups: array,
        };
      });
      newSelectedRowKeys = newSelectedRowKeys.filter((selectedKey) => {
        return selectedKey !== groupData.id;
      });
    }
    setSelectedRowKeys(newSelectedRowKeys)
  };
  useEffect(() => {
    props.onActionPerformed?.('BUTTON_LIST', buttonList);
    if (isWalkThrough) {
      setOpenModal({
        type: 'CreateSegment',
      });
    }
  }, []);
  useEffect(() => {
    props.onActionPerformed?.('BULK_BUTTON_LIST', bulkButtonList);
  }, [membersGroupTableState.selectedGroups.length == 1, hasSelected]);

  useEffect(() => {
    props.onActionPerformed?.('SELECT_GROUP', membersGroupTableState.selectedGroups);
  }, [membersGroupTableState.selectedGroups.length]);


  useEffect(() => {
    onTableTopBarActionPerformed(
      props.selectedActionCode,
      props.selectedRawData
    );
  }, [props.selectedRawData]);

  const onRowClicked = (data: any, columnKey?: string) => {
    const url = isLeadGroupsPage()
      ? `/members/lead_groups/${data.id}`
      : `${isPopulationGroupContext ? '/members/groups/' : '/suggestionRules/rule/'}${props?.context}/${data.id}`;    
    const state: {
      groupData: IGroupData;
      context: CampaignSegmentTableContext;
      inactiveColumnClicked?: boolean;
    } = {
      groupData: data,
      context: props?.context || CampaignSegmentTableContext.POPULATION_GROUP
    };

    if (columnKey === MEMBER_FILTER_STATUS.INACTIVE) {
      state.inactiveColumnClicked = true;
    }
    navigate(url, {
      state,
      replace: false,
    });
  };
  const onAddMemberClick = async (id: string) => {
    setIsActionLoading(true);
    const res = await getCampaignSegmentRules(id);

    if (res) {
      setEditData({
        ...editData,
        id: res.id,
        description: res.description,
        audienceName: res.name,
        patientList:
          res.type === POPULATION_GROUP_TYPES.STATIC ||
          res.type === POPULATION_GROUP_TYPES.STATIC_LEAD
            ? res.rule.patientFilter.contactIds
            : [],
        jsonTree:
          res.type === POPULATION_GROUP_TYPES.DYNAMIC ||
          res.type === POPULATION_GROUP_TYPES.DYNAMIC_LEAD
            ? res.displayJson
            : {},
      });
      setIsActionLoading(false);
      setOpenModal({
        type: 'AddGroupMember',
      });
    }
  };

  const onEdit = async (id: string) => {
    setIsActionLoading(true);
    const res = await getCampaignSegmentRules(id);

    if (res) {
      setEditData({
        ...editData,
        id: res.id,
        description: res.description,
        audienceName: res.name,
        patientList:
          res.type === POPULATION_GROUP_TYPES.STATIC ||
          res.type === POPULATION_GROUP_TYPES.STATIC_LEAD
            ? res.rule.patientFilter.contactIds
            : [],
        jsonTree:
          res.type === POPULATION_GROUP_TYPES.DYNAMIC ||
          res.type === POPULATION_GROUP_TYPES.DYNAMIC_LEAD
            ? res.displayJson
            : {},
        executionFrequency: res.type === POPULATION_GROUP_TYPES.DYNAMIC ||
          res.type === POPULATION_GROUP_TYPES.DYNAMIC_LEAD? res.executionFrequency: '',
        populationGroupId: res?.inputRuleId,
        memberStatusCodeFromResponseWhenEditingGroup: res.type === POPULATION_GROUP_TYPES.DYNAMIC
          ? (openInHint
            ? res?.preFilter?.hintMembershipStatus?.params?.value
            : res?.preFilter?.foldMembershipStatus?.params?.value)
          : '',
        ruleLocations: res?.ruleLocations || []
      });
      setIsActionLoading(false);
      setOpenModal({
        type: 'EditCampaign',
      });
    }
  };

  const onAuditClick = (id: string) => {
    setMembersGroupTableState((prev) => {
      return {
        ...prev,
        showGroupDetailDrawer: true,
        selectedRuleIdForAuditDrawer: id,
      }
    })
  }

  const onDelete = async (id: string, message: string) => {
    setConfirmationModal({
      ...confirmationModal,
      isOpen: true,
      id: id,
      message: message,
      title: intl.formatMessage({id: 'deleteGroup'}),
      action: CAMPAIGN_SEGMENT_TABLE_ACTIONS.DELETE,
    });
  };

  const onDeleteConfirm = async () => {
    deleteCampaignSegmentRules(confirmationModal.id)
      .then((resp: any) => {
        if (resp.id) {
          showToast(toast, intl.formatMessage({id: 'deleteGroupSuccessMessage'}), ToastType.success);
          setConfirmationModal({...confirmationModal, isOpen: false, id: ''});
          setIsActionLoading(false);
          loadMembersGroup(true);
        }
      })
      .catch((error) => {

        setConfirmationModal({...confirmationModal, isOpen: false, id: ''});
        setIsActionLoading(false);
        showToast(toast, intl.formatMessage({id: 'deleteGroupErrorMessage'}), ToastType.error);
      });
  };

  const onTriggerRule = async (id: string) => {
    setConfirmationModal({
      ...confirmationModal,
      isOpen: true,
      id: id,
      title: intl.formatMessage({id: 'executeRule'}),
      message: intl.formatMessage({id: 'executeRuleConfirmMessage'}),
      action: CAMPAIGN_SEGMENT_TABLE_ACTIONS.EXECUTE_RULE
    });
  };

  const onTriggerRuleConfirm = () => {
    setIsActionLoading(true);
    executeCampaignSegmentRule(confirmationModal.id)
      .then((resp: any) => {
          showToast(toast, intl.formatMessage({id: 'executeRuleSuccessMessage'}), ToastType.success);
          setConfirmationModal({...confirmationModal, isOpen: false, id: ''});
          setIsActionLoading(false);
          loadMembersGroup(false);
      })
      .catch((error) => {

        showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
        setConfirmationModal({...confirmationModal, isOpen: false, id: ''});
        setIsActionLoading(false);
      });
  }

  const deleteModalBtnList = [
    {
      show: true,
      id: 1,
      btnText: intl.formatMessage({
        id: 'cancel',
      }),
      isLoading: false,
      textColor: Colors.Custom.mainSecondaryBrown,
      variant: BUTTON_TYPE.SECONDARY,
      isTransBtn: false,
      onClick: () => {
        setConfirmationModal({...confirmationModal, isOpen: false, id: ''});
      },
    },
    {
      show: true,
      isLoading: isActionLoading,
      id: 2,
      btnText: intl.formatMessage({id: confirmationModal.action === CAMPAIGN_SEGMENT_TABLE_ACTIONS.DELETE ? 'delete': 'execute'}),
      textColor: Colors.Custom.mainPrimaryPurple,
      variant: BUTTON_TYPE.PRIMARY,
      isTransBtn: false,
      onClick: () => {
        setIsActionLoading(true);
        if(confirmationModal.action === CAMPAIGN_SEGMENT_TABLE_ACTIONS.DELETE){
          onDeleteConfirm();
        }else if(confirmationModal.action === CAMPAIGN_SEGMENT_TABLE_ACTIONS.EXECUTE_RULE){
          onTriggerRuleConfirm()
        }
      },
    },
  ];

  const handleOnFilterChange = (filterData: IPopulationGroupFilter, appliedFilters: string[]) => {
    setMembersGroupTableState((prev) => {
        return {
            ...prev,
            page: 0,
            appliedFilters: appliedFilters,
            filter: filterData,
        };
    });

    if(props.onActionPerformed){
      props.onActionPerformed(COMMON_ACTION_CODES.CANCEL, {});
      props.onActionPerformed(COMMON_ACTION_CODES.SAVE, appliedFilters)
    }
}

  const selectedGroupLocationIds = useMemo(() => {
    if (!isMultiTenancyEnabled) {
      return [];
    }
    return membersGroupTableState?.groupAndActiveContactIds?.[0]?.group?.locationIds?.map(
      (loc: {locationId: string}) => loc?.locationId
    );
  }, [membersGroupTableState?.groupAndActiveContactIds?.length, isMultiTenancyEnabled]);

  return (
    <View style={{opacity: isActionLoading ? 0.5 : 1}} position="relative">
      <Modal
        isOpen={confirmationModal.isOpen}
        onClose={() => {
          setConfirmationModal((prev) => {
            return {
              ...prev,
              isOpen: false,
            };
          });
        }}
      >
        <Content style={{}}>
          <CloseButton />
          <Header>
            <DisplayText size={'mdMedium'} textLocalId={confirmationModal.title} textType="Heading" />
          </Header>
          <Body>
            <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} textLocalId={confirmationModal?.message} textType="Text" />
          </Body>
          <Footer style={{}}>
            <Button.Group>
              {deleteModalBtnList.map((button) => {
                return (
                  <FoldButton
                    key={button.id}
                    nativeProps={{
                      variant: button.variant,
                      onPress: () => {
                        button.onClick();
                      },
                      style: {marginHorizontal: 5},
                    }}
                    customProps={{
                      btnText: intl.formatMessage({
                        id: button.btnText,
                      }),
                      withRightBorder: false,
                    }}
                  ></FoldButton>
                );
              })}
            </Button.Group>
          </Footer>
        </Content>
      </Modal>

      {isActionLoading && (
        <Center position={'absolute'} height="100%" width="100%" zIndex={100}>
          <Spinner size={'lg'} />
        </Center>
      )}
      <TableWrapper
        pagination={{
          current: membersGroupTableState.page + 1,
          pageSize: membersGroupTableState.pageSize,
          total: membersGroupTableState.total || 0,
          pageSizeOptions: [...DEFAULT_PAGE_SIZE_OPTIONS,  MODULE_PAGINATION_COUNT.MEMBERS_GROUP_TABLE_PAGE_SIZE].sort((a, b) => a - b),
          onChange: (currentPage, currentPageSize) => {
            setMembersGroupTableState((prev) => {
              return {
                ...prev,
                page: currentPage - 1,
                pageSize: currentPageSize,
              };
            });
          },
          showSizeChanger: true,
        }}
        hideOnSinglePage={false}
      >
        <Table
          dataSource={membersGroupTableState.formattedMembersGroupData}
          columns={getGroupListColumns(
            onSingleGroupBoxActionPerformed,
            onEdit,
            onAddMemberClick,
            onDelete,
            onTriggerRule,
            onAuditClick,
            onRowClicked, 
            props?.context || CampaignSegmentTableContext.POPULATION_GROUP,
            openInHint,
            membersGroupTableState?.selectedGroups?.length || 0,
            membersGroupTableState?.sort,
            isMultiTenancyEnabled
          )}
          onChange={(pagination, filters, sorter: any) => {
            if (sorter) {
              setMembersGroupTableState((prev) => ({
                ...prev,
                page: 0,
                sort: {
                  columnKey: sorter.columnKey,
                  order: sorter.order,
                },
              }));
            }
          }}
          loading={membersGroupTableState?.isLoading}
          rowClassName={(record, index) =>
            index % 2 == 0 ? 'table-row-light' : ''
          }
          className="lead-list-table-view patient-list-view"
          scroll={{x: 500, y: height - heightDifferenceForTable}}
          rowSelection={
            isPopulationGroupContext
              ? {
                  type: 'checkbox',
                  selectedRowKeys: membersGroupTableState?.selectedGroups?.map(
                    (selectedGroup) => `${selectedGroup?.id}_${selectedGroup?.name}` || ''
                  ),
                  onSelect: async (item: any, isSelected: boolean) => {
                    try {
                      if(isMsoEnabled){
                        const groupDetails = await getGroupDetailById(item?.id);
                        const updatedItem = {
                          ...item,
                          locationIds: groupDetails?.data?.ruleLocations || [],
                        };
                        handleSelection(updatedItem, isSelected);
                      }else{
                        handleSelection(item, isSelected);
                      }
                    } catch (error) {
                      console.error('Error fetching group details:', error);
                    }
                  },
                  hideSelectAll: true,
                }
              : undefined
          }
          // onChange={(pagination, filters, sorter: any) => {
          // }}
          rowKey={(row) => `${row.id}_${row.name}` as string}
          onRow={(_record: any) => {
            return {
              onClick: () => {
                onRowClicked(_record);
              },
            };
          }}
          pagination={false}
        />
      </TableWrapper>

      {/* {(!membersGroupTableState?.isLoading ||
        membersGroupTableState?.membersTableGroupData?.length) &&
      false ? (
        <View
          style={{
            backgroundColor: '#fff',
            borderColor: Colors.Custom.BorderColor,
            borderRadius: 8,
            overflow: 'hidden',
          }}
        >
          <FlatList
            numColumns={isIPadScreen ? 2 : 4}
            onEndReachedThreshold={0.2}
            key={isIPadScreen ? 'ipad' : 'web'}
            ListFooterComponent={
              membersGroupTableState?.isLoading ? (
                <Skeleton.Text lines={4}></Skeleton.Text>
              ) : (
                <></>
              )
            }
            onEndReached={() => {
              setMembersGroupTableState((prev) => {
                const newPage = prev.page + 1;
                if (prev.total > newPage * prev.pageSize) {
                  return {
                    ...prev,
                    page: prev.page + 1,
                    pageSize: prev.pageSize,
                  };
                } else {
                  return prev;
                }
              });
            }}
            height={height - 300}
            contentContainerStyle={{flexGrow: 1, overflow: 'scroll'}}
            data={membersGroupTableState.membersTableGroupData}
            renderItem={(data: any) => {
              const group = data?.item || {};
              return (
                <HStack
                  style={{
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  <SingleGroupBox
                    key={group.id}
                    groupData={group}
                    onActionPerformed={(actionCode, actionData) => {
                      onSingleGroupBoxActionPerformed(actionCode, actionData);
                    }}
                    onChecked={(groupData, isChecked) => {
                      handleSelection(groupData, isChecked);
                    }}
                  />
                </HStack>
              );
            }}
          />
        </View>
      ) : (
        <></>
      )} */}

      <Drawer
        open={campaignDrawerState?.isVisible}
        width={Dimensions.get('window').width * 0.7}
        onClose={() => {
          setCampaignDrawerState({
            isVisible: false,
            segmentId: null,
          });
        }}
        headerStyle={{
          display: 'none',
        }}
      >
        {campaignDrawerState?.isVisible ? (
          <AddOrUpdateCampaign
            shouldShowEdit={true}
            initData={undefined}
            segmentId={campaignDrawerState?.segmentId}
            close={() => {
              setCampaignDrawerState({
                isVisible: false,
                segmentId: null,
              });
            }}
          />
        ) : null}
      </Drawer>

      {openModal && (
        <Drawer
          headerStyle={{
            display: 'none',
          }}
          width={openModal.customDrawerWidth ||
            (openModal?.type === 'importRule'
              ? finalWidthForImportRulesDrawer
              : finalWidth)
          }
          open={openModal?.type ? true : false}
          closeIcon={null}
          closable
          // title={onDrawerHeader(openModal?.type)}
          maskClosable={true}
          destroyOnClose
          onClose={() => {
            setOpenModal({
              type: '',
            });
            switch (openModal?.type) {
              case 'importRule':
                loadMembersGroup(true);
                break;
              default:
                break;
            }
          }}
        >
          {onDrawerContent(openModal?.type)}
        </Drawer>
      )}
      {formSendModal && (
        <ContactSendForm
          isVisible={formSendModal}
          assignmentData={{
            assignedById: accountUuid,
          }}
          onActionComplete={() => {
            setFormSendModal(false);
          }}
          onClose={() => {
            setFormSendModal(false);
          }}
          sendingFormBySelectingPopulationGroupFromList={true}
          groupAndActiveContactIds={
            membersGroupTableState?.groupAndActiveContactIds
          }
          contactIds={
            membersGroupTableState?.groupAndActiveContactIds[0]?.contactIds
          }
          formLocations={selectedGroupLocationIds}
          useAbsoluteLocation={isMultiTenancyEnabled}
        />
      )}

      {membersGroupTableState.deleteModal && (
        <DeleteEmployeeModal
          isOpen={membersGroupTableState.deleteModal}
          title={'Delete Group'}
          subTitle={'Are you sure, you want to delete group?'}
          onConfirm={() => {
            onMembersGroupTableActionPerformed(
              COMMON_ACTION_CODES.DELETE,
              membersGroupTableState.selectedGroup
            );
            setMembersGroupTableState((prev) => {
              return {
                ...prev,
                total: null,
                page: 0,
                membersTableGroupData: [],
                deleteModal: false,
              };
            });
          }}
          onClose={() => {
            setMembersGroupTableState((prev) => {
              return {
                ...prev,
                deleteModal: false,
              };
            });
          }}
        />
      )}

      {membersGroupTableState.showSummary && (
        <GroupSummary
          isOpen={membersGroupTableState.showSummary}
          groupData={membersGroupTableState.selectedGroup}
          onClose={() => {
            setMembersGroupTableState((prev) => {
              return {
                ...prev,
                showSummary: false,
              };
            });
          }}
        />
      )}

      {membersGroupTableState.showMemberList && (
        <GroupMemberListDrawer
          groupData={membersGroupTableState.selectedGroup}
          isOpen={membersGroupTableState.showMemberList}
          onClose={() => {
            setMembersGroupTableState((prev) => {
              return {
                ...prev,
                showMemberList: false,
              };
            });
          }}
        />
      )}
      {inactiveOrNoMemberAlert && (
        <FHAlertDialog
          isOpen={inactiveOrNoMemberAlert}
          header={'Send Form?'}
          message={intl.formatMessage({
            id: 'selectedGroupsContainInactiveMembers',
          })}
          buttonActions={[
            {
              textLocalId: 'No',
              buttonProps: {
                variant: BUTTON_TYPE.SECONDARY,
              },
              onPress: () => {
                setInactiveOrNoMemberAlert(false);
              },
            },
            {
              textLocalId: 'Yes',
              buttonProps: {
                variant: BUTTON_TYPE.PRIMARY,
              },
              onPress: () => {
                setFormSendModal(true);
                setInactiveOrNoMemberAlert(false);
                setSelectedRowKeys(
                  membersGroupTableState.groupAndActiveContactIds.map(
                    (Item) => Item?.group?.id
                  ) as any
                );
                setMembersGroupTableState((prev) => {
                  return {
                    ...prev,
                    selectedGroups:
                      membersGroupTableState?.groupAndActiveContactIds?.map(
                        (Item) => Item?.group
                      ) as any,
                  };
                });
              },
            },
          ]}
        />
      )}
      {membersGroupTableState.showAssignJourneyMemberListDrawer && (
        <AssignJourneyListView
          fetchContactsBy={FetchContactsBy.GROUP_ID}
          locationAndRuleIdBasedContactIdsFilter={true}
          ruleData={membersGroupTableState?.selectedGroups?.[0]}
          visible={membersGroupTableState.showAssignJourneyMemberListDrawer}
          selectedJourney={membersGroupTableState?.selectedJourneyToAssign}
          onAssign={(record: IMembersGroupData, unassignedContactsUuids: string[]) => {
            setMembersGroupTableState((prev) => ({
              ...prev,
              showAssignJourneyMemberListDrawer: false,
            }));
            onClickAssignCareJourney(
              membersGroupTableState.selectedJourneyToAssign,
              unassignedContactsUuids
            );
          }}
          onClose={() =>
            setMembersGroupTableState((prev) => ({
              ...prev,
              showAssignJourneyMemberListDrawer: false,
              selectedJourneyToAssign: undefined,
            }))
          }
        />
      )}
      {careJourneyState.isCareJourneyInactiveMemberAlertOpen && (
        <AntdModal
          open={careJourneyState.isCareJourneyInactiveMemberAlertOpen}
          onCancel={() => {
            setCareJourneyState((prev) => ({
              ...prev,
              isCareJourneyInactiveMemberAlertOpen: false,
              assignCareJourneyData: undefined as any,
            }));
          }}
          footer={null}
          width="35%"
          bodyStyle={{
            backgroundColor: Colors.Custom.BackgroundColor,
            padding: 0,
          }}
          title={'Assign Journey?'}
          closable={false}
          className="popGroupFormNotificationConfirmation"
        >
          <Divider></Divider>
          <div className="modal-body">
            <Text>
              {intl.formatMessage({
                id: 'popGroupCareJourneyAssignInactiveMemberNotification',
              })}
            </Text>
          </div>
          <div className="modal-footer" style={{border: 'none'}}>
            <Button.Group>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    setCareJourneyState((prev) => ({
                      ...prev,
                      isCareJourneyInactiveMemberAlertOpen: false,
                      assignCareJourneyData: undefined as any,
                    }));
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({id: 'No'}),
                  withRightBorder: false,
                }}
              />
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    assignCareJourney(
                      careJourneyState.assignCareJourneyData?.record,
                      careJourneyState.assignCareJourneyData?.groups
                    );
                    setCareJourneyState((prev) => ({
                      ...prev,
                      isCareJourneyInactiveMemberAlertOpen: false,
                      assignCareJourneyData: undefined as any,
                    }));
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({id: 'Yes'}),
                  withRightBorder: false,
                }}
              />
            </Button.Group>
          </div>
        </AntdModal>
      )}

      {props.selectedActionCode === TABLE_TOP_BAR_ACTION_CODES.FILTER_VIEW && (
        <PopulationGroupFilter
          isLeadGroup={isLeadGroupsPage() ? true : false}
          onClose={() => {
            props.onActionPerformed && props.onActionPerformed(COMMON_ACTION_CODES.CANCEL, {});
          }}
          filterData={membersGroupTableState.filter}
          onApply={(filterData: IPopulationGroupFilter, appliedFilters: string[]) => {
            handleOnFilterChange(filterData, appliedFilters);
          }}
          onReset={() => {
            handleOnFilterChange({type: ''}, []);
          }}
        ></PopulationGroupFilter>
      )}
      {membersGroupTableState.showGroupDetailDrawer &&
        !!membersGroupTableState.selectedRuleIdForAuditDrawer && (
          <GroupDetailsDrawer
            onCancel={onCancelAuditDetailDrawer}
            ruleId={membersGroupTableState.selectedRuleIdForAuditDrawer}
          />
        )}
    </View>
  );
};

export default CampaignSegmentTable;
