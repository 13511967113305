import React from 'react';
import {View, Text} from 'react-native';
import {Colors} from '../../../../styles';
import Feather from 'react-native-vector-icons/Feather';
import CPStatusBadge from './components/CPStatusBadge';
import FoldButtonV2, {
  ButtonType,
} from '../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import {
  CARE_PROGRAM_TYPE_CODES,
  CONTACT_CARE_PROGRAM_STATUS_CODES,
} from '../../../../constants/MlovConst';
import {CareProgramStatusAction} from './hooks/useCareProgramStatusAction';
import {ContactCareProgramReducerAction} from './reducer';
import CareProgramAssignmentView from './components/CareProgramAssignmentView';
import {Divider, Popover, Skeleton} from 'antd';
import TimeLoggingComponent from '../../TeamInbox/Conversations/MessagingContactDetails/TimeLoggingComponent';
import CareManagementThreeDotsMenu from './CareManagementThreeDotsMenu';
import {Pressable} from 'native-base';
import './styles.css';
import {useContactCareProgramHeaderActions} from './useContactCareProgramHeaderActions';
import {CareManagementThreeDotsMenuType} from './consts';

interface IContactCareProgramHeaderViewProps {
  statusId: string;
  careProgramTypeCode: string;
  careProgramName: string;
  careProgramId: string;
  contactId: string;
  componentLoading: boolean;
  careProgramAssigneeId?: string;
  dispatch: (action: ContactCareProgramReducerAction, payload: any) => void;
  careProgramStartDate: string;
}

const HeaderView: React.FC<IContactCareProgramHeaderViewProps> = (
  props: IContactCareProgramHeaderViewProps
) => {
  const {
    statusId,
    careProgramTypeCode,
    careProgramName,
    careProgramId,
    contactId,
    componentLoading,
    careProgramAssigneeId,
    dispatch,
    careProgramStartDate,
  } = props;
  const {
    activeDrawerCode,
    canComplete,
    completeActionLabel,
    loadingAction,
    statusCode,
    careProgramStatus,
    isAllowedToTakeActions,
    isStatusPopoverOpen,
    canUpdateStatus,
    getCareProgramUpdateStatusList,
    onStatusChange,
    setActiveDrawerCode,
    handleCareProgramAssignee,
    handleMarkAsComplete,
    handleClose,
    onStatusPopoverOpenChange,
  } = useContactCareProgramHeaderActions({
    statusId,
    careProgramId,
    contactId,
    careProgramTypeCode,
    careProgramStartDate,
    dispatch,
  });

  return (
    <div className="flex flex-row justify-between items-center header-view">
      <div className="flex flex-row">
        {componentLoading ? (
          <Skeleton.Input
            active
            style={{
              width: 500,
              borderRadius: 8,
            }}
          />
        ) : (
          <div className="flex flex-row items-center">
            <Text style={{fontSize: 14, fontWeight: '500', marginRight: 4}}>
              {careProgramName
                ? `${careProgramName} (${careProgramTypeCode})`
                : ''}
            </Text>
            {canUpdateStatus() ? (
              <Popover
                placement="bottomLeft"
                overlayStyle={{
                  padding: 0,
                }}
                overlayClassName="custom-popover p0"
                showArrow={false}
                overlayInnerStyle={{
                  borderRadius: 8,
                }}
                open={isStatusPopoverOpen}
                onOpenChange={(visible) => {
                  onStatusPopoverOpenChange(visible);
                }}
                content={
                  <View style={{padding: '8px', width: '144px'}}>
                    {getCareProgramUpdateStatusList().map((item) => (
                      <Pressable
                        key={item.id}
                        _hover={{
                          backgroundColor: Colors.FoldPixel.GRAY50,
                        }}
                        onPress={() => {
                          onStatusChange(item);
                        }}
                        style={{padding: '8px', borderRadius: 4}}
                      >
                        {item.value}
                      </Pressable>
                    ))}
                  </View>
                }
                trigger="hover"
              >
                <div style={{cursor: 'pointer'}}>
                  <CPStatusBadge statusCode={statusCode} />
                </div>
              </Popover>
            ) : (
              <CPStatusBadge statusCode={statusCode} />
            )}
            <CareProgramAssignmentView
              contactId={contactId}
              contactCareProgramId={careProgramId}
              careProgramAssigneeId={careProgramAssigneeId}
              onAssigneeActionPerformed={handleCareProgramAssignee}
            />
          </div>
        )}
      </div>

      <div className="flex flex-row items-center">
        {isAllowedToTakeActions &&
          (careProgramTypeCode === CARE_PROGRAM_TYPE_CODES.CCM ||
            careProgramTypeCode === CARE_PROGRAM_TYPE_CODES.ECM) &&
          ![
            CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED,
            CONTACT_CARE_PROGRAM_STATUS_CODES.CLOSED,
            CONTACT_CARE_PROGRAM_STATUS_CODES.AUTO_CLOSED,
          ].includes(statusCode) && (
            <TimeLoggingComponent
              contactUuid={contactId}
              contactCareProgramId={careProgramId}
              careProgramStartDate={careProgramStartDate}
            />
          )}
        {canComplete && (
          <>
            <Divider type="vertical" style={antStyles.dividerStyle} />
            <FoldButtonV2
              label={completeActionLabel}
              isDisabled={!canComplete}
              buttonType={ButtonType.tertiary}
              isLoading={loadingAction === CareProgramStatusAction.COMPLETE}
              onPress={handleMarkAsComplete}
            />
          </>
        )}
        {canUpdateStatus() && (
          <>
            <CareManagementThreeDotsMenu
              careProgramTypeCode={careProgramTypeCode}
              contactId={contactId}
              careProgramId={careProgramId}
              activeDrawerCode={activeDrawerCode}
              onClose={() => {
                setActiveDrawerCode(undefined);
              }}
              onSuccess={(action) => {
                if (action === CareManagementThreeDotsMenuType.CLOSE_PROGRAM) {
                  setActiveDrawerCode(undefined);
                  dispatch(
                    ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_STATUS,
                    careProgramStatus.closed
                  );
                }
              }}
            />
            <Divider type="vertical" style={antStyles.dividerStyle} />
          </>
        )}
        <div className="hover-pointer" onClick={handleClose}>
          <Feather name="x" size={24} color={Colors.Custom.Gray500} />
        </div>
      </div>
    </div>
  );
};

const antStyles: Record<string, React.CSSProperties> = {
  dividerStyle: {
    backgroundColor: Colors.FoldPixel.GRAY50,
    alignSelf: 'center',
    height: 32,
  },
};

export default React.memo(HeaderView);
