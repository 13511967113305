import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../../../styles';

export const styles = StyleSheet.create({
  wrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    marginTop: 32,
  },
  container: {
    backgroundColor: Colors.Custom.White,
    borderRadius: 8,
    padding: 16,
    alignItems: 'center',
    width: '100%',
    maxWidth: 600,
    gap: 16,
    borderWidth: 1,
    borderColor: Colors.Custom.GRAY150,
  },
  appointmentContainer: {
    backgroundColor: Colors.Custom.White,
    borderRadius: 8,
    alignItems: 'center',
    width: '100%',
    maxWidth: 600,
    borderWidth: 1,
    borderColor: Colors.Custom.GRAY150,
  },
  header: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.Custom.GRAY150,
    padding: 12,
    width: '100%',
  },
  titleContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  title: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.Custom.Gray900,
    marginBottom: 4,
  },
  titleScheduled: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.Custom.SuccessColor,
  },
  dateContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    marginBottom: 8,
    flexWrap: 'nowrap',
  },
  appoinmentDateContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  dateItem: {
    alignItems: 'center',
    gap: 4,
  },
  divider: {
    width: 1,
    height: 20,
    backgroundColor: Colors.Custom.Gray200,
    marginHorizontal: 8,
  },
  dateText: {
    fontSize: 12,
    color: Colors.Custom.Gray500,
    fontWeight: '400',
  },
  doctorInfo: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    flex: 1,
  },
  doctorName: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.Custom.Gray700,
  },
  doctorRole: {
    fontSize: 14,
    color: Colors.Custom.Gray500,
    backgroundColor: Colors.Custom.Gray100,
    paddingHorizontal: 6,
    paddingVertical: 2,
    borderRadius: 4,
    minWidth: 100,
    maxWidth: 200,
    textAlign: 'center',
  },
  appointmentDetails: {
    gap: 4,
    padding: 12,
    flex: 1,
  },
  detailRow: {
    gap: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  detailText: {
    fontSize: 14,
    color: Colors.Custom.Gray500,
  },
  scheduleButton: {
    backgroundColor: Colors.Custom.Primary300,
    borderRadius: 4,
    paddingVertical: 8,
    paddingHorizontal: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  pressable: {
    borderColor: Colors.Custom.Gray300,
    borderRadius: 4,
    paddingVertical: 7,
    paddingHorizontal: 8,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 0.5,
  },
  pressableText: {
    color: Colors.Custom.Gray500,
    fontSize: 14,
    lineHeight: 17,
    fontWeight: '500'
  },
  headerMainView: {
    width: '100%',
    flexDirection: 'row'
  },
  headerView: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  pressableView: {
    position: 'absolute',
    right: 0
  },
  appointmentDetailsView: {
    flex: 1,
    flexDirection: 'column',
    padding: 10
  },
  appointmentDetailsViewItem: {
    borderWidth: 2,
    borderColor: '#E2E8F0',
    borderRadius: 8,
    padding: 10
  },
  drawerContainerView: {
    flex: 1 ,
    paddingVertical: 0 ,
    paddingLeft: 26
  },
  appointmentDetailsViewItemText: {
    flex: 1,
    flexDirection: 'column',
    padding: 25,
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonText: {
    color: Colors.Custom.White,
    fontSize: 14,
    lineHeight: 17,
    fontWeight: '500',
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
    padding: 12,
  },
  viewButton: {
    borderRadius: 6,
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    backgroundColor: Colors.Custom.White,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  viewButtonText: {
    color: Colors.Custom.Gray500,
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 20,
  },
  moreButton: {
    alignItems: 'center',
    padding: 4,
    marginLeft: 4,
  },
  cardContainer: {
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
});

export const antStyles: Record<string, React.CSSProperties> = {
  skeletonWidth: {
    width: '60%',
  },
};
