import BaseService from '../../../../services/CommonService/BaseService';
import {getUserUUID} from '../../../../utils/commonUtils';
import {IGridColumn} from '../interface';

export const useGridConfigurations = () => {
  const userUuid = getUserUUID();
  const baseService = BaseService.getSharedInstance().axios;
  // const baseUrl = 'http://localhost:5431/crm-nest/api/v1/grid-configurations';
  const baseUrl = 'crm-nest/api/v1/grid-configurations';

  const getGridConfigurationDataFromAPI = async (
    tableCode: string,
    entityCode: string
  ) => {
    const baseService = BaseService.getSharedInstance().axios;
    const gridConfigurationUrl = `crm-nest/api/v1/grid-configurations/${tableCode}`;
    const gridResponse = await baseService.get(gridConfigurationUrl, {
      params: {
        entityCode: entityCode,
      },
    });
    return gridResponse?.data;
  };

  const postGridConfigurationData = async (
    tableCode: string,
    entityCode: string,
    gridColumns: IGridColumn[],
    sortingData?: {
      gridSortColumnCode: string;
      gridSortingValue: string;
    }
  ) => {
    const gridConfigurationUrl = `${baseUrl}/${tableCode}`;

    let data: any = {};
    data.gridColumns = gridColumns;
    data.entityCode = entityCode;
    if (sortingData?.gridSortColumnCode) {
      data = {
        ...data,
        gridSortColumnCode: sortingData.gridSortColumnCode,
        gridSortingValue: sortingData.gridSortingValue || null
      };
    }


    const response = await baseService.post(gridConfigurationUrl, data);
    return response?.data;
  };

  const putGridConfigurationData = async (
    grid_table_id: string,
    updateGridColumns?: Partial<IGridColumn>[],
    sortingData?: {
      gridSortColumnId: string;
      gridSortingValue: string;
    }
  ) => {
    let data: any = {};
    if (updateGridColumns && updateGridColumns?.length > 0) {
      data.updateGridColumns = updateGridColumns;
    }
    if (sortingData?.gridSortColumnId) {
      data = {
        gridSortColumnId: sortingData.gridSortColumnId,
        gridSortingValue: sortingData.gridSortingValue || null
      };
    }
    const gridConfigurationUrl = `${baseUrl}/${grid_table_id}`;
    const response = await baseService.put(gridConfigurationUrl, data);
    return response?.data;
  };

  const putGridConfigurationDataToDefault = async (
    tableCode: string,
    grid_table_id: string,
    entityCode: string
  ) => {
    const gridConfigurationDefaultUrl = `${baseUrl}/apply-default/${tableCode}`;
    const response = await baseService.put(gridConfigurationDefaultUrl, {
      grid_table_id,
      entityCode
    });
    return response?.data;
  };

  const formatGridColumnsData = (data: IGridColumn[]) => {
    return data
      .filter((column) => !column.isDeleted && column.isVisible)
      .sort((a, b) => a.sequence - b.sequence);
  };

  const getGridConfigurationData = async (
    tableCode: string,
    entityCode: string
  ) => {
    const gridResponse = await getGridConfigurationDataFromAPI(
      tableCode,
      entityCode
    );
    if (gridResponse?.gridColumns) {
      const gridColumnsData: IGridColumn[] = formatGridColumnsData(
        gridResponse?.gridColumns
      );
      return gridColumnsData;
    }
  };

  return {
    getGridConfigurationData,
    getGridConfigurationDataFromAPI,
    postGridConfigurationData,
    putGridConfigurationData,
    putGridConfigurationDataToDefault
  };
};
