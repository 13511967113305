import { Colors } from '../../../../../../../styles';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffff',
    padding: 16,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.Custom.GRAY150,
  },
  warningMessage: {
    backgroundColor: '#FFF3E0',
    padding: 16,
    borderRadius: 4,
    marginBottom: 20,
  },
  formRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    gap: 24,
  },
  input: {
    borderRadius: 4,
    paddingHorizontal: 12,
    paddingVertical: 8,
    fontSize: 12,
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: 24,
  },
  alignItemsCenter: {
    alignItems: 'center'
  },
  loadingText: {
    marginLeft: 12,
    color: Colors.Custom.Gray500
  }
});

export const antdStyles = {
  datePicker: {
    width: '100%',
    fontSize: 12,
    height: 36,
    borderRadius: 4,
  },
};
