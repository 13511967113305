import {View, Text, StyleSheet, Pressable} from 'react-native';
import React, {useContext, useMemo, useState} from 'react';
import {HStack, Spacer, VStack} from 'native-base';
import {Colors} from '../../../../../styles';
import {CarePlanScreenContext} from '../interfaces';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import Stack from '../../../../common/LayoutComponents/Stack';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import InfoSvg from '../../../../common/Svg/InfoSvg';
import { Popover, Skeleton } from 'antd';
import ClosedCollapseSvg from '../../../../common/Svg/SideCarSvg/ClosedCollpaseSvg';
import { DISPLAY_SLASH_DATE_FORMAT } from '../../../../../constants';
import { getDateStrFromFormat } from '../../../../../utils/DateUtils';
import ZapSvg from '../../../../../assets/svg/ZapSvg';
import { CARE_PLAN_SOURCE_TYPE_CODES } from '../../../../../constants/MlovConst';
import UserSvgV2 from '../../../../common/Svg/UserSvgV2';
import { useIntl } from 'react-intl';

const Header = (props: {
  headerAction: React.ReactNode;
  context: CarePlanScreenContext;
  title: string;
  titleFontSize?: number | string;
  subTitle?: string | JSX.Element;
  subTitleStyle?: any
  prefixAction?: React.ReactNode;
  carePlanSourceName: string;
  getCarePlanSourceName: () => void;
  onAutomationNavigation: () => void;
  loadingCarePlanSourceName: boolean;
  carePlanCreatedOn: string;
  carePlanSourceTypeId: string;
  automationSourceTypeId: string;
  disableAutomationNavigation: boolean;
  sourceId?: string;
}) => {
  const {
    headerAction,
    context,
    title,
    subTitle,
    subTitleStyle,
    carePlanSourceName,
    getCarePlanSourceName,
    loadingCarePlanSourceName,
    carePlanCreatedOn,
    carePlanSourceTypeId,
    automationSourceTypeId,
    onAutomationNavigation,
    disableAutomationNavigation,
    sourceId,
    prefixAction,
    titleFontSize,
  } = props;
  const contextData = useContext(CommonDataContext);
  const isSidecar = contextData?.sidecarContext?.isSidecar;
  const intl = useIntl();
  const [isInfoPopoverOpen, setIsInfoPopoverOpen] = useState(false);
  const isAutomationSource = carePlanSourceTypeId === automationSourceTypeId;

  const dynamicStyles = useMemo(() => {
    return StyleSheet.create({
      stackStyle: {
        marginLeft: !!props?.prefixAction ? 10 : undefined
      },
    });
  }, [props?.prefixAction]);

  const formattedCarePlanCreatedOn = useMemo(() => {
    return getDateStrFromFormat(carePlanCreatedOn, DISPLAY_SLASH_DATE_FORMAT)
  }, [carePlanCreatedOn]);

  const handleInfoPopoverVisibleChange = (visible: boolean) => {
    if (visible && !carePlanSourceName) {
      getCarePlanSourceName();
    }
    setIsInfoPopoverOpen(visible);
  };
  const popoverContent = (
    <Stack direction="column" style={styles.popoverContainer} space={4}>
      <>
        {loadingCarePlanSourceName ? (
          <Skeleton.Input active size="small" />
        ) : (
          <Stack direction="row" space={4}>
            {isAutomationSource ? (
              <ZapSvg strokeColor={Colors.FoldPixel.GRAY400} size={16} />
            ) : (
              <UserSvgV2 customStrokeColor={Colors.FoldPixel.GRAY400} width={16} height={16} />
            )}
            <Text style={styles.sourceName}>{carePlanSourceName}</Text>
          </Stack>
        )}
      </>
      <Text style={styles.createdDate}>{`Created: ${formattedCarePlanCreatedOn}`}</Text>
      {!loadingCarePlanSourceName && isAutomationSource && (
        <Pressable onPress={onAutomationNavigation} disabled={disableAutomationNavigation}>
          <Stack direction="row" space={11} style={{ alignItems: 'center' }}>
            <Text style={styles.viewAutomation}>
              {intl.formatMessage({ id: 'viewAutomation' })}
            </Text>
            <ClosedCollapseSvg customStrokeColor={Colors?.FoldPixel?.PRIMARY300} />
          </Stack>
        </Pressable>
      )}
    </Stack>
  );

  return (
    <VStack>
      <VStack
        w="100%"
        px={5}
        paddingY={2}
        backgroundColor={isSidecar ? undefined : Colors.Custom.White}
        borderBottomWidth={1}
        borderColor={Colors.Custom.BorderColor}
      >
        <HStack alignItems="center">
          {props.prefixAction}
          <Stack direction="column" style={dynamicStyles.stackStyle}>
            <Stack direction="row" space={8} style={{alignItems: 'center'}}>
              <DisplayText
              textLocalId={title || ''}
                size={'smMedium'}
                extraStyles={{
                  fontSize: props?.titleFontSize || 14,
                  color: Colors.FoldPixel.GRAY400
                }}
              />              
            </Stack>
            {subTitle && (
              <Stack direction="row" space={8} style={{alignItems: 'center'}}>
                {typeof subTitle === 'string' ? (
                  <DisplayText
                    extraStyles={{
                      color: Colors.Custom.Gray500,
                      ...subTitleStyle,
                    }}
                    textLocalId={subTitle || ''}
                  />
                ) : (
                  subTitle
                )}
                {context !== CarePlanScreenContext.ADD_CARE_PLAN &&
                  !!carePlanCreatedOn && (
                    <Popover
                      placement={'bottom'}
                      overlayInnerStyle={{borderRadius: 4}}
                      trigger="click"
                      open={isInfoPopoverOpen}
                      onOpenChange={handleInfoPopoverVisibleChange}
                      content={popoverContent}
                    >
                      <Pressable
                        onPress={() =>
                          handleInfoPopoverVisibleChange(!isInfoPopoverOpen)
                        }
                      >
                        <InfoSvg customDimension={'14'} />
                      </Pressable>
                    </Popover>
                  )}
              </Stack>
            )}
          </Stack>
          <Spacer />
          <HStack space={1} alignItems="center">
            {headerAction}
          </HStack>
        </HStack>
      </VStack>
    </VStack>
  );
};

const styles = StyleSheet.create({
  popoverContainer: {
    paddingHorizontal: 8,
    paddingVertical: 6,
    borderRadius: 4,
  },
  sourceName: {
    color: Colors.FoldPixel.GRAY400,
    fontWeight: '400',
    fontSize: 14,
  },
  createdDate: {
    color: Colors.FoldPixel.GRAY300,
    fontWeight: '400',
    fontSize: 12,
  },
  viewAutomation: {
    color: Colors.FoldPixel.PRIMARY300,
  },
});

export default Header;
