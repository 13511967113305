export const WIDGET_TYPES = {
  TPA_CARE_ADVOCATE: 'TPA_CARE_ADVOCATE',
  PATIENT_INFORMATION_FORM: 'PATIENT_INFORMATION',
};

export const FORM_CATEGORY_TYPES = {
  PROVIDER_REQUEST_FORM: 'PROVIDER_REQUEST_FORM',
  NOTES: 'SOAP',
  ASSESSMENT_FORM_CATEGORY: 'CLINICAL_ASSESSMENT',
  CARE_PLAN: 'CARE_PLAN',
};

export const ADMISSION_TYPE = {
  OBSERVATION: 'observation',
  READMISSION: 'readmission',
};

export const CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE = {
  ADT_EVENT: 'ADT_EVENT',
  FOLD: 'FOLD',
};

