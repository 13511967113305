import { Radio, RadioChangeEvent } from 'antd';
import React, { useContext } from 'react';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { getMlovListFromCategory } from '../../../utils/mlovUtils';
import { MLOV_CATEGORY } from '../../../constants';
import { Colors } from '../../../styles';
import { View } from 'native-base';

interface RevokeConsentReasonListProps {
  onRemoveReasonSelect: (reasonId: string) => void;
}

interface MlovItem {
  id: string;
  value: string;
}

const RevokeConsentReasonList: React.FC<RevokeConsentReasonListProps> = ({ onRemoveReasonSelect }) => {
  const commonData = useContext(CommonDataContext);
  const revokeReasonsMlovList = getMlovListFromCategory(
    commonData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_PROGRAM_CONSENT_REVOKE_REASON
  );

  const onRadioChange = (e: RadioChangeEvent): void => {
    const reasonId = e?.target?.value;
    onRemoveReasonSelect?.(reasonId);
  };

  return (
    <>
      {revokeReasonsMlovList?.length > 0 && (
        <Radio.Group onChange={onRadioChange}>
          <View style={{ marginTop: 16 }}>
            {revokeReasonsMlovList?.map((item: MlovItem) => (
              <Radio
                key={item.id}
                value={item.id}
                style={{ marginBottom: 8, color: Colors.Custom.Gray500 }}
              >
                {item.value}
              </Radio>
            ))}
          </View>
        </Radio.Group>
      )}
    </>
  );
};

export default RevokeConsentReasonList;
