import React from 'react';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {Colors} from '../../../../../styles';
import Stack from '../../../../common/LayoutComponents/Stack';
import NoCarePlanCreatedSvg from '../../../../common/Svg/CarePlanSvgs/NoCarePlanCreatedSvg';
import { FoldButton } from '../../../../CommonComponents/FoldButton/FoldButton';
import { BUTTON_TYPE } from '../../../../../constants';
import Feather from 'react-native-vector-icons/Feather';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';

export const NoCarePlanCreatedFallback = (props: {handleCreateCarePlanClick?: () => void}) => {
  const intl = useIntl();
  return (
    <Stack
      direction="column"
      style={styles.stackStyle}
    >
      <NoCarePlanCreatedSvg />
      <DisplayText
        size="lgNormal"
        textLocalId={'noCarePlanCreated'}
        extraStyles={{
          color: Colors.Custom.Gray500,
          textAlign: 'center',
          maxWidth: 300,
          fontSize: 16,
          marginTop: 20
        }}
      />
      <FoldButton
        customProps={{
          btnText: intl.formatMessage({ id: 'createNew' }),
          textColor: Colors.Custom.Primary300,
        }}
        nativeProps={{
          leftIcon: (
            <Feather
              name="plus"
              color={Colors.Custom.mainPrimaryPurple}
              size={16}
            />
          ),
          backgroundColor: Colors.Custom.PrimaryColor100,
          borderColor: Colors.Custom.Primary200,
          variant: BUTTON_TYPE.PRIMARY,
          height: '32px',
          borderRadius: 4,
          borderWidth: 0.5,
          fontSize: 14,
          fontWeight: '500',
          onPress: () => {
            props.handleCreateCarePlanClick?.();
          },
          marginTop: 5
        }}
      />
    </Stack>
  );
};

const styles = StyleSheet.create({
  stackStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 24
  }
});
