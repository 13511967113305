import React, {useContext, useEffect, useMemo, useState} from 'react';
import {View, Text, Pressable, ScrollView, Stack} from 'native-base';
import {StyleSheet} from 'react-native';
import {debounce} from 'lodash';
import {useIntl} from 'react-intl';
import { SearchBar } from '../../common/SearchBar';
import { Colors } from '../../../styles';
import { getMlovListFromCategory } from '../../../utils/mlovUtils';
import { MLOV_CATEGORY } from '../../../constants/MlovConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { Checkbox } from 'antd';
import { ICareProgramFilter } from './interface';

interface ICareProgramSelectionView {
  onProgramSelect: (program: any) => void;
  onClose: () => void;
  searchText: string;
  onSearchChange: (value: string) => void;
  isMultiSelect?: boolean;
  selectedPrograms?: ICareProgramFilter[]; 
}

const CareProgramSelectionView = ({
  onProgramSelect,
  onClose,
  searchText,
  onSearchChange,
  isMultiSelect = false,
  selectedPrograms = [],
}: ICareProgramSelectionView) => {
  const commonContextData = useContext(CommonDataContext);
  const careProgramTypesList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_TYPES
    ) || [];

  const CARE_MANAGEMENT_ACTION_LIST = useMemo(() => {
    return careProgramTypesList.map((item) => {
      return {
        tabCode: item.code,
        tabTitle: item.value,
      };
    });
  }, [careProgramTypesList]);

  const intl = useIntl();
  const [filteredPrograms, setFilteredPrograms] = useState(CARE_MANAGEMENT_ACTION_LIST);
  
  useEffect(() => {
    const filtered = CARE_MANAGEMENT_ACTION_LIST.filter(item => 
      item?.tabTitle?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
    setFilteredPrograms(filtered);
  }, [searchText]);

  useEffect(() => {
    return () => {
      setFilteredPrograms(CARE_MANAGEMENT_ACTION_LIST);
    };
  }, []);

  const handleProgramSelect = (program: any, isChecked: boolean) => {
    if (isMultiSelect) {
      const updatedPrograms = isChecked 
        ? [...selectedPrograms, program]
        : selectedPrograms.filter((p) => p.tabCode !== program.tabCode);
      
      onProgramSelect(updatedPrograms);
    } else {
      onProgramSelect(program);
      onClose();
    }
  };

  return (
    <View style={{width: '20rem'}}>
      <ScrollView>
        <View style={{paddingHorizontal: 4, paddingVertical: 4}}>
          <SearchBar
            searchText={searchText}
            placeholderText="Search Care Programs"
            onChange={debounce(onSearchChange, 200)}
          />
        </View>

        <ScrollView maxH={400}>
          <Stack direction='column'>
            {filteredPrograms?.length ? (
              filteredPrograms.map((program) => {
                const isSelected = selectedPrograms?.some(
                  p => p.tabCode === program.tabCode
                );
                return (
                  <Pressable
                    key={program.tabCode}
                    onPress={() => {
                      handleProgramSelect(program, !isSelected)
                      if (!isMultiSelect) {
                        onClose();
                      }
                    }}
                    _hover={{
                      backgroundColor: Colors.FoldPixel.GRAY50,
                      borderRadius: 4,
                    }}
                  >
                   <Stack direction="row" alignItems="center" px={2}>
                      {isMultiSelect && (
                        <Checkbox
                          checked={isSelected}
                          onChange={(e) => {
                            e.stopPropagation();
                            handleProgramSelect(program, e.target.checked);
                          }}
                        />
                      )}
                      <Text 
                        style={[
                          styles.popoverItem,
                          isSelected && styles.popoverItemSelected
                        ]}
                      >
                        {program.tabTitle}
                      </Text>
                    </Stack>
                  </Pressable>
                );
              })
            ) : (
              <Text
                textAlign={'center'}
                color={Colors.Custom.Gray500}
                fontWeight={'400'}
                fontSize={12}
                my={5}
              >
                {intl.formatMessage({id: 'noCarePrograms'})}
              </Text>
            )}
          </Stack>
        </ScrollView>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  popoverItem: {
    padding: 8,
    fontSize: 14,
    fontWeight: '400',
    color: Colors.Custom.Gray500,
  },
  popoverItemSelected: {
    color: Colors.Custom.Primary300,
    fontWeight: '500',
  }
});

export default CareProgramSelectionView;