import { View } from "react-native";
import React, { useContext } from "react";
import { addTimeToDate } from "../../../../../../../utils/DateUtils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_FUTURE_APPOINTMENT_DATA } from "../../../../../../../services/Appointment/AppointmentQueries";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../../../../../constants/Configs";
import { useEffect, useState } from "react";
import { AppointmentDetailsBanner } from "../../../../../../PersonOmniView/MiddleContainer/PatientNotes/components/AppointmentDetailsBanner";
import { Stack, VStack } from "native-base";
import { IAddOrUpdateCareProgramAttributesPayload } from "../../../../../ContactCareProgram/interface";
import { ADD_OR_UPDATE_STEP_ADDITIONAL_ATTRIBUTES } from "../../../../../../../services/CareProgram/CareProgramQueries";
import CustomDrawer from "../../../../../../common/CustomDrawer/CustomDrawer";
import { FoldButton } from "../../../../../../CommonComponents/FoldButton/FoldButton";
import { Colors } from "../../../../../../../styles/Colors";
import { ContactCareProgramReducerAction } from "../../../../../ContactCareProgram/ContactCareProgramView/reducer";
import { ADDITIONAL_ATTRIBUTE_KEY } from "../../../../../../common/MemebersView/constant";
import { getWhereConditionForUpcomingAppointmentData } from "../../../../../CareManagementView/CareManagementUtils";
import { getMlovListFromCategory } from "../../../../../../../utils/mlovUtils";
import { MLOV_CATEGORY, VIRTUAL_ASSIGNEE_TYPE_CODES } from "../../../../../../../constants/MlovConst";
import { CommonDataContext } from "../../../../../../../context/CommonDataContext";
import { IAppointmentDetail } from "../../../../../../PersonOmniView/LeftContainer/OtherDetails/PatientAppointmentDataView";
import { Skeleton } from "antd";
import { Text } from 'native-base';
import { styles } from "./CareProgramAppointmentStyle";
import { useIntl } from "react-intl";

interface CareProgramAppointmentListProps {
    contactUuid: string;
    careProgramId?: string;
    stepId?: string;
    handleAppointmentListDrawerClose?: (value: boolean) => void;
    isAppointmentListDrawerOpen?: boolean;
    handleAppointmentSummary?: (data?: any) => void;
    handleUpdateCareProgram?: (actionCode?: string, actionData?: any) => void;
    appointmentAttributeId?: string;
    pcpUserData?: any;
    selectedAppointmentData?: any;
    careProgramTypeCode?: string;
    isCareManagerAppointment?: boolean;
    isPcpAppointment?: boolean;
    careManagerUserData?: any;
}

const CareProgramAppointmentList = ({ contactUuid, careProgramId, stepId, handleAppointmentListDrawerClose, isAppointmentListDrawerOpen, handleAppointmentSummary, handleUpdateCareProgram, appointmentAttributeId, pcpUserData, selectedAppointmentData, careProgramTypeCode, isCareManagerAppointment, isPcpAppointment, careManagerUserData }: CareProgramAppointmentListProps) => {
    const commonDataContext = useContext(CommonDataContext);
    const intl = useIntl();
    const [appointmentList, setAppointmentList] = useState<IAppointmentDetail[]>([]);
    const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
    const [isLoadingAppointmentList, setIsLoadingAppointmentList] = useState<boolean>(false);
    const [selectedAppointment, setSelectedAppointment] = useState<any>(selectedAppointmentData);
    const appointmentStatusList =
        getMlovListFromCategory(
            commonDataContext.CARE_STUDIO_MLOV,
            MLOV_CATEGORY.APPOINTMENT_STATUS
        ) || [];

    const [getFutureAppointmentData] = useLazyQuery(GET_FUTURE_APPOINTMENT_DATA, {
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        fetchPolicy: 'no-cache',
    });

    const [addOrUpdateCareProgramAttributes] = useMutation(
        ADD_OR_UPDATE_STEP_ADDITIONAL_ATTRIBUTES,
        {
            context: { service: CARESTUDIO_APOLLO_CONTEXT },
            fetchPolicy: 'no-cache',
        }
    );

    const addOrUpdateAppointmentAttribute = async (appointmentId: string, stepId: string, careProgramId: string) => {
        const params: IAddOrUpdateCareProgramAttributesPayload = {
            careProgramAttributes: [
                {
                    contactCareProgramId: careProgramId,
                    subjectId: stepId,
                    subjectTypeCode: 'CARE_PROGRAM_STEP',
                    additionalAttributes: [
                        {
                            attributeKey: ADDITIONAL_ATTRIBUTE_KEY.APPOINTMENT_ID,
                            attributeValueDataTypeCode: 'string',
                            attributeValue: {
                                valueString: appointmentId,
                            },
                            isDeleted: false,
                        },
                    ],
                },
            ],
        };
        await addOrUpdateCareProgramAttributes({
            variables: { params },
        });
    }

    const handleAppointmentList = async () => {
        setIsLoadingAppointmentList(true);
        const startDateTime = addTimeToDate(new Date(), -1, 'WEEK').toISOString();
        const endDateTime = addTimeToDate(new Date(), 1, 'MONTH').toISOString();
        const userId = isPcpAppointment ? pcpUserData?.uuid : careManagerUserData?.uuid
        if (userId) {
            const whereCondition = getWhereConditionForUpcomingAppointmentData(
                userId,
                contactUuid,
                appointmentStatusList,
                startDateTime,
                endDateTime,
                VIRTUAL_ASSIGNEE_TYPE_CODES.CLINIC,
                careProgramTypeCode == 'AWV' ? careProgramTypeCode : 'ROUTINE'
            );
            const response = await getFutureAppointmentData({
                variables: { whereCondition: whereCondition },
            });
            if (response?.data && response?.data?.appointments && response?.data?.appointments?.length) {
                const updatedAppointments = response.data.appointments.map((appointment: any) => ({
                    ...appointment,
                    participants: appointment.participants?.map((participant: any) => {
                        const appointmentUserUuid = isPcpAppointment ? pcpUserData.uuid : careManagerUserData.uuid
                        if (participant.userId === appointmentUserUuid) {
                            return {
                                ...participant,
                                user: isPcpAppointment ? pcpUserData : careManagerUserData
                            };
                        }
                        return participant;
                    })
                }));
                setAppointmentList(updatedAppointments);
            } else {
                setAppointmentList([]);
            }
        }
        setIsLoadingAppointmentList(false);
    }

    useEffect(() => {
        handleAppointmentList();
    }, []);

    const handleAppointmentClick = (appointment: any) => {
        setSelectedAppointment(appointment);
    }

    const handleScheduleAppointment = async (
        appointmentId: string
    ) => {
        try {
            setIsButtonLoading(true);
            const careProgramStepAdditionalAttribute = {
                attributeKey: ADDITIONAL_ATTRIBUTE_KEY.APPOINTMENT_ID,
                attributeValueDataTypeCode: 'string',
                attributeValue: {
                    valueString: appointmentId,
                },
                ...(appointmentAttributeId && { id: appointmentAttributeId }),
                subjectId: stepId,
                subjectTypeCode: 'CARE_PROGRAM_STEP',
            };
            if (stepId && careProgramId) {
                await addOrUpdateAppointmentAttribute(appointmentId, stepId, careProgramId);
            }
            handleUpdateCareProgram && await handleUpdateCareProgram(ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_STEP_ADDITIONAL_ATTRIBUTES, careProgramStepAdditionalAttribute);
            handleAppointmentSummary && await handleAppointmentSummary(selectedAppointment);
            return {
                success: true,
                appointmentId: appointmentId
            };
        } catch (error) {
            return false;
        }
    };

    const LoadingSkeletonView = (
        <Stack direction="column" >
            <Stack direction="row" >
                <Skeleton active paragraph={true} />
            </Stack>
        </Stack>
    );


    return (

        <CustomDrawer
            title={intl.formatMessage({ id: 'appointmentsList' })}
            customization={{
                width: 650
            }}
            open={isAppointmentListDrawerOpen}
            onClose={() => {
                handleAppointmentListDrawerClose && handleAppointmentListDrawerClose(false);
            }}
          customStylesClasses="no-body-padding "
            headerButtons={[
                <FoldButton
                    customProps={{
                        btnText: 'Link',
                    }}
                    nativeProps={{
                        isDisabled: !selectedAppointment && appointmentList.length > 0 ,
                        isLoading: isButtonLoading,
                        onPress: async () => {
                            selectedAppointment && await handleScheduleAppointment(selectedAppointment.id);
                            handleAppointmentListDrawerClose && handleAppointmentListDrawerClose(false);
                            setIsButtonLoading(false);

                        },
                        backgroundColor: !selectedAppointment
                            ? Colors.FoldPixel.GRAY50
                            : Colors.Custom.Primary300,
                        _text: {
                            fontSize: 14,
                            lineHeight: 16.8,
                            fontWeight: 'bold',
                            color: !selectedAppointment
                                ? Colors.FoldPixel.GRAY150
                                : Colors.Custom.BackgroundColor,
                        }
                    }}
                />,
            ]}

        ><View> 
            <View style={styles.drawerContainerView}>
                 <Text color={Colors.FoldPixel.GRAY300} fontSize={16} >{intl.formatMessage({ id: 'appointmentsFromLastWeekToNextMonth' })}</Text>
            </View>
            {isLoadingAppointmentList ? LoadingSkeletonView : appointmentList.length > 0 ? (
                <View style={styles.appointmentDetailsView}>
                    {appointmentList.map(
                        (appointment: IAppointmentDetail, index: number) => {
                            const key = `appointment_${appointment.id}_${index}`
                            return (
                                <VStack key={key} style={{ marginBottom: 10 }}>
                                    <View style={styles.appointmentDetailsViewItem}>
                                        <AppointmentDetailsBanner
                                            appointment={appointment}
                                            canOpenAppointmentDetailsModal={false}
                                            canOpenAppointmentTaskModal={false}
                                            canShowViewChecklistLink={true}
                                            handleAppointmentClick={handleAppointmentClick}
                                            selectedAppointmentId={selectedAppointment?.id}
                                        />
                                    </View>
                                </VStack>
                            );
                        }
                    )}
                </View>
            ) : (
                <View style={styles.appointmentDetailsViewItemText}>
                    <Text>{intl.formatMessage({ id: 'noAppointmentsFound' })}</Text>
                </View>
            )}
            </View>
        </CustomDrawer>
    )
}

export default React.memo(CareProgramAppointmentList);