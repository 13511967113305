import {useLazyQuery} from '@apollo/client';
import {Center, Pressable, ScrollView, Skeleton, Text, View} from 'native-base';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {Keyboard, FlatList as RNFlatList, SafeAreaView} from 'react-native';
import {FlatList} from 'react-native-bidirectional-infinite-scroll';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {COPY_MESSAGE_CODE, MESSAGE_FLAT_LIST_CONST, MESSAGE_WINDOW_TABS} from '../../../../../constants';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {CONVERSATION_LOCAL_EVENT_CODES, WEB_SOCKET_EVENT_CODE} from '../../../../../constants/WebSocketConst';
import ConversationsQueries from '../../../../../services/Conversations/ConversationsQueries';
import {TestIdentifiers, testID} from '../../../../../testUtils';
import {EventBus} from '../../../../../utils/EventBus';
import {getUserUUID, isChannelEmail, showInfoOnMessageHeader} from '../../../../../utils/commonUtils';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {isWeb} from '../../../../../utils/platformCheckUtils';
import StickyNoteMessageView from '../../../../common/ActionMessageView/StickyNoteMessageView';
import {ConversationAttachments} from '../../../../common/ChatUI/MessageBox/ConversationAttachments';
import {MessagesFlatList} from '../../../../common/MessagesFlatList';
import {EmptyStateSvg} from '../../../../common/Svg';
import {CHANNEL_TYPE, CHANNEL_TYPE_CODE} from '../ConversationConst';
import {
  IConversationData,
  IMessageBoxData,
  IMessagingWindowCommon,
} from '../interfaces';
import {
  getContactDataAndType,
  getLastNonInternalMessageInList,
  getMembersId,
  getUniqueDisplayMessageList,
  isBroadCastGroup,
  isInternalChat} from './MessagingUtils';
import {ICopiedMessageItem} from './interface';
import { MessagingEventQueue } from '../MessagingEventQueue/MessagingEventQueue';
import {MessagingWindowCommonTabs} from './MessagingWindowCommonTabs';
import {RenderMsgList} from './RenderMsgList';
import { getMessagingWindowCommonStyles } from './MessagingWindowCommonStyles';

const GetMessagingListElem = (props: IMessagingWindowCommon): JSX.Element => {
  const {
    msgData,
    selectedConversation,
    selectedInboxTypeCode,
    isKeyboardVisible,
    isPrivateTab,
    searchMessage,
    onRedirectToMentionChange  } = props;
  const eventQueueRef = useRef(new MessagingEventQueue({moduleCode: 'MessagingWindowCommon'}));
  const eventQueue = eventQueueRef?.current;

  const userUuid = getUserUUID();
  const conversationUuid = selectedConversation?.uuid;
  const contactInfo = getContactDataAndType(
    selectedConversation || ({} as IConversationData)
  );
  const isEmailInbox = isChannelEmail(props.selectedConversation?.conversationInbox?.channelType || '');
  const lastNonInternalMessageInDisplayList = getLastNonInternalMessageInList(
    msgData.displayData || []
  );
  const isMentionConversation =
    selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION;

  const showInfoOnHeaderData = showInfoOnMessageHeader();

  const contactData: any = contactInfo.contactData;
  const [stateData, setStateData] = useState({
    lastSeenData: [] as any[],
    tab: MESSAGE_WINDOW_TABS.CHAT,
    copiedMsgList: [] as ICopiedMessageItem[],
    copied: false,
    actionMessageLoading: false,
    headerLoading: false,
    footerLoading: false,
  });
  const intl = useIntl();

  const isCopyOrSelectMessageInDescending = isAccountConfigEnabled(CONFIG_CODES.COPY_SELECT_MESSAGE_IN_DESCENDING);

  const isContact =
    contactData.contactType?.contactType?.id?.length;

  const isViewStickyNote =
    showInfoOnHeaderData?.ADD_STICKY_NOTE &&
    isContact && !isBroadCastGroup(selectedConversation as IConversationData) &&
    !isInternalChat(selectedConversation as any);

  const styles = React.useMemo(
    () =>
      getMessagingWindowCommonStyles({msgWindowHeight: props.msgWindowHeight}),
    [props.msgWindowHeight]
  );

  const [GetLastSeenByConversationId] = useLazyQuery(
    ConversationsQueries.GET_LAST_SEEN_BY_CONVERSATION_ID,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const [groupMemberId, setGroupMemberId] = React.useState<any[]>([]);
  useEffect(() => {
    const groupMemberId = getMembersId(
      selectedConversation,
      contactData,
      userUuid
    );
    setGroupMemberId(groupMemberId);
    return () => {
      setGroupMemberId([])
    };
  }, [
    selectedConversation?.groupConversation?.groupMembers,
    contactData?.uuid,
    userUuid,
  ]);


  const toShowPhiWarning = () => {
    if (selectedConversation?.conversationInbox?.channelType) {
      const type = selectedConversation?.conversationInbox?.channelType || '';
      if (
        type === CHANNEL_TYPE.CHANNEL_EMAIL &&
        isKeyboardVisible &&
        isPrivateTab !== true
      ) {
        return true;
      } else if (
        type === CHANNEL_TYPE.CHANNEL_TWILIO_SMS &&
        isKeyboardVisible &&
        isPrivateTab !== true
      ) {
        return true;
      } else if (
        type === CHANNEL_TYPE.CHANNEL_WEB_WIDGET &&
        isKeyboardVisible &&
        isPrivateTab !== true
      ) {
        return true;
      }
      return false;
    } else {
      if (contactData?.source) {
        if (
          contactData?.source === 'WEB_WIDGET' &&
          isKeyboardVisible &&
          isPrivateTab !== true
        ) {
          return true;
        }
        return false;
      }
      return false;
    }
  };

  const getLastSeenByUsers = useCallback(() => {
    GetLastSeenByConversationId({
      variables: {
        conversationUuid: conversationUuid,
      },
    }).then((data) => {
      if (data?.data?.conversationLastseens) {
        setStateData((prev)=> {
          return {
            ...prev,
            lastSeenData: data.data.conversationLastseens,
          }
        });
      } else {
        setStateData((prev)=> {
          return {
            ...prev,
            lastSeenData: [],
          }
        });
      }
    });
  }, [conversationUuid]);

  const usersLastSeenUpdated = (data: any) => {
    if (
      conversationUuid === data?.data?.conversationUuid
    ) {
      getLastSeenByUsers();
    }
  };

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.MESSAGE_READ,
      usersLastSeenUpdated
    );
    getLastSeenByUsers();

    return () => {
      eventBus.removeEventListener(usersLastSeenUpdated);
    };
  }, [conversationUuid]);

  const messageList = getUniqueDisplayMessageList(msgData.displayData);
  const row: Array<any> = React.useMemo(() => {
    return [];
  }, []);
  let prevOpenedRow: any;
  const flatListRef = useRef<RNFlatList<IMessageBoxData>>(null);

  const scrollToMessage = React.useCallback((msg: IMessageBoxData) => {
    if (isWeb()) {
      onScrollToMessageFail(msg);
      return;
    }
    const index = messageList.findIndex(
      (element) => element.uuid === msg.uuid
    );
    if(index === -1 || index > 15){
      onScrollToMessageFail(msg)
    }
    if (flatListRef?.current && index > 1) {
      try {
        flatListRef.current?.scrollToIndex({
          animated: true,
          index: index,
          viewPosition: 0,
        });
        onScrollToMessageFail(msg, true)
      } catch (error) {

      }
    }
  }, [])

  const onScrollToMessageFail = (
    msg: IMessageBoxData,
    isSetClickedMessageUuid?: boolean
  ) => {
    if (msg?.messageData && props.onScrollToMessageFail) {
      props.onScrollToMessageFail(
        {
          uuid: msg?.messageData?.uuid,
          id: msg?.messageData?.id,
          createdAt: msg?.messageData?.createdAt,
        },
        isSetClickedMessageUuid
      );
    }
  };

  const closeRow = React.useCallback((index: number) => {
    if (prevOpenedRow) prevOpenedRow.close();
    prevOpenedRow = row[index];
  }, []);


  const showConversationAttachmentOption = () => {
    const channelType = selectedConversation?.conversationInbox?.channelType;
    if (channelType === CHANNEL_TYPE.CHANNEL_TWILIO_SMS) {
      return false;
    }
    return props?.showTabs || !isWeb();
  };

  const handleCopyClick = React.useCallback((selectedItem: ICopiedMessageItem) => {
    const mutableArray = stateData.copiedMsgList;
    let updatedArray = [] as ICopiedMessageItem[]
    if (selectedItem?.isChecked) {
      let selectedMessage = {} as ICopiedMessageItem;
      messageList?.forEach((msg) => {
        if (msg.id === selectedItem.id) {
          selectedMessage = selectedItem;
        } else {
          return;
        }
      });
      if (selectedMessage.id) {
        mutableArray.push(selectedMessage);
        updatedArray = mutableArray
        setStateData((prev) => {
          return {
            ...prev,
            copiedMsgList: mutableArray
          }
        })
      }
    } else {

      const index = mutableArray.findIndex(msg => msg.id === selectedItem.id);
      if (index !== -1) {
        mutableArray.splice(index, 1);
      }
      updatedArray = mutableArray
      setStateData((prev) => {
        return {
          ...prev,
          copiedMsgList: mutableArray
        }
      })

    }
    const updatedOrderArray = updatedArray.sort(function (a, b) {
      const firstDate: any = new Date(b.date);
      const secondDate: any = new Date(a.date);
      if (isCopyOrSelectMessageInDescending) {
        const diff: any = firstDate - secondDate;
        return diff;
      } else {
        const diff: any = secondDate - firstDate;
        return diff;
      }
    });
    props.onActionPerformed?.(COMMON_ACTION_CODES.COPY_MESSAGE_LIST, updatedOrderArray)
  }, [stateData.copiedMsgList?.length, props.isCopyModeStateOnCode]);

  useEffect(() => {
    if (props.isCopyModeStateOnCode == COPY_MESSAGE_CODE.COPY_MODE_COPIED || props.isCopyModeStateOnCode == COPY_MESSAGE_CODE.COPY_MODE_OFF) {
      setStateData((prev) => {
        return {
          ...prev,
          copiedMsgList: [],
        };
      });
    }
  }, [props.isCopyModeStateOnCode]);

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        headerLoading:
          stateData.headerLoading && msgData?.loadingLoadMore
            ? msgData?.loadingLoadMore
            : false,
        footerLoading:
          stateData.footerLoading && msgData?.loadingLoadMore
            ? msgData?.loadingLoadMore
            : false,
      };
    });
  }, [msgData?.displayData, msgData?.loadingLoadMore]);

  const onMsgReceivedListenerFn = useCallback(
    () => {
      if (
        !flatListRef.current ||
        isMentionConversation ||
        isWeb()
      ) {
        return;
      }
      flatListRef.current.scrollToIndex({animated: true, index: 0});
    },
    [conversationUuid]
  );

  useEffect(() => {
    eventQueue.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.NEW_MESSAGE_CREATED,
      onMsgReceivedListenerFn
    );
    return () => {
      eventQueue.removeEventQueue();
    };
  }, []);

  const loadMoreOlderMessages = async () => {
    if (msgData?.areMessagesRemaining) {
      setStateData((prev) => {
        return {
          ...prev,
          headerLoading: true,
          footerLoading: false,
        };
      });
      props.onFetchMoreIfAvailable();
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          headerLoading: false,
        };
      });
    }
  };

  const loadMoreRecentMessages = async () => {
    if (msgData?.lastConversationMsgUuid !== messageList?.[0]?.uuid && msgData?.lastConversationMsgUuid !== messageList?.[1]?.uuid) {
      setStateData((prev) => {
        return {
          ...prev,
          footerLoading: true,
          headerLoading: false,
        };
      });
      props.onFetchMoreRecentIfAvailable();
    }
  };

 

  return (
    <View flex={1}>
      {messageList?.length > 0 ? (
        <>
          {!isWeb() && toShowPhiWarning() && (
            <View mt={0.5} p={2} bgColor={'secondary.100'}>
              <Pressable onPress={()=> {
                Keyboard.dismiss();
              }}>
              <Text {...testID(TestIdentifiers.phiWarning)} color={'secondary.800'} fontSize={11}>
                {intl.formatMessage({
                  id: 'phiWarning',
                })}
              </Text>
              </Pressable>
            </View>
          )}
          {!isWeb() && isViewStickyNote ? (
            <StickyNoteMessageView
              contactData={contactData}
              conversationId={conversationUuid}
            />
          ) : (
            <></>
          )}

          <SafeAreaView style={styles.safeAreaViewStyle}>
          {showConversationAttachmentOption() && (
              <MessagingWindowCommonTabs
                isEmailInbox={isEmailInbox}
                onTabPress={(tab) => {
                  Keyboard.dismiss();
                  setStateData((prev) => {
                    return {
                      ...prev,
                      tab: tab,
                    };
                  });
                }}
                tab={stateData.tab}
              />
            )}
            {stateData?.tab === MESSAGE_WINDOW_TABS.CHAT && (
              <GestureHandlerRootView style={styles.gestureHandlerRootViewStyle}>
                {stateData?.headerLoading && (
                  <View {...testID(TestIdentifiers.pageLoading)}>
                    <Skeleton.Text lines={3}></Skeleton.Text>
                  </View>
                )}
                {isWeb() ? (
                  <FlatList
                    contentContainerStyle={{flexDirection: 'column-reverse'}}
                    keyboardShouldPersistTaps='handled'
                    initialNumToRender={1}
                    windowSize={5}
                    maxToRenderPerBatch={1}
                    disableVirtualization={true}
                    onEndReached={loadMoreOlderMessages}
                    onStartReached={loadMoreRecentMessages}
                    onEndReachedThreshold={1}
                    onStartReachedThreshold={.1}
                    //activityIndicatorColor={'black'}
                    //showDefaultLoadingIndicators={true}
                    style={styles.flatListStyle}
                    data={messageList}
                    keyExtractor={(item) =>
                      'msgBox_' + item.uuid
                    }
                    renderItem={({index, item}) => {
                      let key = item?.taskCount
                        ? item.uuid + item.displayContent + item?.taskCount
                        : item.uuid + item.displayContent;
                      if (item?.communicationTypes?.length) {
                        key = key + item?.communicationTypes?.length;
                      }
                      return (
                        <RenderMsgList
                          channelData={
                            selectedConversation?.conversationInbox
                              ?.channelEmail
                          }
                          groupMemberId={groupMemberId}
                          handleCopyClick={handleCopyClick}
                          index={isWeb() ? 0 : index}
                          item={item}
                          lastNonInternalMessageInDisplayList={
                            isEmailInbox ? lastNonInternalMessageInDisplayList : undefined
                          }
                          lastSeenData={stateData.lastSeenData}
                          onRedirectToMentionChange={onRedirectToMentionChange}
                          onReplyCallback={props.onReplyCallback}
                          prevMessage={
                            index != undefined
                              ? messageList[index + 1]
                              : undefined
                          }
                          redirectToMention={props.redirectToMention}
                          scrollToMessage={scrollToMessage}
                          searchMessage={searchMessage}
                          selectedConversation={selectedConversation}
                          clickEventId={props.msgData.clickEventId}
                          clickMessageUuid={props.clickMessageUuid}
                          groupMemberDataLoading={props.groupMemberDataLoading}
                          isCopyModeStateOnCode={props.isCopyModeStateOnCode}
                          isReadOnlyView={props.isReadOnlyView}
                          messageAction={props.messageAction}
                          navigation={props.navigation}
                          onActionPerformed={props.onActionPerformed}
                          onAddCommunicationType={props.onAddCommunicationType}
                          onMessageAction={props.onMessageAction}
                          onViewTaskAction={props.onViewTaskAction}
                          resetClickedMessageUuid={
                            props.resetClickedMessageUuid
                          }
                          selectedInboxTypeCode={props.selectedInboxTypeCode}
                          showModal={props.showModal}
                          closeRow={closeRow}
                          row={row}
                          key={key}
                        />
                      );
                    }}
                    onScrollBeginDrag={()=> {
                      Keyboard.dismiss();
                    }}
                    {...testID('MessageList')}
                  />
                ) : (
                  <MessagesFlatList
                    ref={flatListRef}
                    inverted
                    keyboardShouldPersistTaps='handled'
                    initialNumToRender={MESSAGE_FLAT_LIST_CONST.INITIAL_ITEMS_TO_RENDER}
                    maxToRenderPerBatch={MESSAGE_FLAT_LIST_CONST.MAX_ITEMS_TO_RENDER}
                    disableVirtualization={true}
                    onEndReached={loadMoreOlderMessages}
                    onStartReached={loadMoreRecentMessages}
                    onEndReachedThreshold={MESSAGE_FLAT_LIST_CONST.ON_END_REACHED_THRESHOLD}
                    onStartReachedThreshold={MESSAGE_FLAT_LIST_CONST.ON_START_REACHED_THRESHOLD}
                    style={styles.flatListStyle}
                    data={messageList}
                    keyExtractor={(item) =>
                      'msgBox_' + item.uuid
                    }
                    renderItem={({index, item}) => {
                      return (
                        <RenderMsgList
                          channelData={
                            selectedConversation?.conversationInbox
                              ?.channelEmail
                          }
                          groupMemberId={groupMemberId}
                          handleCopyClick={handleCopyClick}
                          index={isWeb() ? 0 : index}
                          item={item}
                          lastNonInternalMessageInDisplayList={
                            isEmailInbox ? lastNonInternalMessageInDisplayList : undefined
                          }
                          lastSeenData={stateData.lastSeenData}
                          onRedirectToMentionChange={onRedirectToMentionChange}
                          onReplyCallback={props.onReplyCallback}
                          prevMessage={
                            index != undefined
                              ? messageList[index + 1]
                              : undefined
                          }
                          redirectToMention={props.redirectToMention}
                          scrollToMessage={scrollToMessage}
                          searchMessage={searchMessage}
                          selectedConversation={selectedConversation}
                          clickEventId={props.msgData.clickEventId}
                          clickMessageUuid={props.clickMessageUuid}
                          groupMemberDataLoading={props.groupMemberDataLoading}
                          isCopyModeStateOnCode={props.isCopyModeStateOnCode}
                          isReadOnlyView={props.isReadOnlyView}
                          messageAction={props.messageAction}
                          navigation={props.navigation}
                          onActionPerformed={props.onActionPerformed}
                          onAddCommunicationType={props.onAddCommunicationType}
                          onMessageAction={props.onMessageAction}
                          onViewTaskAction={props.onViewTaskAction}
                          resetClickedMessageUuid={
                            props.resetClickedMessageUuid
                          }
                          selectedInboxTypeCode={props.selectedInboxTypeCode}
                          showModal={props.showModal}
                          closeRow={closeRow}
                          row={row}
                          key={item.uuid + item.displayContent}
                        />
                      );
                    }}
                    onScrollToIndexFailed={(info) => {
                      if (!flatListRef.current) {
                        return;
                      }
                      const offset = info.averageItemLength * info.index;
                      flatListRef.current?.scrollToOffset({offset: offset});
                      let timeout: NodeJS.Timeout;
                      const wait = new Promise((resolve) => {
                        timeout = setTimeout(resolve, 50);
                      });
                      wait.then(() => {
                        flatListRef.current?.scrollToIndex({
                          index: info.index,
                          animated: true,
                        });
                        clearTimeout(timeout);
                      });
                    }}
                    {...testID('MessageList')}
                  />
                )}
                {stateData?.footerLoading && (
                  <View {...testID(TestIdentifiers.pageLoading)}>
                    <Skeleton.Text lines={3}></Skeleton.Text>
                  </View>
                )}
              </GestureHandlerRootView>
            )}
            {stateData?.tab === MESSAGE_WINDOW_TABS.FILES && (
              <ScrollView
                style={styles.conversationAttachmentsScrollViewStyle}>
                <ConversationAttachments
                  conversationId={selectedConversation?.id || -1}
                  conversationDisplayId={selectedConversation?.displayId || -1}
                  navigation={props?.navigation}
                />
              </ScrollView>
            )}
          </SafeAreaView>
        </>
      ) : (
        <View flex={1} width={'full'} zIndex={10}>
          <Center
            alignItems={'center'}
            justifyContent={'center'}
            alignContent={'center'}
            flex={1}
            height={'100%'}
            {...testID('NoMessages')}
          >
            <EmptyStateSvg titleId="noMessages" />
          </Center>
        </View>
      )}
    </View>
  );
};

export default GetMessagingListElem;
