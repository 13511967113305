import { useLazyQuery } from '@apollo/client';
import { Select, Spin } from 'antd';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { Text } from 'react-native';
import EmployerQueries from '../../../../../services/Employer/EmployerQueries';
import { getAccountUUID } from '../../../../../utils/commonUtils';
import { COMPARISON_OPERATOR } from '../../../constants';

const EmployerDropdownList = (props: {
    defaultValue?: any;
    isShowError?:boolean
    onChange: (actionData?: any,extraData?:any) => void;
    isVbcTenant?: boolean;
    selectedComparisonOperatorValue?: string;
}) => {
    const [employerList, setEmployerList] = useState<any[]>([]);
    const [isLoading, setLoading]= useState(false);
    const accountUuid = getAccountUUID();
    const [searchText, setSearchText] = useState<string | undefined>();

  useEffect(() => {
     onSearch('')
  }, [props]);

  const [searchEmployer] = useLazyQuery(EmployerQueries.employersSearchAndOrderByName, {
    fetchPolicy: 'no-cache',
    onCompleted: (response: any) => {
      if (response?.employers?.length > 0) {
        const data = response.employers;
        setEmployerList([...data]);
      } else {
        setEmployerList([]);
      }
      setLoading(false);
    },
    onError: (error) => {

      setLoading(false);
    },
  });

  const onSearch = (searchString: string) => {
      setLoading(true);
      setSearchText(searchString);
      searchEmployer({
        variables: {
          accountUuid: accountUuid,
          query: `%${searchString}%`,
        },
      });
  };

  useEffect(()=>{
    if(props.selectedComparisonOperatorValue == COMPARISON_OPERATOR.ANY){
      props.onChange([],[])
    }
  }, [props.selectedComparisonOperatorValue])

  return  props.selectedComparisonOperatorValue != COMPARISON_OPERATOR.ANY ?
    <Select
      size="large"
      mode="multiple"
      showSearch
      status={props.isShowError && !(props?.defaultValue && props?.defaultValue?.length)?'error':''}
      allowClear
      filterOption={false}
      value={props?.defaultValue || undefined}
      onSearch={debounce(onSearch, 500)}
      onChange={(value: any) => {
        if (value && value.length > 0) {
          const employersName = value?.map((employerId:any)=>{
            const employerName = employerList?.find((employer)=>{
              return employer?.id == employerId
            })
            return employerName?.name
          })
          props.onChange(value,employersName);
        } else {
          props.onChange(undefined);
        }
      }}
      placeholder={props?.isVbcTenant ? "Select Contract": "Select Employer"}
      loading={isLoading}
      notFoundContent={
        isLoading ?
        <Spin size="small" /> :
        (searchText && !employerList.length && <Text>No Data Found</Text>)
      }
      style={{ width: '100%', }}
    >
      {
        employerList?.map((item: any) => {
          return (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          );
        })
      }
    </Select>  : <>All Employers Selected By Default</>

};

export default EmployerDropdownList;
