import {ColumnsType} from 'antd/lib/table';
import {HStack, IconButton, Text, Tooltip, View} from 'native-base';
import {useIntl} from 'react-intl';
import Icon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {Colors} from '../../../../styles';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {IMemberStatusData} from './interface';
import {TagComponent} from '../../../common/TagComponent';

export const getMemberStatusColumns = (
  onActionPerformed: (actionCode: string, record: IMemberStatusData) => void
) => {
  const intl = useIntl();
  const columns: ColumnsType<IMemberStatusData> = [
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: '12%',
      render: (title: string, record: IMemberStatusData) => (
        <View justifyContent={'flex-start'} flexDirection={'row'}>
          <TagComponent
            tagText={record.name}
            tagColor={record.color}
            tagStyle={{borderRadius: 30}}
            tagTextStyle={{
              fontSize: 12,
              fontWeight: '700',
            }}
          />
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="description"
        />
      ),
      dataIndex: 'description',
      key: 'description',
      width: '40%',
      render: (description: string, record: IMemberStatusData) => (
        <View justifyContent={'flex-start'} flexDirection={'row'}>
          <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
            {record.description}
          </Text>
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="color"
        />
      ),
      render: (record: IMemberStatusData) => (
        <HStack space={2} justifyContent={'flex-start'}>
          <View
            width={5}
            height={5}
            backgroundColor={record.color}
            borderRadius={4}
          ></View>
          <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
            {record.color}
          </Text>
        </HStack>
      ),
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="statusType"
        />
      ),
      dataIndex: 'statusType',
      key: 'type',
      width: '12%',
      render: (statusType: string, record: IMemberStatusData) => (
        <View justifyContent={'flex-start'} flexDirection={'row'}>
        <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
          {record.statusType}</Text>
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="actions"
        />
      ),
      key: 'actions',
      fixed: 'right',
      width: '10%',
      render: (record: IMemberStatusData) => {
        return (
          <View flex={1} flexDirection={'row'}>
            <Tooltip
              label={
                record.isDefault
                  ? 'Default member statuses cannot be edited'
                  : 'Edit'
              }
            >
              <IconButton
                disabled={record?.isDefault}
                icon={
                  <Feather
                    name="edit-2"
                    size={17}
                    color={Colors.Custom.Gray600}
                  />
                }
                onPress={() => {
                 !record.isDefault &&  onActionPerformed(COMMON_ACTION_CODES.EDIT, record);
                }}
              />
            </Tooltip>
            <Tooltip
              label={
                record.isDefault
                  ? 'Default member statuses cannot be deleted'
                  : 'Delete'
              }
            >
              <IconButton
                disabled={record?.isDefault}
                icon={
                  <Icon name="delete" size={17} color={Colors.Custom.Gray600} />
                }
                onPress={() => {
                 !record.isDefault &&  onActionPerformed(COMMON_ACTION_CODES.DELETE, record);
                }}
              />
            </Tooltip>
          </View>
        );
      },
    },
  ];
  return columns;
};
