export const buildEncounterParams = (
  patientId: string,
  options?: {
    type?: string;
    startDate?: string;
  }
) => {
  let params = `patient=${patientId}`;
  if (options?.type) {
    params += `&type=${options.type}`;
  }
  if (options?.startDate) {
    params += `&gt${options.startDate}`;
  }
  return params;
};

export const getPreviousTime = (time: string, diffInMonths: number) => {
  const date = new Date(time)
  date.setMonth(date.getMonth() - diffInMonths)
  return date.toISOString()
}