import {useLazyQuery} from '@apollo/client';
import {useContext, useEffect, useState} from 'react';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../../constants/Configs';
import {GetContactOutreachActivities} from '../../../../../../../services/ContactOutreach/ContactOutreachQueries';
import {getMlovListFromCategory} from '../../../../../../../utils/mlovUtils';
import {
  MLOV_CATEGORY,
  OUTREACH_STATUS_CODES,
} from '../../../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';

export const useStepOutreachStatus = (
  contactId: string,
  stepId: string,
  careProgramTypeId: string,
  contactCareProgramId: string
) => {
  const mlovData = useContext(CommonDataContext);
  const [loading, setLoading] = useState(false);
  const [completionStatus, setCompletionStatus] = useState({
    total: 0,
    completed: 0,
    failed: 0,
  });

  const OutreachStatusList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_PROGRAM_OUTREACH_STATUS
  );


  const [getOutreachActivity] = useLazyQuery(GetContactOutreachActivities, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    fetchOutreachStatus();
  }, [stepId]);

  const getOutreachActivityWhereCondition = () => {
    const whereCondition = {
      contactId: {_eq: contactId},
      careProgramTypeId: {
        _eq: careProgramTypeId,
      },
        careProgramSteps: {
          contactCareProgram: {
            id: {
              _eq: contactCareProgramId,
            },
          },
        },
      };
    return whereCondition;
  };

  const fetchOutreachStatus = async () => {
    try {
      setLoading(true);
      const response = await getOutreachActivity({
        variables: {
          whereCondition: getOutreachActivityWhereCondition(),
          limit: 10,
          offset: 0,
        },
      });

      const outreaches = response?.data?.ContactOutreachActivities || [];
      const total = outreaches.length;

      const successStatusId = OutreachStatusList.find(
        (status) => status.code === OUTREACH_STATUS_CODES.SUCCESS
      )?.id;
      const failedStatusId = OutreachStatusList.find(
        (status) => status.code === OUTREACH_STATUS_CODES.FAILED
      )?.id;

      const completed = outreaches.filter(
        (outreach: any) => outreach.statusId === successStatusId
      ).length;
      const failed = outreaches.filter(
        (outreach: any) => outreach.statusId === failedStatusId
      ).length;

      setCompletionStatus({
        total,
        completed,
        failed,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    completionStatus,
  };
};
