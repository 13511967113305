import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {cloneDeep, debounce} from 'lodash';
import {
  Box,
  Center,
  Heading,
  Icon,
  Skeleton,
  Spinner,
  Text,
  View,
  VStack,
} from 'native-base';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {PERSON_TYPES, WINDOW_EVENT_CODES} from '../../../constants';
import {
  CARESTUDIO_APOLLO_CONTEXT,
} from '../../../constants/Configs';
import {FORM_STATUS_CODE} from '../../../constants/MlovConst';
import {FormsQueries} from '../../../services';
import { getEhrCapabilitiesWithResource } from '../../../services/CommonService/AidBoxService';
import { GET_OR_CREATE_FORMS_LOGS } from '../../../services/Forms/FormsQueries';
import {Colors, Icons} from '../../../styles';
import {testID, TestIdentifiers} from '../../../testUtils';
import LocalStorage from '../../../utils/LocalStorage';
import { FormContext } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import { forEachExtensiveFormComponent } from '../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import {IFormPrefilledByFoldProgressState, IPublicFormData, IPublicFormProps} from './PublicFormInterfaces';
import PublicFormView from './PublicFormView';
import { FormComponentType } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { WIDGET_TYPES } from '../../RightSideContainer/Forms/FormsConstants';
import { decodeTpaCareAdvocateParams } from '../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/ActivityAdditionalInfo/CommonComponent/FormResponse/FormUtils';
import {useContactsLocation} from '../../CustomHooks/useContactsLocation';
import useEHRCapabilities from '../../../screens/BusinessStudio/useEHRCapabilities';

export const accountConfigObject = {
  BrandColorPrimary: '#4F2D90',
  BrandColorSecondary: '#825AC7',
  BrandColorTertiary: '#825AC7',
};

const PublicForm = (props: {params: IPublicFormProps}) => {
  const [submitForm] = useMutation(FormsQueries.SUBMIT_FORM_RESPONSE);
  const [submitTpaForm] = useMutation(FormsQueries.SUBMIT_TPA_FORM_RESPONSE);
  const [submitPatientInformationForm] = useMutation(FormsQueries.SUBMIT_PATIENT_INFORMATION_FORM_RESPONSE);
  const [statusLoading, setStatusLoading] = useState(true);
  const [formStatus, setFormStatus] = useState('');
  const [formSubjectIdState, setFormSubjectIdState] = useState(props?.params?.subjectId);
  const [widgetType, setWidgetType] = useState(props?.params?.widgetType);
  const [showThankYouPage, setShowThankYouPage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [formResponseId, setFormResponseId] = useState<string | undefined>();
  const [draftData, setDraftData] = useState<any>();
  const [submittedData, setSubmittedData] = useState<any>();
  const [draftChanges, setDraftChanges] = useState<any>();
  const [accountLocationId, setAccountLocationId] = useState('');

  const [capabilityData, setCapabilityData] = useState({
    data: [],
    loading: true,
  });
  const [formWidgetAccessToken, setFormWidgetAccessToken] = useState<string>();
  const [tpaCareAdvocateParams, setTpaCareAdvocateParams] = useState<any>({
    accountId: props.params.accountId,
    formId: props.params.formId,
    tpaCareAdvocateId: props.params.contactId,
    widgetAuthId: ''
  });

  const [patientInformationFormParams, setPatientInformationFormParams] = useState<any>({
    accountId: props?.params?.accountId,
    formId: props?.params?.formId,
    widgetAuthId: ''
  });


  const [ formPrefilledByFoldProgressState, setFormPrefilledByFoldProgressState, ]
    = useState<IFormPrefilledByFoldProgressState>({
      componentWiseProgress: {},
      disallowProgressUpdates: true,
      formPrefilledByFoldPercent: 0,
    });

  const isTpaCareAdvocateForm = widgetType === WIDGET_TYPES.TPA_CARE_ADVOCATE;
  const isPatientInformationForm = widgetType === WIDGET_TYPES.PATIENT_INFORMATION_FORM;

  const [formData, setFormData] = useState<IPublicFormData>({
    name: '',
    id: '',
    components: [],
    noData: true,
  } as IPublicFormData);
  const headers = {
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
      headers: {
        'account-uuid': props.params.accountId,
        'form-widget-access-token': formWidgetAccessToken,
      },
    },
  };
  const [getFormLogs] = useLazyQuery(FormsQueries.GET_FORMS_LOGS, {
    fetchPolicy: 'no-cache',
    ...headers
  });

  const [ getOrCreateFormLogApi ] = useMutation(FormsQueries.GET_OR_CREATE_FORMS_LOGS, {
    fetchPolicy: 'no-cache',
    ...headers,
  });

  const [ authenticateSubmitFormResponseWidget ] = useMutation(FormsQueries.AUTHENTICATE_FORM_RESPONSE_WIDGET, {
    fetchPolicy: 'no-cache',
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    variables: {
      params: {
        formId: props.params.formId,
        formCode: props.params.formCode,
        contactId: props.params.contactId,
        subjectId: formSubjectIdState,
        accountUuid: props.params.accountId,
        token: props.params.token,
      },
    },
    onCompleted: (response) => {
      const formStatusCode = response?.authenticateSubmitFormResponseWidget?.formLogStatusCode;
      if (formStatusCode) {
        setFormStatus(formStatusCode);
        if (formStatusCode === FORM_STATUS_CODE.SUBMITTED) {
          setStatusLoading(false);
          return;
        }
      }
      setStatusLoading(false);
      const formWidgetAccessToken: string = response?.authenticateSubmitFormResponseWidget?.formWidgetAccessToken;
      if (formWidgetAccessToken) {
        LocalStorage.setItem('formWidgetData', JSON.stringify({ formWidgetAccessToken }));
        setFormWidgetAccessToken(formWidgetAccessToken);
      } else {
        onAuthenticateSubmitFormResponseWidgetError();
        // setStatusLoading(false);
        // setCapabilityData(prev => ({ ...prev, loading: false }));
      }
    },
    onError: async () => {
      onAuthenticateSubmitFormResponseWidgetError();
    },
  });

  const [ authenticateWidgetToken ] = useMutation(FormsQueries.AUTHENTICATE_WIDGET_TOKEN, {
    fetchPolicy: 'no-cache',
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    onCompleted: (response) => {
      const widgetAccessToken: string = response?.authenticateWidgetToken?.widgetAccessToken;
      if (widgetAccessToken) {
        LocalStorage.setItem('formWidgetData', JSON.stringify({ widgetAccessToken }));
        setFormWidgetAccessToken(widgetAccessToken);
      } else {
        onAuthenticateSubmitFormResponseWidgetError();
      }
      setCapabilityData(prev => ({ ...prev, loading: false }));
      setStatusLoading(false);
    },
    onError: async () => {
      onAuthenticateSubmitFormResponseWidgetError();
    },
  });


  async function onAuthenticateSubmitFormResponseWidgetError() {
    LocalStorage.setItem('formWidgetData', JSON.stringify({ formWidgetAccessToken: 'undefined' }));
    const userDataString = await LocalStorage.getItem('user');
    const userData = JSON.parse(userDataString);
    const foldAccessToken = userData?.fold_access_token;
    if (foldAccessToken) {
      setFormWidgetAccessToken('undefined');
      setStatusLoading(false);
      setCapabilityData(prev => ({ ...prev, loading: false }));
    } else {
      if (props.params.readOnly) {
        setFormWidgetAccessToken('undefined');
        return;
      }
      setFormWidgetAccessToken(undefined);
      setStatusLoading(false);
      setCapabilityData(prev => ({ ...prev, loading: false }));
    }
  }

  let loading = false;

  const getFormLogsData = async () => {
    if (
      props.params.formId &&
      props.params.contactId &&
      formSubjectIdState
    ) {
      let formLogsData;

      try {
        formLogsData = await getOrCreateFormLogApi({
          variables: {
            formId: props.params.formId,
            contactId: props.params.contactId,
            subjectId: formSubjectIdState,
            sourceId: props.params.sourceId,
          },
        });
      } catch (error) {
        setStatusLoading(false);
        return;
      }

      if (formLogsData?.data?.formLog?.id) {
        const formLog = formLogsData.data.formLog;
        if (formLog.status?.code) {
          setFormStatus(formLog.status?.code);
        }
        if (formLog.subjectId) {
          setFormSubjectIdState(formLog.subjectId);
        }
        if (formLogsData?.data?.formLog?.accountLocationId) {
          setAccountLocationId(formLog.accountLocationId);
        }
        if (formLog?.formResponse?.foldPrefilledDataProgressByComponent) {
          try {
            setFormPrefilledByFoldProgressState({
              componentWiseProgress: (formLog?.formResponse?.foldPrefilledDataProgressByComponent || {}),
              formPrefilledByFoldPercent: parseFloat(formLog?.formResponse?.foldPrefilledDataProgressPercentage || '0'),
              disallowProgressUpdates: true,
            });
          } catch (error) {}
        }
        if (formLog.formResponse?.id) {
          if (!formResponseId) {
            setFormResponseId(formLog.formResponse.id);
          }

          if (formLog.formResponse?.formResponse?.components?.length) {
            const components = formLog.formResponse.formResponse.components;
            const submittedData: any = {};
            forEachExtensiveFormComponent(components, (component) => {
              if (component.type === FormComponentType.BUTTON) {
                component.disableOnInvalid = false;
              } else {
                submittedData[component.key] = component.selectedValue;
              }
            });
            if (Object.keys(submittedData).length) {
              setDraftData({response: { data: submittedData }});
            }
            setFormData((prev: any) => ({...prev, components: cloneDeep(components)}));
          }
        } else {
          setFormPrefilledByFoldProgressState({
            disallowProgressUpdates: false,
            componentWiseProgress: {},
            formPrefilledByFoldPercent: 0,
          });
        }
      }
    }
    setStatusLoading(false);
  };

  const [ formUpdateDataApi, formUpdateData ] = useLazyQuery(
    props.params.formCode
      ? FormsQueries.GET_FORM_BY_CODE
      : FormsQueries.GET_FORM_BY_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id: isTpaCareAdvocateForm
        ? tpaCareAdvocateParams.formId
        : isPatientInformationForm
        ? patientInformationFormParams?.formId
        : props.params.formId,
        code: props.params.formCode,
      },
      ...headers,
      onCompleted: (data: any) => {
        if (data?.form?.name) {
          const responseFormData: IPublicFormData = cloneDeep(data.form);
          forEachExtensiveFormComponent(responseFormData?.components || [], (component) => {
            if (component.type === FormComponentType.BUTTON) {
              component.disableOnInvalid = false;
            }
          });
          setFormData((prev) => {
            if (!prev.components?.length) {
              prev.components = responseFormData.components;
            }
            prev.id = responseFormData.id;
            prev.name = responseFormData.name;
            prev.noData = false;
            return {...prev};
          });
        }
      },
    },
  );

  loading = formUpdateData.loading || showLoader || statusLoading || capabilityData.loading;

  useEffect(() => {
    if (!formWidgetAccessToken) {
      return;
    }
    if (draftChanges && formData.id && formSubjectIdState && !isTpaCareAdvocateForm && !isPatientInformationForm) {
      const updatedComponents = cloneDeep(formData.components);
      forEachExtensiveFormComponent(updatedComponents, (component) => {
        if (component.key && (draftChanges[component.key] || draftChanges[component.key] == 0)) {
          component.selectedValue = draftChanges[component.key];
        }
      });
      const data: any = {
        formId: formData.id,
        eventId: props.params.eventId,
        formResponse: {components: updatedComponents},
        reference: {},
        patientId: props.params.patientId,
        contactId: props.params.contactId,
        subjectId: formSubjectIdState,
        sourceId: props.params.sourceId,
        isDraft: true,
        formResponseId: formResponseId,
        foldPrefilledDataProgressByComponent: {
          ...(formPrefilledByFoldProgressState?.componentWiseProgress || {}),
        },
        foldPrefilledDataProgressPercentage: (formPrefilledByFoldProgressState?.formPrefilledByFoldPercent || 0),
      };
      if (props?.params?.workflowMasterId) {
        data.reference.workflowMasterId = props.params.workflowMasterId;
      }
      submitForm({
        variables: {
          data,
        },
        ...headers,
        onCompleted: (data) => {
          // notification.info({
          //   message: 'Your progress is being saved as you enter data in forms',
          //   duration: 3,
          // });
          if (data?.submitFormResponse?.id && !formResponseId) {
            setFormResponseId(data.submitFormResponse.id);
          }
        },
      });
    }
  }, [draftChanges, formWidgetAccessToken, formPrefilledByFoldProgressState]);

  useEffect(() => {
    if (!formWidgetAccessToken || !submittedData || !formData.id) {
      return;
    }
    setShowLoader(true);
    const data: any = {
      eventId: props.params.eventId,
      formResponse: {components: submittedData},
      isDraft: false,
      foldPrefilledDataProgressByComponent: {
        ...(formPrefilledByFoldProgressState?.componentWiseProgress || {}),
      },
      foldPrefilledDataProgressPercentage: (formPrefilledByFoldProgressState?.formPrefilledByFoldPercent || 0),
    };

    if(isTpaCareAdvocateForm){
      data.tpaCareAdvocateId = tpaCareAdvocateParams.tpaCareAdvocateId;
      data.formId = tpaCareAdvocateParams.formId;
    } else if(isPatientInformationForm){
      data.formId = patientInformationFormParams?.formId;
    } else {
      data.formId = formData.id,
      data.patientId = props.params.patientId;
      data.contactId = props.params.contactId;
      data.subjectId = formSubjectIdState;
      data.sourceId = props.params.sourceId;
      data.formResponseId = formResponseId
    }


    if (props?.params?.workflowMasterId) {
      data.reference.workflowMasterId = props.params.workflowMasterId;
    }
    if(isTpaCareAdvocateForm){
      submitTpaForm({
        variables: {
          data,
        },
        onCompleted: () => {
          broadcastMessage();
          setShowThankYouPage(true);
          setShowLoader(false);
        },
        onError: () => {
          setShowLoader(false);
        },
        ...headers,
      });
    } else if(isPatientInformationForm){
      submitPatientInformationForm({
        variables: {
          data,
        },
        onCompleted: () => {
          broadcastMessage();
          setShowThankYouPage(true);
          setShowLoader(false);
        },
        onError: () => {
          setShowLoader(false);
        },
        ...headers,
      });
    } else {
      submitForm({
        variables: {
          data,
        },
        onCompleted: () => {
          broadcastMessage();
          setShowThankYouPage(true);
          setShowLoader(false);
        },
        onError: () => {
          setShowLoader(false);
        },
        ...headers,
      });
    }

  }, [submittedData, formWidgetAccessToken]);

  const debouncedSave = useCallback(
    debounce(async (newData) => {
      // Only auto save form if valid, if subjectId is available then only it's a valid link
      if (!formSubjectIdState) {
        return;
      }
      setDraftChanges({...newData});
    }, 4000),
    [setDraftChanges],
  );

  const broadcastMessage = () => {
    const windowObject = window.opener || window.parent || window;
    if (windowObject) {
      const messageData = JSON.stringify({
        messageCode: WINDOW_EVENT_CODES.FORM_SUBMIT,
        contactId: props.params.contactId,
      });
      windowObject.postMessage(messageData, '*');
    }
  };

  const handleOnSubmit = useCallback((updatedComponents: any) => {
    if (updatedComponents) {
      setSubmittedData(updatedComponents);
    }
  }, []);

  const handleOnChange = useCallback((formUpdates: any) => {
    if (formUpdates.changed) {
      debouncedSave(formUpdates.data);
    }
  }, []);

  const handleOnUpdateFormPrefilledByFoldProgress = useCallback((formPrefilledByFoldProgressInput: IFormPrefilledByFoldProgressState) => {
    if (formPrefilledByFoldProgressState.disallowProgressUpdates) {
      return;
    }
    setFormPrefilledByFoldProgressState(prev => {
      return {
        ...prev,
        ...formPrefilledByFoldProgressInput,
      };
    });
  }, [ formPrefilledByFoldProgressState?.disallowProgressUpdates ]);

  const bgColor = props.params.bg ? `#${props.params.bg}` : 'white';
  const fetchCapabilities = (locationId?: string) => {
    getEhrCapabilitiesWithResource(
      '',
      false,
      (data) => {
        setCapabilityData((prev) => ({
          ...prev,
          data: data.data,
          loading: false,
        }));
      },
      (error) => {
        setCapabilityData(prev => ({...prev, loading: false}));
      },
      {},
      locationId
    );
  }
  useEffect(() => {
    // getFormLogsData();
    LocalStorage.removeItem('formWidgetData');

    if (isTpaCareAdvocateForm) {
      getTpaCareAdvocateParams();
    } else if(isPatientInformationForm){
      getPatientInformationFormParams();
    } else {
      authenticateSubmitFormResponseWidget();
    }

    fetchCapabilities(accountLocationId);

  }, []);

  const getTpaCareAdvocateParams = async () => {
    const decodedTpaCareAdvocateParams = await decodeTpaCareAdvocateParams(props.params.token || '');
    if(decodedTpaCareAdvocateParams){
      setTpaCareAdvocateParams(()=> ({
        formId: decodedTpaCareAdvocateParams.payload?.formId,
        tpaCareAdvocateId: decodedTpaCareAdvocateParams.payload?.tpaCareAdvocateId,
        accountId: decodedTpaCareAdvocateParams.accountId,
        widgetAuthId: decodedTpaCareAdvocateParams.widgetAuthId
      }));
      authenticateWidgetToken({
        variables: {
          params: {
            token: props.params.token,
            widgetType: PERSON_TYPES.TPA_CARE_ADVOCATE
          },
        }
      });
    }
  }

  const getPatientInformationFormParams = async () => {
    const decodedPatientInformationFormParams = await decodeTpaCareAdvocateParams(props?.params?.token || '');
    if(decodedPatientInformationFormParams){
      setPatientInformationFormParams(()=> ({
        formId: decodedPatientInformationFormParams?.payload?.formId,
        accountId: decodedPatientInformationFormParams?.accountId,
        widgetAuthId: decodedPatientInformationFormParams?.widgetAuthId
      }));
      authenticateWidgetToken({
        variables: {
          params: {
            token: props.params.token,
            widgetType: WIDGET_TYPES.PATIENT_INFORMATION_FORM
          },
        }
      });
    }
  }

  useEffect(() => {
    if (formWidgetAccessToken) {
      if (props?.params?.formComponents?.length) {
        setFormData((prev) => {
          return {
            ...prev,
            id: props?.params?.id,
            name: props?.params?.name,
            components:  JSON.parse(props?.params?.formComponents),
            noData: false,
          }
        });
      } else {
          formUpdateDataApi();
      }
      if(!isTpaCareAdvocateForm && !isPatientInformationForm){
        getFormLogsData();
        fetchCapabilities(accountLocationId);
      }
    }
  }, [ formWidgetAccessToken ]);
  const SkeletonForm = new Array(6).fill(0);
  return (
    <>
      <style type="text/css">{Icons.iconsStyle}</style>
      {formStatus === FORM_STATUS_CODE.SUBMITTED ? (
        <Box
          borderColor={Colors.Custom.BorderColor}
          rounded="lg"
          borderWidth={1}
          backgroundColor={bgColor}
          marginX={{
            base: 0,
            lg: 32,
          }}
          marginY={{
            base: 0,
            lg: 8,
          }}
        >
          <View
            padding={{
              base: 4,
              sm: 2,
              md: 4,
              lg: 8,
              xl: 8,
            }}
          >
            <VStack space={2}>
              <View alignItems="center" justifyContent="center">
                <Icon
                  as={AntIcon}
                  name="checkcircleo"
                  size="24"
                  color="success.500"
                />
              </View>
              <View alignItems="center" justifyContent="center">
                <Heading>No Action Required</Heading>
                <Text>
                  You have already submitted the response for this form.
                </Text>
              </View>
            </VStack>
          </View>
        </Box>
      ) : (
        <View flex={1}>
          {!loading && !formData.noData && !showThankYouPage && (
            <PublicFormView
              optionData={{
                accountLocationUuid: accountLocationId,
                contactId: props.params.contactId,
                accountUUID: props.params.accountId || tpaCareAdvocateParams.accountId || patientInformationFormParams.accountId,
                formContext: FormContext.patientForm,
                ehrCapabilities: capabilityData.data,
                tpaCareAdvocateId: tpaCareAdvocateParams?.tpaCareAdvocateId,
                widgetType: widgetType,
                proxyHeaders: {
                  'account-uuid': props.params.accountId || tpaCareAdvocateParams.accountId || patientInformationFormParams.accountId,
                  'form-widget-access-token': formWidgetAccessToken,
                }
              }}
              backgroundColor={bgColor}
              formData={formData}
              // accountConfig={accountConfig}
              // hideBorder={props.params.isEmbedded === 'true'}
              onSubmit={handleOnSubmit}
              onChange={handleOnChange}
              submittedData={draftData}
              readOnly={props.params.readOnly === 'true'}
              isFromFormWidget={true}
              formPrefilledByFoldProgress={formPrefilledByFoldProgressState}
              updateFormPrefilledByFoldProgress={handleOnUpdateFormPrefilledByFoldProgress}
              proxyHeaders={{
                'account-uuid': props.params.accountId || tpaCareAdvocateParams.accountId || patientInformationFormParams?.accountId,
                'form-widget-access-token': formWidgetAccessToken,
              }}
            />
          )}
          {loading && (
            <VStack
              flex={1}
              justifyContent={'flex-end'}
              marginX={{
                base: 0,
                lg: 32,
              }}
              my={5}
            >
              {SkeletonForm?.map((item, index) => (
                <Box key={index} m={2}>
                  <Skeleton.Text mb={1} />
                  <Skeleton height={100} />
                </Box>
              ))}
            </VStack>
          )}
          {showThankYouPage && (
            <Box
              borderColor={Colors.Custom.BorderColor}
              rounded="lg"
              borderWidth={1}
              backgroundColor={bgColor}
              marginX={{
                base: 0,
                lg: 32,
              }}
              marginY={{
                base: 0,
                lg: 8,
              }}
            >
              <View
                padding={{
                  base: 4,
                  sm: 2,
                  md: 4,
                  lg: 8,
                  xl: 8,
                }}
              >
                <VStack space={2}>
                  <View alignItems="center" justifyContent="center">
                    <Icon
                      as={AntIcon}
                      name="checkcircleo"
                      size="24"
                      color="success.500"
                    />
                  </View>
                  <View alignItems="center" justifyContent="center">
                    <Heading>Thank you</Heading>
                    <Text>Your response has been submitted.</Text>
                  </View>
                </VStack>
              </View>
            </Box>
          )}
          {!loading && formData.noData && (
            <Box
              borderColor={Colors.Custom.BorderColor}
              rounded="lg"
              borderWidth={1}
              backgroundColor={bgColor}
              marginX={{
                base: 0,
                lg: 32,
              }}
              marginY={{
                base: 0,
                lg: 8,
              }}
            >
              <View
                padding={{
                  base: 4,
                  sm: 2,
                  md: 4,
                  lg: 8,
                  xl: 8,
                }}
              >
                <VStack space={2}>
                  <View alignItems="center" justifyContent="center">
                    <Icon
                      as={AntIcon}
                      name="warning"
                      size="24"
                      color="error.500"
                    />
                  </View>
                  <View alignItems="center" justifyContent="center">
                    <Heading>
                      This link is either expired or invalid. Please contact
                      support.
                    </Heading>
                    {/* <Text>Your submission has been sent.</Text> */}
                  </View>
                </VStack>
              </View>
            </Box>
          )}
        </View>
      )}
    </>
  );
};

export default PublicForm;
