import { Observation } from 'fhir/r4';
import { get, has, toNumber } from 'lodash';
import moment from 'moment';
import { useContext } from 'react';
import {
  DISPLAY_DATE_FORMAT,
  MLOV_CATEGORY,
  PERSON_CONTACT_TYPE_CODES,
  PERSON_TYPES,
  PREFERRED_CHANNEL_CODE,
  PREFERRED_CONTACT_CODE,
} from '../../../../../../constants';
import {CommonDataContext, ICommonData} from '../../../../../../context/CommonDataContext';
import {IAllowedOperation, IEhrCapability, IKeyOperation, IMlov, IPersonContact, PossibleValue} from '../../../../../../Interfaces';
import BaseService from '../../../../../../services/CommonService/BaseService';
import { getCapability } from '../../../../../../utils/capabilityUtils';
import {
  getEmailContactObjFromList,
  getPhoneContactObjFromList,
} from '../../../../../../utils/commonUtils';
import { getAgeDiffInYears, getAgeValue } from '../../../../../../utils/DateUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../../utils/mlovUtils';
import { ISelectedConsent } from '../../interfaces';

const getContactLanguages = (singleLeadData: any) => {
  if (singleLeadData?.contactLanguages?.length) {
    return singleLeadData?.contactLanguages[0];
  } else if (singleLeadData?.contactObj?.contactLanguages?.length) {
    return singleLeadData?.contactObj?.contactLanguages[0];
  } else {
    return [];
  }
};
export const getFormDataFromLeadData = (
  singleLeadData: any,
  mlovData: ICommonData
): any => {
  if (singleLeadData && Object.keys(singleLeadData).length) {
    const personContactTypeList = getMlovListFromCategory(
      mlovData.MLOV,
      MLOV_CATEGORY.PERSON_CONTACT_TYPE
    );
    const pronounList = getMlovListFromCategory(
      mlovData.MLOV,
      MLOV_CATEGORY.PRONOUN
    );
    const pronounId =
      singleLeadData?.person?.pronounId ||
      singleLeadData?.contactObj?.person?.pronounId;
    let pronounName = '';
    if (pronounId) {
      const getPronounObj = pronounList?.find((singlePronounObj) => {
        return singlePronounObj.id === pronounId;
      });
      pronounName = getPronounObj?.code || '';
    }
    const locationUuid =
      singleLeadData?.contactPracticeLocations?.length > 0
        ? singleLeadData?.contactPracticeLocations[0]?.practiceLocationUuid
        : '';

    const accountLocationUuid =
      singleLeadData?.contactPracticeLocations?.length > 0
        ? singleLeadData.contactPracticeLocations[0]?.accountLocation?.uuid ||
          ''
        : '';
    const emailContactTypeId = getMlovIdFromCode(
      personContactTypeList,
      PERSON_CONTACT_TYPE_CODES.EMAIL
    );
    const phoneContactTypeId = getMlovIdFromCode(
      personContactTypeList,
      PERSON_CONTACT_TYPE_CODES.PHONE
    );

    let contactList = singleLeadData?.personContact || [];
    const contactLanguages = getContactLanguages(singleLeadData);

    if (contactList && contactList.length) {
      contactList = updateContactTypeIdIfNotExist(
        contactList,
        personContactTypeList
      );
    }
    const emailObj = getEmailContactObjFromList(
      contactList,
      personContactTypeList,
      1
    );
    const phoneObj = getPhoneContactObjFromList(
      contactList,
      personContactTypeList,
      1
    );

    const secondaryEmailObj = getEmailContactObjFromList(
      contactList,
      personContactTypeList,
      2
    );
    const secondaryPhoneObj = getPhoneContactObjFromList(
      contactList,
      personContactTypeList,
      2
    );
    const personContactList = getPersonContactList(
      emailObj,
      phoneObj,
      secondaryEmailObj,
      secondaryPhoneObj
    );
    const secondaryEmail = secondaryEmailObj ? secondaryEmailObj?.value : null;
    const secondaryPhone = secondaryPhoneObj ? secondaryPhoneObj?.value : null;

    let ageInYears;
    let birthDate =
      singleLeadData?.person?.birthDate &&
      new Date(singleLeadData?.person?.birthDate);
    if (birthDate) {
      birthDate = moment(birthDate).format(DISPLAY_DATE_FORMAT);
      ageInYears = moment().diff(birthDate, 'years', false);
    }
    const address = singleLeadData?.personAddress?.length ? singleLeadData?.personAddress[0] : singleLeadData?.contactObj?.personAddress?.length ? singleLeadData?.contactObj?.personAddress[0] : undefined;
    // let genderName = '';
    // if (singleLeadData?.person?.genderId) {
    //     genderName = getMlovValueFromId(personGenderList, singleLeadData?.person?.genderId)
    // }

    const firstName = singleLeadData?.person?.firstName
      ? singleLeadData?.person?.firstName
      : singleLeadData?.name
      ? singleLeadData?.name
      : '';
    const middleName = singleLeadData?.person?.middleName
      ? ' ' + singleLeadData?.person?.middleName
      : '';
    const lastName = singleLeadData?.person?.lastName
      ? ' ' + singleLeadData?.person?.lastName
      : '';
    const fullName = firstName + middleName + lastName;
    const note =
      singleLeadData?.notes && singleLeadData?.notes.length
        ? singleLeadData?.notes[singleLeadData?.notes.length - 1]
        : '';
    const preferredChannel =
      singleLeadData?.contactPreferredCommunicationChannel?.[0];

    const tagIds = singleLeadData?.contactTagging
      ?.map((item: any) => item?.labels?.id)
      ?.filter((item: any) => !!item) || [];
    const formTempData = {
      leadId: singleLeadData?.id || '',
      firstName: singleLeadData?.person?.firstName || singleLeadData?.person?.name || singleLeadData?.contactObj?.person?.firstName,
      name: fullName ? fullName : singleLeadData?.name,
      cityId: address?.cityId || '',
      contactType: singleLeadData?.contactType?.contactType || '',
      contactConsents: singleLeadData?.contactConsents,
      stateId: address?.stateId || '',
      zipCodeId: address?.zipcodeId || '',
      email: singleLeadData?.email || singleLeadData?.contactObj?.email || '',
      phone: singleLeadData?.phoneNumber || singleLeadData?.contactObj?.phoneNumber || '',
      emailObj: emailObj || {},
      phoneObj: phoneObj || {},
      secondaryEmailObj: secondaryEmailObj || {},
      secondaryPhoneObj: secondaryPhoneObj || {},
      emailContactTypeId: emailContactTypeId,
      phoneContactTypeId: phoneContactTypeId,
      personAddressId: address?.id || '',
      cityName: address?.cities?.name || '',
      id: singleLeadData?.id || '',
      stateName: address?.states?.name || '',
      typeId: address?.typeId || '',
      zipCode: address?.zipcodes?.code || '',
      patientId: singleLeadData?.patient
        ? singleLeadData?.patient?.patientId
        : '',
      patientUuid: singleLeadData?.patient
        ? singleLeadData?.patient?.patientUuid
        : '',
      annualVisitDate: singleLeadData?.patient?.annualVisitDate
        ? singleLeadData?.patient?.annualVisitDate
        : undefined,
      isWeeklyCheckin: singleLeadData?.patient?.isWeeklyCheckin
        ? singleLeadData?.patient?.isWeeklyCheckin
        : false,
      personId: singleLeadData?.person?.id || '',
      lastName: singleLeadData?.person?.lastName || singleLeadData?.contactObj?.person?.lastName || '',
      middleName: singleLeadData?.person?.middleName || '',
      bloodGroupId:
        singleLeadData?.person?.bloodGroupId ||
        singleLeadData?.contactObj?.person?.bloodGroupId,
      genderId: singleLeadData?.person?.genderId || '',
      genderName:
        singleLeadData?.person?.gender?.code ||
        singleLeadData?.person?.gender?.value ||
        '',
      sexAtBirth: singleLeadData?.person?.sexAtBirth?.value || singleLeadData?.person?.sexAtBirth || singleLeadData?.contactObj?.sexAtBirth || '',
      sexAtBirthCode: singleLeadData?.person?.sexAtBirth?.code,
      practitionerId: singleLeadData?.person?.practitionerId || singleLeadData?.contactObj?.person?.practitionerId || '',
      practitionerName: '',
      birthDate: singleLeadData?.person?.birthDate
        ? singleLeadData?.person?.birthDate : singleLeadData?.contactObj?.person ? singleLeadData?.contactObj?.person?.birthDate
        : undefined,
      marriageAnniversaryDate: singleLeadData?.person?.marriageAnniversaryDate
      ? singleLeadData?.person?.marriageAnniversaryDate
      : undefined,
      age: ageInYears || '',
      noteId: note.id || '',
      personTypeId: singleLeadData?.contactType?.id || '',
      note: note.content || '',
      // line1: singleLeadData?.address?.line1 || '',
      // line2: singleLeadData?.address?.line2 || '',
      isEhrChecked: true,
      locationUuid: locationUuid || '',
      contactUUID: singleLeadData?.uuid || '',
      uuid: singleLeadData?.uuid || '',
      workflowMetaData: isString(singleLeadData?.workflowMetaData),
      address: address?.line1 || '',
      line1: address?.line1 || '',
      line2: address?.line2 || '',
      ...(address?.states?.abbr && {abbr: address?.states?.abbr}),
      contactTags: tagIds,
      accountLocationUuid: accountLocationUuid,
      contactLanguages: contactLanguages,

      employerId:
        (singleLeadData?.employees?.length &&
          singleLeadData?.employees[0]?.employerId) ||
        null,
      employerName: (singleLeadData?.employees?.length &&
          singleLeadData?.employees[0]?.employer?.name) ||
        null,
      employeeId:
        (singleLeadData?.employees?.length &&
          singleLeadData?.employees[0]?.contactId) ||
        null,
      secondaryEmail: secondaryEmail,
      secondaryPhone: secondaryPhone,
      personContactList: personContactList,
      preferredChannelId: preferredChannel?.id || '',
      preferredModeId: preferredChannel?.preferredCommunicationModeId || '',
      personContact: singleLeadData?.personContact,
      chosenName : singleLeadData?.person?.chosenName || singleLeadData?.contactObj?.person?.chosenName,
      pronounId: pronounId,
      pronounName: pronounName,
      contactData: singleLeadData,
      sexAtBirthId: singleLeadData?.person?.birthSexId || ''
    };
    return formTempData;
  }
  return {};
};

function isString(arg: any) {
  const argIsString = typeof arg === 'string';
  if (argIsString) {
    return null;
  } else {
    return arg;
  }
}

const updateContactTypeIdIfNotExist = (
  contactList: IPersonContact[],
  personContactTypeList: IMlov[]
): IPersonContact[] => {
  const tempContactList: IPersonContact[] = [];
  const emailContactTypeId = getMlovIdFromCode(
    personContactTypeList,
    PERSON_CONTACT_TYPE_CODES.EMAIL
  );
  const phoneContactTypeId = getMlovIdFromCode(
    personContactTypeList,
    PERSON_CONTACT_TYPE_CODES.PHONE
  );
  contactList.forEach((singleContact: IPersonContact) => {
    const tempContactObj = {...singleContact};
    if (!tempContactObj.typeId) {
      if (tempContactObj.value && tempContactObj.value.includes('@')) {
        tempContactObj.typeId = emailContactTypeId;
      } else {
        tempContactObj.typeId = phoneContactTypeId;
      }
    }
    tempContactList.push({...tempContactObj});
  });
  return tempContactList;
};

const baseService = BaseService.getSharedInstance().axios;

export const sendPatientAppInvite = (args: {
  email: string;
  accountId: string | number;
  accountUuid: string;
  contactUuid: string;
}) => {
  const url = 'crm-nest/patientAppInvite';
  const payload = args;
  return baseService.post(url, payload);
};

export const getNameObject = (resource: any) => {
  const firstName = resource.name.length > 0 ? resource.name[0].given[0] : '';
  const lastName = resource.name.length > 0 ? resource.name[0].family : '';
  return {
    firstName,
    lastName,
  };
};

export const getAddressObject = (resource: any) => {
  if (resource.address.length < 0) {
    return {
      city: '',
      state: '',
      zipCode: '',
    };
  }
  const city = resource.address[0].city;
  const state = resource.address[0].city;
  const zipCode = resource.address[0].postalCode;
  return {
    city,
    state,
    zipCode,
  };
};

export const getFormattedPatientData = (response: any) => {
  const entry = response.entry;
  const list = entry.map((item: any) => {
    const resource = item.resource;
    const gender = resource.gender;
    const id = resource.id;
    const {firstName, lastName} = getNameObject(resource);
    const {city, state, zipCode} = getAddressObject(resource);

    return {
      id,
      firstName,
      lastName,
      gender,
      city,
      state,
      zipCode,
    };
  });
  return list;
};

export const getDuplicateEmailTextByContactType = (
  contactType: string,
  duplicateType: string
) => {
  let message = '';
  if (contactType === PERSON_TYPES.CUSTOMER) {
    message = `Patient with same ${
      duplicateType == 'EMAIL' ? 'email' : 'phone'
    }  already exists`;
  } else {
    message = `Prospect with same  ${
      duplicateType == 'EMAIL' ? 'email' : 'phone'
    } already exists`;
  }
  return message;
};

export const getErrorMessageForSingleContactQuery = (error: any) => {
  const errorMessage: string = error.message;
  if (errorMessage.toLowerCase().includes('email')) {
    return 'Email Id is already registered to the account';
  }
  return 'Something went wrong. Please try again later';
};

export const getPersonContactList = (
  pEmailObj: any,
  pPhoneObj: any,
  sEmailObj: any,
  sPhoneObj: any
) => {
  const personContactList = [];
  if (pEmailObj) {
    personContactList.push(pEmailObj);
  }
  if (pPhoneObj) {
    personContactList.push(pEmailObj);
  }
  if (sEmailObj) {
    personContactList.push(sEmailObj);
  }
  if (sPhoneObj) {
    personContactList.push(sPhoneObj);
  }
  return personContactList;
};

export const checkIfAgeIsLessThan18 = (birthDate: any): boolean => {
  if (birthDate) {
    const ageValue = getAgeDiffInYears(birthDate);
    return ageValue < 18;
  }
  return false;
}

export const checkIfResourceIsEnabled = (
  fieldObj: {[key: string]: any} | undefined,
  locationId: string | undefined,
  allCapabilities?: Array<IEhrCapability>
): boolean => {
  const appliedForResourceNames = get(fieldObj, 'appliedFor', []);
  const capabilities = allCapabilities !== undefined ? allCapabilities : [];

  const isResourceEnabled = appliedForResourceNames.some(
    (appliedForResourceName: string) => {
      let appliedForResourceCapability: IEhrCapability = {};

      if (capabilities.length) {
        const resourceCapability = capabilities.find((capability) => {
          return capability.resourceName === appliedForResourceName;
        });
        appliedForResourceCapability =
          resourceCapability !== undefined ? resourceCapability : {};
      } else {
        appliedForResourceCapability = getCapability(
          appliedForResourceName,
          '',
          locationId
        );
      }

      if (has(appliedForResourceCapability, 'abilities')) {
        const resourceAbilities = get(
          appliedForResourceCapability,
          'abilities'
        );

        if (has(resourceAbilities, 'isEnabled')) {
          const isEnabled = get(resourceAbilities, 'isEnabled');
          return isEnabled === true;
        }
      }
    }
  );

  return !!isResourceEnabled;
};

const checkConditionalFieldsForFieldConfig = (
  conditionalFields: {[key: string]: any},
  fieldKey: string,
  allCapabilities:Array<IEhrCapability>,
  locationId: string | undefined
) => {
  if (has(conditionalFields, fieldKey)) {
    const fieldObj = get(conditionalFields, fieldKey);

    if (has(fieldObj, 'appliedFor')) {
      const isResourceEnabled = checkIfResourceIsEnabled(
        fieldObj,
        locationId,
        allCapabilities
      );
      // const isResourceEnabled = true;
      if (isResourceEnabled) {
        return fieldObj;
      } else {
        return {};
      }
    }
  } else {
    // * if conditional fields doesn't have the key supplied to it
    return {};
  }
};


export const getFieldConfig = (
  fieldKey: string,
  locationId: string | undefined,
  resourceName: string,
  ehrCapabilities?: Array<IEhrCapability>
): any => {
  const allCapabilities = ehrCapabilities !== undefined ? ehrCapabilities : [];
  let capabilityOfResource:IEhrCapability = {};

  if(allCapabilities.length){
    const capability = allCapabilities.find((capability)=>{
      return capability.resourceName === resourceName;
    })
    capabilityOfResource = capability !== undefined ? capability : {};
  }else {
    capabilityOfResource = getCapability(resourceName, '', locationId);
  }

  const resourceAbilities = capabilityOfResource?.abilities;
  const keyAllowedOperations = resourceAbilities?.keyAllowedOperations;

  if (has(keyAllowedOperations, fieldKey)) {
    const fieldConfig = get(keyAllowedOperations, fieldKey, {});
    return fieldConfig;
  } else if (has(resourceAbilities, 'conditionalFields')) {
    const conditionalFields = get(resourceAbilities, 'conditionalFields', {});
    const fieldConfig = checkConditionalFieldsForFieldConfig(
      conditionalFields,
      fieldKey,
      allCapabilities,
      locationId
    );
    return fieldConfig;
  }

  return {};
};

export const isHeightOrWeightPending = (list: Observation[]) => {
  if (list.length < 2) return true;
  const hasObservationsWithoutId = list.some((observation) => !observation.id);
  return hasObservationsWithoutId;
}

export const mapGenderDisplayCode = (personGender:IMlov[],genders:PossibleValue[]) => {
  const updatedGenderArray = personGender.map((personGender: IMlov) => {
    const genderItems = genders.find((gender: PossibleValue) => gender.code === personGender.code)
    if (genderItems) {
      personGender.value = genderItems.display;
    }
    return personGender
  })
  return updatedGenderArray;
}


export const getSelectedConsentMlov = (selectedConsent: ISelectedConsent, consentMlovList: IMlov[]) => {
  const consentIds = [];
  if (selectedConsent?.consentToCall) {
    consentIds.push(getMlovIdFromCode(consentMlovList, PREFERRED_CHANNEL_CODE.VOICE));
  }
  if (selectedConsent?.consentToEmail) {
    consentIds.push(getMlovIdFromCode(consentMlovList, PREFERRED_CHANNEL_CODE.EMAIL));
  }
  if (selectedConsent?.consentToText) {
    consentIds.push(getMlovIdFromCode(consentMlovList, PREFERRED_CHANNEL_CODE.SMS));
  }
  return consentIds;
}


export const getFieldConfigFunction = (
  fieldKey: string,
  locationId: string | undefined,
  resourceName: string,
  ehrCapabilities?: Array<IEhrCapability>
): any => {
  const allCapabilities = ehrCapabilities !== undefined ? ehrCapabilities : [];
  const capabilityOfResource: IEhrCapability = {};

  const resourceAbilities = capabilityOfResource?.abilities;
  const keyAllowedOperations = resourceAbilities?.keyAllowedOperations;

  if (has(keyAllowedOperations, fieldKey)) {
    const fieldConfig = get(keyAllowedOperations, fieldKey, {});
    return fieldConfig;
  } else if (has(resourceAbilities, 'conditionalFields')) {
    const conditionalFields = get(resourceAbilities, 'conditionalFields', {});
    const fieldConfig = checkConditionalFieldsForFieldConfig(
      conditionalFields,
      fieldKey,
      allCapabilities,
      locationId
    );
    return fieldConfig;
  }

  return {};
};