import { CONTACT_CARE_PROGRAM_STATUS_CODES } from "../../../constants/MlovConst";
import { IContactCareProgramStep } from "./interface";

export const getAttributeValueFromStepJson = (step: IContactCareProgramStep, attributeKey: string) => {
  const attribute = step?.careProgramStepAdditionalAttributes?.find(
    (attribute) => attribute.attributeKey === attributeKey
  );

  return Object.values(attribute?.attributeValue || {})[0]
};

export const canUpdateCareProgramStatus = (statusCode: string) => {
  if (statusCode) {
    if (
      [
        CONTACT_CARE_PROGRAM_STATUS_CODES.AUTO_CLOSED,
        CONTACT_CARE_PROGRAM_STATUS_CODES.CLOSED,
        CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED,
      ].includes(statusCode)
    ) {
      return false;
    }
    return true;
  }
  return false;
};