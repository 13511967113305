import {gql} from '@apollo/client';

export const GetUserSettings = gql`
  query getUserSettings($userId: uuid!, $tenantId: uuid!) {
    defaultUserSettings(where: {isDeleted: {_eq: false}}) {
      id
      code
      value
      userSettings(where: {isDeleted: {_eq: false}, tenantId: { _eq: $tenantId}, _or:[{ userId: {_eq: $userId}}, {userId: {_is_null: true}}]}, order_by: {userId: desc_nulls_last}, limit: 1) {
        id
        userId
        tenantId
        value
      }
    }
  }
`;

export const GetAccountSettings = gql`
  query getUserSettings($tenantId: uuid!) {
    defaultUserSettings(where: {isDeleted: {_eq: false}}) {
      id
      code
      value
      userSettings(where: {isDeleted: {_eq: false}, tenantId: {_eq: $tenantId}, userId: {_is_null: true}}, limit: 1) {
        id
        userId
        tenantId
        value
      }
    }
  }
`;

export const GetAccountSettingsByCode = gql`
  query getAccountSettingsByCode( $code: String!, $tenantId: uuid!) {
    defaultUserSettings(where: {code: {_eq: $code}, isDeleted: {_eq: false}}) {
      id
      code
      value
      userSettings(where: {isDeleted: {_eq: false}, tenantId: {_eq: $tenantId}, userId: {_is_null: true}}, limit: 1) {
        id
        userId
        tenantId
        value
      }
    }
  }
`;
