import { IAddMemberStatusState, IGetMemberStatusResponse, IMemberStatusData, IMemberStatusDataErrors, IMemberStatusRef } from './interface';
import { MEMBER_STATUS_DESCRIPTION_MAX_CHAR_LIMIT, MEMBER_STATUS_NAME_MAX_CHAR_LIMIT } from './MemberStatusConst';

export const getFormattedMemberStatusData = (
  memberStatusData: IGetMemberStatusResponse[]
) => {
  const statusData: IMemberStatusData[] = memberStatusData?.map(
    (memberStatus) => {
      return {
        id: memberStatus?.id,
        name: memberStatus?.name,
        color: memberStatus?.color,
        key: memberStatus?.code || '',
        description: memberStatus?.description || '',
        statusType: memberStatus?.isDefault? 'Default': 'Custom',
        inUse: memberStatus?.inUse,
        isDefault: memberStatus?.isDefault
      };
    }
  );
  return statusData;
};

export const validateMemberStatusData = (
  MemberStatusRef: React.MutableRefObject<IMemberStatusRef>,
  MemberStatusFormData: IAddMemberStatusState
) => {
  const errors = {} as IMemberStatusDataErrors;
  let validate = true;
  errors.name = !MemberStatusRef?.current['name']?.value.trimStart()
    ? 'Name is required'
    : MemberStatusRef?.current['name']?.value.length < 3
    ? 'Enter minimum 3 characters'
    : MemberStatusRef?.current['name']?.value.length > MEMBER_STATUS_NAME_MAX_CHAR_LIMIT
    ? `Maximum ${MEMBER_STATUS_NAME_MAX_CHAR_LIMIT} characters are allowed`
    : '';

  errors.description = !MemberStatusRef?.current['description']?.value.trimStart()
    ? 'Description is required'
    : MemberStatusRef?.current['description']?.value.length < 3
    ? 'Enter minimum 3 characters'
    : MemberStatusRef?.current['description']?.value.length > MEMBER_STATUS_DESCRIPTION_MAX_CHAR_LIMIT
    ? `Maximum ${MEMBER_STATUS_DESCRIPTION_MAX_CHAR_LIMIT} characters are allowed`
    : '';

  if (errors.name || errors.color || errors.description) {
    validate = false;
  }
  return {
    validate: validate,
    errors: errors
  };
}

export const getMemberStatusBodyData = (
  MemberStatusRef: React.MutableRefObject<IMemberStatusRef>,
  MemberStatusFormData: IAddMemberStatusState
) => {
  const MemberStatusId = MemberStatusFormData?.selectedMemberStatus?.id;
  return {
    id: MemberStatusId ? MemberStatusId : '',
    name: MemberStatusRef?.current['name']?.value,
    description: MemberStatusRef?.current['description']?.value,
    color: MemberStatusFormData?.color,
    type: MemberStatusFormData?.selectedType,
  }
}
