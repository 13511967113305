import {getDiffInDays, getDiffInHours} from '../../../../../../../utils/DateUtils';
import {dischargeDispositionOptions, ICareProgramAdditionalAttribute} from './interface';
import BaseService from '../../../../../../../services/CommonService/BaseService';
import {CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE} from '../../../../FormsConstants';

export const getFormttedAdditionalAttributes = (
  additionalAttributes: ICareProgramAdditionalAttribute[]
) => {
  const formattedData: Record<string, any> = {};

  for (const attr of additionalAttributes) {
    formattedData[attr.attributeKey] = {
      value: attr.attributeValue.valueString,
      source: attr.source || CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
    };
  }

  return formattedData;
};

export const calculateLengthOfStay = (
  admissionDate: string,
  dischargeDate: string,
  displayUnit?: string
) => {
  if (!admissionDate || !dischargeDate) {
    return 0;
  }
  let diff;
  if (displayUnit && displayUnit == 'hour') {
    diff = getDiffInHours(admissionDate, dischargeDate)
    return diff
  }
  const diffInDays = getDiffInDays(admissionDate, dischargeDate);
  return diffInDays;
};

export const isSourceFromAdtEvent = (source: string) => {
  if (!source) {
    return false;
  }
  return source === CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.ADT_EVENT;
};

export const getDiagnosisCodes = async (searchString?: string) => {
  const diagnosisUrl = `/integration/api/codes`;
  const axiosService = BaseService.getSharedInstance();
  const axios = axiosService.axios;
  const response = await axios.get(diagnosisUrl, {
    params: {
      limit: 5,
      offset: 0,
      searchType: 'Diagnosis',
      ...(searchString && {searchString}),
    },
  });
  return response.data;
};

export const getFormattedDiagnosisCodes = async (searchString?: string) => {
  const diagnosisCodes = await getDiagnosisCodes(searchString);
  const formattedDiagnosisCodes =
    diagnosisCodes?.codeable?.map((singleDiagnosis: any) => ({
      label: singleDiagnosis?.coding?.[0]?.display,
      value: singleDiagnosis?.coding?.[0]?.code,
    })) || [];
  return formattedDiagnosisCodes;
};

export const getSelectedDischargeDispositionOption = (option: string) => {
  const selectedOption = dischargeDispositionOptions?.find((item) => option && item?.value === option)?.value
  return selectedOption || 'other'
}
