import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const getMessagingWindowCommonStyles = ({
  msgWindowHeight,
}: {
  msgWindowHeight?: number;
}) => {
  return StyleSheet.create({
    flatListStyle: {
      borderBottomColor: Colors.Custom.BorderColor,
      flex: 1,
      height: '100%',
      ...(msgWindowHeight ? {maxHeight: msgWindowHeight} : {}),
      flexDirection: 'column-reverse',
    },
    conversationAttachmentsScrollViewStyle: {
      borderBottomColor: Colors.Custom.BorderColor,
      flex: 1,
      ...(msgWindowHeight ? {maxHeight: msgWindowHeight} : {}),
    },
    safeAreaViewStyle: {
      flex: 1,
    },
    gestureHandlerRootViewStyle: {
      flex: 1,
    },
  });
};
