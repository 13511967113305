import {IAdditionalAttribute, IStepsLog, TAdditionalAttributeValueDataTypeCodes} from "../../../services/ContactCareProgram/interface";
import { ICareProgramAssignee } from "../../PersonOmniView/MiddleContainer/CarePlan/interfaces";
import {IContactOutreachActivity} from "../../SideCar/components/ContactOutreachActivities/interfaces";
import { CareManagementThreeDotsMenuType } from "./ContactCareProgramView/consts";

export interface IContactCareProgram {
  id: string;
  payerCareProgram: IPayerCareProgram;
  endDateTime: string;
  contactCareProgramSteps: IContactCareProgramStep[];
  additionalAttributes: IAdditionalAttribute[];
  // consentLog: null;
  assessmentLogs: IAssessmentLog[];
  contactId: string;
  createdOn: string;
  statusId: string;
  assigneeId?: string;
  assigneeTypeId?: string;
  stepsLog: IStepsLog[];
  complexity?: string;
}


export interface IAssessmentLog {
  id: string;
  careProgramStepId: string;
  resourceId: string;
  resourceTypeCode: string;
  contactCareProgramId: string;
}

export interface IContactCareProgramStep {
  id: string;
  title: string;
  isRequired: boolean;
  careProgramStepType: IMlov;
  parentStepId?: string;
  careProgramSubSteps: ICareProgramSubStep[];
  sequenceNumber: number;
  displaySequenceNumber: number;
  careProgramStepAdditionalAttributes: IAdditionalAttribute[];
  // used for UI only
  subSteps?: IContactCareProgramStep[];
  isExpanded?: boolean;
  contactOutreachActivities?: IContactOutreachActivity[];
}

export interface IMlov {
  id: string;
  code: string;
  value: string;
}

export interface IAttributeValue {
  valueBoolean?: boolean;
  valueString?: string;
  valueNumeric?: number;
  valueJson?: any;
}

export interface ICareProgramSubStep {
  id: string;
  careProgramSubStepType: IMlov;
  careProgramStepStatus: IMlov;
}

export interface IPayerCareProgram {
  careProgramTypeId: string;
  careProgramType: IMlov;
}

export enum ContactCareProgramComponentStatus {
  LOADING = 'loading',
  ERROR = 'error',
  EMPTY = 'empty',
  SUCCESS = 'success',
}


export interface IContactCareProgramState {
  contactCareProgramDetails?: IContactCareProgram;
  status: ContactCareProgramComponentStatus | undefined;
  selectedStepId: string | undefined;
  activeStepId: string | undefined;
  sideBarExpandedStepKeysSet: Set<string>;
  groupedSteps: IContactCareProgramStep[];
  stepProgressMap: Map<string, number>;
  userMap: Map<string, string>;
  formScoreMap: Map<string, IFormScore[]>;
}


export interface IFormLogResponse {
  componentWiseProgress: Record<string, number>;
  formPrefilledByFoldPercent: number;
  submittedData: any[];
  formComponents: any[];
  formLog: any;
  previousFormLogIsExisting?: boolean;
  metadataScore?: IFormScore[];
}

export interface IFormScore {
  id: string;
  groupId: string;
  metadata: {
    operation: string;
    score: number;
    state: string;
    name: string;
  };
}

export interface IFormsByCategory {
  id: string;
  name: string;
  code: string;
  forms: {
    id: string;
    name: string;
  }[];
}

export interface IActivityLog {
  id: string;
  performedByTypeCode: string;
  performedById: string;
  timestamp: string;
  actionCode: string;
  data: {
    newData?: Record<string, unknown>;
    oldData?: Record<string, unknown>;
  };
}

export enum AddButtonAction {
  SEND_FORM = 'sendForm',
  BILLING = 'billing',
  ADD_OUTREACH = 'outreach',
}
export interface ICareProgramAssignmentView {
  contactCareProgramId: string;
  contactId: string;
  careProgramAssigneeId?: string;
  onAssigneeActionPerformed: (assigneeId: string) => void;
  onCareProgramAssigneeChange?: (value: ICareProgramAssignee) => void;
  popoverContent?: React.ReactNode;
  parentPopoverVisible?: boolean;
  closeParentPopover?: () => void;
}


export type CareProgramStepPayload = Omit<
  IContactCareProgramStep & {
    additionalAttributes: IAdditionalAttribute[];
  },
  | 'id'
  | 'careProgramStepType'
  | 'careProgramSubSteps'
  | 'careProgramStepAdditionalAttributes'
  | 'sequenceNumber'
  | 'displaySequenceNumber'
>;

export type AddCareProgramStepInput = {
  contactId: string;
  contactCareProgramId: string;
  careProgramSteps: CareProgramStepPayload[];
}


export interface IAddOrUpdateCareProgramAttributesPayload {
  careProgramAttributes: {
    subjectId: string;
    subjectTypeCode: string;
    contactCareProgramId: string;
    additionalAttributes?: {
      attributeKey: string;
      attributeValue?: IAttributeValue;
      attributeValueDataTypeCode: TAdditionalAttributeValueDataTypeCodes;
      isDeleted: boolean;
    }[];
  }[];
}

export enum AttributeKey {
  FORM_LOG_STATUS = 'formLogStatus',
  APPOINTMENT_ID = 'appointmentId',
  DISABLED_EXTRA_STEP_ACTIONS = 'disabledExtraStepActions',
  PREVIOUS_FORM_INSTANCE_DISABLED = 'previousFormInstanceDisabled',
  SUPPORTED_ACTIONS = 'supportedActions',
  SYNC_WITH_EHR = 'syncToEhr',
}

export interface CPTCode {
  category: string;
  code: string;
  cptCode: string;
  amount: number;
  title: string;
  description: string;
}

export interface ICareProgramActionsPayload {
  careProgramParams: {
    contactCareProgramId: string;
    contactCareProgramStepId: string;
  };
  actionTypeCode: string;
  formResponseParams?: any;
  formLogLinkParams?: any;
}


export interface AddOrUpdateContactOutreachActivityInput {
  id?: string;
  contactId: string;
  resourceId?: string;
  resourceType?: string;
  statusId: string;
  careProgramTypeId: string;
  typeId?: string;
  communicationModeId?: string;
  outcomeId?: string;
  note?: string;
  outreachDateTime: string;
  careProgramStepPayload?: {
    contactCareProgramId: string;
    careProgramStepId?: string;
  };
}

export interface IStepViewConfig {
  showProgress: boolean;
  showSequenceNumber: boolean;
  displayTitle: string;
}
export interface ICareProgramData {
  selectedStep: IContactCareProgramStep;
  careProgramId: string;
  careProgramTypeId: string;
  handleUpdateCareProgram?: (actionCode?: string, actionData?: any) => void;
  complexity: string;
}

export interface ICareManagementThreeDotsMenuProps {
  careProgramTypeCode: string;
  contactId: string;
  careProgramId: string;
  hidePopoverContainer?: boolean;
  activeDrawerCode?: CareManagementThreeDotsMenuType;
  onClose?: () => void;
  onSuccess?: (action: CareManagementThreeDotsMenuType) => void;
}
