import { useLazyQuery } from '@apollo/client';
import { cloneDeep, debounce } from 'lodash';
import {
  Badge,
  Divider,
  Input,
  HStack,
  ScrollView,
  Spinner,
  useToast,
  VStack,
  Icon,
  Text
} from 'native-base';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Pressable, useWindowDimensions, View } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import { MLOV_CATEGORY, PARENT_CODE, PERSON_TYPES, SMALL_WINDOW_1500 } from '../../constants';
import { CONFIG_CODES } from '../../constants/AccountConfigConst';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../constants/Configs';
import { TASK_ALERT_CATEGORY_CODES, TASK_STATUS_CODES } from '../../constants/MlovConst';
import { CommonDataContext } from '../../context/CommonDataContext';
import { FormsQueries, LeadQueries } from '../../services';
import { getCurrentPatientDeceasedData } from '../../services/CommonService/AidBoxService';
import { GET_AGGREGATE_TASK_COUNT } from '../../services/Task/TaskQueries';
import { Colors } from '../../styles';
import { getCarePlanProps, getUserUUID, isEnableCareProgram, showAlertsInSideCar, showHomeMonitoringInSideCar, showSummaryInSideCar } from '../../utils/commonUtils';
import { showToast, ToastType } from '../../utils/commonViewUtils';
import { isAccountConfigEnabled } from '../../utils/configUtils';
import {
  getCareStudioMlovListByCategoryCode,
  getMlovIdFromCode,
  getMlovListFromCategory
} from '../../utils/mlovUtils';
import {ClinicalSectionFilterTabs} from '../PatientOmniView/Sections/ClinicalSection/ClinicalSectionFilterTabs/ClinicalSectionFilterTabs';
import {getFormDataFromLeadData} from '../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import {formatFormsResponse} from '../RightSideContainer/Forms/FHFormio/CustomComponents/ClinicalAssessment/ClinicalAssessmentHelper';
import {IFormResponse} from '../RightSideContainer/Forms/FHFormio/CustomComponents/ClinicalAssessment/interfaces';
import {IContact, IConversationData} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import Stack from '../common/LayoutComponents/Stack';
import SearchBar from '../common/SearchBar/SearchBar';
import SearchIconSvg from '../common/Svg/SearchMessageAllSvg/SearchIconSvg';
import FilterIconSvgForAlerts from '../common/Svg/SideCarSvg/FilterIconSvgForAlerts';
import AlertsView from './AlertsView';
import CareGapDetailView from './CareGapDetailView';
import InsightsCareBoardView from './components/InsightsCareBoardView';
import InsightsCreateReferral from './components/InsightsCreateReferral';
import InsightsReferralOrdersView from './components/InsightsReferralOrdersView';
import InsightsSummaryView from './components/InsightsSummaryView';
import { ASSESSMENT_FORM_CATEGORY, SIDE_BAR_CARE_MENU_CODES } from './constants';
import { ITaskAlert } from './SideBarCustomHooks/useFetchAlerts';
import SidecarAssignCareJourney from './SidecarAssignCareJourney';
import { SidecarPatientInfoBanner } from './SidecarPatientInfoBanner';
import { IDiagnosisGap, ISidecarTab } from './interfaces';
import DiagnosisGapDetailView from './DiagnosisGapDetailView';
import { SidecarTitle } from './SidecarTitle';

import CarePlan from '../PersonOmniView/MiddleContainer/CarePlan/CarePlan';
import { ICarePlanProps } from '../PersonOmniView/MiddleContainer/CarePlan/interfaces';
import SideCarPatientComms from './SideCarPatientComms';
import InsightsTasksView from './components/InsightsTasksView';
import { TaskPanelType } from '../TaskCard/TaskEnum';
import InsightsPatientNotesView from './components/InsightsPatientNotesView';
import { CONVERSATION_CONTACT_TYPES_ARRAY, taskCountCategory } from './SideCarConst';
import { getUserTasksDefaultParams } from '../TaskModule/TaskModuleHelper';
import { ITaskFilter } from '../TaskModule/TaskInterfaces';
import useTaskCountManager from '../common/CareDashboard/CustomHook/useTaskCountManager';
import SidecarPatientChatManager from './SidecarPatientChatManager';
import { MessageUnreadCount } from './SideCarHelpers/MessageUnreadCount';
import { EventBus } from '../../utils/EventBus';
import CustomTabsView from '../common/CareDashboard/CareDashboardTopBar/CustomTabsView';
import { ITabsList } from '../common/CareDashboard/CareDashboardTopBar/interfaces';
import CrossIconSvg from '../common/Svg/CrossIconSvg';
import { LeftContainerMainView } from '../PersonOmniView/LeftContainer/LeftContainerMainView';
import { IPersonData } from '../PersonOmniView/PersonHeaderBar/interfaces';
import ContactTimelineView from '../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/ContactTimelineView/ContactTimelineView';
import CareManagement from '../RightSideContainer/CareManagementView/CareManagement';
import {TagDataListView} from '../PersonOmniView/LeftContainer/OtherDetails/TagDataListView';
import {PATIENT_QUICK_PROFILE_PARENT_CODES} from '../../constants/ActionConst';
import {redirectToNewTab} from './SidecarUtils';
interface ISidecarDrawerProps {
  onClose: () => void;
  isInsightsDrawerVisible?: boolean;
  onActionClick?: (action: string) => void;
  contactId: string;
  selectedAction: string;
  isFoldView?: boolean;
  reasonForPrioritization?: string[];
  reasonForPrioritizationQA?: string[];
  sideCarDashboardEnabled?: boolean;
  containerHeight?: number;
  conversationData?: IConversationData;
  parentCode?: string;
}

export interface ISidecarInsightsDrawerState {
  forms: IFormResponse[];
  showFiltersTabInAlerts: boolean;
  alertsFilterCount: number;
  alertsCount: number;
  showSearchBar: boolean;
  searchText: string;
  contactData?: IContact;
  formattedContactData?: IContact;
  isLoading?: boolean;
  selectedTab: string;
  showAssignCareJourney: boolean;
  showReferralOrders: boolean;
  showCreateOrEditReferral: boolean;
  referralData: any;
  selecetedCareGapDetail?:  ITaskAlert;
  taskPanelType: TaskPanelType
  selectedDiagnosisGapDetail?: IDiagnosisGap;
  taskCountCategories: {key: string, code: string, name: string, filters: ITaskFilter}[];
  messageCount: number;
}

enum IInsightsTabs {
  SUMMARY = 'summary',
  DASHBOARD = 'dashboard',
  ALERTS = 'alerts',
  REFERRAL = 'referral',
  REFERRALADD = 'referralAdd',
  CHAT = 'chat',
  TASKS = 'tasks',
  PATIENT_NOTES = 'patientNotes',
  CARE_MANAGEMENT = 'careManagement',
  INSIGHTS = 'insights',
  ACTIVITY = 'activity'
}

export const SidecarInsightsDrawer = (props: ISidecarDrawerProps) => {
  const isCompactView = props?.parentCode === PATIENT_QUICK_PROFILE_PARENT_CODES.MESSAGING;
  const {contactId} = props;
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const isCareProgramEnabled = isEnableCareProgram(mlovData.userSettings);
  const isEFaxMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_E_FAX_COMMUNICATION);
  const isReferralEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_REFERRAL_ENABLED)
  const showSummary = showSummaryInSideCar();
  const showHomeMonitoring = showHomeMonitoringInSideCar();
  const showAlerts = showAlertsInSideCar() && !props.sideCarDashboardEnabled;
  const toast = useToast();
  const userUuid = getUserUUID();
  const headerContainerRef: any = useRef();
  const isSidecarContext = mlovData?.sidecarContext?.isSidecar;
  const [isNavigatingToCarePlan, setIsNavigatingToCarePlan] = useState(false);
  const currentUserUUID = getUserUUID();
  const {height} = useWindowDimensions();
  const currentUserId = mlovData?.userData?.id;
  const { width } = useWindowDimensions();
  const isSmallScreen = width < SMALL_WINDOW_1500;
  const showOnlyInternalChatInSidecar = isAccountConfigEnabled(CONFIG_CODES.SHOW_ONLY_INTERNAL_CHAT_IN_SIDECAR);
  const [renderedTabs, setRenderedTabs] = useState<string[]>([]);
  const [tabs, setTabs] = useState<ITabsList[]>([]);
  const [loadingTabs, setLoadingTabs] = useState<string[]>([]);

  const loadingTimeoutRef = useRef<NodeJS.Timeout>();

  const getDefaultSelectedTab = () => {
    if(showAlerts) {
      return IInsightsTabs.ALERTS;
    }
    if(showSummary) {
      return IInsightsTabs.SUMMARY;
    }
    if(showHomeMonitoring) {
      return IInsightsTabs.DASHBOARD;
    }
    return IInsightsTabs.TASKS;
  }

  const [componentState, setComponentState] =
    useState<ISidecarInsightsDrawerState>({
      forms: [],
      isLoading: true,
      selectedTab: getDefaultSelectedTab(),
      showFiltersTabInAlerts: false,
      alertsFilterCount: 0,
      alertsCount: 0,
      showSearchBar: false,
      searchText: '',
      showAssignCareJourney: false,
      showCreateOrEditReferral: false,
      showReferralOrders: false,
      referralData: {},
      selecetedCareGapDetail: {} as ITaskAlert,
      taskPanelType: TaskPanelType.INTERNAL,
      selectedDiagnosisGapDetail: {} as IDiagnosisGap,
      taskCountCategories: [],
      messageCount: 0
    });

  const { taskCount } = useTaskCountManager({
    params: componentState.taskCountCategories,
    isFetchTodaysCount: false,
  });

  const taskCountNumber = taskCount?.length ? taskCount[0]?.count : 0;

  const carePlanProps = getCarePlanProps(componentState?.formattedContactData, componentState?.contactData, setIsNavigatingToCarePlan, props?.isFoldView);

  const taskAlertDisplayCategoryMlovs =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_ALERT_DISPLAY_CATEGORIES
    ) || [];

  const isSidecarPatientContext = mlovData?.sidecarContext?.isPatient;
  const [GetContact] = useLazyQuery(LeadQueries.GetContact, {
    fetchPolicy: 'no-cache',
  });

  const taskStatusMlovs = getCareStudioMlovListByCategoryCode(MLOV_CATEGORY.TASK_STATUS)?.filter((mlov)=> {
    return [TASK_STATUS_CODES.MISSED,TASK_STATUS_CODES.ACCEPTED].includes(mlov.code)
  })
  const taskPriorityMlovs = getCareStudioMlovListByCategoryCode(MLOV_CATEGORY.TASK_PRIORITY);

  const [getPatientForms] = useLazyQuery(
    FormsQueries.GET_FORM_RESPONSES_SCORE_BY_FORM_CATEGORY,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  const [getAlertsCount, {loading: alertsCountLoading}] = useLazyQuery(
    GET_AGGREGATE_TASK_COUNT,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      variables: {
        params: {
          assigneeIds: [userUuid],
          includeOnlyAlerts: true,
          contactIds: [componentState?.contactData?.uuid],
          taskAlertDisplayCategoryIds: getMlovIdFromCode(
            taskAlertDisplayCategoryMlovs,
            TASK_ALERT_CATEGORY_CODES.PENDING
          ),
        },
      },
      onCompleted: (response) => {
        setComponentState((prev) => {
          return {
            ...prev,
            alertsCount: response?.getTasks?.aggregate?.total || 0,
          };
        });
      },
    }
  );

  useEffect(()=> {
    if(componentState?.contactData?.uuid) {
      const categories = taskCountCategory.map((item: any) => {
      const params = getUserTasksDefaultParams({
        selectedTab: '',
        topBarData: {
          selectedStatusList: taskStatusMlovs,
          selectedPriorityList: taskPriorityMlovs,
          selectedContactUuid: [{value: componentState.contactData?.uuid}],
        } as any,
        taskStatusList: taskStatusMlovs,
        taskPriorityList: taskPriorityMlovs,
        defaultUserId: currentUserUUID,
        allUsersPool: [],
        searchString: '',
        boardType: null as any,
        categoryCode: item.code
      });
      return {
        key: item.code,
        code: item.code,
        name: item.name,
        filters: params
      }
      });


      setComponentState((prev)=> {
        return {
          ...prev,
          taskCountCategories: categories
        }
      })
    }

    const contactIdNumber = Number(contactId) || 0;

    if(!contactIdNumber){
      return;
    }

    const eventBus = EventBus.getEventBusInstance();
    const messageUnreadCountInstance = new MessageUnreadCount({
      conversationTypesArray: CONVERSATION_CONTACT_TYPES_ARRAY,
      eventBus: eventBus,
      userUuid: currentUserUUID,
      contactId: contactIdNumber,
      userId: currentUserId,
      updateMessageCountCallback: updateMessageCountCallback,
      selectedConversationUuid: ""
    });

    const fetchAndSetMessageCount = async () => {
      await messageUnreadCountInstance.fetchInitialMessageCountForPatientContext();
      setComponentState((prev) => ({
        ...prev,
        messageCount: messageUnreadCountInstance.getMessageCount(),
      }));
    };

    fetchAndSetMessageCount();
    messageUnreadCountInstance.addEventListener();
  },[componentState?.contactData?.uuid]);

  const updateMessageCountCallback = (newCount: number) => {
    setComponentState((prev) => ({
      ...prev,
      messageCount: newCount,
    }));
  };

  useEffect(() => {
    getContactData(contactId);
  }, [props.contactId]);

  useEffect(() => {
    if(componentState?.contactData?.uuid) {
      updateRenderedTabs(getDefaultSelectedTab());
      setTabs(insightTabs || []);
    }
  },[componentState?.contactData?.uuid]);

  useEffect(() => {
    setUpdatedTabsCount();
  }, [taskCountNumber, componentState?.alertsCount, componentState?.messageCount]);

  const setUpdatedTabsCount = () => {
    const countMapping = {
      [IInsightsTabs.TASKS]: taskCountNumber,
      [IInsightsTabs.ALERTS]: componentState?.alertsCount,
      [IInsightsTabs.CHAT]: componentState?.messageCount
    };

    const countTooltipMapping = {
      [IInsightsTabs.ALERTS]: `${componentState?.alertsCount} pending alerts`,
    }

    const updatedTabs = tabs.map(item => ({
      ...item,
      count: countMapping[item.key as keyof typeof countMapping] ?? item.count,
      countTooltip: countTooltipMapping[item.key as keyof typeof countTooltipMapping] ?? item.countTooltip
    }));   ``
    setTabs(updatedTabs || []);
  }

  const getContactData = async (contactId: string) => {
    if (!contactId) {
      return;
    }

    setComponentState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });

    try {
      const response = await GetContact({
        variables: {
          id: contactId,
        },
      });

      if (response?.data?.contact) {
        const formattedContactaData = getFormDataFromLeadData(
          response?.data?.contact,
          mlovData
        );
        setComponentState((prev) => {
          return {
            ...prev,
            contactData: response?.data?.contact,
            contactType: response?.data?.contact?.contactType?.contactType,
            formattedContactData: formattedContactaData,
            isLoading: false,
          };
        });
        getAlertsCount();
        getCurrentPatientDeceasedData(response?.data?.contact);
        getPatientFormsData(response?.data?.contact?.uuid);
      }
    } catch (error) {
      setComponentState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
    }
  };

  const handleCreateCarePlanClick = useCallback(() => {
    if (isSidecarContext) {
      const path = `/members/patient/${componentState?.formattedContactData?.id}/careManagement/all`
      redirectToNewTab(path);
    } else {
      setIsNavigatingToCarePlan(true);
    }
  }, [componentState?.formattedContactData?.id]);

  const getPatientFormsData = async (contactId: string) => {
    try {
      const response = await getPatientForms({
        variables: {
          contactId: contactId,
          category: ASSESSMENT_FORM_CATEGORY,
        },
      });
      const forms = formatFormsResponse(response?.data?.forms);
      setComponentState((prev) => {
        return {
          ...prev,
          forms,
        };
      });
    } catch (error) {}
  };

  const groupMemberData = {
    groupUserId: componentState?.contactData?.uuid,
    groupUserTypeId:
      componentState?.formattedContactData?.contactType?.contactTypeId,
  };

  const canShowChatOption = () => {
    if (!isSidecarPatientContext) {
      return true;
    }
    return isSidecarPatientContext && !showOnlyInternalChatInSidecar;
  }

  const insightTabs = useMemo(() => {
    const items: ITabsList[] = [];

    if (props.sideCarDashboardEnabled && isCareProgramEnabled) {
      items.push({
        key: IInsightsTabs.INSIGHTS,
        title: intl.formatMessage({ id: 'insights' }),
        containerElement: (
          <VStack>
            <LeftContainerMainView
              unformmatedContactData={componentState?.contactData}
              personType={PERSON_TYPES.CUSTOMER}
              personData={componentState?.formattedContactData as unknown as IPersonData}
            />
          </VStack>
        ),
      });
    }

    if (showAlerts) {
      items.push({
        key: IInsightsTabs.ALERTS,
        title: intl.formatMessage({id: 'alerts'}),
        countLoading: alertsCountLoading,
        count: componentState?.alertsCount,
        countTooltip: `${componentState?.alertsCount} pending alerts`,
        containerElement: (
          <View style={{paddingHorizontal: 12, paddingVertical: 12}}>
            <AlertsView
              openCareGapDetail={(alert) => {
                setComponentState((prev) => {
                  return {
                    ...prev,
                    selecetedCareGapDetail: alert,
                  };
                });
              }}
              reasonForPrioritization={props?.reasonForPrioritization}
              reasonForPrioritizationQA={props?.reasonForPrioritizationQA}
              openDiagnosisGapDetail={(diagnosisGap) => {
                setComponentState((prev) => {
                  return {
                    ...prev,
                    selectedDiagnosisGapDetail: diagnosisGap,
                  };
                });
              }}
              contactId={componentState?.contactData?.uuid}
              showFiltersTabInAlerts={componentState?.showFiltersTabInAlerts}
              onFilterApplied={(
                filterCount: number,
                isResetFilter?: boolean
              ) => {
                setComponentState((prev) => ({
                  ...prev,
                  alertsFilterCount: filterCount,
                  ...(isResetFilter && {showFiltersTabInAlerts: false}),
                }));
              }}
              searchText={componentState.searchText}
              formattedContactData={componentState.formattedContactData}
              contactData={componentState?.contactData}
              isCompactView={props.isFoldView}
              onPendingCountChange={(count: number) => {
                setComponentState((prev) => {
                  return {
                    ...prev,
                    alertsCount: count,
                  };
                });
              }}
            />
          </View>
        ),
      });
    }

    if (showSummary) {
      items.push({
        key: IInsightsTabs.SUMMARY,
        title: intl.formatMessage({id: 'summary'}),
        containerElement: (
          <InsightsSummaryView
            forms={componentState.forms}
            contactData={componentState.contactData}
            formattedContactData={componentState.formattedContactData}
            sideCarDashboardEnabled={props?.sideCarDashboardEnabled}
            assignJourneyOptionClicked={() => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  showAssignCareJourney: true,
                };
              });
            }}
            isCompactView={isCompactView}
            conversationData={props?.conversationData}
          />
        ),
      });
    }

    if (showHomeMonitoring) {
      items.push({
        key: IInsightsTabs.DASHBOARD,
        title: intl.formatMessage({id: 'dashboard'}),
        containerElement: (
          <InsightsCareBoardView
            contactData={componentState.contactData}
            formattedContactData={componentState.formattedContactData}
            isSidecarContext={isSidecarContext}
          />
        ),
      });
    }

    if (canShowChatOption()) {
      items.push({
        key: IInsightsTabs.CHAT,
        title: intl.formatMessage({id: 'communication'}),
        count: componentState?.messageCount,
        containerElement: (
          <View style={{height: height - (props.isFoldView ? 280 : 220)}}>
            <SidecarPatientChatManager
              isDefaultClose={false}
              groupMemberData={groupMemberData as any}
              parentCode={PARENT_CODE.MESSAGING_DETAIL_PANEL}
              style={{marginBottom: 10, borderWidth: 0}}
              shouldDisableLimit={true}
              contactData={componentState?.contactData}
            />
          </View>
        ),
      });
    }

    if (isCareProgramEnabled && !props.sideCarDashboardEnabled) {
      items.push({
        key: IInsightsTabs.PATIENT_NOTES,
        title: intl.formatMessage({id: 'patientNotes'}),
        containerElement: (
          <InsightsPatientNotesView
            contactData={componentState.contactData}
            formattedContactData={componentState.formattedContactData}
            onTaskPanelChanged={(taskPanelType) => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  taskPanelType: taskPanelType,
                };
              });
            }}
          />
        ),
      });
    }

    if (props.sideCarDashboardEnabled) {
      items.push({
        key: IInsightsTabs.ACTIVITY,
        title: intl.formatMessage({id: 'activity'}),
        containerElement: (
          <ContactTimelineView contactData={componentState.contactData as IContact} />
        ),
      });
    }

    const taskCountNumber = taskCount?.length ? taskCount[0]?.count : 0
    if(true) {
      items.push({
        key: IInsightsTabs.TASKS,
        title: intl.formatMessage({id: 'tasks'}),
        count: taskCountNumber,
        containerElement: (
          <InsightsTasksView
            contactData={componentState.contactData}
            formattedContactData={componentState.formattedContactData}
            onTaskPanelChanged={(taskPanelType) => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  taskPanelType: taskPanelType,
                };
              });
            }}
          />
        ),
      });
    }

    if (isEFaxMessagingEnabled && isReferralEnabled && props.sideCarDashboardEnabled) {
      items.push({
        key: IInsightsTabs.REFERRAL,
        title: intl.formatMessage({id: 'referral'}),
        containerElement: (
          <InsightsReferralOrdersView
            formattedContactData={componentState?.formattedContactData}
            contactData={componentState?.contactData}
            createReferralClicked={(val: any) => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  showCreateOrEditReferral: true,
                  referralData: val,
                };
              });
            }}
            onBack={() => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  selectedTab: IInsightsTabs.REFERRAL,
                  showReferralOrders: false,
                };
              });
            }}
          />
        ),
      });
    }

    if (!isSidecarContext && componentState?.contactData && !props.sideCarDashboardEnabled) {
      items.push({
        key: IInsightsTabs.CARE_MANAGEMENT,
        title: intl.formatMessage({id: 'careManagement'}),
        containerElement: (
          <CareManagement
            contactId={componentState?.contactData?.id}
            contactUuid={
              componentState?.contactData?.uuid
            }
            isCompactView
          />
        ),
      });
    }

    return items;
  }, [
    componentState?.contactData?.uuid,
    componentState?.showFiltersTabInAlerts,
    componentState.forms?.length,
    componentState.searchText,
    componentState.alertsCount,
    componentState.messageCount,
    taskCountNumber,
    componentState.alertsFilterCount,
  ]);
  const careGapDetailView = () => {
    return (
      <CareGapDetailView
        contactUuid={componentState.contactData?.uuid || componentState.formattedContactData?.uuid || ''}
        onBackClick={() => {
          setComponentState((prev) => {
            return {
              ...prev,
              selecetedCareGapDetail: {} as ITaskAlert,
            };
          });
          setTabReLoading(IInsightsTabs.ALERTS, true);
        }}
        careGapDetail={componentState.selecetedCareGapDetail || {} as ITaskAlert}
      />
    );
  };

  const diagnosisGapDetailView = () => {
    return (
      <DiagnosisGapDetailView
        onClose={() => {
          setComponentState((prev) => {
            return {
              ...prev,
              selectedDiagnosisGapDetail: {} as IDiagnosisGap,
            };
          });
          setTabReLoading(IInsightsTabs.ALERTS, true);
        }}
        contactData={componentState.contactData}
        diagnosisGapDetail={componentState.selectedDiagnosisGapDetail || {} as IDiagnosisGap}
      />
    )
  }

  const showFilterIcon =
    componentState?.selectedTab === SIDE_BAR_CARE_MENU_CODES.ALERTS;
  const showSearchIcon =
    componentState?.selectedTab === SIDE_BAR_CARE_MENU_CODES.ALERTS;

  const getBottomPadding = () => {
    if(componentState.selectedTab === IInsightsTabs.CHAT) {
      return 0;
    }
    return 20;
  }

  const getPaddingLeft = () => {
    const excludedTabs = [
      SIDE_BAR_CARE_MENU_CODES.ALERTS,
      SIDE_BAR_CARE_MENU_CODES.TASKS,
      SIDE_BAR_CARE_MENU_CODES.REFERRAL,
      SIDE_BAR_CARE_MENU_CODES.CHAT
    ];

    return excludedTabs.includes(componentState.selectedTab) ? 0 : 6;
  };

  const updateRenderedTabs = (code: string, updateTabIndex?: boolean) => {
    const isRenderedTabPresent = renderedTabs.find(item => item === code);
    const updatedRenderedTabs = renderedTabs;
    if(!isRenderedTabPresent) {
      const updatedTab = insightTabs.find(item => item.key === code);
      if(updatedTab) {
        updatedRenderedTabs.push(code);
        setRenderedTabs(updatedRenderedTabs);
      }
    }

    if(updateTabIndex) {
      const tabIndex = tabs.findIndex(item => item.key === code);
      if(tabIndex !== -1 && updateTabIndex) {
        const toIndex = tabs?.length > 2 ? 3 : 2;
        const updatedTabs = moveTabs(tabs, tabIndex, toIndex);
        setTabs(updatedTabs);
      }
    }
  }

  const isTabContentViewVisible = (code: string) => {
    if(isDetailViewVisible()) {
      return false;
    }
    return code === componentState.selectedTab ? true : false;
  }

  const getDetailViewHeight = () => {
    return height - (props.isFoldView ? 280 : 140);
  }

  const isDetailViewVisible = () => {
    return componentState.showAssignCareJourney || componentState.showCreateOrEditReferral || componentState.selecetedCareGapDetail?.id || componentState.selectedDiagnosisGapDetail?.id || isNavigatingToCarePlan;
  }

  const setTabReLoading = (code: string, isLoading: boolean) => {
    if (loadingTimeoutRef.current) {
      clearTimeout(loadingTimeoutRef.current);
    }

    updateTabLoading(code, isLoading);

    loadingTimeoutRef.current = setTimeout(() => {
      updateTabLoading(code, false);
    }, 500);
  }

  const updateTabLoading = (code: string, isLoading: boolean) => {
    if(isLoading) {
      setLoadingTabs((prev) => {
       return [...prev, code];
      });
    }
    else {
      setLoadingTabs((prev) => {
        return prev.filter(item => item !== code);
      });
    }
  }

  useEffect(() => {
    // Cleanup function
    return () => {
      if (loadingTimeoutRef.current) {
        clearTimeout(loadingTimeoutRef.current);
      }
    };
  }, []);

  const getDetailView = () => {
    return (
      <ScrollView style={{height: undefined, overflow: 'scroll', backgroundColor: Colors.FoldPixel.GRAY50}}>
        {componentState.showAssignCareJourney && (
          <SidecarAssignCareJourney
            contactData={componentState.contactData}
            onComplete={(record: any) => {
              setComponentState((prev) => ({
                ...prev,
                selectedTab: IInsightsTabs.SUMMARY,
                showAssignCareJourney: false,
              }));
              setTabReLoading(IInsightsTabs.SUMMARY, true);
            }}
            onBack={() => {
              setComponentState((prev) => ({
                ...prev,
                showAssignCareJourney: false,
              }));
            }}
          />
        )}
        {componentState.showCreateOrEditReferral && (
          <InsightsCreateReferral
            contactData={componentState?.contactData}
            formattedContactData={componentState?.formattedContactData}
            preview={componentState.referralData?.preview}
            note={componentState?.referralData?.note}
            receiverDetails={componentState?.referralData?.receiverDetails}
            documents={componentState?.referralData?.documents}
            isEdit={componentState?.referralData?.isEdit}
            details={componentState?.referralData?.details}
            inboxId={componentState?.referralData?.inboxId}
            setCreateReferralFormVisible={componentState?.referralData?.setCreateReferralFormVisible}
            onBack={() => {
              componentState.referralData.onBack();
              setComponentState((prev) => ({
                ...prev,
                showCreateOrEditReferral: false,
              }));
              setTabReLoading(IInsightsTabs.REFERRAL, true);
            }}
            onComplete={() => {
              componentState.referralData.onBack();
              setComponentState((prev) => ({
                ...prev,
                selectedTab: IInsightsTabs.REFERRAL,
                showCreateOrEditReferral: false,
              }));
              setTabReLoading(IInsightsTabs.REFERRAL, true);
            }}
          />
        )}
        {isNavigatingToCarePlan &&(
            <View style={{flex: 1}}>
              <CarePlan {...(carePlanProps as ICarePlanProps)} />
            </View>
        )}
        {componentState.selecetedCareGapDetail?.id && careGapDetailView()}
        {componentState.selectedDiagnosisGapDetail?.id && diagnosisGapDetailView()}
      </ScrollView>
    );
  };

  const onSearch = (value: string) => {
    setComponentState((prev) => {
      return {
        ...prev,
        searchText: value,
      };
    });
  }

  const moveTabs = (tabs: ITabsList[], fromIndex: number, toIndex: number) => {
    const itemRemoved = tabs.splice(fromIndex, 1)
    tabs.splice(toIndex, 0, itemRemoved[0])
    return tabs;
  }

  return (
    <>
      {componentState?.isLoading ? (
        <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
          <Spinner size={'lg'} />
        </View>
      ) : (
        <Stack direction="column" style={{flex: 1}}>
          {isSidecarPatientContext && (
            <SidecarTitle
              onClose={props?.onClose}
              patientUuid={componentState?.contactData?.uuid}
              titleId="insights"
              paddingVertical={6}
              showCreateNewButton={true}
            />
          )}
          <Stack
            direction="row"
            style={{
              justifyContent: 'space-between',
              paddingLeft: 16,
              paddingTop: 16,
            }}
          >
            <Stack
              direction="row"
              style={{
                width: '40%',
                flex: 1,
                paddingBottom: 16,
              }}
            >
              {componentState?.contactData && (
                <View ref={headerContainerRef} style={{flex: 1}}>
                  <SidecarPatientInfoBanner
                    handleCreateCarePlanClick={handleCreateCarePlanClick}
                    contactData={componentState?.contactData}
                    headerContainerRef={headerContainerRef}
                    showAssignJourney={true}
                    assignJourneyOptionClicked={() => {
                      setComponentState((prev) => {
                        return {
                          ...prev,
                          showAssignCareJourney: true,
                        };
                      });
                    }}
                    referralOrderOptionClicked={() => {
                      setComponentState((prev) => {
                        return {
                          ...prev,
                          showReferralOrders: true,
                          selectedTab: IInsightsTabs.REFERRAL,
                        };
                      });
                      updateRenderedTabs(IInsightsTabs.REFERRAL, true);
                    }}
                    showCloseButton={props?.isFoldView}
                    handleCloseClick={props?.onClose}
                    isCompactView={props?.isFoldView || props?.sideCarDashboardEnabled}
                    taskPanelType={componentState?.taskPanelType}
                  />
                </View>
              )}
            </Stack>
          </Stack>
          <Divider />
          {props?.sideCarDashboardEnabled && <TagDataListView
            taggableType={'Contact'}
            contactData={componentState?.contactData}
            conversationData={props?.conversationData}
            sideCarDashboardEnabled={props.sideCarDashboardEnabled}
          />}
          <Divider/>
          <>
            {isDetailViewVisible() ? (
              <></>
            ) : componentState.showSearchBar ? (
              <Stack
                direction={'row'}
                style={{
                  alignItems: 'center',
                  marginVertical: 4,
                  marginHorizontal: 12,
                }}
              >
                <Input
                  placeholder={'Search tasks by title or description'}
                  onChangeText={debounce(onSearch, 500)}
                  width={'100%'}
                  height={'36px'}
                  leftElement={
                    <View style={{marginLeft: 8}}>
                      <SearchIconSvg
                        size={18}
                        customStrokeColor={Colors.FoldPixel.GRAY200}
                      />
                    </View>
                  }
                  placeholderTextColor={Colors.FoldPixel.GRAY300}
                  backgroundColor={Colors.Custom.White}
                  rightElement={
                    <Pressable
                      style={{
                        height: 30,
                        width: 40,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onPress={() => {
                        setComponentState((prev) => ({
                          ...prev,
                          showSearchBar: false,
                          searchText: '',
                        }));
                      }}
                    >
                      <CrossIconSvg
                        customStrokeColor={Colors.FoldPixel.GRAY300}
                        size={20}
                      />
                    </Pressable>
                  }
                />
              </Stack>
            ) : (
              insightTabs?.length > 1 && (
                <>
                  <Stack
                    direction={'row'}
                    style={{
                      alignItems: 'center',
                      marginVertical: 0,
                      marginLeft: 0,
                      paddingRight: 16,
                      borderBottomWidth: 0.5,
                      borderBottomColor: Colors.FoldPixel.GRAY150,
                    }}
                  >
                    {tabs?.length > 0 && (
                      <CustomTabsView
                        innerWidth={isSidecarContext ? 700 : 700}
                        canNavigate={false}
                        tabsList={tabs}
                        isDisabled={false}
                        isCompactView={true}
                        minScreenSupportWidth={1800}
                        isAddMaxTab={true}
                        tabListWrapperStyle={{
                          marginHorizontal: 0,
                          paddingHorizontal: 8,
                        }}
                        selectedTabKey={componentState.selectedTab}
                        numberOfElements={4}
                        leftOffset={0}
                        onTabClick={(
                          tabItem,
                          index?: number,
                          lastIndexInVisibleList?: number
                        ) => {
                          const code = tabItem.key;
                          if (code === IInsightsTabs.ALERTS) {
                            getAlertsCount();
                          }
                          setComponentState((prev) => {
                            return {
                              ...prev,
                              selectedTab: code,
                              alertsFilterCount: 0,
                              searchText: '',
                              taskPanelType: TaskPanelType.INTERNAL,
                            };
                          });
                          if (
                            lastIndexInVisibleList ||
                            lastIndexInVisibleList === 0
                          ) {
                            const tabIndex = tabs.findIndex(item => item.key === code);
                            const lastTabs = moveTabs(tabs, tabIndex, lastIndexInVisibleList);
                            setTabs(lastTabs);
                          }
                          updateRenderedTabs(code);
                        }}
                      />
                    )}

                    <View style={{flex: 1}} />
                    {componentState.selectedTab ===
                      SIDE_BAR_CARE_MENU_CODES.ALERTS && (
                      <>
                        {showFilterIcon && (
                          <Pressable
                            onPress={() => {
                              setComponentState((prev) => {
                                return {
                                  ...prev,
                                  showFiltersTabInAlerts:
                                    !prev.showFiltersTabInAlerts,
                                };
                              });
                            }}
                          >
                            {
                              <Stack
                                direction={'column'}
                                style={{position: 'relative'}}
                              >
                                {componentState.alertsFilterCount > 0 && (
                                  <>
                                    {
                                      <Badge
                                        mb={-2}
                                        position={'absolute'}
                                        top={-4}
                                        right={-10}
                                        mt={
                                          componentState.alertsFilterCount > 0
                                            ? -2
                                            : 0
                                        }
                                        zIndex={100}
                                        variant="solid"
                                        backgroundColor={
                                          Colors.Custom?.badgeColorOrange
                                        }
                                        alignSelf="flex-end"
                                        size={'smMedium'}
                                        style={{
                                          paddingVertical: 1,
                                          paddingHorizontal: 4,
                                        }}
                                        _text={{
                                          fontSize: 9,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {componentState.alertsFilterCount}
                                      </Badge>
                                    }
                                  </>
                                )}
                                <FilterIconSvgForAlerts />
                              </Stack>
                            }
                          </Pressable>
                        )}
                        <View
                          style={{
                            width: 1,
                            height: 20,
                            backgroundColor: Colors.Custom.Gray200,
                            marginHorizontal: 12,
                          }}
                        ></View>
                        {showSearchIcon && (
                          <Pressable
                            onPress={() => {
                              setComponentState((prev) => ({
                                ...prev,
                                showSearchBar: true,
                              }));
                            }}
                          >
                            <SearchIconSvg
                              customStrokeColor={Colors.FoldPixel.GRAY300}
                              strokeWidth={1}
                            />
                          </Pressable>
                        )}
                      </>
                    )}
                  </Stack>
                  {componentState?.showFiltersTabInAlerts && <Divider />}
                </>
              )
            )}

            {insightTabs
              ?.filter((item) => renderedTabs.includes(item.key))
              ?.map((item) => {
                const isLoading = loadingTabs.includes(item.key);
                return (
                  <ScrollView
                    key={item.key}
                    style={{
                      display: isTabContentViewVisible(item.key)
                        ? 'flex'
                        : 'none',
                      backgroundColor: Colors.FoldPixel.GRAY50,
                      ...(componentState.selectedTab !== 'referral' && {
                        paddingBottom: 60,
                      }),
                      paddingBottom: props?.isFoldView
                        ? getBottomPadding()
                        : 60,
                      paddingLeft: getPaddingLeft(),
                      height: props?.isFoldView ? 400 : props.sideCarDashboardEnabled && props.containerHeight ? props.containerHeight : undefined,
                    }}
                    scrollEnabled={
                      componentState.selectedTab !==
                      SIDE_BAR_CARE_MENU_CODES.TASKS
                    }
                  >
                    {isLoading ? (
                      <View
                        style={{
                          height: getDetailViewHeight(),
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Spinner size={'lg'} />
                      </View>
                    ) : (
                      item.containerElement
                    )}
                  </ScrollView>
                );
              })}
            {isDetailViewVisible() && getDetailView()}
          </>
        </Stack>
      )}
    </>
  );
};


