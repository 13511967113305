import {getTenantForDemo} from '../../components/RightSideContainer/Analytics/EmployerAnalytics/tempDataFile';
import {IndexedDBService} from '../IndexedDBService';

export interface IDemoRulesData {
  ruleId: string;
  activePatients: number;
  inactivePatients: number;
  contactIds: number[];
}

const isDemoAccount = getTenantForDemo();
const indexedDBService = IndexedDBService.getInstance();

const getRandomNumber = (min = 50, max = 100) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

class SalesDataService {
  private static instance: SalesDataService;

  constructor() {}

  public static getInstance() {
    if (!SalesDataService.instance) {
      SalesDataService.instance = new SalesDataService();
    }
    return SalesDataService.instance;
  }

  public async getDemoRulesDataByRuleId(ruleId: string) {
    try {
      const data = await indexedDBService.getDataByKey<IDemoRulesData>(
        'demoRules',
        ruleId
      );
      return data;
    } catch (error) {
      console.error('Error fetching demo rules data:', error);
      return null;
    }
  }

  public async setDemoRulesDataByRuleId(ruleId: string, data: IDemoRulesData) {
    try {
      await indexedDBService.addDataByKey('demoRules', ruleId, data);
    } catch (error) {
      console.error('Error setting demo rules data:', error);
      return null;
    }
  }

  public async getActiveAndInactiveNumberByRuleId(
    ruleId: string
  ): Promise<{activePatients: number; inactivePatients: number}> {
    if (!ruleId || !isDemoAccount) {
      return {
        activePatients: 0,
        inactivePatients: 0,
      };
    }
    const data = await salesDataService.getDemoRulesDataByRuleId(ruleId);
    if (!data?.ruleId) {
      const newData = await this.setActiveAndInactiveNumberByRuleId(ruleId);
      return {
        activePatients: newData.activePatients,
        inactivePatients: newData.inactivePatients,
      };
    }
    return {
      activePatients: data.activePatients,
      inactivePatients: data.inactivePatients,
    };
  }

  public async setActiveAndInactiveNumberByRuleId(ruleId: string) {
    const randomActivePatients = getRandomNumber(50, 100);
    const randomInactivePatients = getRandomNumber(1, 10);
    await salesDataService.setDemoRulesDataByRuleId(ruleId, {
      activePatients: randomActivePatients,
      inactivePatients: randomInactivePatients,
      contactIds: [],
      ruleId,
    });
    return {
      activePatients: randomActivePatients,
      inactivePatients: randomInactivePatients,
    };
  }
}

export const salesDataService = SalesDataService.getInstance();
