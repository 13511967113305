export const MLOV_CATEGORY = {
  PERSON_ADDRESS_TYPE: 'PersonAddressType',
  PERSON_ADDRESS_USE: 'PersonAddressUse',
  PERSON_CONTACT_TYPE: 'PersonContactType',
  PERSON_CONTACT_USE: 'PersonContactUse',
  PERSON_GENDER: 'PersonGender',
  USER_TYPE: 'UserType',
  CUSTOMER_TYPE: 'CustomerType',
  FORM_RESOURCE: 'FormResource',
  CONVERSATION_STATUS: 'ConversationStatus',
  RELATIONS: 'Relations',
  SCHEDULE_LOCATION_TYPE: 'ScheduleLocationType',
  TAGGER_TYPE: 'TaggerType',
  LABEL_TYPE: 'LabelType',
  TASK_STATUS: 'TaskStatus',
  USER_ROLES: 'UserRoles',
  CARE_JOURNEY_DURATION: 'CareProgramDuration',
  OBSERVATION_CATEGORY: 'ObservationCategory',
  RECENT_ITEM_TYPES: 'RecentItemTypes',
  APPOINTMENT_TYPE_CATEGORY: 'AppointmentTypeCategory',
  APPOINTMENT_STATUS: 'AppointmentStatus',
  GroupType: 'GroupType',
  GroupMemberType: 'GroupMemberType',
  APPOINTMENT_PARTICIPANT_STATUS: 'AppointmentParticipantStatus',
  TASK_PRIORITY: 'TaskPriority',
  FORM_STATUS: 'FormStatus',
  TASK_ENTITY_TYPE: 'TaskEntityType',
  FORM_SOURCE: 'FormSource',
  CONDITION_CLINICAL_STATUS: 'ConditionClinicalStatus',
  IMMUNIZATION_STATUS: 'ImmunizationStatus',
  AVAILABILITY_TYPE: 'AvailabilityType',
  TASK_DISPLAY_CATEGORY: 'TaskDisplayCategory',
  TASK_DUE_DATE_CATEGORY: 'TaskDueDateCategory',
  CANNED_RESPONSE_TYPE: 'CannedResponseType',
  DOCUMENT_TYPE: 'DocumentType',
  DOCUMENT_SUBJECT_TYPE: 'DocumentSubjectType',
  PRIMARY_CARE_TEAM: 'CareTeamType',
  CARE_TEAM_USER_POOL: 'UserPoolType',
  VIRTUAL_NUMBER_ASSIGNEE: 'VirtualNumberAssignee',
  CONSENT: 'Consent',
  CONSENT_PROVIDER_TYPE: 'ConsentProviderType',
  TEAM_PREFERENCE: 'TeamPreference',
  PATIENT_CARE_JOURNEY_STATUS: 'PatientCareJourneyStatus',
  PREFERRED_COMMUNICATION_CHANNEL: 'PreferredCommunicationChannel',
  FEED_CATEGORY: 'FeedCategory',
  SEX_AT_BIRTH: 'SexAtBirth',
  USER_PREFERENCES_TYPE: 'UserPreferencesType',
  GROUP_WELCOME_MESSAGES_TYPE: 'GroupWelcomeMessagesType',
  USER_PREFERENCE_TYPE: 'UserPreferencesType',
  PROVIDER_IDENTIFIER_TYPE: 'ProviderIdentifierType',
  NOTE_SUBJECT_TYPE: 'SubjectType',
  BLOOD_GROUP: 'BloodGroup',
  PRONOUN: 'Pronoun',
  SectionType: 'SectionType',
  APPOINTMENT_PARTICIPANT_TYPE: 'AppointmentParticipantType',
  FORM_BATCH_STATUS: 'FormBatchStatus',
  PatientCareJourneyGoalStatus: 'PatientCareJourneyGoalStatus',
  CareProgramStatus: 'CareProgramStatus',
  CARE_PROGRAM_STATUS: "CareProgramStatus",
  CARE_JOURNEY_START_TYPE: 'CareJourneyStartType',
  COST_CATEGORY: 'CostCategory',
  SQS_EXECUTION_STATUS: 'SqsMessageStatus',
  APPOINTMENT_CUSTOM_FIELD_TYPE: 'AppointmentCustomFieldType',
  DATE_TIME_DURATION: 'DateTimeDuration',
  PACKAGE_TYPE: 'PackageType',
  CONTACT_CARE_PROGRAM_CONSENT_TYPE: 'contact_care_program_consent_type',
  CARE_PROGRAM_TYPES: 'care_program_types',
  CARE_TEAM_MEMBER_TYPE: 'CareTeamMemberType',
  CARE_PROGRAM_STEP_TYPE: 'care_program_step_type',
  CARE_PROGRAM_STEP_STATUS: 'care_program_step_status',
  SQS_MESSAGE_STATUS: 'SqsMessageStatus',
  CONTACT_CARE_PROGRAM_SUB_STEP: 'care_program_sub_step_type',
  CONTACT_CARE_PROGRAM_BILLABLE_ACTIVITY_STATUS: 'contact_care_program_billable_activity_status',
  CARE_PROGRAM_TYPE: 'care_program_types',
  CARE_PLAN_STATUS: 'CarePlanStatus',
  CARE_PLAN_CATEGORY: 'CarePlanCategory',
  GOAL_TARGET_ENTITY_TYPE: 'GoalTargetEntityType',
  GOAL_STATUS: 'GoalStatus',
  GOAL_TARGET_STATUS: 'GoalTargetStatus',
  CCM_BILLING_TASK_TYPE: 'ccm_billing_task_type',
  APPOINTMENT_VISIT_TYPE: 'AppointmentTypeCatgory',
  CARE_PROGRAM_BILLABLE_ACTIVITY_STATUS: 'contact_care_program_billable_activity_status',
  CARE_PROGRAM_OUTREACH_STATUS: 'care_program_step_outreach_status',
  OUT_REACH_STEP_OUTCOMES: 'OutReachStepOutcomes',
  TASK_SUBJECT: 'task_subject',
  CARE_PROGRAM_OUTREACH_MODE: 'care_program_outreach_mode',
  TASK_ALERT_DISPLAY_CATEGORIES: 'task_alert_display_categories',
  TASK_ALERT_RESOLVE_REAOSNS: 'task_alert_resolve_reasons',
  TASK_ALERT_DISMISS_REASONS: 'task_alert_dismiss_reasons',
  TASK_ALERT_DISPLAY_PREFERENCE_REASONS: 'task_alert_display_preference_reasons',
  TASK_TYPE_ACTIONS: 'task_type_actions',
  RULE_ENTITY_TYPE: 'rule_entity_type',
  DASHBOARD: 'Dashboard',
  SETTING: 'Setting',
  GLOBAL_ROLES: 'GlobalRoles',
  GLOBAL_USER_ROLES: 'GlobalUserRoles',
  CONTACT_CARE_PROGRAM_STATUS: 'care_program_status',
  CONTACT_CARE_PROGRAM_SOURCE: 'contact_care_program_source',
  TASK_ASSIGNEE_TYPE: 'TaskAssigneType',
  USER_DAY_OPTIMIZER_SCHEDULE_STATUS: 'UserDayOptimizerScheduleStatus',
  CARE_PLAN_SOURCE: 'CarePlanSource',
  CONTACT_OUTREACH_COMMUNICATION_MODE: 'ContactOutreachCommunicationMode',
  DIAGNOSIS_GAP_DISMISS_REASONS: 'DiagnosisGapDismissReasons',
  CARE_PROGRAM_COMPLEXITY: 'care_program_complexity',
  CARE_PROGRAM_AWV_TYPE: 'care_program_awv_type',
  CARE_PROGRAM_CONSENT_REVOKE_REASON: 'care_program_consent_revoke_reason',
  TCM_CLOSED_STATUS_REASON: 'tcm_closed_status_reason',
  CCM_CLOSED_STATUS_REASON: 'ccm_closed_status_reason',
  AWV_CLOSED_STATUS_REASON: 'awv_closed_status_reason',
  CARE_PROGRAM_CLOSED_STATUS_REASON: 'care_program_closed_status_reason',
};

export const GOAL_TARGET_STATUS_CODES = {
  IN_PROGRESS: 'in-progress',
};

export const GOAL_STATUS_CODES = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
  IN_PROGRESS: 'in-progress',
  NO_PROGRESS: 'no-progress'
};

export const GOAL_TARGET_ENTITY_TYPE_CODES = {
  VITAL: 'VITAL',
  ACTIVITY: 'ACTIVITY',
  LAB_ANALYTE: 'LAB_ANALYTE',
  SOCIAL: 'SOCIAL',
  FORM: 'FORM',
  OTHER: 'OTHER',
}

export const CARE_PLAN_STATUS_CODES = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  COMPLETED: 'completed',
  IN_REVIEW: 'on-hold',
}

export const CARE_PLAN_SOURCE_TYPE_CODES = {
  AUTOMATION: 'AUTOMATION',
  MANUAL: 'MANUAL',
}

export const CARE_PLAN_CATEGORY_CODES = {
  SYMPTOM_MONITORING: 'SymptomMonitoring',
  HOME_MONITORING: 'HomeMonitoring',
  MEDICATION_MANAGEMENT: 'MedicationManagement',
  DIET: 'Diet',
  EXERCISE: 'Exercise',
  HABITS: 'Habits',
  LAB_MONITORING: 'LabMonitoring',
  HEALTH_MAINTENANCE: 'HealthMaintenance',
}

export const FEED_CATEGORY = {
  CONTACT_ACTIVITY: 'CONTACT_ACTIVITY',
  PROVIDER_ACTIVITY: 'PROVIDER_ACTIVITY',
};

export const CARE_TEAM = {
  CODE: 'PRIMARY_CARE_TEAM',

};
export const USER_POOL_CODES = {
  CARE_TEAM: 'care_team_user_pool',
  APPOINTMENT_TYPE: 'appointment_type_user_pool',
  APPOINTMENT_TYPE_RESTRICTED_USER_POOL: 'appointment_type_restricted_user_pool'
};
export const PERSON_CONTACT_TYPE_CODES = {
  EMAIL: 'email',
  PHONE: 'phone',
  SMS: 'sms',
};

export const LABEL_TYPE_CODES = {
  CONVERSATION: 'CONVERSATION',
  CONTACT: 'CONTACT',
  TASK: 'TASK'
};

export const USER_PREFERENCE_CODE = {
  DEFAULT_FORM: 'default_user_form_data',
  FEATURES_VIEWED: 'features_viewed',
  USER_CONVERSATION_PREFERENCE: 'user_messages'
};

export const MLOV_CODES = {
  MONTH: 'MONTH',
  COMPLETED: 'completed',
  VITAL_SIGNS: 'vital-signs',
  ONE_ON_ONE_APPOINTMENT: 'ONE',
  GROUP_APPOINTMENT: 'GROUP',
  WORKFLOW_APPOINTMENT: 'WORKFLOW',
  CARE_JOURNEY: 'CARE_JOURNEY',
  CONTACT: 'CONTACT',
  GROUP_SESSION: 'GROUP_SESSION',
  DAY_OPTIMIZER_DONE: 'done',
  DAY_OPTIMIZER_TODO: 'todo'
};

export const GLOBAL_ROLE_CODES = {
  GLOBAL_ADMIN: 'GLOBAL_ADMIN',
  SUPER_ANALYTIC: 'SUPER_ANALYTIC',
}
export const GLOBAL_ROLE_LIST = [GLOBAL_ROLE_CODES.GLOBAL_ADMIN,GLOBAL_ROLE_CODES.SUPER_ANALYTIC]
export const USER_ROLE_CODES = {
  ALLIED_PHYSICIAN: 'ALLIED_PHYSICIAN',
  BUSINESS_OWNER: 'BUSINESS_OWNER',
  CARE_PROGRAM_MANAGER: 'CARE_PROGRAM_MANAGER',
  COMMUNITY_HEALTH_WORKER: 'COMMUNITY_HEALTH_WORKER',
  EXTERNAL_CASE_MANAGER: 'EXTERNAL_CASE_MANAGER',
  FINANCE_TEAM_MEMBER: 'FINANCE_TEAM_MEMBER',
  FRONT_DESK_MANAGER: 'FRONT_DESK_MANAGER',
  GUARDIAN: 'GUARDIAN',
  HEALTH_COACH: 'HEALTH_COACH',
  LOCATION_PRACTICE_MANAGER: 'LOCATION_PRACTICE_MANAGER',
  MENTAL_HEALTH_GUARDIAN: 'MENTAL_HEALTH_GUARDIAN',
  NURSE_GUARDIAN: 'NURSE_GUARDIAN',
  NURSE_PRACTITIONER: 'NURSE_PRACTITIONER',
  ONSITE_GUARDIAN: 'ONSITE_GUARDIAN',
  PHYASST: 'PHYASST',
  PHYSICIAN: 'PHYSICIAN',
  EMPLOYER: 'EMPLOYER',
  CUSTOMER_SUCCESS: 'CUSTOMER_SUCCESS',
  WORFLOW_USER: 'WORKFLOW_USER',
  QUALIFIED_HEALTH_PROFESSIONAL: 'QUALIFIED_HEALTH_PROFESSIONAL',
  CERTIFIED_NURSE_MIDWIVES: 'CERTIFIED_NURSE_MIDWIVES',
  CLINICAL_NURSE_SPECIALIST: 'CLINICAL_NURSE_SPECIALIST',
  WORKFLOW: 'WORKFLOW',
  CUSTOM_ROLE: 'CUSTOM_ROLE'
};
export const GLOBAL_ROLES = {
  GLOBAL_ADMIN: 'GLOBAL_ADMIN'
}

export const TASK_PRIORITY_CODES = {
  LOW: 'routine',
  MEDIUM: 'urgent',
  HIGH: 'asap',
};

export const TASK_STATUS_CODES: {[key: string]: string; ACCEPTED: string; COMPLETED: string; MISSED: string; DELETE: string;} = {
  DELETE: 'delete',
  COMPLETED: 'completed',
  ACCEPTED: 'accepted',
  MISSED: 'missed',
};

export const TASK_DUE_DATE_CODES = {
  PAST: 'past',
  TODAY: 'today',
  FUTURE: 'future',
};

export const TASK_STATUS = {
  COMPLETED: 'completed',
  ACCEPTED: 'accepted',
  MISSED: 'missed',
  ALL: 'all',
}

export const TASK_DUE_DATE = {
  PAST: 'past',
  TODAY: 'today',
  FUTURE: 'future',
};

export const ORDERED_TASK_PRIORITY_CODES_ASC = [
  TASK_PRIORITY_CODES.LOW,
  TASK_PRIORITY_CODES.MEDIUM,
  TASK_PRIORITY_CODES.HIGH,
];

export const ORDERED_TASK_PRIORITY_CODES_DESC = [
  TASK_PRIORITY_CODES.HIGH,
  TASK_PRIORITY_CODES.MEDIUM,
  TASK_PRIORITY_CODES.LOW,
];

export const ORDERED_TASK_STATUS_CODES_DESC = [
  TASK_STATUS_CODES.ACCEPTED,
  TASK_STATUS_CODES.MISSED,
  TASK_STATUS_CODES.COMPLETED
]

export const ORDERED_TASK_DUE_DATE_CODES_DESC = [
  TASK_DUE_DATE_CODES.PAST,
  TASK_DUE_DATE_CODES.TODAY,
  TASK_DUE_DATE_CODES.FUTURE,
];

export const GROUP_TYPE_CODES = {
  PRIVATE: 'PRIVATE',
  PATIENT_BROADCAST: 'PATIENT_BROADCAST',
  INTERNAL: 'INTERNAL',
  USER_BROADCAST: 'USER_BROADCAST',
};

export const FREQUENCY_CODES = {
  NEVER: 'NEVER',
};


export const APPOINTMENT_STATUS_CODES = {
  PROPOSED: 'proposed',
  PENDING: 'pending',
  ARRIVED: 'arrived',
  FULFILLED: 'fulfilled',
  CANCELLED: 'cancelled',
  NO_SHOW: 'noshow',
  ENTERED_IN_ERROR: 'entered-in-error',
  CHECKED_IN: 'checked-in',
  WAIT_LIST: 'waitlist',
  BOOKED: 'booked',
  SCHEDULED: 'booked',
  CHECKED_OUT: 'checked-out',
  RESCHEDULED: 'rescheduled',
  DECLINED: 'declined',
  ACCEPTED: 'accepted',
  NEEDS_ACTION: 'needs-action',
};

export const CARE_PROGRAM_STATUS_CODES = {
  ACTIVE: 'ACTV',
  DRAFT: 'DRFT',
  INACTIVE: 'INAV',
  FULL: 'FULL'
}

export const CARE_PROGRAM_OUTREACH_MODE_CODES = {
  AUTOMATION: 'automation',
  MANUAL: 'manual',
  AUTOMATION_OR_MANUAL: 'automation_or_manual'
};

export const BILLING_ACTIVITY_STATUS = {
  DRAFT: 'draft',
  PROCESSED_DRAFT: 'processed_draft',
  PENDING: 'pending',
  COMPLETED: 'completed',
}

export const APPOINTMENT_PARTICIPANT_STATUS_CODES = {
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  NEEDS_ACTION: 'needs-action',
  TENTATIVE: 'tentative',
};

export const LOCATION_TYPE_CODES = {
  VIRTUAL: 'VIRTUAL',
  AT_CLINIC: 'CLINIC',
};

export const FORM_STATUS_CODE = {
  PENDING: 'PENDING',
  SUBMITTED: 'SUBMITTED',
  DRAFT: 'DRAFT',
};

export const TASK_ENTITY_TYPE_CODES = {
  FORM: 'FORM',
  GOAL: 'GOAL',
  OUTREACH: 'OUTREACH',
};

export const FORM_SOURCE = {
  WORKFLOW: 'WORKFLOW',
  APPOINTMENT: 'APPOINTMENT',
  CARE_JOURNEY: 'CARE_JOURNEY',
  CHAT: 'CHAT',
  PATIENT_PROFILE: 'PATIENT_PROFILE',
  EMAIL: 'EMAIL',
  POPULATIONGROUP: 'POPULATIONGROUP',
  CARE_PLAN: 'CARE_PLAN',
};

export const CONDITION_CLINICAL_STATUS_CODE = {
  ACTIVE: 'active',
};

export const CONDITION_VERIFICATION_STATUS_CODE = {
  CONFIRMED: 'confirmed',
};

export const IMMUNIZATION_STATUS_CODE = {
  COMPLETED: 'completed',
};

export const MEDICATION_STATUS_CODE = {
  ACTIVE: 'active',
};

export const SURGICAL_HISTORY_STATUS_CODE = {
  COMPLETED: 'completed',
};

export const AVAILABILITY_TYPE_CODES = {
  PRACTICE_SCHEDULE: 'practice_schedule',
  INBOX: 'inbox',
  APPOINTMENT_TYPE: 'appointment_type'
};

export const DOCUMENT_TYPE_CODES = {
  REPORTS: 'reports',
  FORMS: 'forms',
  PERSONAL_IDS: 'personal_ids',
  OTHERS: 'others',
  CARE_PLAN: 'care_plans'
};

export const DOCUMENT_SUBJECT_TYPE_CODES = {
  CONTACT: 'contact',
  CARE_JOURNEY: 'care-journey',
  PATIENT_CARE_JOURNEY: 'patient-care-journey'

};

export const USER_PREFERENCES_TYPES = {
  LAST_USED_TIMEZONE: 'last_used_timezone',
};

export const PATIENT_CARE_JOURNEY_STATUS_CODE = {
  ACTIVE: 'active',
  ASSIGNED: 'assigned',
  PENDING: 'pending',
  STOPPED: 'stopped',
  PAUSED: 'paused',
  RESTART: 'restart',
  COMPLETE: 'completed',
  GOAL_STATUS_CHANGE: 'goals_status_change'
};

export const PATIENT_CARE_JOURNEY_REASON_CODE = {
  PATIENT_OPTED_OUT: 'PATIENT_OPTED_OUT'
};

export const NOTE_SUBJECT_TYPE_CODES = {
  APPOINTMENT_NOTES: 'APPT_NOTES',
  PATIENT_INSTRUCTION: 'PAT_INSTRUCTION_NOTES'
};

export const TASK_DUE_DATE_CATEGORY = {
  ALL: 'all',
  OVERDUE: 'overdue',
  TODAY: 'today',
  TOMORROW: 'tomorrow',
  LATER: 'later',
};

export const VIRTUAL_ASSIGNEE_TYPE_CODES = {
  USER: 'USER',
  CLINIC: 'CLINIC',
  OTHER: 'OTHER',
}

export const APPOINTMENT_STATUS_FOR_AUTOMATON = [
  APPOINTMENT_STATUS_CODES.PROPOSED,
  APPOINTMENT_STATUS_CODES.CANCELLED,
  APPOINTMENT_STATUS_CODES.PENDING,
  APPOINTMENT_STATUS_CODES.NO_SHOW,
  APPOINTMENT_STATUS_CODES.CHECKED_IN,
  APPOINTMENT_STATUS_CODES.CHECKED_OUT,
  APPOINTMENT_STATUS_CODES.DECLINED,
  APPOINTMENT_STATUS_CODES.SCHEDULED
];

export const APPOINTMENT_PARTICIPANT_TYPE_CODES = {
  PATIENT: 'PAT',
  PRIMARY_USER: 'PPRF',
  SECONDARY_USER: 'SPRF',
};

export const FORM_BATCH_STATUS_CODES = {
  PASS: 'PASS',
  FAIL: 'FAIL',
  PENDING: 'PENDING',
}

export const CARE_PROGRAM_STATUS = {
  ACTIVE: 'ACTV',
  DRAFT: 'DRAFT',
}


export const APPOINTMENT_TYPE_CATEGORY_CODES = {
  ONE_ON_ONE: 'ONE',
  GROUP: 'GROUP',
  GROUP_SESSION: 'GROUP_SESSION',
} as const;

export const SQS_EXECUTION_STATUS_CODES = {
  PROCESSED : 'PROCESSED',
  PENDING : 'PENDING',
  IN_QUEUE : 'IN-QUEUE',
  FAILED : 'FAILED'
}
export const USER_PREFERENCES_CODES = {
  USER_CALENDAR_DAY_VIEW_USER_ORDER: 'user_calendar_day_view_user_order',
  USER_PATIENT_SYNOPSIS_DEFAULT_TEMPLATE: 'user_patient_synopsis_default_template',
  USER_PATIENT_HEALTHMAP_DEFAULT_TEMPLATE: 'user_patient_healthmap_default_template'

}

export const APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES = {
  SLOT_WITHIN_OFFSET: 'SLOT_WITHIN_OFFSET',
  SLOT_WITHIN_LIMIT: 'SLOT_WITHIN_LIMIT',
  CANCEL_LIMIT: 'CANCEL_LIMIT',
  RESCHEDULE_LIMIT: 'RESCHEDULE_LIMIT'
};

export const DATE_TIME_DURATION_CODES = {
  HOURS: 'HOURS',
  DAYS: 'DAYS',
  MINUTES: 'MINUTES'
};

export const CARE_PROGRAM_TYPE_CODES = {
  CCM: 'CCM',
  TCM: 'TCM',
  ECM: 'ECM',
  ACCP: 'ACCP',
  AWV: 'AWV',
}

export const CONSENT_TYPE_CODES = {
  COMMUNICATION: 'COMMUNICATION',
  CARE_PROGRAM: 'CARE_PROGRAM',
  ...CARE_PROGRAM_TYPE_CODES,
};

export const FORM_CONSENT_TYPE_LIST = [
  { label: 'Communication', value: CONSENT_TYPE_CODES.COMMUNICATION },
  { label: 'Care Program', value: CONSENT_TYPE_CODES.CARE_PROGRAM },
];
export const FORM_CONSENT_TYPE_CARE_PROGRAM_LIST = [
  { label: 'Care Program', value: CONSENT_TYPE_CODES.CARE_PROGRAM },
];

export const CONSENT_DOCUMENT_SUB_CATEGORY_CODES = {
  OTHER: 'OTHER',
  CARE_PROGRAM: CONSENT_TYPE_CODES.CARE_PROGRAM,
};

export const CONSENT_DOCUMENT_SUB_CATEGORY_LIST = [
  { label: 'Care Program', value: CONSENT_TYPE_CODES.CARE_PROGRAM },
  { label: 'Other', value: CONSENT_DOCUMENT_SUB_CATEGORY_CODES.OTHER },
];

export const CARE_PROGRAM_CONSENT_TYPE = {
  VERBAL: 'verbal',
  WRITTEN: 'written',
  NOT_REQUIRED: 'not-required',
  DECLINED: 'declined',
};

export const CONSENT_RESOURCE_TYPE_CODES = {
  FORM_LOG: 'FORM_LOG',
  DOCUMENT: 'DOCUMENT',
}

export const CARE_PROGRAM_STEP_CODES = {
  CARE_PROGRAM_ELIGIBILITY: 'care_program_eligibility',
  ANNUAL_WELLNESS_VISIT: 'annual_wellness_visit',
  CONSENT: 'consent',
  ENROLLMENT: 'enrollment',
  CARE_PLAN: 'care_plan'
};
export const CARE_PROGRAM_SUB_STEP_CODES = {
  CARE_PROGRAM_ELIGIBILITY_COMPLETE: 'care_program_eligibility_complete',
  ANNUAL_WELLNESS_VISIT_PENDING: 'annual_wellness_visit_pending',
  ANNUAL_WELLNESS_VISIT_SCHEDULED: 'annual_wellness_visit_scheduled',
  ANNUAL_WELLNESS_VISIT_COMPLETED: 'annual_wellness_visit_completed',
  CONSENT_PENDING: 'consent_pending',
  CONSENT_SENT_TO_PATIENT: 'consent_sent_to_patient',
  CONSENT_RECEIVED: 'consent_received',
  CONSENT_DECLINED: 'consent_declined',
  ENROLLMENT_IN_PENDING: 'enrollment_in_pending',
  ENROLLMENT_IN_PROGRESS: 'enrollment_in_progress',
  ENROLLMENT_COMPLETE: 'enrollment_complete',
  CARE_PLAN_ASSIGN_PENDING: 'care_plan_assign_pending',
  CARE_PLAN_ASSIGN_COMPLETE: 'care_plan_assign_complete',
  IN_REVIEW: 'in_review',
  COMPLETED: 'completed',
  REVIEWED: 'reviewed',
  SKIPPED: 'skipped',
};
export const CARE_PROGRAM_STEP_STATUS_CODES = {
  TO_DO: 'to_do',
  SKIP: 'skip',
  DONE: 'done',
  FAIL: 'fail',
  EXPIRE: 'expire',
  IN_PROGRESS: 'in_progress',
  REJECT: 'reject',
  REJECTED: 'rejected',
  DECLINED: 'declined',
  CLOSED: 'closed',
  AUTO_CLOSED: 'auto_closed',
  COMPLETED: 'completed',
} as const;

export const CARE_TEAM_MEMBER_TYPE = {
  PCP: 'PCP',
  CARE_MANAGER: 'CARE_MANAGER',
  BILLING_PRACTITIONER: 'BILLING_PRACTITIONER'
};

export const CARE_PROGRAM_STEP_TYPE = {
  CARE_PLAN: 'care_plan',
  CARE_PROGRAM_ELIGIBILITY: 'care_program_eligibility',
  ENROLLMENT: 'enrollment',
  ANNUAL_WELLNESS_VISIT: 'annual_wellness_visit',
  CONSENT: 'consent',
  CUSTOM: 'custom',
};

export const CARE_PROGRAM_SUB_STEP_TYPE = {
  CONSENT_DECLINED: 'consent_declined',
  ANNUAL_WELLNESS_VISIT_SCHEDULED: 'annual_wellness_visit_scheduled',
  CONSENT_SENT_TO_PATIENT: 'consent_sent_to_patient',
  ANNUAL_WELLNESS_VISIT_PENDING: 'annual_wellness_visit_pending',
  CARE_PLAN_ASSIGN_PENDING: 'care_plan_assign_pending',
  ENROLLMENT_IN_PROGRESS: 'enrollment_in_progress',
  CONSENT_RECEIVED: 'consent_received',
  ENROLLMENT_IN_PENDING: 'enrollment_in_pending',
  CARE_PROGRAM_ELIGIBILITY_COMPLETE: 'care_program_eligibility_complete',
  ENROLLMENT_COMPLETE: 'enrollment_complete',
  CONSENT_PENDING: 'consent_pending',
  CARE_PLAN_ASSIGN_COMPLETE: 'care_plan_assign_complete',
  ANNUAL_WELLNESS_VISIT_COMPLETED: 'annual_wellness_visit_completed',
};

export const CARE_PROGRAM_STEP_STATUS = {
  SKIP: 'skip',
  TO_DO: 'to_do',
  DONE: 'done',
  FAIL: 'fail',
};
export const SQS_MESSAGE_STATUS_CODES = {
  PENDING: 'PENDING',
  IN_QUEUE: 'IN-QUEUE',
  PROCESSED: 'PROCESSED',
  FAILED: 'FAILED',
}
export const BILLABLE_ACTIVITY_STATUS_STATUS_CODES = {
  PENDING: 'pending',
  COMPLETED: 'completed',
}

export const CARE_PROGRAM_TYPES = {
  TCM: 'TCM',
  ECM: 'ECM',
  CCM: 'CCM',
  ACCP: 'ACCP',
  AWV: 'AWV',
}

export const APPOINTMENT_TYPE_VISIT_TYPE = {
  AWV: 'AWV',
  ROUTINE: 'ROUTINE'
} as const;

export const OUTREACH_STATUS_CODES = {
  FAILED: 'failed',
  PENDING: 'pending',
  SUCCESS: 'success',
} as const;

export const OUT_REACH_OUTCOME_CODES = {
  PCP_APPOINTMENT_SCHEDULED: 'pcp_appointment_scheduled',
  REFERRED_TO_URGENT_CARE: 'referred_to_urgent_care',
  REFERRED_TO_ED: 'referred_to_ed',
  SPOKE_WITH_PATIENT: 'spoke_with_patient',
  SPOKE_WITH_CAREGIVER: 'spoke_with_caregiver',
  FACE_TO_FACE_VISIT: 'face_to_face_visit',
  FACE_TO_FACE_VISIT_WITH_CAREGIVER: 'face_to_face_visit_with_caregiver',
  PROVIDER_INTERVENTION: 'provider_intervention',
  CONTINUE_AND_FOLLOW_UP_AS_PER_CADENCE: 'continue_and_follow_up_as_per_cadence',
  NO_ANSWER_LEFT_VOICEMAIL: 'no_answer_left_voicemail',
  NO_ANSWER_UNABLE_TO_LEAVE_VOICEMAIL: 'no_answer_unable_to_leave_voicemail',
  PHONE_NOT_IN_SERVICE: 'phone_not_in_service',
  PORTAL_MESSAGE_SENT: 'portal_message_sent',
  PATIENT_DECLINED: 'patient_declined',
  NOT_OUR_PATIENT: 'not_our_patient',
  DECEASED: 'deceased',
  SUCCESSFUL_AUTOMATIC_OUTREACH: 'successful_automatic_outreach',
  UNSUCCESSFUL_AUTOMATIC_OUTREACH: 'unsuccessful_automatic_outreach',
};

export const OUT_REACH_OUTCOME_CATEGORY_CODES = {
  OUTREACH_COMPLETED: 'Outreach Completed',
  FOLLOW_UP_NEEDED: 'Follow-Up Needed',
  REMOVE_PATIENT: 'Remove Patient',
};

export const OUT_REACH_OUTCOME_CATEGORIES = {
  [OUT_REACH_OUTCOME_CATEGORY_CODES.OUTREACH_COMPLETED]: [
    OUT_REACH_OUTCOME_CODES.PCP_APPOINTMENT_SCHEDULED,
    OUT_REACH_OUTCOME_CODES.REFERRED_TO_URGENT_CARE,
    OUT_REACH_OUTCOME_CODES.REFERRED_TO_ED,
    OUT_REACH_OUTCOME_CODES.SPOKE_WITH_PATIENT,
    OUT_REACH_OUTCOME_CODES.SPOKE_WITH_CAREGIVER,
    OUT_REACH_OUTCOME_CODES.FACE_TO_FACE_VISIT,
    OUT_REACH_OUTCOME_CODES.FACE_TO_FACE_VISIT_WITH_CAREGIVER,
    OUT_REACH_OUTCOME_CODES.PROVIDER_INTERVENTION,
    OUT_REACH_OUTCOME_CODES.CONTINUE_AND_FOLLOW_UP_AS_PER_CADENCE,
    OUT_REACH_OUTCOME_CODES.SUCCESSFUL_AUTOMATIC_OUTREACH,
  ],
  [OUT_REACH_OUTCOME_CATEGORY_CODES.FOLLOW_UP_NEEDED]: [
    OUT_REACH_OUTCOME_CODES.NO_ANSWER_LEFT_VOICEMAIL,
    OUT_REACH_OUTCOME_CODES.NO_ANSWER_UNABLE_TO_LEAVE_VOICEMAIL,
    OUT_REACH_OUTCOME_CODES.PHONE_NOT_IN_SERVICE,
    OUT_REACH_OUTCOME_CODES.PORTAL_MESSAGE_SENT,
    OUT_REACH_OUTCOME_CODES.UNSUCCESSFUL_AUTOMATIC_OUTREACH,
  ],
  [OUT_REACH_OUTCOME_CATEGORY_CODES.REMOVE_PATIENT]: [
    OUT_REACH_OUTCOME_CODES.PATIENT_DECLINED,
    OUT_REACH_OUTCOME_CODES.NOT_OUR_PATIENT,
    OUT_REACH_OUTCOME_CODES.DECEASED,
  ],
};

export const TASK_SUBJECT_TYPE = {
  PATIENT_CASE: 'PATIENT_CASE',
  PATIENT_REFERRAL: 'PATIENT_REFERRAL',
  ORDER: 'ORDER'
}

export const TASK_ALERT_CATEGORY_CODES = {
  PENDING: 'pending',
  SNOOZED: 'snoozed',
  RESOLVED: 'resolved',
  DISMISSED: 'dismissed',
}

export const TASK_ALERT_CUSTOM_CATEGORY_CODES = {
  CARE_GAPS: 'caregaps',
}
export const DIAGNOSIS_GAPS_CATEGORY_CODES = {
  DIAGNOSIS_GAPS: 'diagnosisGaps',
}

export const DIAGNOSIS_GAP_DISMISS_REASONS_CODES = {
  CONDITION_RESOLVED: 'condition_resolved',
  AWAITING_RESULTS: 'awaiting_results',
  EVALUATE_NEXT_VISIT: 'evaluate_next_visit',
  NOT_MEET_CRITERIA: 'not_meet_criteria',
  INSUFFICIENT_INFO: 'insufficient_info',
  ALTERNATIVE_DIAGNOSIS: 'alternative_diagnosis',
  REQUIRES_MORE_INFO: 'requires_more_info',
}

export const RULE_ENTITY_TYPE_CODE = {
  CARE_PLAN_GOAL: 'care_plan_goal',
}

export enum CareProgramComplexityCode {
  SIMPLE = 'SIMPLE',
  COMPLEX = 'COMPLEX',
}

export const CARE_PROGRAM_COMPLEXITY_STATUS_CODES = [
  {
    code: CareProgramComplexityCode.SIMPLE,
    value: 'Moderate',
  },
  {
    code: CareProgramComplexityCode.COMPLEX,
    value: 'Complex',
  },
];

export const CONTACT_CARE_PROGRAM_STATUS_CODES = {
  NEW: 'new',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  AUTO_CLOSED: 'auto_closed',
  TO_DO: 'to_do',
  CLOSED: 'closed',
  PAUSED: 'paused',
  FOLLOW_UP: 'follow_up'
}


export const CONTACT_CARE_PROGRAM_STATUS_VALUES = {
  [CONTACT_CARE_PROGRAM_STATUS_CODES.NEW]: 'New',
  [CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS]: 'In Progress',
  [CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED]: 'Completed',
  [CONTACT_CARE_PROGRAM_STATUS_CODES.AUTO_CLOSED]: 'Auto Closed',
  [CONTACT_CARE_PROGRAM_STATUS_CODES.CLOSED]: 'Closed',
  [CONTACT_CARE_PROGRAM_STATUS_CODES.PAUSED]: 'Paused',
  [CONTACT_CARE_PROGRAM_STATUS_CODES.FOLLOW_UP]: 'Follow Up'
}

export const CONTACT_CARE_PROGRAM_SOURCE_CODES = {
  ADT_EVENT: 'adt_event',
  FILE_IMPORT: 'file_import',
  POP_GROUP: 'pop_group'
}
