import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const CarePlanStatusInProgress = () => (
  <Svg width="16" height="17" viewBox="0 0 16 17" fill="none">
    <Path
      d="M0.5 8.5C0.5 4.35786 3.85786 1 8 1C12.1421 1 15.5 4.35786 15.5 8.5C15.5 12.6421 12.1421 16 8 16C3.85786 16 0.5 12.6421 0.5 8.5Z"
      fill="#FEF4E6"
    />
    <Path
      d="M0.5 8.5C0.5 4.35786 3.85786 1 8 1C12.1421 1 15.5 4.35786 15.5 8.5C15.5 12.6421 12.1421 16 8 16C3.85786 16 0.5 12.6421 0.5 8.5Z"
      stroke="#F79009"
    />
    <Path
      d="M8 4.5V5.83333M12 8.5H10.6667M8 12.5V11.1667M4 8.5H5.33333M5.17151 5.67157L6.11432 6.61438M10.8284 5.67157L9.88556 6.61438M10.8285 11.3284L9.88568 10.3856M5.17163 11.3284L6.11444 10.3856"
      stroke="#F79009"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
);

export default React.memo(CarePlanStatusInProgress);
