
import { Pressable, Spinner, Text } from 'native-base';
import React, { useMemo, useState } from 'react';
import { Colors } from '../../../../../styles';
import Stack from '../../../../common/LayoutComponents/Stack';
import { View, StyleSheet } from 'react-native';
import { testID, TestIdentifiers } from '../../../../../testUtils';
import { ADD_TASK_BUTTONS } from '../../../../common/AddTask/AddEditTaskView/TaskConstant';


export interface IFoldButtonV2Props {
  label: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  buttonType: ButtonType;
  onPress?: () => void;
  showRightBorder?: boolean;
  rightIcon?: JSX.Element;
  textSize?: number;
  height?: number;
  width?: number | string;
  flex?: number;
  onTextClick?: () => void;
  leftIcon?: JSX.Element;
  hideBorder?: boolean;
  borderColor?: string;
  hoverBackgroundColor?: string;
  hoverTextColor?: string;
}

export enum ButtonType {
  'primary',
  'secondary',
  'tertiary'
}

const FoldButtonV2 = (props: IFoldButtonV2Props) => {
  const { onPress, buttonType, label, isDisabled, isLoading, rightIcon, showRightBorder, height, width, textSize, flex, onTextClick, leftIcon, hideBorder, borderColor, hoverBackgroundColor, hoverTextColor } = props;
  const [isHovered, setIsHovered] = useState(false);

  const getBorderColor = () => {
    if (isDisabled && (buttonType === ButtonType.primary || buttonType === ButtonType.tertiary || buttonType === ButtonType.secondary)) {
      return Colors.FoldPixel.GRAY50;
    }
    if (buttonType === ButtonType.primary) {
      return Colors.FoldPixel.PRIMARY300;
    } else if (buttonType === ButtonType.tertiary) {
      return Colors.Custom.PrimaryColor200;
    } else if (borderColor) {
      return borderColor;
    } else {
      return Colors.FoldPixel.GRAY300;
    }
  }

  const getBackgroundColor = () => {
    if (isHovered && !isDisabled && hoverBackgroundColor) {
      return hoverBackgroundColor
    }
    if (isDisabled) {
      if (buttonType === ButtonType.primary) {
        return Colors.FoldPixel.GRAY50;
      } else if (buttonType === ButtonType.tertiary || buttonType === ButtonType.secondary) {
        return Colors.FoldPixel.GRAY50;
      } else {
        return Colors.Custom.White;
      }
    } else {
      if (buttonType === ButtonType.primary) {
        return Colors.FoldPixel.PRIMARY300;
      } else if (buttonType === ButtonType.tertiary) {
        return Colors.FoldPixel.PRIMARY100;
      } else {
        return Colors.Custom.White;
      }
    }
  }

  const getTextColor = () => {
    if (isHovered && !isDisabled && hoverTextColor) {
      return hoverTextColor
    }
    if (isDisabled) {
      if (buttonType === ButtonType.primary) {
        return Colors.FoldPixel.GRAY150;
      } else if (buttonType === ButtonType.tertiary || buttonType === ButtonType.secondary) {
        return Colors.FoldPixel.GRAY150;
      } else {
        return Colors.FoldPixel.GRAY250;
      }
    } else {
      if (buttonType === ButtonType.primary) {
        return Colors.Custom.White;
      } else if (buttonType === ButtonType.tertiary) {
        return Colors.FoldPixel.PRIMARY300;
      } else {
        return Colors.FoldPixel.GRAY300;
      }
    }
  }

  const dynamicStyles = useMemo(() => {
    return StyleSheet.create({
      pressable: {
        borderColor: getBorderColor(),
        backgroundColor: getBackgroundColor(),
      },
      stack: {
        alignItems: 'center',
        justifyContent: 'center',
        height: height,
        ...(width && { width }),
      },
      text: {
        color: getTextColor(),
        textAlign: 'center',
        ...(textSize && { fontSize: textSize }),
      },
      rightBorder: {
        marginHorizontal: 4,
        height: 16,
        width: 1,
        backgroundColor: getBorderColor(),
      },
    });
  }, [buttonType, isDisabled, height, isHovered]);

  return (
    <Pressable
      px={2}
      py={1}
      rounded={'sm'}
      borderWidth={hideBorder ? 0 : 0.5}
      mx={1}
      flex={flex}
      alignItems={"center"}
      onPress={onPress}
      isDisabled={isDisabled}
      style={dynamicStyles.pressable}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
      {...(label === ADD_TASK_BUTTONS.CANCEL ? testID(TestIdentifiers.cancelBtn) :
         label === ADD_TASK_BUTTONS.COMMENT ? testID(TestIdentifiers.commentBtn) : {})}

    >
      <Stack direction='row' space={4} style={dynamicStyles.stack}>
        {isLoading && <Spinner size={'sm'} color={Colors.FoldPixel.GRAY300} />}
        {leftIcon}
        {!!label && (
          <Text style={dynamicStyles.text} onPress={onTextClick} {...testID(label)}>
            {label}
          </Text>
        )}
        {showRightBorder && <View style={dynamicStyles.rightBorder} />}
        {rightIcon}
      </Stack>
    </Pressable>
  );
};

export default FoldButtonV2;
