import {HStack, Pressable, ScrollView, Text, VStack} from 'native-base';
import { Navigate, useNavigate } from 'react-router-dom';
import {Colors} from '../../../styles';
import {DisplayText} from '../../common/DisplayText/DisplayText';
import {
  IMiddleContainerTab,
  IPersonTabBarProps,
} from '../MiddleContainer/interfaces';
import {getMiddleContainerTabsByPersonType} from '../MiddleContainer/MiddleContainerUtils';
import {styles} from './PersonTabBarStyles';
import {isEnableCareProgram, isSideCarEnabledForPatientDashboard} from '../../../utils/commonUtils';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {useContext} from 'react';
import {PERSON_TYPES} from '../../../constants';
import {getActionEventAndResourceCodeByOption} from '../../RightSideContainer/Contacts/ContactsUtils';
import {usePermissions} from '../../CustomHooks/usePermissions';
import {isAccountConfigEnabled} from '../../../utils/configUtils';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';

const PersonTabBar = (props: IPersonTabBarProps) => {
  const {selectedTabCode, onSelectTab, contactId, locationId} = props;
  const mlovData = useContext(CommonDataContext);
  const userSettings = mlovData.userSettings;
  const enableCareProgram = isEnableCareProgram(mlovData.userSettings);
  const sideCarDashboardEnabled = isSideCarEnabledForPatientDashboard(userSettings);
  const isEFaxMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_E_FAX_COMMUNICATION);
  const isReferralEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_REFERRAL_ENABLED)
  const tablistByPersonType = getMiddleContainerTabsByPersonType(
    props.personType,
    enableCareProgram,
    locationId,
    sideCarDashboardEnabled,
    isEFaxMessagingEnabled,
    isReferralEnabled
  );
  const {check} = usePermissions()
  const navigate = useNavigate();

  return (
    <ScrollView horizontal>
    <HStack style={[styles.container]}>
      {tablistByPersonType?.map((item: IMiddleContainerTab, index: number) => {
        const eventAndResource: any = getActionEventAndResourceCodeByOption(item.tabCode);
        const isPermissionAllowed =  check(eventAndResource?.actionEvent, eventAndResource?.resourceCode) 
        if (eventAndResource?.resourceCode && !isPermissionAllowed) {
          return
        }
        return (
          <Pressable
            key={index}
            onPress={() => {
              if (selectedTabCode === item?.tabCode) {
                return;
              }
              if (contactId) {
                if (props?.personType === PERSON_TYPES.CUSTOMER) {
                  navigate(`/members/patient/${contactId}/${item.tabTitle}`);
                } else {
                  navigate(`/members/prospect/${contactId}/${item.tabTitle}`);
                }
                onSelectTab(item.tabCode)
              } else {
                onSelectTab(item.tabCode)
              }
            }}>
            <VStack
              style={[styles.tabContainer, {borderRadius: 20}]}
              backgroundColor={
                selectedTabCode === item.tabCode
                  ? Colors.primary['300'] || ''
                  : ''
              }
            >
              <Text
                size={'smRegular'}
                color={
                  selectedTabCode === item.tabCode
                    ? '#fff' || ''
                    : Colors.FoldPixel.GRAY300
                }
              >
                <DisplayText textLocalId={item.tabTitle} />
              </Text>
            </VStack>
          </Pressable>
        );
      })}
    </HStack>
    </ScrollView>
  );
};

export default PersonTabBar;
