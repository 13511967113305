import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {
  IMemberStatusData,
  IMemberStatusFormBodyData,
  IMemberStatusView,
  IMemberStatusViewState,
} from './interface';
import {getFormattedMemberStatusData} from './MemberStatusUtils';
import {MEMBER_STATUS_GET_DATA_LIMIT} from './MemberStatusConst';
import {
  createMemberStatus,
  deleteMemberStatus,
  getMemberStatusData,
  updateMemberStatus,
} from './MemberStatusService';
import {MemberStatusSettingTable} from './MemberStatusSettingTable';
import {Skeleton, useToast, View} from 'native-base';
import {AddMemberStatusDrawer} from './AddMemberStatusDrawer';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {notification} from 'antd';

const MemberStatusSettingView = (props: IMemberStatusView) => {
  const intl = useIntl();
  const toast = useToast();

  const [stateData, setStateData] = useState<IMemberStatusViewState>({
    openAddMemberStatusDrawer: false,
    loading: true,
    memberStatusData: [],
    updateStatusLoading: false,
    selectedMemberStatus: undefined,
    addUpdateMemberStatusLoading: false,
    offSet: 0,
    pageNo: 1,
    limit: MEMBER_STATUS_GET_DATA_LIMIT,
    total: 0,
  });

  const onPagination = (page: number, pageSize: number) => {
    const pageNumber = page;
    const pageLimit = pageSize;
    const offset = pageNumber * pageLimit - pageLimit;
    setStateData((prev) => {
      return {
        ...prev,
        offSet: offset,
        pageNo: page,
        limit: pageLimit,
      };
    });
  };

  const onDelete = async (formData: IMemberStatusFormBodyData) => {
    setStateData((prev) => ({
      ...prev,
      addUpdateMemberStatusLoading: true,
    }));

    try {
      await deleteMemberStatus(formData?.id);
      setStateData((prev) => ({
        ...prev,
        addUpdateMemberStatusLoading: false,
      }));
      onMemberStatusDeleteSuccess();
    } catch (error: any) {
      setStateData((prev) => ({
        ...prev,
        addUpdateMemberStatusLoading: false,
      }));
      notification.error({
        message:
          error.response.data.message ||
          intl.formatMessage({id: 'apiErrorMsg'}),
        duration: 4.0,
        placement: 'topRight',
      });
    }
  };

  const onSubmit = async (formData: IMemberStatusFormBodyData) => {
    setStateData((prev) => ({
      ...prev,
      addUpdateMemberStatusLoading: true,
    }));
    const createMemberStatusPayload = {
      id: formData?.id,
      body: {
        name: formData?.name,
        description: formData?.description,
        color: formData?.color
      },
    };
    try {
      const response = formData?.id
        ? await updateMemberStatus(createMemberStatusPayload)
        : await createMemberStatus(createMemberStatusPayload);
      let MemberStatusData = {} as IMemberStatusData;
      if (response?.data?.id) {
        MemberStatusData = {
          id: response?.data?.id,
          name: response?.data?.name,
          description: response?.data?.description || '',
          statusType: response?.data?.isDefault ? 'Default' : 'Custom',
          key: response?.data?.code,
          color: '',
          inUse: false,
          isDefault: response?.data?.isDefault,
        };
      }
      setStateData((prev) => ({
        ...prev,
        addUpdateMemberStatusLoading: false,
      }));
      onActionPerformed?.(COMMON_ACTION_CODES.SUCCESS, MemberStatusData);
    } catch (error: any) {
      setStateData((prev) => ({
        ...prev,
        addUpdateMemberStatusLoading: false,
        openAddMemberStatusDrawer: false
      }));
      notification.error({
        message:
          error.response.data.message ||
          intl.formatMessage({id: 'apiErrorMsg'}),
        duration: 4.0,
        placement: 'topRight',
      });
    }
  };

  const onActionPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        onDrawerVisibleChange(true, actionData);
        break;
      case COMMON_ACTION_CODES.DELETE:
        onDelete(actionData);
        break;
      case COMMON_ACTION_CODES.SUCCESS:
        onMemberStatusAddUpdateSuccess();
        break;
      // case COMMON_ACTION_CODES.FAILED:
      //   onMemberStatusAddUpdateFailed(actionData);
      //   break;
      default:
        break;
    }
  };

  const onMemberStatusDeleteSuccess = () => {
    if (props?.searchString) {
      getMemberStatus(props?.searchString);
    } else {
      getMemberStatus('');
    }
    showToast(
      toast,
      intl.formatMessage({
        id: 'memberStatusDeletedMsg',
      }),
      ToastType.success,
      4000
    );
    onDrawerVisibleChange(false);
    props?.onViewChangeCallback?.();
  };

  const onMemberStatusAddUpdateSuccess = () => {
    if (props?.searchString) {
      getMemberStatus(props?.searchString);
    } else {
      getMemberStatus('');
    }
    showToast(
      toast,
      intl.formatMessage({
        id: stateData?.selectedMemberStatus?.id
          ? 'memberStatusUpdateMsg'
          : 'memberStatusAddedMsg',
      }),
      ToastType.success,
      4000
    );
    onDrawerVisibleChange(false);
    props?.onViewChangeCallback?.();
  };

  const onDrawerVisibleChange = (
    isOpen: boolean,
    rowRecord?: IMemberStatusData
  ) => {
    setStateData((prev) => {
      return {
        ...prev,
        addUpdateMemberStatusLoading: false,
        openAddMemberStatusDrawer: isOpen,
        selectedMemberStatus: isOpen ? rowRecord : ({} as IMemberStatusData),
      };
    });
  };

  useEffect(() => {
    onDrawerVisibleChange(props?.openModal || false);
  }, [props.openModal]);

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        offSet: 0,
        pageNo: 1,
      };
    });
    if (props?.searchString) {
      getMemberStatus(props?.searchString);
    } else {
      getMemberStatus('');
    }
  }, [props.searchString]);

  useEffect(() => {
    if (props?.searchString) {
      getMemberStatus(props?.searchString);
    } else {
      getMemberStatus('');
    }
  }, [stateData?.pageNo, stateData?.limit]);

  const getMemberStatus = async (searchString: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const params = {
      pageNo: stateData?.pageNo,
      pageSize: stateData?.limit,
      searchString: searchString,
    };
    try {
      const response = await getMemberStatusData(params);
      if (response?.data?.items?.length) {
        const MemberStatus = response?.data?.items;
        const formattedData = getFormattedMemberStatusData(MemberStatus);
        setStateData((prev) => {
          return {
            ...prev,
            memberStatusData: formattedData,
            loading: false,
            total: response?.data?.total,
          };
        });
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
            memberStatusData: [],
          };
        });
      }
    } catch (error: any) {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
      showToast(
        toast,
        error?.response?.data?.message ||
          intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
        4000
      );
    }
  };

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <View
        style={{
          flex: 1,
        }}
      >
        {stateData?.openAddMemberStatusDrawer && (
          <AddMemberStatusDrawer
            isDrawerOpen={stateData?.openAddMemberStatusDrawer}
            onDrawerClose={() => {
              onDrawerVisibleChange(false);
              props?.onViewChangeCallback?.();
            }}
            selectedMemberStatus={stateData?.selectedMemberStatus}
            onActionPerformed={onActionPerformed}
            onSubmit={onSubmit}
          />
        )}
        {stateData.loading ? (
          <View background={'#fff'} padding={3}>
            <Skeleton.Text lines={6} />
          </View>
        ) : (
          <MemberStatusSettingTable
            onPagination={onPagination}
            pageNo={stateData?.pageNo}
            pageSize={stateData?.limit}
            total={stateData?.total}
            data={stateData?.memberStatusData}
            onActionPerformed={onActionPerformed}
            loading={stateData?.updateStatusLoading || stateData?.loading}
          />
        )}
      </View>
    </View>
  );
};

export default MemberStatusSettingView;
