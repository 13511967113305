import React, {useContext, useState} from 'react'
import {FormContext, FormViewType} from '../../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {getAccountUUID, getFoldAccessToken, getUserUUID} from '../../../../../utils/commonUtils';
import useEHRCapabilities from '../../../../../screens/BusinessStudio/useEHRCapabilities';
import { IAdditionalAttribute } from '../../../../../services/ContactCareProgram/interface';
import { useContactCareProgramContext } from '../ContactCareProgram.context';
import { ICareProgramData, IContactCareProgramStep } from '../../interface';

const useFormOptions = (props: {
  accountLocationUuid?: string;
  patientId?: string;
  contactId?: string;
  contactUuid?: string;
  componentView?: FormViewType;
  backgroundSaveEnabled?: boolean;
  contactCareProgramId?: string;
  contactName?: string;
  careProgramData?: ICareProgramData;
  formContext?: FormContext;
  contactData?: {
    contactId?: string,
    contactUuid?: string,
    patientId?: string
  },
  handleUpdateCareProgram?: (actionCode?: string, actionData?: any) => void;
}) => {
  const contextData = useContext(CommonDataContext);
  const {accountLocationUuid, patientId, contactId, contactUuid, contactCareProgramId, contactName, careProgramData, contactData, handleUpdateCareProgram} = props;
  const accountUUID = getAccountUUID();
  const ehrCapabilities = useEHRCapabilities({locationId: accountLocationUuid});
  const foldAccessToken = getFoldAccessToken();

  const [intakeOptions] = useState({
    foldAccessToken,
    accountUUID,
    patientId,
    contactId,
    contactUuid,
    contactName,
    careProgramData,
    backgroundSaveEnabled: props.backgroundSaveEnabled || false,
    ehrCapabilities: ehrCapabilities,
    accountLocationUuid: accountLocationUuid,
    formContext: props.formContext || FormContext.patientForm,
    MLOV: contextData.MLOV,
    CARE_STUDIO_MLOV: contextData.CARE_STUDIO_MLOV,
    CLOUD_TELEPHONY_MLOV: contextData.CLOUD_TELEPHONY_MLOV,
    userSettings: contextData.userSettings,
    userData: contextData.userData,
    sidecarContext: contextData.sidecarContext,
    ...(props.componentView && {componentView: props.componentView}),
    contactCareProgramId: contactCareProgramId,
    contactDetails: {...contactData},
    handleUpdateCareProgram: handleUpdateCareProgram
  });

  return {
    intakeOptions,
  };
};

export default useFormOptions