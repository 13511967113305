import React from 'react';
import {Stack, Text} from 'native-base';
import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';
import {
  CONTACT_CARE_PROGRAM_STATUS_CODES,
  CONTACT_CARE_PROGRAM_STATUS_VALUES,
} from '../../../../../constants/MlovConst';

const CPStatusBadge = ({statusCode}: {statusCode?: string}) => {
  const containerStyle = () => {
    switch (statusCode) {
      case CONTACT_CARE_PROGRAM_STATUS_CODES.NEW:
        return styles.newStatus;
      case CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS:
      case CONTACT_CARE_PROGRAM_STATUS_CODES.FOLLOW_UP:
        return styles.inProgressStatusCode;
      case CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED:
        return styles.completedStatus;
      case CONTACT_CARE_PROGRAM_STATUS_CODES.CLOSED:
      case CONTACT_CARE_PROGRAM_STATUS_CODES.AUTO_CLOSED:
        return styles.decliendStatus;
      case CONTACT_CARE_PROGRAM_STATUS_CODES.PAUSED:
        return styles.pausedStatus;
    }
    return styles.decliendStatus;
  };

  const textColor = () => {
    switch (statusCode) {
      case CONTACT_CARE_PROGRAM_STATUS_CODES.NEW:
        return Colors.FoldPixel.PRIMARY300;
      case CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS:
      case CONTACT_CARE_PROGRAM_STATUS_CODES.FOLLOW_UP:
        return Colors.FoldPixel.STATUS_IN_PROGRESS;
      case CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED:
        return Colors.FoldPixel.STATUS_DARK_SUCCESS;
      case CONTACT_CARE_PROGRAM_STATUS_CODES.CLOSED:
      case CONTACT_CARE_PROGRAM_STATUS_CODES.AUTO_CLOSED:
        return Colors.FoldPixel.STATUS_ERROR;
      case CONTACT_CARE_PROGRAM_STATUS_CODES.PAUSED:
        return Colors.FoldPixel.GRAY300;
    }
    return Colors.FoldPixel.STATUS_ERROR;
  };

  if (!statusCode) {
    return null;
  }

  return (
    <Stack direction={'row'} style={[styles.commonStyles, containerStyle()]}>
      <Text wordBreak={'break-word'} color={textColor()} size={'xsNormal'}>
        {CONTACT_CARE_PROGRAM_STATUS_VALUES[statusCode]}
      </Text>
    </Stack>
  );
};
const styles = StyleSheet.create({
  commonStyles: {
    paddingHorizontal: 4,
    paddingVertical: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  newStatus: {
    backgroundColor: Colors.FoldPixel.PRIMARY100,
  },
  decliendStatus: {
    backgroundColor: Colors.FoldPixel.ALERT_HOVER_COLOR,
  },
  inProgressStatusCode: {
    backgroundColor: Colors.Custom.PendingTaskBackgroundColor,
  },
  completedStatus: {
    backgroundColor: Colors.FoldPixel.STATUS_LIGHT_SUCCESS,
  },
  pausedStatus: {
    backgroundColor: Colors.FoldPixel.GRAY50,
  },
});
export default CPStatusBadge;
