import {createContext, Dispatch, useContext} from 'react';
import {
  IContactCareProgramState
} from '../interface';
import {
  IContactCareProgramReducerAction
} from './reducer';
import {CareProgramStatusAction} from './hooks/useCareProgramStatusAction';
import {IPersonData} from '../../../PersonOmniView/MiddleContainer/interfaces';
import {IContact} from '../../TeamInbox/Conversations/interfaces';

export const ContactCareProgramContext = createContext<{
  dispatch: Dispatch<IContactCareProgramReducerAction> | undefined;
  state: IContactCareProgramState;
  onUpdateCareProgramStatus: (action: CareProgramStatusAction) => Promise<void>;
  refetchMasterData: () => Promise<void>;
  scrollContentViewToTop: () => void;
  accountLocationUuid?: string;
  contactName?: string;
  personData?: IPersonData | IContact | undefined;
}>({
  dispatch: undefined,
  state: {} as IContactCareProgramState,
  onUpdateCareProgramStatus: async () => {},
  refetchMasterData: async () => {},
  scrollContentViewToTop: () => {},
  accountLocationUuid: '',
  contactName: '',
  personData: undefined,
});

export const useContactCareProgramContext = () => {
  const context = useContext(ContactCareProgramContext);
  if (context === undefined) {
    throw new Error('useContactCareProgramContext must be used within a ContactCareProgramProvider')
  }
  return context;
};

export const ContactCareProgramProvider = ContactCareProgramContext.Provider;

export default {
  ContactCareProgramContext,
  useContactCareProgramContext,
  ContactCareProgramProvider,
};
