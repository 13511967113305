import {ParamListBase, RouteProp} from '@react-navigation/native';
import {NativeStackNavigationProp} from '@react-navigation/native-stack';
import {InboxType} from '../../../../constants/ConstantValues';
import {IContactSource} from '../../../../constants/interface';
import {IMlov, IMlovByCategory, IUser} from '../../../../Interfaces';
import {IGroupConversation, IUserRoles} from '../../../../services/Conversations/interfaces';
import {IInbox} from '../../../../services/Inbox/interfaces';
import {IConversationInboxs, IReplyMessageObject} from '../../../common/ChatUI/MessageBox/interfaces';
import {
  IInboxFilter,
  ILabel,
  ITeam,
} from '../../../common/FilterView/interfaces';
import {MessageActionID} from '../../../common/MessageAction/MessageActionView';
import {IAppointmentType} from '../../AccountSettings/AppointmentTypes/Interfaces';
import {IUserInboxList} from './ConversationChannelTabs/interfaces';
import {UserRole} from '../../Contacts/TeamMembers/AddEditUser/interfaces';
import {ICommonFilterData, Users} from './ConversationContainer/ConversationFilters/interface';
import {ICommunicationType} from './CommunicationTypes/interfaces';
import { IGetMessages, IMessageRespData, IUserPreferences } from './MessagingWindow/interface';
import {ICallLogEntry, ISipNumberList, IUserList} from '../CallLogs/interface';
import {IStickyNoteData} from '../../../common/ActionMessageView/interfaces';
import {IConversationFilterValue} from './ConversationChannelNew/Interfaces';
import { IMessageCreated } from '../../../../Interfaces/WebSocketEventInterfaces';
import { IContactScoreReference } from '../../../RafScore/interface';

export type ITypingStatus = 'on' | 'off';
export interface IConversationFilterState {
  datePicker: IDatePicker;
  statusObj: IStatusObj;
  contactType: IContactTypeObj;
}

// create an interface here to the contact type object

export interface IStatusObj {
  displayString: string;
}

export interface IDatePicker {
  fromDate: string;
  toDate: string;
}

export interface IContactTypeObj {
  displayString: string;
  code: string;
}

export interface ICustomerDetailsProps {
  id: string;
  fullName: string;
  email: string;
  timeStamp: string;
  recentText: string;
  origin: string;
  unread: string;
  avatarUrl: string;
  phone: string;
  details: IDetailsPanelProps[];
  conversation: IMessagesProps[];
}

export interface IMessagingWindowProps {
  moduleCode?: string;
  isDisplayHeader: boolean;
  selectedConversation: IConversationData;
  onConversationActionPerformed: (actionCode: string, actionData?: any) => void;
  selectedTabCode?: string;
  selectedInboxTypeCode?: string;
  conversationInbox?: any;
  isInDrawerView?: boolean;
  showTabs?: boolean;
  msgQueue?: MsgQueue[];
  onAddMsgToQueue?: (queue: MsgQueue[]) => void;
  showInfoIconInHeader?: boolean;
  isDetailsContainerVisible?: boolean;
  isInstantChatView?: boolean;
  parentCode?: string;
  navigateOrOpenContactIdDrawer?: (contactId?: string, additionalData?: any) => void;
  onMsgChangeText?: (richText?: any) => void;
  isSeachMsgContainerVisible?: boolean;
  searchMessage?: ISearchMessageData;
  enableConversationSelection?: boolean;
  showNavigationBack?: boolean;
  navigationBackPressed?: () => void;
}

export interface IMessagingWindowNativeProps {
  moduleCode?: string;
  selectedConversation: IConversationData;
  selectedInboxTypeCode?: string;
  navigation?: any;
  notificationConversationId?: number;
  searchMessage?: ISearchMessageData;
}

export interface IDetailsPanelProps {
  property: string;
  value: string;
}

export interface IMessagesProps {
  text: string;
  position: 'right' | 'left' | undefined;
}

export interface ITagMenuItemsProps {
  color: string;
  label: string;
}

export interface IConversationStatusFilterProps {
  onStatusChange: (statusObj: IMlov) => void;
  statusList: IMlov[];
  statusValue: string;
}

export interface IConversationListProps {
  navigation?: any;
  conversationFilter: any;
  conversationUserFilterData: IConversationFilterValue;
  searchString?: string;
  selectedTabCode: string;
  readConversationDataId: number;
  isConversationSelected?: boolean;
  onConversationActionPerformed?: (
    actionCode: string,
    actionData?: any
  ) => void;
  groupCreatedCount?: number;
  selectedInboxTypeCode: string;
  onActionPerformed?: any;
  allInboxList?: IUserInboxList;
  enableConversationSelection?: boolean;
  checkedSelectAll?: boolean;
  checkedSelectList?: boolean;
  isDeselectedSomeConversations?: boolean;
  setUserPreferenceChangeLoading?: () => void;
  selectedSubtabCode?: string;
  selectedInboxUserIds?: string[];
  emailInboxUserPreference?: string;
  otherContactTypeId?: string;
  isEmailUserPreferenceEnabled?: boolean;
  userList?: IUserList[];
  sipNumberList?: ISipNumberList[];
}

export interface INativeConversationListProps {
  navigation?: any;
  onSelectedConversationChanged?:any;
  searchString: string;
  selectedConversationType: InboxType;
  newConversationCreatedAt?: string;
  onClickActionPerformed?: (
    data: any,
    readMessagesCallBack?: (displayId: number) => void,
    selectedAssignedUserData?: Users,
  ) => void;
  isNewConversationCreated?: boolean;
  selectedCommonFilter: ICommonFilterData;
  onRefreshTabCount?: () => void;
  selectedSubtabCode?: string;
}

export interface IConversationListItemProps {
  singleConversationData: IConversationData;
  searchString?: string;
  selectedConversation?: IConversationData;
  parentCode?: string;
  index?: number;
  displayTimeType?: string;
  conversationConfig?: {
    tabTypeCode?: tabTypeCode;
    tabData: {
      conversationMentionId: string;
      isRead?: boolean;
    };
    selectedInboxTypeCode: string;
  },
  allInboxList?: IUserInboxList,
  selectedInboxTypeCode?: string;
  conversationListWidth?: any;
  showFoldStatus?: boolean;
  enableConversationSelection?: boolean;
  hideOnlineIndicator?: boolean;
  selectedConversationId?: number
  isSelected?:boolean;
  selectedConversationMentionId?: string
  selectedSubtabCode?: string,
  userList?: IUserList[];
  sipNumberList?: ISipNumberList[];
  isUnreadMsgPresent?: boolean;
}
export const tabTypeString = {
  CONVERSATION_MENTIONS: 'CONVERSATION_MENTIONS' as tabTypeCode,
  MISS_CALL: 'MISS_CALL' as tabTypeCode,
}

export type tabTypeCode =
  | 'CONVERSATION_MENTIONS'
  | 'MISS_CALL'
  | '';
export interface IConversationContainerProps {
  onClickActionPerformed?: (
    data: any,
    selectedTabCode: any,
    selectedInboxTypeCode: any,
    readMessagesCallBack?: (conversationId: number) => void
  ) => void;
  mlovData?: IMlovByCategory;
  conversationFilterData?: IFilterSidebarData;
  conversationFilterProps?: any,
  conversationUserFilterData: IConversationFilterValue;
  selectedTabCode?: string;
  searchString?: string;
  readConversationData?: IConversationData;
  setSearchText?: (searchTxt: string) => void;
  onTabSelection?: (tabCode: string) => void;
  onConversationActionPerformed?: (
    actionCode: string,
    conversationData?: IConversationData | any,
  ) => void;
  onConversationFilterPerformed?: (
    actionCode: string,
    filterCode: string,
    filterData?: any
  ) => void;
  navigation?: any;
  selectedInboxTypeCode: string;
  onActionPerformed?: any;
  allInboxList?: IUserInboxList;
  isConversationSelected?: boolean;
  filterModal?: (isFilterDrawerOpen: boolean) => void;
  selectedInboxData?: any;
  enableConversationSelection?: boolean;
  checkedSelectAllConversation?: boolean;
  isDeselectedSomeConversations?: boolean;
  markAsReadConversationUuids?: string[];
  setUserPreferenceChangeLoading?: () => void;
  emailInboxUserPreference?: string;
  otherContactTypeId?: string;
  isEmailUserPreferenceEnabled?: boolean;
  sideCarSearchString?: string;
  userList?: IUserList[];
  sipNumberList?: ISipNumberList[];
}

export interface IConversationTabsProps {
  inboxList?: IInbox[];
  conversationFilterData: any;
  searchString: string;
  selectedTabCode: string;
  readConversationData: IConversationData;
  onConversationActionPerformed: (
    actionCode: string,
    conversationData?: IConversationData
  ) => void;
  onTabSelection: (tabCode: string) => void;
  groupCreatedCount?: number;
  selectedInboxTypeCode: string;
}

export interface IConversationTabsNativeProps {
  navigation?: any;
  searchString: string;
  onClickActionPerformed?: (
    data: any,
    readMessagesCallBack?: (displayId: number) => void,
    selectedAssignedUserData?: Users,
    pcpUserData?: IUser,
  ) => void;
  onTabSelected?: (item: any) => void;
  selectedConversationType: InboxType;
  conversationTypeList: InboxType[];
  selectedCommonFilter: ICommonFilterData;
  newConversationCreatedAt?: string;
  userList?: IUserList[];
  sipNumberList?: ISipNumberList[];
}

export interface IConversationFilterSliderProps {
  onConversationFilterPerformed: (
    actionCode: string,
    filterCode: string,
    filterData?: any
  ) => void;
}
export interface IConversationListHeaderBarProps {
  onConversationHeaderActionPerformed: (
    actionCode: string,
    actionData?: any
  ) => void;
  mlovData: IMlovByCategory;
}

export interface IConversationFilterPopupProps {
  onActionPerformed: (actionCode: string, actionData?: any) => void;
  selectedFilters: any;
  filterData: any;
}

export interface IConversationStatusCount {
  count: number;
  status: number;
}
export interface IConversationResponse {
  conversations: IConversationData[];
  conversationStatusCount: IConversationStatusCount[];
  conversationMentions?: IConversationMentionsData[];
  emailConversationActivities?: IEmailConversationActivities[];
  callConversationActivities?: ICallConversationActivities[];
  efaxConversationActivities?: IEFaxConversationActivities[];
}

export interface IConversationContactTimelineResponse {
  conversationMessageGroups: IConversationMessageGroups[];
}

export interface IConversationMessageContentAndContentAttributeResponse {
  messages: IConversationMessageContentAndContentAttribute[];
}

export interface IConversationMessageGroups {
  messageConversation?: IConversationData;
  message: IConversationMessageWithCallLogData;
  messageContent: {
    conversationMessages: IConversationMessageContent[];
  };
}

export interface IConversationMessageContent {
  content: string;
}

export interface IConversationMessageContentAndContentAttribute {
  id: number;
  uuid: string;
  content: string;
  contentAttributes: string;
}

export interface IEmailConversationActivities {
  conversations: IConversationData;
  lastOutgoingEmailAt?: string;
}

export interface IEFaxConversationActivities {
  conversations: IConversationData;
  lastOutgoingEFaxAt?: string;
}

export interface ICallConversationActivities {
  conversation: IConversationData;
  lastCallMessage: IConversationMessage;
  lastIncomingMissedCallMessage?: IConversationMessage;
  lastOutgoingMissedCallMessage?: IConversationMessage;
  lastIncomingVoiceMailMessage?: IConversationMessage;
  lastIncomingAbandonedCallMessage?: IConversationMessage;
  lastIncomingCompletedCallMessage?: IConversationMessage;
  lastOutgoingCompletedCallMessage?: IConversationMessage;
}
export interface IConversationMentionsData {
  isRead?: boolean;
  id: string;
  conversation: IConversationData;
  conversationMessages: IConversationMessage;
}
export interface INewConversationResponse {
  conversations: IConversationData[];
  conversationMentions?: IConversationMentionsData[];
}

export interface IConversationData {
  contactId: number;
  accountId: number;
  id: number;
  uuid: string;
  displayId: number;
  inboxId: number;
  status: number;
  additionalAttributes: IAdditionalAttributes;
  assigneeId: number;
  unreadMessages: IUnreadMessages[];
  unreadMsgCount: number;
  conversationMessages: IConversationMessage[];
  conversationContact: IContact;
  conversationInbox: IConversationInbox;
  createdAt?: string;
  updatedAt?: string;
  lastMissCallAt?: string;
  assignedUser: {
    id: number;
    name: string;
    uuid: string
    email: string
  };
  groupConversation: IGroupConversation;
  agentsData?: any;
  conversationMentionId?: string;
  isRead?: boolean;
  contactInbox?: any;
  isSelected?: boolean;
  subject?: string;
  workflowCommunicationLogResponseId?: string;
  contactLocationUuid?: string
  lastOutgoingEmailAt?: string;
  blockReasonCode?: string;
  lastOutgoingEFaxAt?: string
}

export interface IConversationInbox {
  id: number;
  name: string;
  channelType: string;
  isDeleted?: boolean;
  channelEmail: IInboxChannelEmail;
  channelTwilioSms?: {
    phoneNumber?: number | string
  }
  channelEfax?: {
    efaxNumber?: number | string
    id?: number;
  }
  inboxMembers: IConversationInboxMember[];
  inboxMembers_aggregate?: IInboxMembersAggregate;
}

export interface IInboxMembersAggregate {
  aggregate: {
    count: number;
  };
}

export interface IInboxChannelEmail {
  forwardToEmail: string;
  forwardRouteId: string;
  smtpAddress: string;
}

interface IConversationInboxMember {
  isDeleted: boolean;
  userId: number;
  user: IConversationInboxUser;
}

interface IConversationInboxUser {
  id: number;
  uuid: string;
  name: string;
  accountUsers: IAccountUser[];
}

interface IAccountUser {
  accountId: number;
  userId: number;
  isActive: boolean;
  isDeleted: boolean;
}

export interface IConversationInboxData {
  name: string;
  id: number;
  channelTwilioSms?: {
    phoneNumber?: number | string
  }
}
export interface IContactInbox {
  inbox: IConversationInboxData;
}
export interface IUnreadMessages {
  id?: number;
  messageType?: number;
  messageMentions?: MessageMentions[]
}

export interface IMention {
  userId: number;
  conversationId: number;
}

export interface IAdditionalAttributes {
  browser: IBrowser;
  referer: string;
  initiated_at: string;
  mail_subject?: string;
}

export interface IBrowser {
  device_name: string;
  browser_name: string;
  platform_name: string;
  browser_version: string;
  platform_version: string;
}
export interface IContact {
  accountLocationUuid: string;
  personContact: any[];
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
  accountId: number;
  createdAt: string;
  updatedAt: string;
  patientId?: any;
  patientUuid?: string;
  pubsubToken: string;
  additionalAttributes: any;
  identifier: string;
  contactProfilePhotos: IContactProfileUrl[];
  customAttributes: {[index: string]: string};
  source: IContactSource;
  contactType: IContactTypeRes;
  lastActivityAt: string;
  isOnline?: boolean;
  isDeleted: boolean;
  isActive: boolean;
  person?: IConversationContactPerson;
  uuid: string;
  patient?: any;
  personAddress: any[];
  contactPracticeLocations: IContactPracticeLocations[];
  contactTagging: IContactTagging[];
  contactDeactivationInfo: IContactDeactivationInfo[];
  contactConsents: any;
  showConsentError: boolean;
  userRoles: any[];
  contactPreferredCommunicationChannel: IContactPreferredCommunicationChannel[];
  contactUser?: any;
  contactAdditionalInfos?: {
    stripeCustomerId?: string
  };
  contactMemberships?: {
    startDate: string;
    endDate: string;
    status: string;
    enrollmentStatus: string;
  }[];
  claimsMemberStatus?: string;
  tempDummyContactUuid?: string;
  patientHieSyncStatus?: boolean;
  contactScore?: {
    foldScore: number;
    hccV24: number;
    hccV28: number;
    reference: IContactScoreReference;
  }
  contactScoreAudits? : {
    scoreDate : string;
    scoreType : string;
    scoreValue : number;
  }[]
  memberStatus?: IMemberStatus;
}

export interface IContactProfileUrl {
  attachmentId?: any;
  profilePhotoUrl?: string;
}

export interface IMemberStatus{
  id: string;
  name: string;
  color: string;
}

export interface IContactDeactivationInfo {
  isActive: boolean;
}

export interface IConversationContactPerson {
  firstName: string;
  birthDate: string;
  id: number;
  gender: {
    value: string;
    code: string;
  };
  bloodGroup: {
    value: string;
    code: string;
    id: string;
  };
  genderId: string;
  birthSexId?: string;
  sexAtBirth?: {
    value?: string;
    code?: string;
    id?: string;
  };
  contactId: string;
  middleName: string;
  lastName: string;
  pronounId: string;
  chosenName: string;
}

export interface IContactTypeRes {
  contactType: IContactType;
  contactTypeId?: string;
}
export interface IContactType {
  code: string;
  value: string;
  id: string;
  isDefault?: boolean;
  can_convert_to_patient?: boolean;
}

export interface IMessageAttachment {
  accountId?: number;
  coordinatesLat?: number;
  coordinatesLong?: number;
  createdAt?: string;
  extension?: any;
  externalUrl?: any;
  fallbackTitle?: any;
  fileType?: number;
  id?: number;
  messageId?: number;
  updatedAt?: string;
  activeStorageAttachments: IActiveStorageAttachment[];
}

export interface IActiveStorageAttachment {
  activeStorageBlob: {
    filename: string;
    contentType: string;
  };
}

export interface IConversationMessageResponse {
  conversation: IConversationMessageData;
}

export interface IConversationMessageMentionPrevResponse {
  conversation: IConversationMessageMentionPrevData;
}
export interface IStickNotesAndLastMessageResponse{
  lastMessage: ILastConversationMsgData;
  stickyNotes?: IStickyNoteData[];
}
export interface IConversationMessageMentionPrevData {
  prevMsg?: IConversationMessage[];
  nextMsg?: IConversationMessage[];
  lastMsg?: IConversationMessage[];
}

export interface IConversationMessageData {
  id?: number
  uuid?: string
  conversationMessages: IConversationMessage[];
  unreadMessages?: IUnreadMessages[];
  conversationInbox?: IConversationInboxs
}

export interface IConversationMessage {
  id?: number;
  uuid?: string;
  parentMessageUuid?: string;
  content?: string;
  senderId?: number;
  senderType?: string;
  contentType?: number | string;
  contentAttributes?: any;
  createdAt?: string;
  updatedAt?: string;
  messageType?: number;
  status?: number | string;
  sourceId?: any;
  senderContact?: ISenderContact;
  senderUser?: ISenderUser;
  private: boolean;
  messageAttachments?: IMessageAttachment[];
  parentMessage?: IConversationMessage;
  messageMentions?: IMessageMentions[]
  isScheduleMessage?: boolean;
  msgAttachment?: IMsgAttachment[];
  iv?: Uint8Array;
  encryptedMessage?: ArrayBuffer;
}

export interface IConversationMessageWithCallLogData extends IConversationMessage{
 callLogData: ICallLogEntry;
}
export interface ISearchMessageData {
  uuid?: string;
  id?: number;
  clickEventId?: string;
  createdAt?: string;
}

export interface IConversationMessageContentAttributes {
  email: IConversationMessageEmail;
  cc_email?: string;
  bcc_email?: string;
  from: any;
  to: any;
  To: any;
  subject: string;
  emailBody: string;
}

export interface IConversationMessageEmail {
  bcc?: any;
  cc?: any;
  content_type: string;
  date: string;
  from: string[];
  html_content: IEmailHtmlcontent;
  in_reply_to?: any;
  message_id: string;
  multipart: boolean;
  number_of_attachments: number;
  subject: string;
  text_content: IEmailHtmlcontent;
  to: string[];
}

interface IEmailHtmlcontent {
  full: string;
  reply: string;
  quoted: string;
}

interface IConversationUser {
  email: string;
  name: string;
  id: number;
  uuid: string;
}

export interface IMsgWithAttachments {
  id: number;
  content?: any;
  account_id: number;
  inbox_id: number;
  conversation_id: number;
  message_type: number;
  created_at: number;
  updated_at: string;
  private: boolean;
  status: string;
  source_id?: any;
  content_type: string;
  sender_type: string;
  sender_id: number;
  uuid: string;
  is_deleted: boolean;
  attachments: IMsgAttachment[];
}

export interface ISenderUser {
  email?: string;
  id: number;
  name: string;
  uuid?: string;
  isDeleted?: boolean;
}

export interface ISenderContact {
  email?: string;
  name: string;
  id: number;
  uuid?: string;
  isDeleted?: boolean
}

export type IMsgBoxDataPosition = 'right' | 'left';
export interface IMessageBoxData {
  id: number;
  uuid: string;
  parentMessageUuid: string;
  displayContent: string;
  date?: string;
  dateStr: string;
  dateInNumber: number;
  updatedDateStr?: string;
  position: IMsgBoxDataPosition;
  senderFullName: string;
  messageType: number;
  msgAttachment?: IMsgAttachment[] | any;
  senderType: string;
  senderUser?: ISenderUser;
  senderContact?: ISenderContact;
  private: boolean;
  gender?: string;
  messageData?: IConversationMessage;
  parentMessage?: IMessageBoxData | any;
  tempId?: any;
  echoId?: string
  communicationTypes?: ICommunicationType[];
  messageMentions?: IMessageMentions[];
  content?: string;
  status?: string | number;
  taskCount?: number;
  errorData?: IErrorStatusData,
}

export interface IErrorStatusData {
  errorCode?: number,
  message?: string,
}
// export interface IParentMessageData {
//   parentData: IMessageBoxData;
// }

export interface IMsgAttachment {
  id: number;
  message_id: number;
  file_type: string;
  account_id: number;
  extension?: any;
  data_url: string;
  thumb_url: string;
  file_name?: string;
}

export interface ICustomerPanelProps {
  contactData: IContact;
  conversationData?: IConversationData;
  onActionPerformed?: (actionCode: string, selectedData: unknown) => {};
  contactType?: IContactType;
  borderLessView?: boolean;
  height?: number;
  extra?: any;
  showEditProfileOnPatientInfoBanner?: boolean;
  parentCode: string;
  hintExtraDetail?: any;
  btnTextVisibility?: any;
  showDetailProfile?: boolean;
  showCloseDrawerIcon?: boolean;
  patientHieSyncCall?: boolean;
  isEmployerDrillDown?: boolean;
}

export interface IMessagingWindowHeaderProps {
  moduleCode?: string;
  contactData: IContact;
  conversation?: IConversationData;
  isDisplayActions: boolean;
  onMsgUpdate?: (msgText: string, msgData?: any) => void;
  onActionPerformed?: (actionCode: string, selectedData: unknown) => void;
  selectedInboxTypeCode?: string;
  defaultActiveStatus?: boolean;
  showInfoIconInHeader?: boolean;
  copyMsgView?: JSX.Element;
  createNoteView?: JSX.Element;
  isDetailsContainerVisible?: boolean;
  renderSelectedMessageView?: JSX.Element;
  assignMessageTypeView?: JSX.Element;
  navigateOrOpenContactIdDrawer?: (contactId?: string, additionalData?: any) => void;
  headerContainerRef?: any;
  isInstantChatView?: boolean;
  isSideDetailVisible?: boolean;
  selectChatView?: JSX.Element;
  isSelectChatOn?: boolean;
  onSelectChatAction?: boolean;
  createTaskView?: JSX.Element;
  cancelView?: JSX.Element;
  isSeachMsgContainerVisible?: boolean;
  handleSelectChat?: () => void;
  parentCode?: string;
  messagingWindowHeaderLoading?: boolean;
  showNavigationBack?: boolean;
  navigationBackPressed?: () => void;
  pcpUserData?: IUser;
}

export interface IMessageStatusError {
  CONVERSATION_ALREADY_RESOLVED: string;
  code: string;
  data: any;
}
export interface IMessagingWindowFooterProps {
  conversationData: IConversationData;
  onMsgSend: (
    msgText: string,
    msgData?: any,
    parentMessage?: IReplyMessageObject
  ) => void;
  onKeyboardCallback?: (e: any) => void;
  onActionMsgFooterPerformed: (actionCode: string, actionData?: any) => void;
  selectedTabCode?: string;
  selectedInboxTypeCode?: string;
  // selectedReplyMsg?: IReplyMessageObject | IMessageBoxData | null;
  selectedReplyMsg?: IMessageBoxData | any;
  conversationInbox?: any;
  contactData?: any;
  isInDrawerView?: boolean;
  navigation?: any;
  msgQueue?: MsgQueue[];
  onAddMsgToQueue?: (queue: MsgQueue[]) => void;
  isDetailsContainerVisible?: boolean
  isInstantChatView?: boolean;
  parentCode?: string
  enableShowAccountNameOnlyToPatient?: boolean;
  onMsgChangeText?: (richText?: string) => void;
  onCallBackEditorInputHeight?: (actionCode: string, data?: any) => void;
  archiveOnSendData?: IUserPreferences;
  isNewLineOnEnter?: IUserPreferences;
  isSeachMsgContainerVisible?: boolean;
  onMentionActionPerformed?: (actionCode: string, actionData: any) => void;
  conversationDraftMessage?: string;
  totalScheduleMessage?: number,
  totalScheduleFailedMessage?: number,
}

export interface IFilterSidebarData {
  TAG: ILabel;
  INBOX: IInboxFilter;
  TEAM: ITeam;
}

export interface IConversationHeaderBtn {
  code: string;
  name: string;
}

export interface IFile {
  name: any;
  type?: string | null;
}
export declare type UploadFileNative = Blob;

export interface IMessagingWindowCommon {
  msgData: any;
  selectedConversation: IConversationData;
  msgWindowHeight?: number;
  navigation?: any;
  selectedInboxTypeCode?: string;
  onReplyCallback?: (data: IReplyMessageObject) => void;
  onFetchMoreIfAvailable?: any;
  onFetchMoreRecentIfAvailable?: any;
  messageAction?: any;
  showModal?: any;
  onActionPerformed?: (actionCode?: string, data?: any) => void;
  // onSwipeMsgToReply?:(msgItem:IReplyMessageObject)=>void;
  onMessageAction?: (
    actionId: MessageActionID,
    data?: any,
    replyObject?: IReplyMessageObject
  ) => void;
  isKeyboardVisible?: boolean;
  isPrivateTab?: boolean;
  isReadOnlyView?: boolean;
  showTabs?: boolean;
  isCopyModeStateOnCode?: string;
  actionMessageCode?: string;
  onAddCommunicationType?: onAddCommunicationType;
  loadingLoadMore?: boolean;
  onViewTaskAction?: (messageUuid: string) => void;
  onScrollToMessageFail?: (
    msg: ISearchMessageData,
    isSetClickedMessageUuid?: boolean
  ) => void;
  isSeachMsgContainerVisible?: boolean;
  searchMessage?: ISearchMessageData;
  clickMessageUuid?: string;
  resetClickedMessageUuid?: () => void;
  onRedirectToMentionChange?: () => void;
  redirectToMention?: boolean;
  groupMemberDataLoading?: boolean;
  userList?: IUserList[];
  sipNumberList?: ISipNumberList[];
}

export interface IMessagingWindowCommonV2 {
  selectedConversation: IConversationData;
  msgWindowHeight?: number;
  navigation?: any;
  selectedInboxTypeCode?: string;
  onReplyCallback?: (data: IReplyMessageObject) => void;
  // onFetchMoreRecentIfAvailable?: any;
  messageAction?: any;
  showModal?: any;
  onActionPerformed?: (actionCode?: string, data?: any) => void;
  // onSwipeMsgToReply?:(msgItem:IReplyMessageObject)=>void;
  onMessageAction?: (
    actionId: MessageActionID,
    data?: any,
    replyObject?: IReplyMessageObject
  ) => void;
  isReadOnlyView?: boolean;
  showTabs?: boolean;
  isCopyModeStateOnCode?: string;
  actionMessageCode?: string;
  onAddCommunicationType?: onAddCommunicationType;
  loadingLoadMore?: boolean;
  onViewTaskAction?: (messageUuid: string) => void;
  onScrollToMessageFail?: (
    msg: ISearchMessageData,
    isSetClickedMessageUuid?: boolean
  ) => void;
  isSeachMsgContainerVisible?: boolean;
  searchMessage?: ISearchMessageData;
  clickMessageUuid?: string;
  resetClickedMessageUuid?: () => void;
  onRedirectToMentionChange?: () => void;
  redirectToMention?: boolean;
  groupMemberDataLoading?: boolean;
  userList?: IUserList[];
  clickEventId?: string;
  conversationMentionId?: string;
  onUpdateUserLastSeen: () => void;
  conversationUpdateOnMsgReceived: (conversationStatus: number) => void;
  onNewMessageReceived: (newMessageUuids: string[]) => void;
  onLoadingLatestMessage: (loadingValue: boolean) => void;
  onLoadingMsgFirstBatch: (loadingValue: boolean) => void;
  onGetStickyNotes: (stickNoteData: IStickyNoteData) => void;
}

export declare type NativeStackScreenProps2<
  ParamList extends ParamListBase,
  RouteName extends keyof ParamList = string,
  NavigatorID extends string | undefined = undefined
> = {
  navigation: NativeStackNavigationProp<ParamList, RouteName, NavigatorID>;
  route?: RouteProp<ParamList, RouteName>;
};

export interface IContactPracticeLocations {
  uuid: string;
  practiceLocationUuid: string;
  id: number;
  contactUuid: string;
  practiceLocation: IPracticeLocation;
  accountLocation?: {uuid?: string}
}

export interface IPracticeLocation {
  name: string;
  practiceCity: IPracticeCity;
}

export interface IPracticeCity {
  name: string;
  id: string;
}
export interface ILabels {
  title: string;
  color: string;
  id: number;
  description: string;
  name?: string;
}

export interface IContactTagging {
  labels: ILabels;
  taggableId: number
}

export interface IAttachAppointmentProps {
  isOpen?: boolean;
  onClose: () => void;
  conversationData: IConversationData;
  onSendMessage: (message: string) => void;
  subdomainBaseUrl: string;
  isGetRawLink?: boolean;
}

export interface IAttachAppointmentDetails {
  isOpen?: boolean;
  appointmentTypes: IAppointmentType[];
  selectedAppointment: IAppointmentType;
  accountUsers: IUser[];
  selectedUsers: IUser[];
  userIds: string[];
  appointmentRoleTypeUsers: IUser[];
  locationGroups: {
    id: string;
    groupName: string;
  }[];
  filterAppointmentTypes: IAppointmentType[];
  filterUsers: IUser[];
  selectedLocationGroupId: string | undefined;
}

export interface IPreferredCommunicationModeMlov {
  code: string;
  value: string;
}

export interface IContactPreferredCommunicationChannel {
  preferredCommunicationModeId: string;
  id: string;
  preferredCommunicationModeMlov: IPreferredCommunicationModeMlov;
}

export interface MsgQueue {
  conversationId: string | number;
  data: any;
  conversationData: any;
}

export interface MessageMentions {
  id: string
}

export interface IMessageMentions {
  id: string
  isRead: boolean
}

export interface IScheduleMessageInterface {
  id: string,
  conversationUuid: string,
  additionalAttributes?: any,
  content: string,
  contentType?: number,
  inboxId?: number,
  private: boolean,
  scheduledAt: string,
  senderId: number,
  senderType?: string,
  status: string,
  updatedAt?: string,
  updatedBy?: string,
  workflowEventId: string,
  channelType: string
  accountUser?: {
    user?: {
      name?: string
      uuid?: string
    }
  },
  isEditModeOn?: boolean;
}

export enum MESSAGE_TYPE {
  incoming = 0,
  outgoing = 1,
  activity = 2,
  template = 3,
  incoming_missed_call = 4,
  incoming_completed_call = 5,
  outgoing_missed_call = 6,
  outgoing_completed_call = 7,
  automation_message= 8
}
export interface IScheduleMessageInterface {
  id: string,
  conversationUuid: string,
  additionalAttributes?: any,
  content: string,
  contentType?: number,
  inboxId?: number,
  private: boolean,
  scheduledAt: string,
  senderId: number,
  senderType?: string,
  status: string,
  updatedAt?: string,
  updatedBy?: string,
  workflowEventId: string,
  channelType: string
  accountUser?: {
    user?: {
      name?: string,
      uuid?: string
    }
  }
}


export interface IUserRoleInterface {
  userRoles: IUserRoles[],
  isPopupView?: boolean
}

export enum CreateNoteType {
  CLINICAL = 'CLINICAL',
  CONTACT = 'CONTACT',
}

export type onAddCommunicationType = (data: {
  messageUuid: string;
  types: ICommunicationType[];
}) => void;

export interface IContentAttribute {
  messageId: string
  hasAttachments: boolean
  importance: string
  parentFolderId: string
  isDeliveryReceiptRequested: any
  isReadReceiptRequested: boolean
  isRead: boolean
  isDraft: boolean
  from: string
  to: To[]
  cc: any[]
  bcc: any[]
  replyTo: any[]
  flag: string
  subject: string
}

export interface To {
  emailAddress: EmailAddress
}

export interface EmailAddress {
  name: string
  address: string
}
export interface IMessagingWindowHeaderActionProps {
  selectedActionView: string;
  contactType: IContactType;
  selectedRowData: any;
  selectedConversation: IConversationData;
  formattedContactData: any;
  onComplete: (
    actionCode?: string,
    actionData?: any,
    selectedActionView?: string
  ) => void;
  selectedTabCode?: string;
}

export interface ISelectedConversationResponse {
  conversationData: IConversationData[];
  lastMessage: ILastConversationMsgData;
  conversationMessagesData: IConversationMessageData;
  stickyNotes?: IStickyNoteData[];
}

export interface ILastConversationMsgData {
  lastMsg: ILastConversationMsg[];
}

export interface ILastConversationMsg {
  id: number;
  uuid: string;
}

export interface IMessagingWindowCommonV2Ref {
  handleCreateConversationTask: (
    taskId: string,
    messageList?: string[]
  ) => Promise<void>;

  onMessageSendAction: (
    msgText: string,
    msgTempData: IMessageRespData,
    parentMessage?: IReplyMessageObject
  ) => void;
  onMessageDelete: (id: any) => void;
  onAttachmentDelete: (data: any) => void;
  handleCommunicationTypeSaveOnMessageList: (messageUuids: any[]) => void;
  checkCommunicationTypeAssignedToSelectedMessages: (
    messageUuids: string[]
  ) => boolean;
  getMessages: (data: IGetMessages) => void;
  onMessageUpdateAfterEditMessageByCurrentUser: (
    data: IMessageCreated,
    shouldSendNotification?: boolean
  ) => void;
  checkLastNonInternalMessageData: () => string;
}

export interface ILastEmailMessageByconversationId {
  messages: [
    {
      uuid: string;
    }
  ];
}
