import {Table, Spin} from 'antd';
import {View} from 'native-base';
import {Dimensions} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {getMemberStatusColumns} from './Helper';
import {TableWrapper} from '../../../common/TableWrapper';
import {IMemberStatusData, IMemberStatusTable} from './interface';


export const MemberStatusSettingTable = (props: IMemberStatusTable) => {
  const { data, onActionPerformed , loading, pageSize, pageNo, total } = props;
  const screenHeight = Dimensions.get('window').height;

  return (
    <View flex={1}>
      <TableWrapper
        pagination={{
          pageSize: pageSize,
          current: pageNo,
          total: total,
          onChange(page, pageSize) {
            if (props?.onPagination) {
              props.onPagination(page, pageSize);
            }
          },
          showSizeChanger: true,
        }}
        hideOnSinglePage={false}
        customStyle={{zIndex: 999}}
      >
        <Table
          style={{
            backgroundColor: '#fff',
          }}
          scroll={{x: 700, y: screenHeight - 260}}
          columns={getMemberStatusColumns(
            onActionPerformed
          )}
          rowClassName={(record, index) =>
            index % 2 == 0 ? 'table-row-light' : ''
          }
          loading={{
            spinning: loading,
            indicator: (
              <View style={{
                justifyContent: 'center',
                height: '100%'
              }}>
                <Spin/>
              </View>
            ),
          }}
          dataSource={data}
          pagination={false}
          rowKey={(record: IMemberStatusData) => record.key}
        />
      </TableWrapper>
    </View>
  );
};
