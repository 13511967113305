import {useContext, useState} from 'react';
import {ADDITIONAL_DATA_CODE} from '../../CareManagementBilling/CareManagementConstants';
import {useCareProgramStatus} from '../../ContactCareProgram/ContactCareProgramView/hooks/useCareProgramStatus';
import {ICareProgramAdditionalData} from '../interface';
import {IKeyPointData} from './useCareProgramAdditionalData';
import {getUserUUID} from '../../../../utils/commonUtils';
import {
  CARE_TEAM,
  CARE_TEAM_MEMBER_TYPE,
  MLOV_CATEGORY,
} from '../../../../constants/MlovConst';
import {getMlovListFromCategory} from '../../../../utils/mlovUtils';
import {getMlovIdFromCode} from '../../../../utils/mlovUtils';
import {
  formatKeyPointData,
  getWhereConditionForUpcomingAppointmentData,
} from '../CareManagementUtils';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {GET_CONTACT_CARE_TEAM_MEMBER_TYPE_BY_MEMBER_TYPE_ID} from '../../../../services/CareTeam/CareTeamQueries';
import {useLazyQuery} from '@apollo/client';
import {GET_FUTURE_APPOINTMENT_DATA} from '../../../../services/Appointment/AppointmentQueries';
import { IFormCommonData } from '../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import { CARE_PROGRAM_STEP_TYPE } from '../../../common/MemebersView/constant';

export const useCareProgramDashboardData = () => {
  const loggedInUserId = getUserUUID();
  const commonDataContext = useContext(CommonDataContext) as IFormCommonData; 
  const careTeamList =
    getMlovListFromCategory(
      commonDataContext.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.PRIMARY_CARE_TEAM
    ) || [];
  const careTeamTypeId = getMlovIdFromCode(careTeamList, CARE_TEAM.CODE);
  const careManagerTypeId = getMlovIdFromCode(careTeamList, CARE_TEAM.CODE);
  const careTeamMemberTypesList =
    getMlovListFromCategory(
      commonDataContext.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_TEAM_MEMBER_TYPE
    ) || [];
  const pcpUserTypeId = getMlovIdFromCode(
    careTeamMemberTypesList,
    CARE_TEAM_MEMBER_TYPE.PCP
  );
  const careManagerUserTypeId = getMlovIdFromCode(
    careTeamMemberTypesList,
    CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
  );
  const appointmentStatusList =
    getMlovListFromCategory(
      commonDataContext.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];

  const [loading, setLoading] = useState(false);
  const {careProgramStatusList} = useCareProgramStatus();

  const [getContactCareTeamMemberTypeByMemberTypeId] = useLazyQuery(
    GET_CONTACT_CARE_TEAM_MEMBER_TYPE_BY_MEMBER_TYPE_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [getFutureAppointmentData] = useLazyQuery(GET_FUTURE_APPOINTMENT_DATA, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const getPatientAdditionalData = async (
    params: ICareProgramAdditionalData
  ) => {
    try {
      const {contactUuid, additionalDataCodes, appointmentStartDate, userMap} =
        params;
      setLoading(true);
      const results: Partial<Record<ADDITIONAL_DATA_CODE, any>> = {};
      const promiseList: Promise<any>[] = [];
      let pcpAndAppointmentPromise: Promise<void> | null = null;
      let pcpUserId: string | undefined;
      let careManagerUserId: string | undefined;
      let appointmentData: any;

      if (
        additionalDataCodes.includes(
          ADDITIONAL_DATA_CODE.PCP_AND_CARE_MANAGER
        ) ||
        additionalDataCodes.includes(ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT) ||
        additionalDataCodes.includes(ADDITIONAL_DATA_CODE.CARE_MANAGER)
      ) {
        pcpAndAppointmentPromise = (async () => {
          const careTeamData = await getContactCareTeamMemberTypeByMemberTypeId(
            {
              variables: {
                contactId: contactUuid,
                careTeamTypeId: careTeamTypeId,
                careTeamMemberTypeId: [pcpUserTypeId],
              },
            }
          );
          const careManagerData = await getContactCareTeamMemberTypeByMemberTypeId(
            {
              variables: {
                contactId: contactUuid,
                careTeamTypeId: careManagerTypeId,
                careTeamMemberTypeId: [ careManagerUserTypeId],
              },
            }
          );

          results[ADDITIONAL_DATA_CODE.PCP_AND_CARE_MANAGER] =
            careTeamData?.data;

          results[ADDITIONAL_DATA_CODE.CARE_MANAGER] =
            careManagerData?.data;
          const pcpUserData =
            careTeamData?.data?.careTeams?.[0]?.careTeamMemberType?.find(
              (careTeamMemberType: any) =>
                careTeamMemberType?.memberType?.code ===
                CARE_TEAM_MEMBER_TYPE.PCP
            );

          pcpUserId = pcpUserData?.userId;

          if (pcpUserId) {
            const whereCondition = getWhereConditionForUpcomingAppointmentData(
              pcpUserId,
              contactUuid,
              appointmentStatusList,
              appointmentStartDate
            );
            const futureAppointmentData = await getFutureAppointmentData({
              variables: {whereCondition: whereCondition},
            });
            appointmentData = futureAppointmentData?.data;
            results[ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT] = appointmentData;
          }
        })();
        promiseList.push(pcpAndAppointmentPromise);
      }

      if (promiseList.length > 0) {
        await Promise.allSettled(promiseList);
      }
      setLoading(false);

      const keyPoints: IKeyPointData[] = [];
      additionalDataCodes.forEach((code) => {
        const keyPoint = formatKeyPointData({
          dataCode: code,
          data: results[code],
          careProgramStatusList,
          userMap,
          loggedInUserId,
        });
        if (keyPoint) {
          keyPoints.push(keyPoint);
        }
      });
      return keyPoints;
    } catch (error) {
      return {};
    }
  };

  return {
    loading,
    getPatientAdditionalData,
  };
};
