import { StyleProp, View, ViewStyle } from 'react-native';
import { Colors } from '../../../styles/Colors';

const Divider = (props: {
  height?: number | string;
  width?: number | string;
  marginHorizontal?: number | string;
  backgroundColor?: string;
  customStyles?: StyleProp<ViewStyle>;
}) => {
  return (
    <View
      style={{
        width: props?.width || 0.5,
        height: props?.height || 16,
        marginHorizontal: props?.marginHorizontal || 12,
        backgroundColor: props.backgroundColor || Colors.FoldPixel.GRAY150,
        ...(props?.customStyles ? {customStyles: props?.customStyles} : {}),
      }}
    />
  );
};

export default Divider;
