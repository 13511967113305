import {useState, useEffect, useRef, useCallback} from 'react';
import {Divider} from 'antd';
import {Box} from 'native-base';
import ListingHeader from './ListingHeader/ListingHeader';
import {PatientData, PatientListingProps} from '../interfaces';
import PatientListingTable from './PatientListing/PatientListingTable';
import PatientAddSection from './PatientAddSection/PatientAddSection';
import {cssPropertiesAntD} from '../style';
import {Colors} from '../../../../../styles';
import {
  DayOptimizerSelectedViewCode,
  DayOptimizerViewAction,
} from '../DayOptimizerConstants';
import Stack from '../../../LayoutComponents/Stack';
import {View, useWindowDimensions} from 'react-native';
import {SidecarInsightsDrawer} from '../../../../SideCar/SidecarInsightsDrawer';
import {withMiniContactViewHOC} from '../../../../MiniContactViewHOC';
import {patientDashboardStyles} from './PatientDashboardStyles';

const PatientDashboard = (props: PatientListingProps) => {
  const {isScheduleManagerVisible} = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const {height} = useWindowDimensions();
  const containerHeight = height - 82;
  const [showAddSection, setShowAddSection] = useState<boolean>(false);
  const [componentState, setComponentState] = useState<{
    selectedViewCode: DayOptimizerSelectedViewCode | undefined;
    selectedPatient: PatientData | undefined;
  }>({
    selectedViewCode: undefined,
    selectedPatient: undefined,
  });

  const isPatientDetailViewVisible =
    componentState?.selectedViewCode ===
    DayOptimizerSelectedViewCode.PATIENT_DETAIL_VIEW;

  const handleViewAction = (
    action: DayOptimizerViewAction,
    patient?: PatientData
  ) => {
    switch (action) {
      case DayOptimizerViewAction.SHOW_PATIENT_DETAIL_VIEW:
        setComponentState((prev) => ({
          ...prev,
          selectedViewCode: DayOptimizerSelectedViewCode.PATIENT_DETAIL_VIEW,
          selectedPatient: patient,
        }));
        break;
      case DayOptimizerViewAction.CLEAR_VIEW:
        setComponentState((prev) => ({
          ...prev,
          selectedViewCode: undefined,
          selectedPatient: undefined,
        }));
        break;
      case DayOptimizerViewAction.REDIRECT_PATIENT_VIEW:
        if (patient?.patientId) {
          props?.openContactInWindowOrOpenContactIdDrawer?.(patient?.patientId);
        }
        break;
    }
  };

  const onAddToList = (val = true) => {
    setShowAddSection(val);
  };

  return (
    <div
      style={{
        width: isScheduleManagerVisible ? '78%' : '100%',
        marginRight: '6px',
        overflow: 'hidden',
      }}
      className="day-op animate-scheduler"
    >
      <Box
        ref={containerRef}
        overflowY={'hidden'}
        borderRadius={12}
        borderColor={Colors.FoldPixel.GRAY150}
        backgroundColor={'white'}
        borderWidth={0.5}
        width={'100%'}
        position="relative"
      >
        {showAddSection ? (
          <PatientAddSection
            containerHeight={containerHeight}
            onClose={() => onAddToList(false)}
          />
        ) : (
          <Box>
            <ListingHeader onAddToList={onAddToList} />
            <Divider style={cssPropertiesAntD.patientListingDivider} />
            <Stack direction="row">
              <View style={{flex: isPatientDetailViewVisible ? 0.5 : 1}}>
                <PatientListingTable
                  isScheduleManagerVisible={isScheduleManagerVisible}
                  containerHeight={containerHeight}
                  onViewAction={handleViewAction}
                  isPatientDetailViewVisible={isPatientDetailViewVisible}
                />
              </View>
              {isPatientDetailViewVisible && (
                <View style={patientDashboardStyles.patientDetailView}>
                  <SidecarInsightsDrawer
                    contactId={componentState?.selectedPatient?.patientId || ''}
                    reasonForPrioritization={componentState?.selectedPatient?.reasonForPrioritisation}
                    reasonForPrioritizationQA={componentState?.selectedPatient?.reasonsQA}
                    onClose={() => {
                      handleViewAction(DayOptimizerViewAction.CLEAR_VIEW);
                    }}
                    isFoldView={true}
                    selectedAction={''}
                    isInsightsDrawerVisible={true}
                  />
                </View>
              )}
            </Stack>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default withMiniContactViewHOC(PatientDashboard);
