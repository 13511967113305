import {Form, Modal, Select} from 'antd';
import {Button, View} from 'native-base';
import {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {StyleSheet, Text, TextInput} from 'react-native';
import {BUTTON_TYPE} from '../../../../constants';
import {Colors} from '../../../../styles/Colors';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {ICreateMemberStatusHistoryParams} from '../../../RightSideContainer/Contacts/MemberStatus/interface';
import {
  getMemberStatusData,
  updateContactStatus,
} from '../../../RightSideContainer/Contacts/MemberStatus/MemberStatusService';
import {getFormattedMemberStatusData} from '../../../RightSideContainer/Contacts/MemberStatus/MemberStatusUtils';
import {
  IContact,
  IMemberStatus,
} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';

const statusStyles = StyleSheet.create({
  statusContainer: {
    paddingHorizontal: 8,
    marginHorizontal: 2,
    borderRadius: 16,
    marginTop: 5,
  },
});

interface MemberStatusListProps {
  contactData?: IContact;
  contactId?: number;
  showMemberStatus?: boolean;
}

export type IMemberStatusRef = {
  [index in IMemberStatusFormKeys]: HTMLInputElement;
};

export type IMemberStatusFormKeys = 'name' | 'description' | 'color';

export const MemberStatusList = (props: MemberStatusListProps) => {
  const [stateData, setStateData] = useState({
    memberStatuses: [] as IMemberStatus[],
    isMemberStatusChanged: false,
    updatedStatusId: '',
    oldStatus: props?.contactData?.memberStatus?.name,
    currentStatus: props?.contactData?.memberStatus?.name,
    newStatus: '',
    loading: false,
  });

  const intl = useIntl();
  const [form] = Form.useForm();
  const memberStatusRef = useRef<IMemberStatusRef>({} as any);

  const updateStatus = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });

    const bodyData: ICreateMemberStatusHistoryParams = {
      body: {
        contactUuid: props?.contactData?.uuid,
        statusId: stateData?.updatedStatusId,
        previousStatusId: props?.contactData?.memberStatus?.id,
        reason: memberStatusRef.current['description'].value,
      },
    };

    try {
      const memberStatus = await updateContactStatus(bodyData);

      setStateData((prev) => ({
        ...prev,
        currentStatus: memberStatus?.data?.name,
        isMemberStatusChanged: false,
        loading: false,
      }));
    } catch (error: any) {
      setStateData((prev) => {
        return {
          ...prev,
          memberStatusData: [],
          isMemberStatusChanged: false,
          loading: false,
        };
      });
    }
  };

  const getMemberStatus = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const params = {
      searchString: '',
    };
    try {
      const response = await getMemberStatusData(params);
      if (response?.data?.items?.length) {
        const MemberStatus = response?.data?.items;
        const formattedData = getFormattedMemberStatusData(MemberStatus);
        setStateData((prev) => {
          return {
            ...prev,
            memberStatuses: formattedData,
            loading: false,
          };
        });
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            memberStatusData: [],
            loading: false,
          };
        });
      }
    } catch (error: any) {
      setStateData((prev) => {
        return {
          ...prev,
          memberStatusData: [],
          loading: false,
        };
      });
    }
  };

  useEffect(() => {
    getMemberStatus();
  }, []);

  return (
    <View>
      {stateData.isMemberStatusChanged && (
        <Modal
          title="Change Status?"
          open={stateData.isMemberStatusChanged}
          footer={null}
          bodyStyle={{
            paddingTop: 12,
            zIndex: 9999999999,
          }}
          closeIcon={<></>}
          maskClosable={false}
          destroyOnClose
        >
          <Text>
            {intl.formatMessage({id: 'memberStatusChange'})} from{' '}
            {stateData.oldStatus} to {stateData.newStatus}
          </Text>

          <Form
            className="custom-form"
            form={form}
            layout="vertical"
            labelCol={{
              style: {
                paddingLeft: 3,
              },
            }}
          >
            <TextInput
              multiline
              numberOfLines={6}
              style={{
                borderWidth: 1,
                borderColor: Colors.Custom.Gray200,
                borderRadius: 6,
                width: '100%',
                paddingLeft: 10,
                paddingTop: 10,
              }}
              ref={(ref) =>
                (memberStatusRef.current['description'] = ref as any as any)
              }
              placeholder="Enter Reason For Change (Optional)"
              placeholderTextColor={Colors.Custom.Gray300}
            />
          </Form>
          <Button.Group gap={'0.5'} justifyContent={'flex-end'}>
            <FoldButton
              customProps={{
                btnText: 'Confirm',
                isDisableOnPress: true,
              }}
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress() {
                  updateStatus();
                },
              }}
            />
            <FoldButton
              customProps={{
                btnText: 'Cancel',
              }}
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress() {
                  setStateData((prev) => ({
                    ...prev,
                    isMemberStatusChanged: false,
                  }));
                },
              }}
            />
          </Button.Group>
        </Modal>
      )}
      <View flex={1} style={statusStyles.statusContainer}>
        <Select
          size="large"
          allowClear={false}
          showSearch={false}
          labelInValue={true}
          filterOption={false}
          placeholder={'Select Status'}
          value={stateData.currentStatus}
          onChange={(value: any) => {
            setStateData((prevState) => ({
              ...prevState,
              isMemberStatusChanged: true,
              oldStatus: props?.contactData?.memberStatus?.name || '',
              newStatus: value?.value,
              updatedStatusId: value?.key,
            }));
          }}
          loading={stateData?.loading}
          style={{height: '40px', width: '150px'}}
        >
          {stateData?.memberStatuses?.map((item) => {
            return (
              <Select.Option key={item.id} value={item.name}>
                {item.name}
              </Select.Option>
            );
          })}
        </Select>
      </View>
    </View>
  );
};
