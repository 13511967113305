import { useLazyQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { getMlovListFromCategory, getMlovIdFromCode } from '../../utils/mlovUtils';
import { MLOV_CATEGORY } from '../../constants';
import { CommonDataContext } from '../../context/CommonDataContext';
import { CARE_TEAM, CARE_TEAM_MEMBER_TYPE } from '../../constants/MlovConst';
import { GET_CONTACT_CARE_TEAM_MEMBER_TYPE_BY_MEMBER_TYPE_ID } from '../../services/CareTeam/CareTeamQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../constants/Configs';
import { UserQueries } from '../../services';
import { IUser } from '../../Interfaces';

interface PCPData {
  userId: string;
  name?: string;
  pcpUserData?: any;
  careManagerUserData?: any;
}

export const useContactPcpAndCareManager = (contactUuid: string) => {
    const commonDataContext = useContext(CommonDataContext);
  const [state, setState] = useState<PCPData | null>(null);
  const [loading, setLoading] = useState(false);

  const [getCareTeamMemberType] = useLazyQuery(
    GET_CONTACT_CARE_TEAM_MEMBER_TYPE_BY_MEMBER_TYPE_ID,
    {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
    }
  );

  const [getUsersByUserUuidsAPI] = useLazyQuery<{
    accountUsers: {
      user: IUser;
    }[];
  }>(UserQueries.GetUsersByUserUuids, {
    fetchPolicy: 'no-cache',
  });

  const fetchPCPData = async () => {
    try {
      setLoading(true);
      const careTeamList = getMlovListFromCategory(
        commonDataContext.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.PRIMARY_CARE_TEAM
      ) || [];
      
      const careTeamTypeId = getMlovIdFromCode(careTeamList, CARE_TEAM.CODE);
      const careTeamMemberTypesList = getMlovListFromCategory(
        commonDataContext.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.CARE_TEAM_MEMBER_TYPE
      ) || [];

      const careManagerTypeId = getMlovIdFromCode(
        careTeamMemberTypesList,
        CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
      );
      
      const pcpUserTypeId = getMlovIdFromCode(
        careTeamMemberTypesList,
        CARE_TEAM_MEMBER_TYPE.PCP
      );

      const careTeamResponse = await getCareTeamMemberType({
        variables: {
          contactId: contactUuid,
          careTeamTypeId: careTeamTypeId,
          careTeamMemberTypeId: [pcpUserTypeId, careManagerTypeId],
        },
      });
      

      const pcpUserData = careTeamResponse?.data?.careTeams?.[0]?.careTeamMemberType?.find(
        (member: any) => member?.memberType?.code === CARE_TEAM_MEMBER_TYPE.PCP
      );

      const careManagerUserData = careTeamResponse?.data?.careTeams?.[0]?.careTeamMemberType?.find(
        (member: any) => member?.memberType?.code === CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
      );

      if (pcpUserData?.userId || careManagerUserData?.userId) {
        const userUuids = [
          ...(pcpUserData?.userId ? [pcpUserData.userId] : []),
          ...(careManagerUserData?.userId ? [careManagerUserData.userId] : [])
        ];
        const userResponse = await getUsersByUserUuidsAPI({
          variables: {
            userUuids: userUuids
          }
        });

        const pcpUserDetails = userResponse?.data?.accountUsers?.find(
          (item: any) => item?.user?.uuid === pcpUserData?.userId
        )?.user;
        const careManagerUserDetails = userResponse?.data?.accountUsers?.find(
          (item: any) => item?.user?.uuid === careManagerUserData?.userId
        )?.user;

        setState({
          userId: pcpUserData?.userId,
          name: pcpUserDetails?.name,
          pcpUserData: pcpUserDetails,
          careManagerUserData: careManagerUserDetails
        });
      } else {
        setState(null);
      }
    } catch (error) {
      console.error('Error fetching PCP data:', error);
      setState(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (contactUuid) {
      fetchPCPData();
    }
  }, [contactUuid]);

  return { 
    state,
    loading, 
    refetch: fetchPCPData,
    pcpName: state?.pcpUserData?.name || '',
    pcpId: state?.pcpUserData?.userId || '',
    careManagerName: state?.careManagerUserData?.name || ''
  };
};