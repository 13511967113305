import {Drawer, Form, Tooltip} from 'antd';
import {Spinner, useToast} from 'native-base';
import {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {TextInput, View} from 'react-native';
import {BUTTON_TYPE} from '../../../../constants';
import {Colors} from '../../../../styles/Colors';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import {
  IAddMemberStatus,
  IAddMemberStatusState,
  IMemberStatusData,
  IMemberStatusDataErrors,
  IMemberStatusFormKeys,
  IMemberStatusRef,
} from './interface';
import {MEMBER_STATUS_REF_KEYS} from './MemberStatusConst';
import {
  getMemberStatusBodyData,
  validateMemberStatusData,
} from './MemberStatusUtils';
import {ColorSelector} from '../Tags/TagsModal/ColorSelector';
import {tagColorList} from '../Tags/TagsModal';

export const AddMemberStatusDrawer = (props: IAddMemberStatus) => {
  const {isDrawerOpen, onDrawerClose, selectedMemberStatus, title} = props;
  const toast = useToast();

  const [stateData, setStateData] = useState<IAddMemberStatusState>({
    isDrawerOpen: isDrawerOpen,
    loading: false,
    errors: {} as IMemberStatusDataErrors,
    selectedType: '',
    selectedMemberStatus: {} as IMemberStatusData,
    addUpdateMemberStatusLoading: false,
    hoveredIndex: null,
    color: '#009688',
    visible: false,
    show: false,
  });

  const memberStatusRef = useRef<IMemberStatusRef>({} as any);
  const intl = useIntl();

  const validateDataOnSubmit = () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true
      };
    });
    const validate = validateMemberStatusData(memberStatusRef, stateData);
    if (validate.validate) {
      props?.onSubmit(getMemberStatusBodyData(memberStatusRef, stateData));
    }
    setStateData((prev) => {
      return {
        ...prev,
        errors: validate.errors,
      };
    });
  };

  const onDrawerVisibleChange = (isOpen: boolean) => {
    setStateData((prev) => {
      return {
        ...prev,
        isDrawerOpen: isOpen,
        loading: false
      };
    });
  };

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedMemberStatus: selectedMemberStatus,
      };
    });
    memberStatusRef.current['name'].value = title || '';
    MEMBER_STATUS_REF_KEYS.forEach((singleKey: IMemberStatusFormKeys) => {
      if (memberStatusRef.current[singleKey] && selectedMemberStatus?.id) {
        memberStatusRef.current[singleKey].value =
          selectedMemberStatus[singleKey] || '';
      }
    });
  }, [selectedMemberStatus]);

  useEffect(() => {
    onDrawerVisibleChange(isDrawerOpen);
  }, [isDrawerOpen]);

  const [form] = Form.useForm();
  return (
    <Drawer
      width={'720px'}
      open={stateData?.isDrawerOpen}
      onClose={() => {
        onDrawerVisibleChange(false);
        onDrawerClose?.();
      }}
      style={{
        borderRadius: 10,
      }}
      title={
        <View
          style={{
            paddingHorizontal: 26,
            marginTop: 16,
          }}
        >
          <ModalActionTitle
            title={'addMemberStatus'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'close',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onDrawerVisibleChange(false);
                  onDrawerClose?.();
                },
              },
              {
                show: true,
                id: 2,
                btnText: selectedMemberStatus?.id ? 'update' : 'create',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                rightIcon: stateData?.loading && <Spinner />,
                onClick: () => {
                  validateDataOnSubmit();
                },
                isLoading: stateData?.addUpdateMemberStatusLoading,
              },
            ]}
          />
        </View>
      }
    >
      <View
        style={{
          paddingHorizontal: 26,
        }}
      >
        <Form
          className="custom-form"
          form={form}
          layout="vertical"
          labelCol={{
            style: {
              paddingLeft: 3,
            },
          }}
        >
          <Form.Item
            label="Status Name"
            tooltip={{
              title: 'Required field',
              icon: (
                <Tooltip title="Required field">
                  <span className="required-dot"></span>
                </Tooltip>
              ),
            }}
            validateStatus={stateData?.errors?.name ? 'error' : ''}
          >
            <TextInput
              style={{
                borderWidth: 1,
                borderColor: Colors.Custom.Gray200,
                borderRadius: 6,
                height: 40,
                width: '100%',
                paddingLeft: 10,
              }}
              ref={(ref) =>
                (memberStatusRef.current['name'] = ref as any as any)
              }
              placeholder="Enter attribute name"
              placeholderTextColor={Colors.Custom.Gray300}
            />
            {stateData?.errors?.name && (
              <div style={{color: Colors?.Custom?.ErrorColor}}>
                {stateData?.errors?.name}
              </div>
            )}
          </Form.Item>
          <Form.Item
            label="Status Description"
            tooltip={{
              title: 'Required field',
              icon: (
                <Tooltip title="Required field">
                  <span className="required-dot"></span>
                </Tooltip>
              ),
            }}
            validateStatus={stateData?.errors?.description ? 'error' : ''}
          >
            <TextInput
              multiline
              numberOfLines={6}
              style={{
                borderWidth: 1,
                borderColor: Colors.Custom.Gray200,
                borderRadius: 6,
                width: '100%',
                paddingLeft: 10,
                paddingTop: 10,
              }}
              ref={(ref) =>
                (memberStatusRef.current['description'] = ref as any as any)
              }
              placeholder="Enter Description here"
              placeholderTextColor={Colors.Custom.Gray300}
            />
            {stateData?.errors?.description && (
              <div style={{color: Colors?.Custom?.ErrorColor}}>
                {stateData?.errors?.description}
              </div>
            )}
          </Form.Item>
          <Form.Item
            label="Status Color"
            tooltip={{
              title: 'Required field',
              icon: (
                <Tooltip title="Required field">
                  <span className="required-dot"></span>
                </Tooltip>
              ),
            }}
            validateStatus={stateData?.errors?.color ? 'error' : ''}
          >
            <ColorSelector
              color={stateData.color}
              title={'memberStatusColor'}
              colorList={tagColorList}
              onBtnPress={(color: string) =>
                setStateData((prev) => {
                  return {
                    ...prev,
                    color: color,
                  };
                })
              }
              onChangeHex={(text) =>
                setStateData((prev) => {
                  return {
                    ...prev,
                    color: '#' + text,
                  };
                })
              }
            />
          </Form.Item>
        </Form>
      </View>
    </Drawer>
  );
};
