import {useLazyQuery} from '@apollo/client';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {
  GET_FORM_CATEGORY_BY_CODE, GET_FORM_CATEGORY_ID_BY_CODES, SEARCH_FORMS_WITH_LOCATION
} from '../../../../../../services/Forms/FormsQueries';
import {FORM_CATEGORY_TYPES} from '../../../../Forms/FormsConstants';
import {Checkbox, Drawer, Skeleton as AntdSkeleton} from 'antd';
import {Skeleton} from 'native-base';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import {Colors} from '../../../../../../styles/Colors';
import {BUTTON_TYPE} from '../../../../../../constants/StringConst';
import {getAccountUUID} from '../../../../../../utils/commonUtils';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {ScrollView, Text} from 'native-base';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import Stack from '../../../../../common/LayoutComponents/Stack';
import {AddCareProgramStepInput, CareProgramStepPayload, IContactCareProgramStep, IFormsByCategory} from '../../../interface';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {MLOV_CATEGORY} from '../../../../../../constants';
import {getMlovListFromCategory} from '../../../../../../utils/mlovUtils';
import {ADDITIONAL_ATTRIBUTE_KEY, CARE_PROGRAM_STEP_TYPE} from '../../../../../common/MemebersView/constant';
import {useContactCareProgramContext} from '../../ContactCareProgram.context';
import { SectionList, TextInput, View } from 'react-native';
import { debounce } from 'lodash';
import { getAttributeValueFromStepJson } from '../../../utils';

export interface IAddAssessmentProps {
  onCancel: () => void;
  onAdd: (payload: AddCareProgramStepInput) => Promise<void>;
  contactAccountLocationId: string | undefined;
  step: IContactCareProgramStep;
}



const AddAssessment = (props: IAddAssessmentProps): JSX.Element => {
  const {onCancel, onAdd, contactAccountLocationId, step: parentStep} = props;
  const context = useContext(CommonDataContext);
  const tenantId = getAccountUUID();

  const {state: {
    contactCareProgramDetails
  }} = useContactCareProgramContext()

  const careProgramStepTypeList =  getMlovListFromCategory(
    context.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_PROGRAM_STEP_TYPE
  );

  const assessmentTypeId = useMemo(
    () =>
      careProgramStepTypeList?.find(
        (item) => item.code === CARE_PROGRAM_STEP_TYPE.ASSESSMENT
      )?.id,
    [careProgramStepTypeList.length]
  );

  const defaultAssessmentCategoryList = getAttributeValueFromStepJson(parentStep, ADDITIONAL_ATTRIBUTE_KEY.DEFAULT_ASSESSMENT_CATEGORIES) as string[];

  const [
    getFormCategoriesByCodes,
    {loading: formCategoriesByCodesLoading, error: formCategoriesByCodesError}
  ] = useLazyQuery(GET_FORM_CATEGORY_ID_BY_CODES, {
    variables: {
      categoryCode: defaultAssessmentCategoryList,
      tenantId: tenantId,
    },
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const [state, setState] = useState<{
    status: 'loading' | 'error' | 'success';
    selectedFormIds: Set<string>;
    formsByCategory: IFormsByCategory[];
  }>({
    status: 'loading',
    selectedFormIds: new Set(),
    formsByCategory: [],
  });
  const [searchText, setSearchText] = useState('');

  const debouncedSetSearchText = useMemo(
    () => debounce((value: string) => {
      fetchFormByCategoryId(value);
    }, 500),
    []
  );

  const onSearch = (value: string) => {
    setSearchText(value);
    debouncedSetSearchText(value)
  }
  const [getForms, {loading: formDataLoading, error: formDataError}] =
    useLazyQuery(SEARCH_FORMS_WITH_LOCATION, {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    });

  const fetchFormByCategoryId = useCallback(async (searchText: string) => {
    try {
      setState({
        ...state,
        status: 'loading',
      });
      const formCategoryResponses = await getFormCategoriesByCodes();

      const formsByCategoryMap: Record<string, any> = {};

      const formCategoryIds: string[] = [];

      formCategoryResponses?.data?.formCategories?.forEach((formCategory: {id: string, code: string, name: string}) => {
        formCategoryIds.push(formCategory.id);
        formsByCategoryMap[formCategory.id] = {
          id: formCategory.id,
          code: formCategory.code,
          name: formCategory.name,
          forms: [],
        }
      });

      const totalCategoryCount = formCategoryIds.length;
      const limitByCategoryCount = 200 / totalCategoryCount;

      const formResponse = await getForms({
        variables: {
          params: {
            categoryIds: formCategoryIds,
            name: `%${searchText}%`,
            offset: 0,
            limit: 200,
            locationIds: contactAccountLocationId
              ? [contactAccountLocationId]
              : undefined,
          },
        },
      });
      const forms = formResponse?.data?.searchForms?.forms;
      forms?.forEach((form: any) => {
        formsByCategoryMap[form.formCategory.id].forms.push(form);
      });

      setState({
        ...state,
        status: 'success',
        formsByCategory: Object.values(formsByCategoryMap),
      });

    } catch (e) {
      setState({
        ...state,
        status: 'error',
      });
    }
  }, []);

  useEffect(() => {
    fetchFormByCategoryId(searchText);
  }, []);

  const onSelectForms = (e: any, form: {id: string}) => {
    setState((prev) => {
      const set = new Set(prev.selectedFormIds);
      if (e.target.checked) {
        set.add(form.id);
      } else {
        set.delete(form.id);
      }
      return {...prev, selectedFormIds: set};
    });
  };

  const getSelectedForms = () => {
    const selectedForms: any[] = [];
    state.formsByCategory.forEach((category) => {
      category.forms.forEach((form) => {
        if (state.selectedFormIds.has(form.id)) {
          selectedForms.push(form);
        }
      })
    })
    return selectedForms;
  }

  const onAddAssessment = async () => {
    const selectedForms = getSelectedForms();

    if (
      !assessmentTypeId ||
      !contactCareProgramDetails?.id ||
      !contactCareProgramDetails?.contactId ||
      !parentStep?.id
    ) {
      return;
    }

    const careProgramSteps: CareProgramStepPayload[] = selectedForms.map(
      (form) => ({
        isRequired: false,
        // Assessment typeId
        careProgramStepTypeId: assessmentTypeId as string,
        title: form.name,
        parentStepId: parentStep.id,
        additionalAttributes: [
          {
            attributeKey: 'formId',
            attributeValue: {
              valueString: form.id,
            },
            attributeValueDataTypeCode: 'string',
          },
          {
            attributeKey: 'supportedActions',
            attributeValue: {
              valueJson: ['SEND_TO_PATIENT', 'DELETE'],
            },
            attributeValueDataTypeCode: 'json',
            isDeleted: false,
          },
        ],
      })
    );

    const payload: AddCareProgramStepInput = {
      contactId: contactCareProgramDetails.contactId,
      contactCareProgramId: contactCareProgramDetails.id,
      careProgramSteps,
    };
    await onAdd(payload);
  }

  const renderFormItem = (formItem: {id: string, name: string}) => {
    return (
      <Stack direction="row" space={2} style={{padding: 2, alignItems: 'center'}}>
        <Checkbox
          value={formItem.id}
          onChange={(e) => onSelectForms(e, formItem)}
        />
        <Text
          ml={2}
          color={Colors.FoldPixel.GRAY400}
          isTruncated
          fontSize={14}
        >
          {formItem.name}
        </Text>
      </Stack>
    )
  }

  const renderFormCategory = (category: string) => {
    return (
      <Text
        fontSize={14}
        color={Colors.FoldPixel.GRAY300}
      >
        {category}
      </Text>
    )
  }

  const getSearchBar = () => {
    return (
      <TextInput
        placeholder="Search Assesments"
        onChangeText={onSearch}
        value={searchText}
        style={{
          borderColor: Colors.FoldPixel.GRAY250,
          borderWidth: 0.5,
          borderRadius: 4,
          padding: 8,
        }}
      />
    )
  }

  const getFormListByCategory = () => {
    switch (state.status) {
      case 'loading':
        return (
          <Stack direction="column" space={5}>
            {[1, 2,3,4,5].map((item) => (
              <Stack key={item} direction="row" space={4} style={{padding: 2, alignItems: 'center'}}>
                <Skeleton h={6} w={6} rounded="full" />
                <Skeleton h={6} w="350px" rounded="sm" />
              </Stack>
            ))}
          </Stack>
        );
      case 'error':
        return <DisplayText textLocalId="apiErrorMessage" />;
      case 'success':
        return (
          <SectionList
            sections={state.formsByCategory
              .map((category) => ({
                title: category.name,
                data: category.forms,
              }))
              .filter((category) => category.data.length)}
            renderItem={({item}) => renderFormItem(item)}
            renderSectionHeader={({section}) =>
              renderFormCategory(section.title)
            }
            SectionSeparatorComponent={({leadingItem}) =>
              leadingItem ? (
                <View style={{height: 16}} />
              ) : (
                <View style={{height: 8}} />
              )
            }
            ItemSeparatorComponent={() => <View style={{height: 4}} />}
          />
        );
    }
  };
  const renderFormsAndSearchBar = () => {
    return (
      <Stack direction="column" space={16}>
        {getSearchBar()}
        {getFormListByCategory()}
      </Stack>
    )
  }

  return (
    <Drawer
      className="add-assessment-drawer"
      destroyOnClose
      placement="right"
      open
      closable
      width={'30%'}
      title={
        <ModalActionTitle
          title={'Add Assessment'}
          titleSize={24}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                onCancel?.();
              },
            },
            {
              show: true,
              btnText: 'add',
              size: 'sm',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              isDisabled: state.selectedFormIds.size === 0,
              onClick: onAddAssessment,
            },
          ]}
        />
      }
    >
      {renderFormsAndSearchBar()}
    </Drawer>
  );
};

export default AddAssessment;
