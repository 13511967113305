import { CARE_PROGRAM_STEP_STATUS } from '../../../../../constants/MlovConst';
import {CARE_PROGRAM_STEP_TYPE} from '../../../../common/MemebersView/constant';
import CarePlan from '../../../../PersonOmniView/MiddleContainer/CarePlan/CarePlan';
import {IContactCareProgramStep, IMlov} from '../../interface';
import AssessmentView from './components/AssessmentView';
import CPBillingView from './components/BillingView/CPBillingView';
import DischargeRefferals from './components/DischargeRefferals';
import { useSelectedStepInCareProgram } from './components/hooks/useSelectedStepInCareProgram';
import OutreachView from './components/OutreachView';

export const getContentView = (params: {
  selectedStep: IContactCareProgramStep;
  handleHeaderSubtitle: (subtitle: string) => void;
}): React.FC => {
  const {selectedStep, handleHeaderSubtitle} = params;
  const viewCode = selectedStep?.careProgramStepType?.code;
  const { accountLocationUuid, personData, contactName} = useSelectedStepInCareProgram();
  let ContentView: React.FC;

  const carePlanView: React.FC = () => (
    <CarePlan
      accountLocationUuid={accountLocationUuid ?? ''}
      contactUuid={personData?.uuid ?? ''}
      contactName={personData?.name ?? ''}
      contactId={personData?.id ?? -1}
      personData={personData}
    />
  );

  switch (viewCode) {
    case CARE_PROGRAM_STEP_TYPE.ASSESSMENT:
    case CARE_PROGRAM_STEP_TYPE.USER_ASSESSMENT:
    case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY:
    case CARE_PROGRAM_STEP_TYPE.ANNUAL_VISIT:
    case CARE_PROGRAM_STEP_TYPE.APPOINTMENT:
    case CARE_PROGRAM_STEP_TYPE.CARE_MANAGER_APPOINTMENT:
      ContentView = AssessmentView;
      break;
    case CARE_PROGRAM_STEP_TYPE.BILLING:
      ContentView = CPBillingView;
      break;
    case CARE_PROGRAM_STEP_TYPE.REFERRAL:
      ContentView = DischargeRefferals;
      break;
    case CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH:
    case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY:
    case CARE_PROGRAM_STEP_TYPE.OUTREACH:
      ContentView = () => <OutreachView handleHeaderSubtitle={handleHeaderSubtitle}/>;
      break;
    case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY:
    case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY:
      ContentView = () => <OutreachView handleHeaderSubtitle={handleHeaderSubtitle}/>;
      break;
    case CARE_PROGRAM_STEP_TYPE.CARE_PLAN:
      ContentView = carePlanView;
      break;
    default:
      ContentView = () => <div>No View Found</div>;
  }
  return ContentView;
};
