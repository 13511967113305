import { IMemberStatusFormKeys } from "./interface";

export const MEMBER_STATUS_GET_DATA_LIMIT = 10;
export const MEMBER_STATUS_SELECT_DATA_LIMIT = 100;

export const MEMBER_STATUS_NAME_MAX_CHAR_LIMIT = 80;
export const MEMBER_STATUS_DESCRIPTION_MAX_CHAR_LIMIT = 400;

export const MEMBER_STATUS_REF_KEYS: Array<IMemberStatusFormKeys> = [
  'name',
  'description',
  'color'
];