export enum QM_IMPROVEMENT_NOTATION {
	DECREASE = 'decrease',
	INCREASE = 'increase',
}

export const QUALITY_MEASURE_STORAGE_KEY = {
  MEASURE_DETAILS: 'MEASURE_DETAILS',
};


export const MEASURE_CRITERIA_STATUS = {
  INCOMPLETE: 'Incomplete',
  COMPLETE: 'Complete'
}

export const MEASURE_RESOURCE_TYPE = {
  ENCOUNTER: 'Encounter',
  OBSERVATION: 'Observation',
  MEDICATION_REQUEST: 'MedicationRequest',
  IMMUNIZATION: 'Immunization',
  SERVICE_REQUEST: 'ServiceRequest',
  CONDITION: 'Condition',
  DIAGNOSTIC_REPORT: 'DiagnosticReport',
  PROCEDURE: 'Procedure'
}

export enum evidenceCode {
  LOINC = 'LOINC',
  SNOMED = 'SNOMED',
  RXNORM = 'RxNORM',
  CVX = 'CVX',
  ICD10 = 'ICD10',
  CPT = 'CPT'
}

export enum SearchCode {
  CVX = 'http://hl7.org/fhir/sid/cvx',
  CPT = 'http://www.ama-assn.org/go/cpt',
  FOLD = 'http://fold.health',
  ICD10 = 'ICD10',
  ICD10D = 'http://hl7.org/fhir/sid/icd-10-cm',
  ICD9 = 'ICD9',
  NPI = 'http://hl7.org/fhir/sid/us-npi',
  RXNORM = 'http://www.nlm.nih.gov/research/umls/rxnorm',
  SNOMED = 'http://snomed.info/sct',
  ATHENA = 'athena',
  ELATION = 'elation',
  IDENTIFIER_TYPE = 'http://terminology.hl7.org/CodeSystem/v2-0203',
  LOINC = 'http://loinc.org'
}

export const RESOURCE_TYPE_CODE_MAP: Record<string, string[]> = {
  [MEASURE_RESOURCE_TYPE.OBSERVATION]: [SearchCode.LOINC, evidenceCode.LOINC, SearchCode.SNOMED, evidenceCode.SNOMED],
  [MEASURE_RESOURCE_TYPE.MEDICATION_REQUEST]: [SearchCode.RXNORM, evidenceCode.RXNORM],
  [MEASURE_RESOURCE_TYPE.IMMUNIZATION]: [SearchCode.CVX, evidenceCode.CVX],
  [MEASURE_RESOURCE_TYPE.CONDITION]: [SearchCode.ICD10D, evidenceCode.ICD10, SearchCode.SNOMED, evidenceCode.SNOMED],
  [MEASURE_RESOURCE_TYPE.DIAGNOSTIC_REPORT]: [SearchCode.LOINC, evidenceCode.LOINC, SearchCode.SNOMED, evidenceCode.SNOMED],
  [MEASURE_RESOURCE_TYPE.PROCEDURE]: [SearchCode.CPT, evidenceCode.CPT, SearchCode.SNOMED, evidenceCode.SNOMED],
};


export const CRITERIA_STRING = {
  CRITERIA_INCOMPLETE: 'Criteria Incomplete',
  ALL_CRITERIA_MATCHED: 'All Criteria Complete',
}

export const NAME_MAX_LENGTH = {
  GROUP_NAME: 400,
  GROUP_NAME_SMALL_SCREEN: 145,
  MEASURE_NAME: 200,
  MEASURE_NAME_SMALL_SCREEN: 110,
}

export const MEASURE_CRITERIA_TYPE = {
  DENOMINATOR_EXCLUSION: 'denominator-exclusion',
  NUMERATOR_EXCLUSION: 'numerator-exclusion',
  DENOMINATOR_EXCEPTION: 'denominator-exception',
  NUMERATOR_EXCEPTION: 'numerator-exception',

}