import React from 'react';
import {Popover} from 'antd';
import {IcdConditionsList} from '../../../../../../RafScore/interface';
import {RAFScorePopoverContent} from '../../../../../../RafScore/RAFScorePopoverContent';
import {
  getArrowSvg,
  getCalculatedRafScoreCondition,
  getRafConditionalColors,
} from '../../../DayOptimizerHelper';
import {IContactScore} from '../../../interfaces';
import {fetchICDConditionsList} from '../../useDOPatientListingHeader';
import {View, Text} from 'react-native';
import {SCORE_TYPE} from '../../../../../../../constants';
import {
  getHccScoreData,
  hasValue,
} from '../../../../../../../utils/commonUtils';
import {
  getBackgroundColorCodeForHCC,
  getColorCodeForHCC,
} from '../../../../../../../sidecar/common/SideCardCommonUtils';
import {styles} from '../../../style';
import { Spinner } from 'native-base';

const RAFModal = (props: {
  score: number | null;
  contactScore: IContactScore;
  contactScoreAudit: any;
  patientId: string;
  practiceLocationId: string;
}) => {
  const [ICDConditions, setICDConditions] = React.useState<IcdConditionsList[]>(
    []
  );
  const [isDataFetched, setIsDataFetched] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (open && !isDataFetched) {
      setIsLoading(true);
      fetchICDConditionsList(props.patientId, props.practiceLocationId)
        .then((ICDConditions) => {
          setICDConditions(ICDConditions);
          setIsDataFetched(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [open]);

  const {hccScore, hccScoreValue} = getHccScoreData(
    props?.contactScore,
    props.contactScoreAudit,
    SCORE_TYPE,
    hasValue
  );

  const foreGround = getColorCodeForHCC(hccScore);
  const background = getBackgroundColorCodeForHCC(hccScore);

  const arrowSvg = getArrowSvg({
    rafScoreCondition: hccScore,
    color: foreGround,
  });

  if (typeof props.score !== 'number' || isNaN(props.score)) {
    return <Text style={styles.rafModalText}>{'-'}</Text>;
  }

  return (
    <Popover
      overlayInnerStyle={{padding: 0, borderRadius: 16}}
      style={{borderRadius: 16}}
      placement={'bottom'}
      trigger="click"
      open={open}
      onOpenChange={setOpen}
      content={
        <>
          {isLoading ? (
            <Spinner />
          ) : (
            <RAFScorePopoverContent
              contactScore={props.contactScore}
              contactScoreAudits={props.contactScoreAudit}
              icdConditionsList={ICDConditions}
            />
          )}
        </>
      }
    >
      <div
        style={{
          backgroundColor: background,
          borderRadius: 4,
          padding: 1,
          gap: 4,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          minWidth: '80px',
          width: 'fit-content',
          alignItems: 'center',
          minHeight: '22px',
        }}
      >
        <Text
          style={{
            fontSize: 14,
            minWidth: '52px',
            width: arrowSvg ? undefined : '100%',
            textAlign: 'center',
            fontWeight: '400',
            color: foreGround,
            paddingLeft: arrowSvg ? '6px' : undefined,
          }}
        >
          {hccScoreValue}
        </Text>
        {arrowSvg ? (
          <View style={{paddingRight: '6px'}}>{arrowSvg}</View>
        ) : null}
      </div>
    </Popover>
  );
};

export default RAFModal;
