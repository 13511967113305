import { gql } from '@apollo/client';

export const BOOK_APPOINTMENT = gql`
  mutation BookAppointment($data: [AppointmentInput!]) {
    createBookedAppointments(params: $data) {
      id
      reference
      statusId
    }
  }
`;

export const UPDATE_APPOINTMENT_WORKFLOW = gql`
  mutation updateAppointments($id: uuid, $workflowListObj: jsonb) {
    updateAppointments(
      where: {id: {_eq: $id}}
      _append: {reference: $workflowListObj}
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_PARTICIPANT_STATUS = gql`
  mutation UpdateParticipantStatus($data: AppointmentInput!) {
    updateParticipantStatus(params: $data) {
      appointmentId
    }
  }
`;



export const GET_APPOINTMENT_BY_ID_WITH_GROUP = gql`
      query GetBookedAppointments($appointmentId: String!) {
        appointments: getBookedAppointments(params: {id: $appointmentId}) {
          id
          name
          description
          startDateTime
          endDateTime
          proposedTime
          declinedReason
          isRsvpEnabled
          locationTypeId
          locationType {
            value
            code
          }
          reasonForVisit
          description
          participants {
            id
            userId
            user
            reference
            contactId
            statusId
            isInitiator
            participantTypeId
          }
          notes {
            id
            content
            subjectTypeId
          }
          tasks
          isBlockedInterval
          statusId
          reference
          userPracticeLocationId
          isRecurrentAppointment
          seriesId
          externalSeriesId
          accountLocationId
          locationGroupId
          isVirtualLocation
          cancelReason
          appointmentType {
            id
            eventName
            locationType {
              value
              code
            }
            category {
              code
            }
            memberLimit
          }
        }
      }
    `;



export const GET_APPOINTMENT_BY_ID = gql`
  query GetBookedAppointments($appointmentId: String!) {
    appointments: getBookedAppointments(params: {id: $appointmentId}) {
      id
      name
      description
      startDateTime
      endDateTime
      proposedTime
      declinedReason
      locationTypeId
      locationType {
        code
      }
      reasonForVisit
      description
      participants {
        id
        user
        patient
        contact
        statusId
        isInitiator
        participantTypeId
        reference
      }
      notes {
        id
        content
        subjectTypeId
      }
      tasks
      isBlockedInterval
      statusId
      reference
      userPracticeLocationId
      accountLocationId
      locationGroupId
      isVirtualLocation
      cancelReason
      appointmentType {
        id
        eventName
        locationType {
          value
          code
        }
        category {
          code
        }
        memberLimit
      }
      statusId
      externalAppointmentId
      isRecurrentAppointment
      seriesId
      externalSeriesId
    }
  }
`;

export const GET_BOOKED_APPOINTMENTS = gql`
  query GetBookedAppointments(
    $startDate: String!
    $endDate: String!
    $userIds: [uuid!]
    $statusIds: [uuid!]
  ) {
    appointments: getBookedAppointments(
      params: {
        startDateTime: $startDate
        endDateTime: $endDate
        userIds: $userIds
        statusIds: $statusIds
      }
    ) {
      id
      name
      description
      startDateTime
      endDateTime
      proposedTime
      declinedReason
      reasonForVisit
      isRsvpEnabled
      participants {
        id
        user
        patient
        contact
        statusId
        isInitiator
        participantTypeId
        contactId
        reference
      }
      notes {
        id
        content
        subjectTypeId
      }
      tasks
      isBlockedInterval
      statusId
      reference
      userPracticeLocationId
      isVirtualLocation
      accountLocationId
      locationGroupId
      locationTypeId
      locationType {
        value
        code
      }
      cancelReason
      appointmentType {
        id
        appointmentCardProperties
        eventName
        locationType {
          value
          code
        }
        category {
          code
        }
        memberLimit
      }
      statusId
      externalAppointmentId
      isRecurrentAppointment
      seriesId
      externalSeriesId
    }
  }
`;
export const GET_BOOKED_APPOINTMENT_BY_EXTERNAL_ID = gql`
query GET_BOOKED_APPOINTMENT_BY_EXTERNAL_ID($externalAppointmentId: String!) {
  appointments(where: {externalAppointmentId: {_eq: $externalAppointmentId}}) {
    id
    externalAppointmentId
    locationTypeId
    name
    startDateTime
    reasonForVisit
    userPracticeLocationId
    accountLocationId
    locationGroupId
    isRecurrentAppointment
    seriesId
    externalSeriesId
    statusId
    tasks(where: {isDeleted: {_eq: false}}) {
      id
      isCompleted
      title
      isDeleted
    }
    participants {
      id
      userId
      statusId
      contactId
      patientId
      participantTypeId
      reference
    }
     appointmentType {
      locationType {
        value
      }
    }
  }
}
`;
export const GET_BOOKED_APPOINTMENTS_BY_ID = gql`
  query GetBookedAppointments($id: String!) {
    appointments: getBookedAppointments(params: {id: $id}) {
      id
      name
      description
      startDateTime
      endDateTime
      proposedTime
      declinedReason
      reasonForVisit
      cancelReason
      participants {
        id
        user
        patient
        contact
        statusId
        isInitiator
        participantTypeId
        reference
      }
      notes {
        id
        content
        subjectTypeId
      }
      tasks
      isBlockedInterval
      statusId
      reference
      userPracticeLocationId
      isVirtualLocation
      accountLocationId
      locationGroupId
      locationTypeId
      locationType {
        value
        code
      }
      isRecurrentAppointment
      seriesId
      externalSeriesId
      appointmentType {
        id
        eventName
        locationType {
          value
          code
        }
        category {
          code
        }
        memberLimit
      }
    }
  }
`;

export const BOOK_WORKFLOW_APPOINTMENT = gql`
  mutation CreateWorkflowAppointment($data: CreateWorkflowAppointmentInput!) {
    createWorkflowAppointments(params: $data) {
      success
    }
  }
`;

export const UPDATE_APPOINTMENT_STATUS = gql`
  mutation UpdateAppointmentStatus($appointmentId: uuid!, $statusId: uuid!) {
    updateAppointment(
      pk_columns: {id: $appointmentId}
      _set: {statusId: $statusId}
    ) {
      id
    }
  }
`;

export const GET_APPOINTMENT_DETAILS_BY_IDS = gql`
  query GetAppointmentDetailsFromIds($appointmentIds: [uuid!]) {
    appointments(where: {id: {_in: $appointmentIds}}) {
      id
      statusId
    }
  }
`;

export const GET_BOOKED_APPOINTMENTS_BY_LOCATION = gql`
  query GetBookedAppointments(
    $startDate: String!
    $endDate: String!
    $userIds: [uuid!]
    $statusIds: [uuid!]
    $accountLocationIds: [uuid!]
  ) {
    appointments: getBookedAppointments(
      params: {
        startDateTime: $startDate
        endDateTime: $endDate
        userIds: $userIds
        statusIds: $statusIds
        accountLocationIds: $accountLocationIds
      }
    ) {
      id
      name
      startDateTime
      endDateTime
      proposedTime
      declinedReason
      reasonForVisit
      cancelReason
      participants {
        id
        user
        patient
        contact
        statusId
        isInitiator
        participantTypeId
        reference
      }
      notes {
        id
        content
        subjectTypeId
      }
      tasks
      isBlockedInterval
      statusId
      reference
      userPracticeLocationId
      isVirtualLocation
      accountLocationId
      locationGroupId
      locationTypeId
      locationType {
        value
        code
      }
      isRsvpEnabled
      isRecurrentAppointment
      seriesId
      externalSeriesId
      appointmentType {
        id
        eventName
        locationType {
          value
          code
        }
        category {
          code
        }
        memberLimit
      }
    }
  }
`;

export const GET_BRIEF_BOOKED_APPOINTMENTS_BY_LOCATION = gql`
  query GetBookedAppointments(
    $startDate: String!
    $endDate: String!
    $userIds: [uuid!]
    $statusIds: [uuid!]
    $accountLocationIds: [uuid!]
    $appointmentTypeIds: [uuid!]
  ) {
    appointments: getBookedAppointments(
      params: {
        startDateTime: $startDate
        endDateTime: $endDate
        userIds: $userIds
        statusIds: $statusIds
        accountLocationIds: $accountLocationIds
        populateBriefDetails: true
        appointmentTypeIds: $appointmentTypeIds
      }
    ) {
      id
      name
      description
      startDateTime
      endDateTime
      proposedTime
      declinedReason
      reasonForVisit
      cancelReason
      participants {
        id
        user
        patient
        contact
        statusId
        isInitiator
        participantTypeId
        contactId
        reference
      }
      notes {
        id
        content
        subjectTypeId
      }
      tasks
      isBlockedInterval
      statusId
      reference
      userPracticeLocationId
      isVirtualLocation
      accountLocationId
      locationGroupId
      locationTypeId
      locationType {
        value
        code
      }
      isRsvpEnabled
      isRecurrentAppointment
      seriesId
      externalSeriesId
      appointmentType {
        appointmentCardProperties
        id
        eventName
        locationType {
          value
          code
        }
        category {
          code
        }
      }
    }
  }
`;

export const GET_APPOINTMENT_TYPES = gql`
  query GetAppointmentTypes {
    appointmentTypes {
      eventName
      duration
      id
    }
  }
`;

export const GET_BOOKED_APPOINTMENTS_BY_CONTACT_ID = gql`
  query GetBookedAppointments(
    $startDateTime: String!
    $endDateTime: String!
    $contactIds: [uuid!]
    $statusIds: [uuid!]
  ) {
    appointments: getBookedAppointments(
      params: {
        startDateTime: $startDateTime
        endDateTime: $endDateTime
        contactIds: $contactIds
        statusIds: $statusIds
      }
    ) {
      id
      externalAppointmentId
      name
      description
      startDateTime
      endDateTime
      declinedReason
      proposedTime
      accountLocationId
      locationGroupId
      userPracticeLocationId
      isVirtualLocation
      reasonForVisit
      cancelReason
      isRecurrentAppointment
      seriesId
      externalSeriesId
      participants {
        id
        user
        userId
        contactId
        reference
        patient
        contact
        isInitiator
        participantTypeId
        statusId
      }
      notes {
        id
        content
        id
        subjectTypeId
      }
      tasks
      statusId
      locationTypeId
      locationType {
        value
        code
      }
      isRsvpEnabled
      reference
      appointmentType {
        id
        eventName
        locationType {
          value
          code
        }
        category {
          code
        }
        memberLimit
      }
    }
  }
`;

export const GET_FUTURE_BOOKED_APPOINTMENTS_BY_CONTACT_IDS = gql`
query getFutureApppointments($contactIds: [uuid!]!, $statusId: uuid!, $startDateTime: timestamptz!) {
  appointments(where: {participants: {contactId: {_in: $contactIds}}, startDateTime: {_gte: $startDateTime}, statusId: {_in: [$statusId]}, isBlockedInterval: {_eq: false}, isDeleted: {_eq: false}, isRecurrentAppointment: {_eq: false}}, order_by: {startDateTime: asc}) {
    id
    startDateTime
    endDateTime
    participants(where: {isDeleted: {_eq: false}, contactId: {_is_null: false}}) {
      contactId
    }
    appointmentType {
      eventName
      visitType {
        code
      }
    }
  }
}
`;

export const GET_APPOINTMENTS_BY_CONTACT_IDS = gql`
  query getApppointments($contactIds: [uuid!]!, $statusId: [uuid!]!) {
    appointments(
      where: {
        participants: {contactId: {_in: $contactIds}}
        statusId: {_nin: $statusId}
        isBlockedInterval: {_eq: false}
        isDeleted: {_eq: false}
        isRecurrentAppointment: {_eq: false}
      }
      order_by: {startDateTime: desc}
    ) {
      id
      startDateTime
      participants(
        where: {isDeleted: {_eq: false}, contactId: {_is_null: false}}
      ) {
        contactId
      }
    }
  }
`;

export const GET_FUTURE_BOOKED_APPOINTMENT_BY_ID = gql`
  query getFutureApppointments($id: uuid!) {
    appointments(where: {id: {_eq: $id} }) {
      id
      startDateTime
      participants(where: {isDeleted: {_eq: false}, contactId: {_is_null: false}}) {
        contactId
      }
      appointmentType {
        visitType {
          code
        }
      }
    }
  }
`;

export const GET_APPOINTMENT_TYPE_BY_ID = gql`
  query GetAppointmentTypeById($appointmentTypeId: uuid!) {
    appointmentType(id: $appointmentTypeId) {
      duration
      description
      eventName
      id
      endDate
      beginDate
      locationTypeId
      bookWithinDays
      locationType {
        value
        code
      }
      tasks(where: {isDeleted: {_eq: false}}, order_by: {createdOn: asc}) {
        id
        title
        isCompleted
      }
      appointmentTypeGroup(
        order_by: {sequence: asc}
        where: {isDeleted: {_eq: false}}
      ) {
        duration
        userId
        id
        roleId
      }
    }
  }
`;

export const GET_AVAILABLE_SLOTS_FOR_DATE_RANGE = gql`
  query getAvailableTimeSlotsForDateRange($data: SlotsForDateRangeInput!) {
    getAvailableTimeSlotsForDateRange(params: $data) {
      slots
      availabilityAggregate
    }
  }
`;

export const GET_USERS_AVAILABLE_SLOTS_FOR_DATE_RANGE = gql`
  query GetUsersAvailableTimeSlotsForDateRange($data: SlotsForDateRangeInput!) {
    getAvailableSlots: getUsersAvailableTimeSlotsForDateRange(params: $data) {
      slots
      availabilityAggregate
      isLocationNotMatchWithAppointmentType
      restrictedUserAppointmentTypes
    }
  }
`;

export const GET_USER_PRACTICE_AVAILABILITY = gql`
  query getUserAvailibility($data: GetUserAvailibilityInput!) {
    getUserAvailibility(params: $data) {
      availability
    }
  }
`;

export const UPDATE_APPOINTMENT_TASK_STATUS = gql`
  mutation updateAppointmentTaskStatus($id: uuid!, $isCompleted: Boolean) {
    updateAppointmentTask(pk_columns: {id: $id}, _set: {isCompleted: $isCompleted}) {
      id
    }
  }
`;

export const SEND_APPOINTMENT_LINK = gql`
  mutation SendAppointmentBookingLink($data: AppointmentLinkInput!) {
    sendAppointmentLink(params: $data) {
      status
    }
  }
`;

export const GET_APPOINTMENT_AGGREGATE = gql`
query GetAggregateBookedAppointments($params: GetAggregateAppointmentInput!) {
  appointments: getAggregateBookedAppointments(params: $params) {
    date
    userId
    accountLocationId
    aggregate {
      total
      blocked
      groupAppointments
      appointments
    }
  }
}
`;

export const GET_APPOINTMENTS_FOR_APPOINTMENT_SEARCH = gql`
query GetAppointmentsForAppointmentSearch($where: appointment_bool_exp!) {
  appointments(where: $where, order_by: {name: asc}) {
    name
    id
    startDateTime
    endDateTime
    accountLocationId
    locationGroupId
    isRecurrentAppointment
    seriesId
    externalSeriesId
    reference
    locationType {
      value
    }
  }
}
`;

export const GET_APPOINTMENTS_FROM_APPOINTMENT_PARTICIPANT = gql`
query GetAppointmentsFromAppointmentParticipant($userUuid: uuid, $appointmentTypecategoryId: uuid, $currentDateTime: timestamptz, $statusId: uuid) {
  appointmentParticipants(where: {
    userId: {_eq: $userUuid},
    appointment: {
       id: {_is_null: false},
       appointmentType: {categoryId: {_eq: $appointmentTypecategoryId}},
       startDateTime: {_gte: $currentDateTime}
       statusId: {_eq: $statusId}
       isDeleted: {_eq: false}
    },
   }, order_by: {appointment: {name: asc}}) {
    appointment {
      name
      endDateTime
      startDateTime
      id
      accountLocationId
      locationGroupId
      seriesId
      isRecurrentAppointment
      reference
      locationType {
        value
      }
    }
  }
}
`;

export const SEND_APPOINTMENT_LINK_TO_PARTICIPANT = gql`
  mutation sendAppointmentLinkToParticipant($params: SendAppointmentLinkToParticipantInput!) {
    sendAppointmentLinkToParticipant(params: $params) {
      data
      to
      template
    }
  }
`;

export const GET_APPOINTMENT_MEETING_LINK_OF_PARTICIPANT = gql`
  query getAppointmentMeetingLinkOfParticipant($participantId: uuid!) {
    appointmentParticipant(id: $participantId) {
      id
      appointmentId
      reference
    }
  }
`;

export const GET_PARTICIPANT_ID_BY_CONTACT_ID = gql`
  query getAppointmentParticipantId($contactUuid: uuid, $appointmentId: uuid) {
    appointmentParticipants(
      where: {
        contactId: {_eq: $contactUuid}
        appointmentId: {_eq: $appointmentId}
        isDeleted: {_eq: false}
      }
    ) {
      id
      appointmentId
      reference
    }
  }
`;
export const GET_PARTICIPANT_ID_BY_CONTACT_ID_LIST = gql`
  query getAppointmentParticipantIdList(
    $contactUuids: [uuid]
    $appointmentId: uuid
  ) {
    appointmentParticipants(
      where: {
        contactId: {_in: $contactUuids}
        appointmentId: {_eq: $appointmentId}
        isDeleted: {_eq: false}
      }
    ) {
      id
      contactId
    }
  }
`;

export const GET_APPOINTMENT_RECORDING_LINK = gql`
  query getAppointmentRecording($params: GetAppointmentRecordingLinkInput!) {
    getAppointmentRecordingLink(params: $params) {
      data {
        meetingOrWebinarId
        link
        id
        createdOn
        recordingInfo {
          recording_end
          recording_start
          id
        }
      }
    }
  }
`;

export const GET_APPOINTMENT_LOGS = gql`
query getActivityLogsDetail($resourceId: uuid!, $limit: Int, $offset: Int, $orderBy: GetActivityLogOrderByInput, $excludedActionCode: [String])   {
  getActivityLogs(params: {resourceId: $resourceId, limit: $limit, offset: $offset, orderBy: $orderBy, excludedActionCode: $excludedActionCode}) {
    aggregate
    activityLogs {
      transactionId
      actionCode
      id
      parentResourceId
      parentResourceTypeCode
      performedById
      performedByTypeCode
      resourceId
      resourceTypeCode
      source
      timestamp
      parentResourceData {
        oldData
      }
      data {
        newData
        oldData
      }
    }
  }
}`;

export const GET_RESOURCE_EXECUTION_LOG_BY_RESOURCE_ID = gql`
  query getResourceExecutionLogByStatusId($resourceId: uuid) {
    message_execution_log(
      where: {resourceId: {_eq: $resourceId}}
      order_by: {updatedOn: desc}
    ) {
      statusId
      resourceId
    }
  }
`;

export const GET_LOCATION_GROUPS = gql`
  query GetLocationGroup {
    locationGroups(where: {groupType: {_eq: "EHR"} isDeleted: {_eq: false}}) {
      id
      groupName
    }
  }
`;

export const GET_APPOINTMENTS_BY_EXTERNAL_ID = gql`
  query GetAppointmentByExternalId($externalAppointmentIds: [String!], $foldAppointmentIds: [uuid!]) {
    appointments(
      where: {
        _or: [
          {externalAppointmentId: {_in: $externalAppointmentIds}}
          {id: {_in: $foldAppointmentIds}}
        ]
        isDeleted: {_eq: false}
      }
    ) {
      id
      externalAppointmentId
      id
      externalAppointmentId
      participants(where: {userId: {_is_null: false}}) {
        userId
      }
      locationGroupId
      accountLocationId
    }
  }
`;

export const GET_FUTURE_APPOINTMENT_DATA = gql`
  query GetFutureAppointmentData($whereCondition: appointment_bool_exp) {
    appointments(
      where: $whereCondition
      order_by: {startDateTime: asc}
    ) {
      id
      name
      description
      reasonForVisit
      proposedTime
      declinedReason
      startDateTime
      endDateTime
      isBlockedInterval
      statusId
      reference
      userPracticeLocationId
      isVirtualLocation
      accountLocationId
      locationGroupId
      locationTypeId
      locationType {
        value
        code
      }
      isRecurrentAppointment
      seriesId
      externalSeriesId
      participants(
        where: {
          isDeleted: {_eq: false},
        }
      ) {
        contactId
        id
        userId
        reference
        contactId
        statusId
        isInitiator
        participantTypeId
      }
      appointmentType {
        eventName
        visitType {
          code
        }
        locationType {
          value
          code
        }
        id
        category {
          code
        }
        memberLimit
      }
    }
  }
`;

export const GET_APPOINTMENT_BY_ID_AND_STATUS = gql`
  query GetAppointmentByIdAndStatus($id: uuid!) {
    appointments(where: {id: {_eq: $id}}) {
      id
      statusId
      status{
        code
      }
    }
  }
`;

export default {
  GET_APPOINTMENT_RECORDING_LINK,
  GET_PARTICIPANT_ID_BY_CONTACT_ID,
  GET_BOOKED_APPOINTMENTS,
  BOOK_APPOINTMENT,
  UPDATE_PARTICIPANT_STATUS,
  BOOK_WORKFLOW_APPOINTMENT,
  UPDATE_APPOINTMENT_STATUS,
  GET_APPOINTMENT_DETAILS_BY_IDS,
  UPDATE_APPOINTMENT_WORKFLOW,
  GET_APPOINTMENT_BY_ID,
  GET_BOOKED_APPOINTMENTS_BY_LOCATION,
  GET_APPOINTMENT_TYPES,
  GET_BOOKED_APPOINTMENTS_BY_CONTACT_ID,
  GET_APPOINTMENT_TYPE_BY_ID,
  GET_AVAILABLE_SLOTS_FOR_DATE_RANGE,
  GET_BOOKED_APPOINTMENTS_BY_ID,
  GET_USER_PRACTICE_AVAILABILITY,
  GET_BRIEF_BOOKED_APPOINTMENTS_BY_LOCATION,
  GET_APPOINTMENT_BY_ID_WITH_GROUP,
  GET_APPOINTMENT_AGGREGATE,
  GET_APPOINTMENTS_FOR_APPOINTMENT_SEARCH,
  GET_APPOINTMENTS_FROM_APPOINTMENT_PARTICIPANT,
  SEND_APPOINTMENT_LINK_TO_PARTICIPANT,
  GET_APPOINTMENT_MEETING_LINK_OF_PARTICIPANT,
  GET_APPOINTMENT_LOGS,
  GET_RESOURCE_EXECUTION_LOG_BY_RESOURCE_ID,
  GET_APPOINTMENTS_BY_EXTERNAL_ID,
  GET_LOCATION_GROUPS,
  GET_FUTURE_APPOINTMENT_DATA
};

