import {
    Box,
    HStack,
    Pressable,
    View,
    VStack,
    Menu,
    Text as NativeText,
    Tooltip,
    Spinner,
    Skeleton,
} from 'native-base';
import { Popover, Typography, Checkbox } from 'antd';
import RocketSvg from '../../../../assets/Icons/RocketSvg';
import TagHorizontal from '../../../../assets/Icons/TagHorizontal';
import UserHeartSvg from '../../../../assets/Icons/UserHeartSvg';
import UserAddSvg from '../../../../assets/svg/UserAddSvg';
import { Colors } from '../../../../styles';
import ClipBoardSvg from '../../Svg/ClipBoardSvg';
import PatientEducationSvg from '../../Svg/SideCarSvg/PatientEducationSvg';
import { ColumnType, ILoadingAction, PatientData } from './interfaces';
import { DayOptimizerColumnCode, DayOptimizerColumnOrder, DayOptimizerViewAction } from './DayOptimizerConstants';
import AlphabetAvatar from '../../Avatar/AlphabetAvatar';
import PatientListInternalTask from '../../PatientListInternalTask/PatientListInternalTask';
import RAFModal from './PatientDashboard/PatientListing/RAFModal/RAFModal';
import ArrowDownSvg from '../../Svg/DayOptimization/ArrowDownSvg';
import ArrowUpSvg from '../../Svg/DayOptimization/ArrowUpSvg';
import { IContactScoreAudit } from '../../MemebersView/interface';
import './styles.css';
import { Moment } from 'moment';
import moment from 'moment';
import DisplayPCPUser from './PatientDashboard/PatientListing/DisplayPCPUser';
import AssignCareManagerPopoverWrapper from './PatientDashboard/PatientListing/AssignCareManagerPopoverWrapper';
import ChevronNewSvg from '../../Svg/ChevronNewSvg';
import ThreeDotsActionPopover from './PatientDashboard/PatientListing/ThreeDotsActionPopover';
import { DoubleTickIconSvg } from '../../../../assets/Icons/DoubleTickIconSvg';
import PenSvg from '../../Svg/PenSvg';
import ClockCircleSvg from '../../Svg/ClockCircleSvg';
import ClipboardAddSvg from '../../Svg/ClipboardAddSvg';
import TasksSvg16 from '../../Svg/TasksSvg16';
import CalendarNextDaySvg from '../../Svg/CalendarNextDaySvg';
import DotIconSvg from '../../Svg/DotIconSvg';
import { DisplayText } from '../../DisplayText/DisplayText';
import { TagDataListView } from '../../../PersonOmniView/LeftContainer/OtherDetails/TagDataListView';
import { RIGHT_SIDE_CONTAINER_CODE } from '../../../../constants';
import { CONVERSATION_ACTION_CODES } from '../../../../constants/ActionConst';
import OutboundCallSmsViewWrapper from './PatientDashboard/PatientListing/OutboundCallSmsViewWrapper';
import FoldButtonV2, { ButtonType } from '../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import { getFormattedPatientForAutomation } from './PatientDashboard/PatientListing/PatientListingHelper';
import Stack from '../../LayoutComponents/Stack';
import BellSvg from '../../../../assets/Icons/BellSvg';
const { Text } = Typography;

export const getMenuItem = (
    item: any,
    record: any,
    onViewAction: (
        action: DayOptimizerViewAction | DayOptimizerMenuItemKeys,
        record: any,
        popover: {open: boolean, closePopover: () => void}
    ) => void,
    popover: {open: boolean, closePopover: () => void}
) => {
  switch (item.key) {
    case DayOptimizerMenuItemKeys.CHANGE_CARE_MANAGER:
      return (
        <AssignCareManagerPopoverWrapper
          record={record}
          customPopoverBody={item.label}
          parentPopover={popover}
        />
      );
    case DayOptimizerMenuItemKeys.INITIATE_PROTOCOL:
      return (
        <Popover
          overlayClassName="threeDotsPopoverStyle"
          placement={'left'}
          trigger="click"
          showArrow={false}
          overlayInnerStyle={{
            width: '144px',
            borderRadius: 8
          }}
          content={
            <View style={{padding: '8px', width: '144px'}}>
              <Text
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: Colors.FoldPixel.GRAY300,
                    padding: '4px 8px',
                    marginBottom: 4,
                }}
              >
                Initiate Protocol
              </Text>
              {getInitiateProtocolMenuItems().map(
                (item) => (
                  <Menu.Item
                    key={item.key}
                    onPress={() => {onViewAction(item.key, record, popover)}}
                    borderRadius={4}
                    padding={'8px'}
                  >
                    {item.label}
                  </Menu.Item>
                )
              )}
            </View>
          }
        >
          <View
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
          >
            <Text>{item.label}</Text>
            <ChevronNewSvg />
          </View>
        </Popover>
      );
    default:
      return item.label;
  }
};

export const renderContactTagsColumn = () => {
    return {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.primary['700'] || ''}}
          textLocalId={'tags'}
        />
      ),
      key: 'tags',
      width: '240px',
      render: (tags: any, selectedRecord: any): JSX.Element => {
        // if (selectedRecord?.additionalDataLoading?.tagLoading) {
        //   return (<Skeleton.Text lines={1}></Skeleton.Text>);
        // }
        if(selectedRecord?.tags?.length > 0) {
            return (
                <TagDataListView
                    taggableType={'Contact'}
                    style={{marginBottom: 10}}
                    hideAddMoreButton={true}
                    tagList={selectedRecord?.tags}
                />
            );
        }
        return <></>
      },
      dataIndex: 'tags',
      responsive: ['md'],
    };
  };

export const getColumns = (
    isPatientViewVisible: boolean,
    onViewAction: (
        action: DayOptimizerViewAction | DayOptimizerMenuItemKeys | string,
        selectedPatient?: PatientData,
        popover?: {open: boolean, closePopover: () => void}
    ) => void,
    isSmallScreen: boolean,
    selectedBulkActionMembers: Map<string, PatientData>,
    columnShuffleDropDownList: any[],
    currentSortingValue: string,
    currentSortingOrderColumnId: string,
    filterFlagList: any,
    currentDate: Date,
    loadingActions: ILoadingAction[],
    totalPatients: number
): ColumnType[] => {
    const columns: ColumnType[] = [];
    const visibleColumns = columnShuffleDropDownList?.filter((column: any) => column?.isVisible)?.sort((a: any, b: any) => {
        return a?.sequence - b?.sequence;
    });
    columns.push({
        ...renderCheckboxColumn(selectedBulkActionMembers, totalPatients, onViewAction),
        fixed: 'left'
    });

    visibleColumns?.forEach(column => {
        const commonColumnProps = getCommonColumnProps(column, onViewAction, isSmallScreen, currentSortingValue, currentSortingOrderColumnId);

        switch (column?.columnCode) {
            case DayOptimizerColumnCode.NAME:
                columns.push(renderNameColumn(commonColumnProps));
                break;
            case DayOptimizerColumnCode.RAF_SCORE:
                columns.push(renderRAFScoreColumn(commonColumnProps));
                break;
            case DayOptimizerColumnCode.PRIORITY:
                columns.push(renderPriorityColumn(commonColumnProps));
                break;
            case DayOptimizerColumnCode.FOLD_ID:
                columns.push(renderFoldIdColumn(commonColumnProps));
                break;
            case DayOptimizerColumnCode.LOCATION:
                columns.push(renderLocationColumn(commonColumnProps));
                break;
            case DayOptimizerColumnCode.PCP:
                columns.push(renderPCPColumn(commonColumnProps));
                break;
            case DayOptimizerColumnCode.CLIENT_ID:
                columns.push(renderClientIdColumn(commonColumnProps));
                break;
            case DayOptimizerColumnCode.ALERTS:
                columns.push(renderAlertsColumn(commonColumnProps));
                break;
            case DayOptimizerColumnCode.REASON_FOR_PRIORITISATION:
                columns.push(renderReasonForPrioritisationColumn(commonColumnProps));
                break;
            case DayOptimizerColumnCode.REASONS_QA: // Column is just for QA Envirement
                columns.push(renderReasonsQAColumn(commonColumnProps));
                break;
            case DayOptimizerColumnCode.TAGS:
                columns.push(renderContactTagsColumn());
                break;
            case DayOptimizerColumnCode.ACTIONS:
                if (!isPatientViewVisible) {
                    columns.push(renderActionsColumn(commonColumnProps, filterFlagList, currentDate, loadingActions));
                }
                break;

        }
    });
    return columns;
};
export enum DayOptimizerMenuItemKeys {
    ADD_RELATIVES = 'add-relatives',
    EDIT_PATIENT = 'edit-patient',
    CONTACT_TAG_VIEW = 'set-tag',
    SCHEDULE_APPOINTMENT = 'schedule-appointment',
    SEND_ASSESSMENT = 'send-assessment',
    ADD_TASK = 'add-task',
    RUN_AUTOMATION = 'run-automation',
    INITIATE_PROTOCOL = 'initiate-protocol',
    INITIATE_PROTOCOL_CCM = 'initiate-protocol-ccm',
    INITIATE_PROTOCOL_TCM = 'initiate-protocol-tcm',
    INITIATE_PROTOCOL_AWV = 'initiate-protocol-awv',
    CHANGE_RISK_LEVEL = 'change-risk-level',
    CHANGE_CARE_MANAGER = 'change-care-manager',
    SEND_EDUCATION = 'send-education',
    ADD_TO_TODAY = 'add-to-today',
    MOVE_TO_NEXT_DAY = 'move-to-next-day',
    MARK_AS_COMPLETE = 'mark-as-complete',
    REMOVE_FROM_LIST = 'remove-from-list',
}

export enum DayOptimizerBulkActions {
    TOOGLE_MEMBER_SELECTION = 'toggle-member-selection',
    SELECT_ALL_MEMBERS = 'select-all-members',
    DESELECT_ALL_MEMBERS = 'deselect-all-members',
    TOGGLE_BULK_ACTION_POPOVER = 'toggle-bulk-action-popover',
    RUN_AUTOMATION = 'bulk-action-run-automation',
    MOVE_TO_NEXT_DAY = 'bulk-action-move-to-next-day',
}

const initiateProtocolMenuItems: {
    key: DayOptimizerMenuItemKeys;
    label: string;
  }[] = [
    {
        key: DayOptimizerMenuItemKeys.INITIATE_PROTOCOL_AWV,
        label: `AWV`,
    },
    {
        key: DayOptimizerMenuItemKeys.INITIATE_PROTOCOL_TCM,
        label: `TCM`,
    },
    {
        key: DayOptimizerMenuItemKeys.INITIATE_PROTOCOL_CCM,
        label: `CCM`,
    },
]

export const getSelectedItemsForAutomation = (
  activeDrawer: DayOptimizerMenuItemKeys | DayOptimizerBulkActions,
  selectedPatient: PatientData | null,
  selectedBulkActionMembers: Map<string, PatientData>
) => {
  if (
    activeDrawer === DayOptimizerMenuItemKeys.RUN_AUTOMATION &&
    selectedPatient
  ) {
    return [getFormattedPatientForAutomation(selectedPatient)];
  } else if (
    activeDrawer === DayOptimizerBulkActions.RUN_AUTOMATION &&
    selectedBulkActionMembers.size > 0
  ) {
    const selectedPatients = Array.from(selectedBulkActionMembers.values());
    const formattedPatients = selectedPatients.map((patient) =>
      getFormattedPatientForAutomation(patient)
    );
    return formattedPatients;
  }
};

export const onTableChange = (
    sorter: any,
    handleSortChange: (columnCode: string, order: string) => void
) => {
    let sortObject: any = {}
    if (Array.isArray(sorter)) {
        sorter.forEach((sorterItem) => {
            const tempSortObject = onSort(sorterItem);
            if (tempSortObject) {
                sortObject = {
                    ...sortObject,
                    ...tempSortObject
                }
            }
        });
    } else {
        const tempSortObject = onSort(sorter);
        if (tempSortObject) {
            sortObject = tempSortObject
        }
    }
    handleSortChange(sortObject.field, sortObject.order);
};

const onSort = (sorter: any) => {
    if (sorter.order === DayOptimizerColumnOrder.ASCENDING || sorter.order === DayOptimizerColumnOrder.DESCENDING || !sorter.order) {
        return {
            field: sorter.field,
            order: !sorter.order ? null : sorter.order === DayOptimizerColumnOrder.ASCENDING ? DayOptimizerColumnOrder.ASC : DayOptimizerColumnOrder.DESC
        }
    }
    return null;
};

const getCommonColumnProps = (column: any, onViewAction: (action: DayOptimizerViewAction, selectedPatient: PatientData) => void, isSmallScreen: boolean, currentSortingValue: string | null, currentSortingOrderColumnId: string) => ({
    title: column?.displayName,
    dataIndex: column?.columnCode,
    width: column?.width || '120px',
    sorter: column?.isSortingApplicable,
    sortOrder: currentSortingOrderColumnId === column?.id ? (currentSortingValue === DayOptimizerColumnOrder.ASC ? DayOptimizerColumnOrder.ASCENDING : currentSortingValue === DayOptimizerColumnOrder.DESC ? DayOptimizerColumnOrder.DESCENDING : null) : null,
    onCell: (record: any) => ({
        onClick: () => {
            onViewAction(DayOptimizerViewAction.SHOW_PATIENT_DETAIL_VIEW, record);
        }
    }),
    onViewAction,
    isSmallScreen
});

const renderCheckboxColumn = (selectedBulkActionMembers: Map<string, PatientData>, totalPatients: number, onViewAction: (action: string, selectedPatient?: PatientData) => void) => ({
    title: <View style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 8 }}>
        <Checkbox
            value='all'
            indeterminate={selectedBulkActionMembers.size > 0 && selectedBulkActionMembers.size < totalPatients}
            checked={totalPatients > 0 && selectedBulkActionMembers.size === totalPatients}
            onClick={() => {
                const isIndeterminate = selectedBulkActionMembers.size > 0 && selectedBulkActionMembers.size < totalPatients;
                let action;
                if (isIndeterminate) {
                    action = DayOptimizerBulkActions.SELECT_ALL_MEMBERS;
                } else if (selectedBulkActionMembers.size === totalPatients) {
                    action = DayOptimizerBulkActions.DESELECT_ALL_MEMBERS;
                } else {
                    action = DayOptimizerBulkActions.SELECT_ALL_MEMBERS;
                }
                console.log(action)
                onViewAction(action);
            }}
        />
    </View>,
    dataIndex: 'select',
    width: '60px',
    fixed: 'left',
    render: (_text: any, record: any) =>
        <Stack direction='row' space={12} style={{ flex: 1, justifyContent: 'space-between', alignItems: 'center', marginLeft: 8 }}>
            <Checkbox
                value={record?.dayOptimizerId}
                checked={selectedBulkActionMembers.has(record?.uuid)}
                onChange={async () => {
                    onViewAction(DayOptimizerBulkActions.TOOGLE_MEMBER_SELECTION, record);
                }}
            />
           {!record.markAsCompleteStatus ? <DotIconSvg/> : <DoubleTickIconSvg size={18} customStrokeColor={Colors.Custom.SuccessColor}/>}
        </Stack>
});

const renderNameColumn = (commonColumnProps: any) => ({
    ...commonColumnProps,
    onCell: () => ({}),
    fixed: 'left',
    render: (name: string, record: any) => (
        <HStack justifyContent={'left'} alignItems='center' space={2} minHeight={'80px'}>
            <AlphabetAvatar
                size={8}
                name={record?.name}
                style={{
                    alignItems: 'center',
                    borderRadius: 8,
                    BorderColor: '#FBCEB7',
                    borderWidth: 1,
                }}
                textStyle={{ fontSize: 12, fontWeight: 500 }}
                disableTop
                userType='PATIENT'
            />
            <VStack width={'100%'} alignItems={'left'}>
                <Pressable onPress={() => {
                    commonColumnProps.onViewAction(DayOptimizerViewAction.REDIRECT_PATIENT_VIEW, record);
                }}>
                    <NativeText
                        size={'smMedium'}
                        maxW={commonColumnProps.isSmallScreen ? '110px' : '260px'}
                        color={Colors.FoldPixel.GRAY400}
                        numberOfLines={1}
                        ellipsizeMode='tail'
                    >
                        {record?.name}
                    </NativeText>
                </Pressable>
                <NativeText color={Colors.FoldPixel.GRAY250} size={'smRegular'}>
                    {record?.gender && record?.age ?
                        `(${record?.gender === 'female' ? 'F' : 'M'}, ${record?.age})` :
                        record?.gender ?
                            `(${record?.gender === 'female' ? 'F' : 'M'})` :
                            record?.age ?
                                `(${record?.age})` :
                                ''}
                </NativeText>
            </VStack>
        </HStack>
    )
});

const dayOptimizerActions = (record: any, action: string, actionCode: string, onViewAction: (action: string, selectedPatient: PatientData) => void) => {
    const patientId = record?.patientId;
    const baseUrl = `${window.location.origin}/#/members/patient/${patientId}`;

    if (['awv', 'tcm', 'ccm'].includes(actionCode)) {
        const urlPath = (action === 'Follow-up' || action.toLowerCase().startsWith('review')) && record?.careProgramId
            ? `${baseUrl}/careManagement/${actionCode}/${record?.careProgramId}`
            : `${baseUrl}/careManagement/${actionCode}`;
        window.open(urlPath, '_blank', 'noopener,noreferrer');
    } else if (actionCode === 'sms') {
        onViewAction(RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER, record);
    } else if (actionCode === 'email') {
        onViewAction(RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP, record);
    } else if (actionCode === 'chat') {
        onViewAction(CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT, record);
    } else {
        window.open(baseUrl, '_blank', 'noopener,noreferrer');
    }
}


const renderActionsColumn = (commonColumnProps: any, filterFlagList: any, currentDate: Date, loadingActions: ILoadingAction[]) => ({
    ...commonColumnProps,
    fixed: 'right',
    onCell: () => ({}),
    render: (actions: string, record: any) => (
        <HStack justifyContent={'space-between'} paddingX={1} alignItems='center' space={1}>
            {record?.actionCode === 'phone' ? (
                <OutboundCallSmsViewWrapper isLoading={record?.actionLoading} action={record?.actions} record={record} actionCode={record?.actionCode} />
            ) : (
                <FoldButtonV2
                    label={record?.actions}
                    onPress={() => {
                        dayOptimizerActions(record, record?.actions, record?.actionCode, commonColumnProps.onViewAction);
                    }}
                    buttonType={ButtonType.tertiary}
                    borderColor={Colors.FoldPixel.PRIMARY300}
                    height={24}
                    width={'82px'}
                    hoverBackgroundColor={Colors.FoldPixel.PRIMARY300}
                    hoverTextColor={Colors.Custom.White}
                    isDisabled={false}
                    textSize={12}
                    isLoading={record?.actionLoading}
                />
            )}

            <ThreeDotsActionPopover
                record={record}
                onViewAction={commonColumnProps.onViewAction}
                filterFlagList={filterFlagList}
                currentDate={currentDate}
                loadingActions={loadingActions}
            />
        </HStack>
    )
})


const renderRAFScoreColumn = (commonColumnProps: any) => ({
    ...commonColumnProps,
    onCell: () => ({}),
    render: commonColumnRenders.RAF
});

const renderPriorityColumn = (commonColumnProps: any) => ({
    ...commonColumnProps,
    width: '60px',
    render: (priority: number, record: any) => (
        <NativeText fontWeight={500} fontSize={14} color={Colors.FoldPixel.GRAY400}>
            {record?.priority || 0}
        </NativeText>
    ),
});

const renderFoldIdColumn = (commonColumnProps: any) => ({
    ...commonColumnProps,
    render: (patientId: string, record: any) => (
        <NativeText size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
            {patientId || record?.patientId}
        </NativeText>
    ),
});

const renderLocationColumn = (commonColumnProps: any) => ({
    ...commonColumnProps,
    width: '200px',
    render: (practiceLocation: string, record: any) => (
        <NativeText size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
            {practiceLocation || record?.practiceLocation}
        </NativeText>
    ),
});

const renderPCPColumn = (commonColumnProps: any) => ({
    ...commonColumnProps,
    width: '300px',
    render: (selectedRecord: any, record: any): JSX.Element => (
        <DisplayPCPUser
            isLoading={record?.pcpUserLoading}
            width={commonColumnProps.width}
            pcpUser={record?.pcpUser}
        />
    ),
});

const renderClientIdColumn = (commonColumnProps: any) => ({
    ...commonColumnProps,
    width: '200px',
    render: (clientId: string, record: any) => (
        <NativeText size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
            { clientId || record?.clientId }
        </NativeText>
    ),
});

const renderAlertsColumn = (commonColumnProps: any) => ({
    ...commonColumnProps,
    width: '84px',
    render: (alerts: number, record: any) => (
        <HStack width={10} backgroundColor={Colors.FoldPixel.GRAY50} borderRadius={4} justifyContent={'center'} padding={1} alignItems='center' space={1}>
            {
                record?.alertsLoading ? (
                    <HStack justifyContent={'center'} alignItems={'center'} space={0.5}>
                        <BellSvg />
                        <Skeleton.Text lines={1} width="20px" />
                    </HStack>
                ) : (
                    <HStack justifyContent={'center'} alignItems={'center'} space={0.5}>
                        <BellSvg />
                        <NativeText size={'smRegular'} color={Colors.FoldPixel.GRAY300}>{record?.alertsCount}</NativeText>
                    </HStack>
                )
            }
        </HStack>
    ),
});

const renderReasonForPrioritisationColumn = (commonColumnProps: any) => ({
 ...commonColumnProps,
  width: '534px',
  render: (reasonForPrioritisation: string[], record: PatientData) => (
    <Box maxWidth='534px'>
      <VStack>
      {record?.actionLoading ? (
        <>
          {[90, 80, 70].map((width, i) => (
            <HStack key={i} space={2} alignItems="center">
              <Text>•</Text>
              <Skeleton.Text lines={1} width={`${width}%`} />
            </HStack>
          ))}
        </>
      ) : (
        record?.reasonForPrioritisation?.map((reason, index) => {
          if (index < 3) {
            return (
              <VStack key={index} alignItems="left" space={1}>
                <NativeText
                  size={'smRegular'}
                  color={Colors.FoldPixel.GRAY250}
                  flex={1}
                  numberOfLines={1}
                  ellipsizeMode='tail'
                  isTruncated
                >
                  • {reason}
                </NativeText>
                {index === 2 && record?.reasonForPrioritisation && record?.reasonForPrioritisation?.length > 3 && (
                  <NativeText
                    fontSize={12}
                    color={Colors.FoldPixel.PRIMARY300}
                  >
                    {`+${record?.reasonForPrioritisation?.length - 3} more`}
                  </NativeText>
                )}
              </VStack>
            );
          }
          return null;
        })
      )}
      </VStack>
    </Box>
  ),
});

const renderReasonsQAColumn = (commonColumnProps: any) => ({
    ...commonColumnProps,
     width: '534px',
     render: (reasonsQA: string[], record: PatientData) => (
       <Box maxWidth='534px'>
         <VStack>
         {record?.actionLoading ? (
           <>
             {[90, 80, 70].map((width, i) => (
               <HStack key={i} space={2} alignItems="center">
                 <Text>•</Text>
                 <Skeleton.Text lines={1} width={`${width}%`} />
               </HStack>
             ))}
           </>
         ) : (
           record?.reasonsQA?.map((reason, index) => {
             if (index < 3) {
               return (
                 <VStack key={index} alignItems="left" space={1}>
                   <NativeText
                     size={'smRegular'}
                     color={Colors.FoldPixel.GRAY250}
                     flex={1}
                     numberOfLines={1}
                     ellipsizeMode='tail'
                     isTruncated
                   >
                     • {reason}
                   </NativeText>
                    {index === 2 && record?.reasonsQA && record?.reasonsQA?.length > 3 && (
                     <NativeText
                       fontSize={12}
                       color={Colors.FoldPixel.PRIMARY300}
                     >
                       {`+${record?.reasonsQA?.length - 3} more`}
                     </NativeText>
                   )}
                 </VStack>
               );
             }
             return null;
           })
         )}
         </VStack>
       </Box>
     ),
   });

const getInitiateProtocolMenuItems = () => {
    return initiateProtocolMenuItems.map((item) => ({
        key: item.key,
        label: (
            <View
                key={item.key + "initiate-protocol-item"}
            >
                <Text
                    style={{
                        color: Colors.FoldPixel.GRAY400,
                        fontSize: 14,
                    }}
                >
                    {item.label}
                </Text>
            </View>
        ),
    }));
}

const menuItems: {
  key: DayOptimizerMenuItemKeys;
  label: string;
  icon: React.ReactElement;
  color?: string;
}[] = [
  {
    key: DayOptimizerMenuItemKeys.ADD_RELATIVES,
    label: 'Add Relatives',
    icon: <UserHeartSvg />,
  },
  {
    key: DayOptimizerMenuItemKeys.EDIT_PATIENT,
    label: 'Edit Patient',
    icon: <PenSvg width={15} height={15} customStrokeColor={Colors.FoldPixel.GRAY300} />,
  },
  {
    key: DayOptimizerMenuItemKeys.CONTACT_TAG_VIEW,
    label: 'Set Tag',
    icon: <TagHorizontal />,
  },
  {
    key: DayOptimizerMenuItemKeys.SCHEDULE_APPOINTMENT,
    label: 'Schedule Appointment',
    icon: <ClockCircleSvg />,
  },
  {
    key: DayOptimizerMenuItemKeys.SEND_ASSESSMENT,
    label: 'Send Assessment',
    icon: <ClipboardAddSvg />,
  },
  {
    key: DayOptimizerMenuItemKeys.ADD_TASK,
    label: 'Add Task',
    icon: <TasksSvg16 />,
  },
  {
    key: DayOptimizerMenuItemKeys.RUN_AUTOMATION,
    label: 'Run Automation',
    icon: <RocketSvg />,
  },
  {
    key: DayOptimizerMenuItemKeys.INITIATE_PROTOCOL,
    label: 'Initiate Protocol',
    icon: <ClipBoardSvg />,
  },
  /* {
    key: DayOptimizerMenuItemKeys.CHANGE_RISK_LEVEL,
    label: 'Change Risk Level',
    icon: <SirenSvg width={15} color={Colors.FoldPixel.GRAY300} />,
  }, */
  {
    key: DayOptimizerMenuItemKeys.CHANGE_CARE_MANAGER,
    label: 'Assign Care Manager',
    icon: <UserAddSvg />,
  },
  {
    key: DayOptimizerMenuItemKeys.SEND_EDUCATION,
    label: 'Send Education',
    icon: <PatientEducationSvg />,
  },
  {
    key: DayOptimizerMenuItemKeys.ADD_TO_TODAY,
    label: 'Add to Today',
    icon: <CalendarNextDaySvg/>,
  },
  {
    key: DayOptimizerMenuItemKeys.MOVE_TO_NEXT_DAY,
    label: 'Move to Next Day',
    icon: <CalendarNextDaySvg/>,
  },
  {
    key: DayOptimizerMenuItemKeys.MARK_AS_COMPLETE,
    label: 'Mark As Complete',
    icon: <DoubleTickIconSvg size={20} />,
  },
  /* {
    key: DayOptimizerMenuItemKeys.REMOVE_FROM_LIST,
    label: 'Remove from List',
    icon: <TrashBinIcon2024 color={Colors.FoldPixel.STATUS_ERROR} />,
    color: Colors.FoldPixel.STATUS_ERROR,
  },*/
];

export const getMenuItems = (
    record: any,
    filterFlagList: any,
    currentDate: Date,
) => {
    const filteredMenuItems = menuItems.filter((item) => {
        const isTodaysDateSelected = currentDate.toDateString() === new Date().toDateString();
        if (isTodaysDateSelected) {
            if (item.key === DayOptimizerMenuItemKeys.ADD_TO_TODAY) {
                return false;
            }
        } else {
            if (item.key === DayOptimizerMenuItemKeys.MOVE_TO_NEXT_DAY) {
                return false;
            }
        }

        if (item.key === DayOptimizerMenuItemKeys.MARK_AS_COMPLETE && record?.markAsCompleteStatus) {
            return false;
        }

        if (!filterFlagList?.isCareProgramEnabled && item.key === DayOptimizerMenuItemKeys.INITIATE_PROTOCOL) {
            return false;
        }

        return item;
    }).map((item) => item);


    return filteredMenuItems?.map((item) => {
        return {
            key: item.key,
                label: (
                    <View
                        flex={1}
                        flexDirection={'row'}
                        key={item.key + "item"}
                        alignItems='center'
                        gap={'4px'}
                        width={'100%'}
                    >
                        {item.icon}
                        <Text
                            style={{
                                color: item?.color || Colors.FoldPixel.GRAY400,
                                fontSize: 14,
                                fontWeight: 400,
                            }}
                        >
                            {item.label}
                        </Text>
                    </View>
                ),
        }
    })
}

const commonColumnRenders = {
    RAF: (rafScore: string, record: any) => {
        let score : number | null = null;
        if(record?.rafScore !== null || record?.rafScore !== undefined){
            score = parseFloat(record?.rafScore);
        }
        return (
            <RAFModal
                score={score}
                contactScore={record?.contactScore}
                contactScoreAudit={record?.contactScoreAudit}
                patientId={record?.patient?.patientId}
                practiceLocationId={record?.practiceLocationAccountId}
            />
        );
    },
};

export const getPatientSearchListingColumns = (
    onActionPerformed: (action: string, record: any) => void
) => {
    return [
        {
            title: 'RAF',
            dataIndex: 'rafScore',
            width: '10%',
            sorter: false,
            render: commonColumnRenders.RAF,
        },
        {
            title: 'Name',
            dataIndex: 'label',
            width: '75%',
            render: (name: string, record: any) => {
                return (
                    <HStack justifyContent={'left'} alignItems='center' space={2}>
                        <AlphabetAvatar
                            size={8}
                            name={name}
                            style={{
                                alignItems: 'center',
                                borderRadius: 8,
                                BorderColor: '#FBCEB7',
                                borderWidth: 1,
                            }}
                            textStyle={{ fontSize: 12, fontWeight: 500 }}
                            disableTop
                            userType='PATIENT'
                        />
                        <VStack width={'100%'} alignItems={'left'}>
                            <NativeText
                                fontWeight={500}
                                fontSize={14}
                                color={Colors.FoldPixel.GRAY400}
                            >
                                {name?.length > 20 ? `${name?.substring(0, 20)}...` : name}
                            </NativeText>
                            <NativeText color={Colors.FoldPixel.GRAY250} size={'smRegular'}>
                                ({record.gender === 'female' ? 'F' : 'M'}, {record?.age}Y)
                            </NativeText>
                        </VStack>
                    </HStack>
                )
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            width: '9%',
            render: (actions: string, record: any) => (
                <HStack justifyContent={'space-between'} paddingX={1} alignItems='center' space={1}>
                    {record.isScheduled ? (
                        <Tooltip label="Already in Day Optimizer for today's date">
                            <Pressable
                                disabled={true}
                                style={{
                                    borderRadius: 4,
                                    borderColor: Colors.FoldPixel.GRAY250,
                                    borderWidth: 0.5,
                                    margin: 0,
                                    paddingHorizontal: '19px',
                                    paddingVertical: '5px',
                                    opacity: 0.5,
                                }}
                            >
                                <NativeText style={{
                                    color: Colors.FoldPixel.GRAY300,
                                    fontSize: 12,
                                    fontWeight: '500',
                                }}>Add</NativeText>
                            </Pressable>
                        </Tooltip>
                    ) : (
                        <Pressable
                            onPress={() => {
                                onActionPerformed('add', record)
                            }}
                            style={{
                                borderRadius: 4,
                                borderColor: Colors.FoldPixel.GRAY250,
                                borderWidth: 0.5,
                                margin: 0,
                                paddingHorizontal: '19px',
                                paddingVertical: '5px',
                            }}
                        >
                            <NativeText style={{
                                color: Colors.FoldPixel.GRAY300,
                                fontSize: 12,
                                fontWeight: '500',
                            }}>Add</NativeText>
                        </Pressable>
                    )}
                </HStack>
            ),
        },
    ]
}

export const getDateLabel = (date: Date): string => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    let label = '';
    if (date.toDateString() === today.toDateString()) {
        label = 'Today';
    } else if (date.toDateString() === yesterday.toDateString()) {
        label = 'Yesterday';
    } else if (date.toDateString() === tomorrow.toDateString()) {
        label = 'Tomorrow';
    } else {
        label = date.toLocaleDateString('en-US', { weekday: 'long' });
    }

    const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return label ? `${label}, ${formattedDate}` : formattedDate;
};

export const changeDate = (currentDate: Date, increment: number): Date => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + increment);
    return newDate;
};

export const disableCalendarDates = () => (current: Moment) => {
    const currentDate = current;
    const today = moment();

    const pastLimit = moment(today).subtract(1, 'months').subtract(1, 'days');
    const futureLimit = moment(today).add(3, 'days');

    return currentDate.isBefore(pastLimit) || currentDate.isAfter(futureLimit);
};

export const InfiniteScrollLoader = () => (
    <HStack space={2} justifyContent='center' alignItems='center' py={4}>
        <Spinner size='sm' color={Colors.Custom.Primary300} />
        <NativeText
            fontSize={12}
            fontWeight={400}
            color={Colors.Custom.Primary300}
            textAlign='center'
        >
            Loading...
        </NativeText>
    </HStack>
);

export const formatDateForApi = (dateString: string): string => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const formatDateForApiFromDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const getRafConditionalColors = (
    rafScoreCondition: number
): { foreGround: string; background: string } => {
    if (rafScoreCondition <= 0.5) {
        return {
            foreGround: Colors.Custom.SuccessColor,
            background: Colors.FoldPixel.STATUS_LIGHT_SUCCESS,
        };
    }
    if (rafScoreCondition > 0.5 && rafScoreCondition <= 1) {
        return {
            foreGround: Colors.FoldPixel.STATUS_IN_PROGRESS,
            background: Colors.Custom.PendingTaskBackgroundColor,
        };
    }
    if (rafScoreCondition > 1) {
        return {
            foreGround: Colors.FoldPixel.STATUS_ERROR,
            background: Colors.Custom.Red100,
        };
    }

    return {
        foreGround: Colors.FoldPixel.STATUS_ERROR,
        background: Colors.Custom.Red100,
    };
};

export const getArrowSvg = (props: {
    rafScoreCondition: number;
    color?: string;
}) => {
    if (props.rafScoreCondition === 0) return null;

    if (props.rafScoreCondition > 0) {
        return (
            <ArrowUpSvg customStrokeColor={props?.color} width={12} height={12} />
        );
    } else {
        return (
            <ArrowDownSvg customStrokeColor={props?.color} width={12} height={12} />
        );
    }
};

export const getCalculatedRafScoreCondition = (
    contactScoreAudit: IContactScoreAudit[],
    score: number
) => {
    const contactScoreAuditList = contactScoreAudit?.filter?.(
        (score: IContactScoreAudit) => {
            return score?.scoreType === 'HCC_V_28';
        }
    );

    if (contactScoreAuditList) {
        if (contactScoreAuditList?.length < 2) {
            return 0;
        }
        const diff =
            contactScoreAuditList?.[0]?.scoreValue -
            contactScoreAuditList?.[1]?.scoreValue;
        return diff;
    }

    return 0;
};

export const removePatientFromList = (contactId: string, list: PatientData[]) : {
    updatedList: PatientData[],
} => {
    const updatedList = list.filter((patient: PatientData) => patient?.patientId !== contactId);
    return {updatedList};
}

export const removeBulkPatientsFromList = (uuids: string[], list: PatientData[]) : {
    updatedList: PatientData[],
} => {
    const updatedList = list.filter((patient: PatientData) => !uuids.includes(patient?.uuid));
    return {updatedList};
}
