import { StyleSheet } from 'react-native';


export const styles = StyleSheet.create({
    filterTitle: {
        maxWidth: '100%',
    },
    innerStack: {
      marginTop: 12
    }
});