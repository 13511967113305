import { ReactComponent } from '@foldhealth/formio-react';

export enum ColumnType {
  TEXT = 'text',
  DATE = 'date'
}

export interface ITableColumn {
  name: string;
  type: ColumnType;
}

export interface ITableRow {
  id: string;
  [key: string]: any;
}

export interface ICustomTableProps {
  component: any;
  tableColumns: ITableColumn[];
  value?: ITableRow[];
  onChange?: (rows: ITableRow[]) => void;
  isPreview?: boolean;
  disabled?: boolean;
}

export interface ICustomTableState {
  rows: ITableRow[];
}
