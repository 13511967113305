import React from 'react';
import {Pressable, View} from 'react-native';
import {Text} from 'native-base';
import {Skeleton} from 'antd';
import Stack from '../../../../../../common/LayoutComponents/Stack';
import {Colors} from '../../../../../../../styles';
import CalenderNavSvg from '../../../../../../common/Svg/ConversationAllSvg/CalenderNavSvg';
import ClockCircleOutlinedSvg from '../../../../../../common/Svg/ClockCircleOutlinedSvg';
import LocationSvg from '../../../../../../common/Svg/LocationSvg';
import CalenderDashedCircleSvg from '../../../../../../common/Svg/CalenderDashedCircleSvg';
import AppointmentDetail from '../../../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentDetail';
import AppointmentBooking from '../../../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBooking';
import {ParticipantType} from '../../../../../../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {DisplayCardAvatar} from '../../../../../../common/DisplayCard/DisplayCardAvatar';
import {replaceHashValueToString} from '../../../../../../../utils/commonUtils';
import {GROUP_MEMBER_TYPE} from '../../../../../../../constants';
import CalenderCheckedSvg from '../../../../../../../assets/Icons/CalenderCheckedSvg';
import {styles, antStyles} from './CareProgramAppointmentStyle';
import {ICareProgramAppointmentViewProps} from './interface';
import {useCareProgramAppointments} from './hooks/useCareProgramAppointments';
import {useIntl} from 'react-intl';
import {Tooltip} from 'antd';
import {
  APPOINTMENT_TYPE_VISIT_TYPE,
  CARE_PROGRAM_TYPE_CODES,
} from '../../../../../../../constants/MlovConst';
import { useCustomToast } from '../../../../../../Toast/ToastProvider';
import { showToast, ToastType } from '../../../../../../../utils/commonViewUtils';
import CareProgramAppointmentList from './CareProgramAppointmentList';
import ArrowRightDiagonalSvg from '../../../../../../common/Svg/ArrowRightDiagonalSvg';

const CareProgramAppointmentView: React.FC<ICareProgramAppointmentViewProps> = (
  props
) => {
  const intl = useIntl();
  const customToast = useCustomToast();
  const {
    careProgramTypeCode,
    careProgramAppointmentState,
    handleAppointmentBookingDrawerClose,
    handleAppointmentDetailDrawerClose,
    handleAppointmentListDrawerClose,
    onAppointmentBooking,
    validateData,
    fetchInitialData,
    careProgramId,
    selectedStep,
    appointmentAttributeId,
    isCareManagerAppointment,
    isPcpAppointment,
    handleAppointmentSummary
  } = useCareProgramAppointments({
    component: props.component,
    onChange: props.onChange,
    handleUpdateCareProgram: props?.handleUpdateCareProgram
  });

  props.validateRef.current = validateData;
  const appointmentPrimaryUserId = isPcpAppointment ? careProgramAppointmentState.pcpUserData?.uuid : careProgramAppointmentState.careManagerUserData?.uuid

  const AppointmentScheduledView = React.useMemo(() => {
    return (
      <Stack direction="column" style={styles.appointmentContainer}>
        <Stack direction="row" style={styles.header}>
          <Stack direction="row" style={styles.titleContainer}>
            <View style={styles.headerMainView}>
              <View style={styles.headerView}>
                <CalenderCheckedSvg
                  customStrokeColor={Colors.Custom.SuccessColor}
                  width={20}
                  height={20}
                />
                <Text style={styles.titleScheduled}>
                  {replaceHashValueToString(
                    {careProgramType: careProgramTypeCode,
                      appointmentStatus: careProgramAppointmentState?.appointmentStatus || 'Scheduled',
                    },
                    intl.formatMessage({id: 'careProgramAppointmentScheduledText'})
                  )}
                </Text>
              </View>
              <View style={styles.pressableView}>
                <Pressable
                  onPress={() => {
                    if (isPcpAppointment) {
                      if (careProgramAppointmentState.pcpUserData?.id) {
                        handleAppointmentListDrawerClose(true);
                      } else {
                        showToast(
                          customToast,
                          intl.formatMessage({id: 'pcpNotPresent'}),
                          ToastType.info,
                          1000,
                          true
                        );
                      }
                    } else if (isCareManagerAppointment) {
                      if (careProgramAppointmentState.careManagerUserData?.id) {
                        handleAppointmentListDrawerClose(true);
                      } else {
                        showToast(
                          customToast,
                          intl.formatMessage({id: 'careManagerNotPresent'}),
                          ToastType.info,
                          1000,
                          true
                        );
                      }
                    }
                  }}
                >
                  <ArrowRightDiagonalSvg color={Colors.Custom.Gray500}></ArrowRightDiagonalSvg> 
                </Pressable>
              </View>
            </View>
          </Stack>
        </Stack>
        <Stack direction="row" style={styles.cardContainer}>
          <Stack direction="column" style={styles.appointmentDetails}>
            <Stack direction="row" style={styles.doctorInfo}>
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: '7',
                  textStyle: {
                    fontSize: 12,
                    fontWeight: '500',
                  },
                }}
                isLetterAvatarShow
                userData={{
                  userId: careProgramAppointmentState.primaryUser?.id || '',
                  userType: GROUP_MEMBER_TYPE.USER,
                  userName: careProgramAppointmentState.primaryUser?.name,
                }}
              />
              <Tooltip title={careProgramAppointmentState.primaryUser?.name}>
                <Text style={styles.doctorName} numberOfLines={1}>
                  {careProgramAppointmentState.primaryUser?.name}
                </Text>
              </Tooltip>
              <Tooltip title={careProgramAppointmentState.primaryUserRole}>
                <Text style={styles.doctorRole} numberOfLines={1}>
                  {careProgramAppointmentState.primaryUserRole}
                </Text>
              </Tooltip>
            </Stack>
            <Stack direction="column" style={styles.appoinmentDateContainer}>
              <Stack direction="row" style={styles.dateItem}>
                <CalenderNavSvg
                  customStrokeColor={Colors.Custom.Gray500}
                  width={16}
                  height={16}
                />
                <Text style={styles.detailText}>
                  {
                    careProgramAppointmentState.appointmentDateAndTime
                      .appointmentDate
                  }
                </Text>
              </Stack>
              <View style={styles.divider} />
              <Stack direction="row" style={styles.dateItem}>
                <ClockCircleOutlinedSvg />
                <Text
                  style={styles.detailText}
                >{`${careProgramAppointmentState.appointmentDateAndTime.startTime} - ${careProgramAppointmentState.appointmentDateAndTime.endTime}`}</Text>
              </Stack>
            </Stack>
            <Stack direction="row" style={styles.detailRow}>
              <LocationSvg
                customColor={Colors.Custom.Gray500}
                width="16"
                height="16"
              />
              <Text style={styles.detailText}>
                {careProgramAppointmentState.appointmentLocation?.name}
              </Text>
            </Stack>
          </Stack>
          <Stack direction="row" style={styles.buttonContainer}>
            <Pressable
              style={styles.viewButton}
              onPress={() => handleAppointmentDetailDrawerClose(true)}
            >
              <Text style={styles.viewButtonText}>
                {intl.formatMessage({id: 'viewDetails'})}
              </Text>
            </Pressable>
          </Stack>
        </Stack>
      </Stack>
    );
  }, [
    careProgramAppointmentState.appointmentLocation,
    careProgramAppointmentState.primaryUser,
    careProgramAppointmentState.appointmentData,
    careProgramAppointmentState.loadingStatus,
    careProgramAppointmentState?.appointmentStatus,
    careProgramAppointmentState?.pcpUserData?.id,
    careProgramAppointmentState?.careManagerUserData?.id,
  ]);

  const AppointmentBookingView = React.useMemo(() => {
    return (
      <Stack direction="column" style={styles.container}>
        <CalenderDashedCircleSvg />
        <Text style={styles.title}>
          {replaceHashValueToString(
            {careProgramType: careProgramTypeCode},
            intl.formatMessage({id: 'AWVAppointmentNotScheduled'})
          )}
        </Text>
        {/* <Stack direction="row" style={styles.dateContainer}>
          <Stack direction="row" style={styles.dateItem}>
            <ClockCircleOutlinedSvg />
            <Text style={styles.dateText}>{`${intl.formatMessage({
              id: 'lastAppointment',
            })} :`}</Text>
            <Text style={styles.dateText}>{'2023-09-15'}</Text>
          </Stack>

          <View style={styles.divider} />

          <Stack direction="row" style={styles.dateItem}>
            <CalenderNavSvg
              customStrokeColor={Colors.Custom.Gray500}
              width={16}
              height={16}
            />
            <Text style={styles.dateText}>{`${intl.formatMessage({
              id: 'nextEligibleDate',
            })} :`}</Text>
            <Text style={styles.dateText}>{'2024-09-15'}</Text>
          </Stack>
        </Stack> */}
        <Stack direction="row" style={styles.buttonContainer}>
        <Pressable
          style={styles.scheduleButton}
          onPress={() => {
            if (isPcpAppointment) {
              if (careProgramAppointmentState?.pcpUserData?.id) {
                handleAppointmentBookingDrawerClose(true);
              } else {
                showToast(
                  customToast,
                  intl.formatMessage({id: 'pcpNotPresent'}),
                  ToastType.info,
                  1000,
                  true
                );
              }
            } else if (isCareManagerAppointment) {
              if (careProgramAppointmentState?.careManagerUserData?.id) {
                handleAppointmentBookingDrawerClose(true);
              } else {
                showToast(
                  customToast,
                  intl.formatMessage({id: 'careManagerNotPresent'}),
                  ToastType.info,
                  1000,
                  true
                );
              }
            }
          }}
        >
          <Text style={styles.buttonText}>
            {intl.formatMessage({id: 'schedule'})}{' '}
            {careProgramTypeCode === CARE_PROGRAM_TYPE_CODES.AWV
              ? 'AWV'
              : 'Appointment'}
          </Text>
        </Pressable>
          <Pressable
            style={styles.pressable}
            onPress={() => {
              if (isPcpAppointment) {
                if (careProgramAppointmentState.pcpUserData?.id) {
                  handleAppointmentListDrawerClose(true);
                } else {
                  showToast(
                    customToast,
                    intl.formatMessage({id: 'pcpNotPresent'}),
                    ToastType.info,
                    1000,
                    true
                  );
                }
              } else if (isCareManagerAppointment) {
                if (careProgramAppointmentState.careManagerUserData?.id) {
                  handleAppointmentListDrawerClose(true);
                } else {
                  showToast(
                    customToast,
                    intl.formatMessage({id: 'careManagerNotPresent'}),
                    ToastType.info,
                    1000,
                    true
                  );
                }
              }
            }}
          >
            <Text style={styles.pressableText}>
              {intl.formatMessage({ id: 'linkAppointments' })}
              <ArrowRightDiagonalSvg color={Colors.Custom.Gray500}></ArrowRightDiagonalSvg>
            </Text>
          </Pressable>
        </Stack>
      </Stack>
    );
  }, [careProgramAppointmentState?.pcpUserData?.id , careProgramAppointmentState?.careManagerUserData?.id]);

  const LoadingSkeletonView = (
    <Stack direction="column" style={styles.appointmentContainer}>
      <Stack direction="row" style={styles.header}>
        <Skeleton active paragraph={false} />
      </Stack>

      <Stack direction="row" style={styles.cardContainer}>
        <Stack direction="column" style={styles.appointmentDetails}>
          <Stack direction="row" style={styles.doctorInfo}>
            <Skeleton.Avatar active size="small" />
            <Skeleton
              active
              paragraph={false}
              style={antStyles.skeletonWidth}
            />
          </Stack>

          <Skeleton active paragraph={{rows: 3}} />
        </Stack>
        <Stack direction="row" style={styles.buttonContainer}>
          <Skeleton.Button active />
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <Stack direction="row" style={styles.wrapper}>
      {careProgramAppointmentState.loadingStatus === 'loading'
        ? LoadingSkeletonView
        : careProgramAppointmentState.appointmentData
        ? AppointmentScheduledView
        : AppointmentBookingView}
      {careProgramAppointmentState.showAppointmentBookingDrawer && (
        <AppointmentBooking
          defaultParticipants={[
            {
              label: props?.contactData?.name || '',
              key: props?.contactData?.uuid || '',
              value: props?.contactData?.uuid || '',
              type: ParticipantType.patient,
            },
          ]}
          bookAppointmentMeta={{
            selectedUser: isCareManagerAppointment ? careProgramAppointmentState.careManagerUserData : careProgramAppointmentState.pcpUserData,
            selectedLocation: {} as any,
          }}
          disablePatientProspectSearch
          disableSelectSlotBy
          disablePrimaryUserSelect
          appointmentVisitType={
            careProgramTypeCode === CARE_PROGRAM_TYPE_CODES.AWV
              ? APPOINTMENT_TYPE_VISIT_TYPE.AWV
              : APPOINTMENT_TYPE_VISIT_TYPE.ROUTINE
          }
          onlyAtClinicAppointmentEnabled
          disableAppointmentReccurence
          isVisible={true}
          onComplete={(data) => onAppointmentBooking(data)}
          onCancel={() => handleAppointmentBookingDrawerClose(false)}
        />
      )}
      {careProgramAppointmentState.showAppointmentDetailDrawer && (
        <AppointmentDetail
          event={{
            detail: careProgramAppointmentState.appointmentData,
          }}
          isVisible={true}
          onComplete={(data?: any) => {
            if (data?.id) {
              onAppointmentBooking(data);
            } else {
              handleAppointmentDetailDrawerClose(false);
            }
          }}
          onClose={(data?: any) => {
            fetchInitialData();
            handleAppointmentDetailDrawerClose(false);
          }}
          onStatusChange={() => {}}
          appointmentVisitType={
            careProgramTypeCode === CARE_PROGRAM_TYPE_CODES.AWV
              ? APPOINTMENT_TYPE_VISIT_TYPE.AWV
              : APPOINTMENT_TYPE_VISIT_TYPE.ROUTINE
          }
          disableSelectSlotBy
          disablePrimaryUserSelect
          onlyAtClinicAppointmentEnabled
          disableAppointmentReccurence
          isCareProgramAppointment
        />
      )}
      {careProgramAppointmentState.showAppointmentListDrawer && (appointmentPrimaryUserId) && (
        <CareProgramAppointmentList
          contactUuid={props?.contactData?.uuid}
          careProgramId={careProgramId}
          stepId={selectedStep?.id}
          handleAppointmentListDrawerClose={handleAppointmentListDrawerClose}
          isAppointmentListDrawerOpen={
            careProgramAppointmentState?.showAppointmentListDrawer
          }
          handleAppointmentSummary={handleAppointmentSummary}
          handleUpdateCareProgram={props.handleUpdateCareProgram}
          appointmentAttributeId={appointmentAttributeId}
          pcpUserData={careProgramAppointmentState.pcpUserData}
          selectedAppointmentData={careProgramAppointmentState.appointmentData}
          careProgramTypeCode={careProgramTypeCode}
          isCareManagerAppointment={careProgramAppointmentState.isCareManagerAppointment}
          isPcpAppointment={careProgramAppointmentState.isPcpAppointment}
          careManagerUserData={careProgramAppointmentState.careManagerUserData}
        />
      )}
    </Stack>
  );
};

export default React.memo(CareProgramAppointmentView);
