import { gql } from "@apollo/client"


const GET_CONTACT_CARE_PROGRAM_CONSENT = gql`
  query GetContactCareProgramConsent($careProgramTypeId: uuid!, $contactId: uuid!) {
    contactCarePrograms(where: { contactId: {_eq: $contactId}, payerCareProgram: {careProgramTypeId: {_eq: $careProgramTypeId}, isDeleted: {_eq: false}}, isDeleted: {_eq: false}}) {
      id
      consentLog {
        id
        hasConsent
      }
    }
  }
`;

const GET_CONTACT_CARE_PROGRAM_CONSENT_BY_ID = gql`
  query GetContactCareProgramConsentById($contactCareProgramId: uuid!) {
    contactCarePrograms(where: { id: {_eq: $contactCareProgramId}, isDeleted: {_eq: false}}) {
      id
      payerCareProgram {
        careProgramTypeId
      }
    	contactCareProgramSteps(where: { isDeleted: {_eq: false}}){
      	id
      	careProgramStepTypeId
      	careProgramSubSteps(where: { isDeleted: {_eq: false }}){
        	id
        	careProgramSubStepTypeId
      	}
    	}
      consentLog {
        id
        hasConsent
        typeId
        resourceId
        resourceTypeCode
      }
      contactId
      eligibilityDateTime
      enrollmentLog {
        id
        enrollmentDateTime
        enrollmentBy
        notes
      }
    }
  }
`;

const GET_CONTACT_CARE_PROGRAM_LIST = gql`
  query GetContactCareProgramList($contactId: uuid!) {
    contactCarePrograms(where: {contactId: {_eq: $contactId}, isDeleted: {_eq: false}}) {
      id
      complexity
      statusId
      payerCareProgram {
        careProgramTypeId
      }
      consentLog {
        id
        hasConsent
        typeId
        resourceId
        resourceTypeCode
      }
      contactId
      eligibilityDateTime
      enrollmentLog {
        id
        enrollmentDateTime
        enrollmentBy
        notes
      }
    }
  }
`;

const GET_CONTACT_CARE_PROGRAM_LIST_BY_CARE_PROGRAM_TYPE_ID = gql`
  query GetContactCareProgramList(
    $careProgramWhereCondition: contact_care_program_bool_exp
  ) {
    contactCarePrograms(where: $careProgramWhereCondition) {
      id
      complexity
      statusId
      startDateTime
      endDateTime,
      assigneeId
      payerCareProgram {
        careProgramTypeId
      }
      consentLog {
        id
        hasConsent
        typeId
        resourceId
        resourceTypeCode
      }
      contactId
      eligibilityDateTime
      enrollmentLog {
        id
        enrollmentDateTime
        enrollmentBy
        notes
      }
      stepsLog(where: {isDeleted: {_eq: false}}) {
        careProgramStepId
        careProgramStepStatusId
      	careProgramStepStatus {
          code
        }
        careProgramStep {
          careProgramStepType {
            code
          }
        }
      }
    }
  }
`;

const UPDATE_CONTACT_CARE_PROGRAM_CONSENT = gql`
  mutation updatePendingConsent($params: UpdatePendingConsentOfContactCareProgramInput!) {
    updatePendingConsentOfContactCareProgram(params: $params) {
      careProgramStepId
      careProgramStepStatusId
      careProgramSubStepId
      contactCareProgramId
      id
    }
  }
`;

const UPDATE_CONTACT_CARE_PROGRAM_STEP_STATUS = gql`
  mutation updateContactCareProgramStatus($params: UpdateContactCareProgramStatusInput!) {
    updateContactCareProgramStatus(params: $params) {
      careProgramStepId
      careProgramStepStatusId
      careProgramSubStepId
      contactCareProgramId
      id
    }
  }
`;

const UPDATE_CONTACT_CARE_PROGRAM_COMPLEXITY = gql`
  mutation UpdateContactCareProgram($params: UpdateContactCareProgramInput!) {
    updateContactCareProgram(params: $params) {
      id
    }
  }
`;

export const UPDATE_CONTACT_CARE_PROGRAM_AWV_TYPE = gql`
  mutation addOrUpdateCareProgramAttributes(
    $params: AddOrUpdateCareProgramAttributesInput!
  ) {
    addOrUpdateCareProgramAttributes(params: $params) {
      additionalAttributes {
        id
        subjectId
        subjectTypeCode
        attributeKey
        attributeValue
        attributeValueDataTypeCode
      }
    }
  }
`;

const GET_CONTACT_CARE_PROGRAM_ADDITIONAL_ATTRIBUTES = gql`
query GetContactCareAdditionalAttributes($subjectId: uuid!, $tenantId: uuid!, $attributeKeys: [String!]!) {
  careProgramAdditionalAttributes(where: {isDeleted: {_eq: false}, subjectId: {_eq: $subjectId}, tenantId: {_eq: $tenantId}, attributeKey: {_in: $attributeKeys}}) {
    id
    subjectId
    subjectTypeCode
    attributeKey
    attributeValue
    attributeValueDataTypeCode
    createdOn
    createdBy
    updatedOn
    updatedBy
    isDeleted
    tenantId
    }
  }
`;


const UPDATE_CONTACT_CARE_PROGRAM_STATUS = gql`
  mutation UpdateContactCareProgram($params: UpdateContactCareProgramInput!) {
    updateContactCareProgram(params: $params) {
      id
    }
  }
`;

export const GET_CONTACT_IDS_BY_PROGRAM_STEPS_ID = gql`
  query getContactIdByFilter($filterCategoryId: uuid!, $statusIds: [uuid!]!) {
  contactCareProgramStepsLogs(
    where: {
      careProgramStep: {
        careProgramStepTypeId: {_eq: $filterCategoryId},
        isDeleted: {_eq: false},
        #displaySequenceNumber: {_neq: -1}
      },
      careProgramStepStatus: {
        id: {_in: $statusIds}
      },
      contactCareProgram:{
        isDeleted:{_eq: false}
      }
    }) {
      contactCareProgram {
        contactId
        id
        payerCareProgram {
          careProgramType {
            code
          }
        }
      }
      careProgramStep {
        careProgramStepTypeId
      }
    }
}
`;

export const GET_CONTACT_IDS_BY_PROGRAM_STEPS_ID_CONTACT_IDS = gql`
  query getContactIdByFilter($filterCategoryId: uuid!, $statusIds: [uuid!]!, $contactIds: [uuid!]!) {
  contactCareProgramStepsLogs(
    where: {
      careProgramStep: {
        careProgramStepTypeId: {_eq: $filterCategoryId},
        isDeleted: {_eq: false},
        #displaySequenceNumber: {_neq: -1}
      },
      careProgramStepStatus: {
        id: {_in: $statusIds}
      }
      contactCareProgram: {
        contactId: {_in: $contactIds},
        isDeleted: {_eq: false}
      }
    }) {
      contactCareProgram {
        contactId
        id
        payerCareProgram {
          careProgramType {
            code
          }
        }
      }
      careProgramStep {
        careProgramStepTypeId
      }
    }
}
`;

export const GET_CONTACT_CARE_PROGRAM_WITH_STEPS_LOG = gql`
query getContactCareProgramWithStepLog($contactIds: [uuid!]!) {
  contactCarePrograms(where: {contactId: {_in: $contactIds}, isDeleted: {_eq: false}}, order_by: {createdOn: desc}) {
    payerCareProgram {
      careProgramType {
        code
        value
      }
    }
    contactCareProgramSteps(where: {isDeleted: {_eq: false}, displaySequenceNumber: {_neq: -1}}, order_by: {sequenceNumber: asc, displaySequenceNumber: asc}) {
      id
      careProgramStepType {
        code
      }
      sequenceNumber
      displaySequenceNumber
    }
    stepsLog(where: {isDeleted: {_eq: false}}) {
      careProgramStepStatus {
        code
        value
      }
      careProgramStepId
      careProgramStepStatusId
      expiresOn
      careProgramStep {
        id
        careProgramStepType {
          code
        }
        careProgramStepOutreaches {
          stepOutreachLog {
           declineDate
          }
        }
      }
      careProgramStepStatus {
        code
        value
      }
    }
    additionalAttributes(where: {isDeleted: {_eq: false}}) {
      attributeKey
      attributeValue
      attributeValueDataTypeCode
    }
    enrollmentLog {
      id
      enrollmentDateTime
      enrollmentBy
      notes
    }
    contactId
    id
    membershipYear
    complexity
    assigneeId
    statusId
  }
}

`;

export const GET_CONTACT_CARE_PROGRAM_WITH_STEPS_LOG_BY_CARE_PROGRAM_TYPE_ID = gql`
  query getContactCareProgramWithStepLogByCareProgramTypeId(
    $careProgramTypeId: uuid!
    $contactIds: [uuid!]!
  ) {
    contactCarePrograms(
      where: {
        contactId: {_in: $contactIds}
        isDeleted: {_eq: false}
        payerCareProgram: {careProgramTypeId: {_eq: $careProgramTypeId}}
      }
      order_by: {createdOn: desc}
    ) {
      payerCareProgram {
        careProgramType {
          code
          value
        }
      }
      contactCareProgramSteps(
        where: {isDeleted: {_eq: false}, displaySequenceNumber: {_neq: -1}}
        order_by: {sequenceNumber: asc, displaySequenceNumber: asc}
      ) {
        id
        careProgramStepType {
          code
        }
        sequenceNumber
        displaySequenceNumber
      }
      stepsLog(where: {isDeleted: {_eq: false}}) {
        careProgramStepStatus {
          code
          value
        }
        careProgramStepId
        careProgramStepStatusId
        expiresOn
        careProgramStep {
          id
          careProgramStepType {
            code
          }
          careProgramStepOutreaches {
            stepOutreachLog {
              declineDate
            }
          }
        }
        careProgramStepStatus {
          code
          value
        }
      }
      additionalAttributes(where: {isDeleted: {_eq: false}}) {
        attributeKey
        attributeValue
        attributeValueDataTypeCode
      }
      enrollmentLog {
        id
        enrollmentDateTime
        enrollmentBy
        notes
      }
      contactId
      id
      membershipYear
      complexity
      assigneeId
      statusId
    }
  }
`;

const GET_STEP_STATUS_OF_LATEST_CARE_PROGRAM = gql`
  query getStepLatestContactCareProgram($params: GetStepLatestContactCareProgramInput!) {
    getStepLatestContactCareProgram(params: $params) {
      id
      contactId
      sourceId
      membershipYear
      consentLog {
        id
        updatedOn
        hasConsent
        typeId
        resourceId
        resourceTypeCode
    }
  }
}`;

export const GET_CONTACT_IDS_BY_PROGRAM_STEPS_IDS = gql`
  query getContactIdByFilter($filterCategoryIds: [uuid!]!, $statusIds: [uuid!]!) {
  contactCareProgramStepsLogs(
    where: {
      careProgramStep: {
        careProgramStepTypeId: {_in: $filterCategoryIds},
        isDeleted: {_eq: false},
        displaySequenceNumber: {_gte: -1}
      },
      careProgramStepStatus: {
        id: {_in: $statusIds}
      },
      contactCareProgram:{
        isDeleted:{_eq: false}
      }
    }) {
      contactCareProgram {
        contactId
        id
        payerCareProgram {
          careProgramType {
            code
          }
        }
      }
      careProgramStep {
        careProgramStepTypeId
      }
    }
}
`;

export const GET_CONTACT_IDS_BY_PROGRAM_STEPS_IDS_FILTER_CONTACT_IDS = gql`
  query getContactIdByFilter($filterCategoryIds: [uuid!]!, $statusIds: [uuid!]!, $contactIds: [uuid!]!) {
  contactCareProgramStepsLogs(
    where: {
      careProgramStep: {
        careProgramStepTypeId: {_in: $filterCategoryIds},
        isDeleted: {_eq: false},
        displaySequenceNumber: {_gte: -1}
      },
      careProgramStepStatus: {
        id: {_in: $statusIds}
      },
      contactCareProgram:{
        isDeleted:{_eq: false}
        contactId: {_in: $contactIds}
      }
    }) {
      contactCareProgram {
        contactId
        id
        payerCareProgram {
          careProgramType {
            code
          }
        }
      }
      careProgramStep {
        careProgramStepTypeId
      }
    }
}
`;

export const GET_RESOURCE_MAPS = gql`
  query getResourceMapWithNote($resourceId: uuid!) {
    resourceMappings(
      where: {
        isDeleted: {_eq: false},
        resourceId: {_eq: $resourceId}
      },
      order_by: {
        createdOn: desc,
      }) {
      resourceId
      sourceId
      sourceTypeCode
      createdOn
      createdBy
    }
  }
`;

export const GET_CONTACT_IDS_BY_PROGRAM_IDS = gql`
  query getContactIdByFilter($payerCareProgramIds: [uuid!]!, $contactIds: [uuid!]!) {
  contactCareProgramStepsLogs(
    where: {
      contactCareProgram:{
        isDeleted:{_eq: false}
        contactId: {_in: $contactIds}
        payerCareProgram: {
          careProgramTypeId: {_in: $payerCareProgramIds}
        }
      }
    }) {
      contactCareProgram {
        contactId
        id
        payerCareProgram {
            id
            careProgramTypeId
        }
      }
    }
}
`;

export const GET_CONTACT_IDS_BY_PROGRAM_IDS_FOR_TCM = gql`
  query getContactIdByFilter($payerCareProgramIds: [uuid!]!, $contactIds: [uuid!]!, $currentDateTime: timestamptz!) {
  contactCareProgramStepsLogs(
    where: {
      contactCareProgram:{
        isDeleted:{_eq: false}
        contactId: {_in: $contactIds}
        payerCareProgram: {
          careProgramTypeId: {_in: $payerCareProgramIds}
        }
        stepsLog: {_and: {expiresOn: {_gt: $currentDateTime, _is_null: false}}}
      }
    }) {
      contactCareProgram {
        contactId
        id
        payerCareProgram {
            id
            careProgramTypeId
        }
      }
    }
}
`;

export const GET_CONTACT_IDS_BY_PAYER_CARE_PROGRAM_ID = gql`
  query getContactIdByFilter($payerCareProgramIds: [uuid!]!) {
  contactCareProgramStepsLogs(
    where: {
      contactCareProgram:{
        isDeleted:{_eq: false}
        payerCareProgram: {
          careProgramTypeId: {_in: $payerCareProgramIds}
        }
      }
    }) {
      contactCareProgram {
        contactId
        id
        payerCareProgram {
            id
            careProgramTypeId
        }
      }
    }
}
`;

export const GET_CONTACT_IDS_BY_PAYER_CARE_PROGRAM_ID_FOR_TCM = gql`
query getContactIdByFilter($payerCareProgramIds: [uuid!]!, $currentDateTime: timestamptz! ) {
  contactCareProgramStepsLogs(where: {contactCareProgram: {isDeleted: {_eq: false}, payerCareProgram: {careProgramTypeId: {_in: $payerCareProgramIds}}, stepsLog: {_and: {expiresOn: {_gt: $currentDateTime, _is_null: false}}}}}) {
    contactCareProgram {
      contactId
      id
      payerCareProgram {
        id
        careProgramTypeId
      }
    }
  }
}
`;


export const GET_CONTACT_IDS_BY_PAYER_CARE_PROGRAM_ID_TCM = gql`
query getContactIdByFilter($payerCareProgramIds: [uuid!]!, $currentDateTime: timestamptz, $contactIds: [uuid!]!) {
  contactCareProgramStepsLogs(where: {contactCareProgram: {isDeleted: {_eq: false}, contactId: {_in: $contactIds}, payerCareProgram: {careProgramTypeId: {_in: $payerCareProgramIds}}, stepsLog: {_and: {expiresOn: {_gt: $currentDateTime, _is_null: false}}}}}) {
    contactCareProgram {
      contactId
      id
      payerCareProgram {
        id
        careProgramTypeId
      }
      stepsLog {
        expiresOn
      }
    }
  }
}
`;

export const GET_CONTACT_CARE_PROGRAM_STEP_OUTREACH = gql`
  query getContactCareProgramStepOutreach(
    $contactCareProgramId: uuid!
    $contactCareProgramStepId: uuid!
  ) {
    contactCareProgram(id: $contactCareProgramId) {
      additionalAttributes {
        attributeKey
        attributeValue
        attributeValueDataTypeCode
      }
      contactCareProgramSteps(where: {id: {_eq: $contactCareProgramStepId}}) {
        id
        careProgramStepType {
          code
          value
        }
        subjectTypeCode
        careProgramStepOutreaches {
          sequence
          name
          typeId
          isRequired
          id
          stepOutreachLog {
            id
            outcomeId
            outreachCompletedBy
            outreachDateTime
            outreachId
            resourceId
            resourceType
            statusId
            status {
              value
              id
              sequence_number
            }
            typeId
            taskId
            stepId
            note
            communicationMode
          }
        }
      }
      stepsLog(where: {careProgramStepId: {_eq: $contactCareProgramStepId}}) {
        careProgramStepId
        id
        expiresOn
      }
    }
  }
`;

export const UPDATE_OUTREACH_MANUALLY = gql`
  mutation updateCareProgramStepOutreach(
    $params: UpdateCareProgramStepOutreachInput!
  ) {
    updateCareProgramStepOutreach(params: $params) {
      id
      name
      communicationMode
      note
      statusId
      outcomeId
      outreachDateTime
      outreachCompletedBy
    }
  }
`;

export const GET_CONTACT_CARE_PROGRAM_CONSENT_LOG_DATA = gql`
  query GetContactCareProgramConsent($contactCareProgramId: uuid!) {
    contactCarePrograms(where: {id: {_eq: $contactCareProgramId}, isDeleted: {_eq: false}}) {
      id
      payerCareProgram {
        careProgramType {
          code
          value
        }
      }
      consentLog {
        id
        hasConsent
        resourceId
        resourceTypeCode
        typeId
      }
    }
  }
`;

export const GET_CONTACT_CARE_PROGRAM_ASSESSMENT_LOG_DATA = gql`
  query GetContactCareProgramConsent($contactCareProgramId: uuid!) {
    contactCarePrograms(where: {id: {_eq: $contactCareProgramId}, isDeleted: {_eq: false}}) {
      id
      payerCareProgram {
        careProgramType {
          code
          value
        }
      }
      assessmentLogs {
        id
        resourceId
        resourceTypeCode
      }
    }
  }
`;


export const  GET_CONSENT_ASSESSMENT_FORM_LOG_DATA = gql`
  query GetContactCareProgramConsent($formLogId: uuid!) {
      formLog(id: $formLogId) {
        id
        contactId
        formResponse {
          form {
            name
            id
          }
        }
      }
  }
`;

// THIS IS MAIN QUERY FOR GETTING CARE PROGRAM DETAILS IN CARE_MANAGEMENT VIEW PLS EDIT WITH CAUTION
export const GET_CONTACT_CARE_PROGRAM_DETAILS = gql`
  query GetContactCareProgramDetails($id: uuid!) {
    contactCarePrograms(
      where: {isDeleted: {_eq: false}, id: {_eq: $id}}
      limit: 1
    ) {
      id
      complexity
      statusId
      assigneeId
      createdOn
      assigneeTypeId
      startDateTime
      endDateTime
      status {
        code
        value
      }
      payerCareProgram {
        careProgramTypeId
        careProgramType {
          code
          value
        }
      }
      additionalAttributes(where: {isDeleted: {_eq: false}}) {
        attributeKey
        attributeValue
        attributeValueDataTypeCode
        source
      }
      contactCareProgramSteps(where: {isDeleted: {_eq: false}}) {
        id
        title
        isRequired
        contactOutreachActivities(where: {isDeleted: {_eq: false}}) {
          id
          outcomeId
          outreachDateTime
          note
        }
        careProgramStepType {
          id
          code
          value
        }
        parentStepId
        careProgramSubSteps(where: {isDeleted: {_eq: false}}) {
          id
          careProgramSubStepType {
            id
            code
            value
          }
          careProgramStepStatus {
            id
          }
        }
        sequenceNumber
        displaySequenceNumber
        careProgramStepAdditionalAttributes(where: {isDeleted: {_eq: false}}) {
          attributeKey
          attributeValue
          attributeValueDataTypeCode
          subjectId
          subjectTypeCode
          id
        }
      }
      consentLog {
        id
        hasConsent
        typeId
        resourceId
        resourceTypeCode
      }
      assessmentLogs(where: {isDeleted: {_eq: false}}) {
        id
        careProgramStepId
        resourceId
        resourceTypeCode
      }
      contactId
      eligibilityDateTime
      enrollmentLog {
        id
        enrollmentDateTime
        enrollmentBy
        notes
      }
      stepsLog(where: {isDeleted: {_eq: false}}) {
        careProgramStepId
        careProgramStepStatusId
        expiresOn
        careProgramStep {
          id
          careProgramStepType {
            code
          }
          careProgramStepOutreaches {
            stepOutreachLog {
              declineDate
            }
          }
        }
      }
    }
  }
`;


export const UPDATE_CARE_PROGRAM = gql`
  mutation UpdateContactCareProgram($params: UpdateContactCareProgramInput!) {
    updateContactCareProgram(params: $params) {
      id
    }
  }
`;

export const GET_CARE_PROGRAM_LAST_ACTIVITY_LOGS = gql`
  query getCareProgramLastActivityLogs($contactCareProgramId: uuid!) {
    getActivityLogs(
      params: {
        resourceId: $contactCareProgramId
        limit: 1
        orderBy: {timeStamp: "desc"}
      }
    ) {
      activityLogs {
        performedByTypeCode
        performedById
        timestamp
        data {
          newData
          oldData
        }
      }
    }
  }
`;

export const GET_CARE_PROGRAM_STEP_LAST_ACTIVITY_LOGS = gql`
  query getCareProgramLastActivityLogs($stepId: uuid!) {
    getActivityLogs(
      params: {
        resourceId: $stepId
        distinctOn: ["actionCode"]
        orderBy: {timeStamp: "desc"}
      }
    ) {
      activityLogs {
        performedByTypeCode
        performedById
        timestamp
        actionCode
        data {
          newData
          oldData
        }
      }
    }
  }
`;

export const GET_CONTACT_CARE_PROGRAM_DATA= gql`
  query GetContactCareProgramDetails($id: uuid!) {
    contactCarePrograms(where: {isDeleted: {_eq: false}, id: {_eq: $id}}, limit: 1) {
      id
      complexity
      payerCareProgram {
        careProgramTypeId
        careProgramType {
          code
          value
        }
      }
    }
  }
`;

export const GET_BILLABLE_CPT_CODES = gql`
  query GetBillableCPTCodes($params: GetBillableCPTCodesInput!) {
    getBillableCPTCodes(params: $params) {
      records {
        category
        code
        cptCode
        amount
        title
        description
      }
    }
  }
`;

export const ARE_ALL_STEPS_COMPLETED = gql`
  mutation areAllRequiredStepsCompleted($contactCareProgramId: String!) {
    areAllRequiredStepsCompleted(params: { contactCareProgramId: $contactCareProgramId }) {
      areAllRequiredStepsCompleted
    }
  }
`;

export const GET_ADDITIONAL_ATTRIBUTES_DATA_BY_CONTACT_CARE_PROGRAM_ID = gql`
  query getAdditionalAttributesDataByContactCareProgramId(
    $contactCareProgramId: uuid!
  ) {
    contactCarePrograms(
      where: {id: {_eq: $contactCareProgramId}, isDeleted: {_eq: false}}
    ) {
      additionalAttributes(where: {isDeleted: {_eq: false}}) {
        attributeKey
        attributeValue
        attributeValueDataTypeCode
        source
      }
    }
  }
`;

export const GET_CARE_PROGRAM_CONSENT = gql`
  query GetCareProgramConsent($subjectId: uuid!) {
    careProgramConsents(where: {subjectId: {_eq: $subjectId}}) {
      id
      hasConsent
      resourceId
      resourceTypeCode
      typeId
      subjectTypeCode
      subjectId
      updatedOn
      updatedBy
      notes
      revokeImpactDate
    }
  }
`;

export const ADD_OR_UPDATE_CARE_PROGRAM_CONSENT = gql`
  mutation AddOrUpdateCareProgramConsent(
    $params: AddOrUpdateCareProgramConsentInput!
  ) {
    addOrUpdateCareProgramConsent(params: $params) {
      id
    }
  }
`;

export const GET_ACTIVE_CARE_PROGRAMS_BY_CARE_PROGRAM_TYPE_ID = gql`
query contactCarePrograms($contactId: [uuid!]!, $statusIds: [uuid!]!) {
  contactCarePrograms(
    where: {contactId: {_in: $contactId}, statusId: {_in: $statusIds}}
  ) {
    id
    contactId
    status{
      code
    }
    payerCareProgram{
      careProgramType{
        code
      }
    }
    startDateTime
    additionalAttributes(
      where: {
        attributeKey: {
          _in: [
            "dischargeDate"
            "facility"
            "destination"
            "primaryDiagnosisCode"
          ]
        }
      }
    ) {
      attributeValue
      attributeKey
    }
  }
}
`;

export const CHECK_REQUIRED_STEPS_COMPLETED_WITH_EXCLUSIONS = gql`
  mutation CheckMandatorySteps($id: String!, $excludeStepTypeCodes: [String]) {
    areAllRequiredStepsCompleted(params: {contactCareProgramId: $id, excludeStepTypeCodes: $excludeStepTypeCodes}) {
      areAllRequiredStepsCompleted
    }
  }
`;

export const GET_CARE_PROGRAM_STEPS_DATA_BY_STEP_ID = gql`
  query GetContactCareProgramListByStepId(
    $contactCareProgramId: uuid!
    $careProgramStepId: uuid!
  ) {
    contactCareProgramStepsLogs(
      where: {
        contactCareProgramId: {_eq: $contactCareProgramId}
        careProgramStepId: {_eq: $careProgramStepId}
      }
    ) {
      id
      careProgramStepStatusId
    }
  }
`;

export const GET_CARE_PROGRAM_STEPS_BY_CARE_PROGRAM_TYPE_ID = gql`
  query GetCareProgramStepsData($careProgramTypeId: uuid!) {
    careProgramSteps(
      where: {
        parentStepId: {_is_null: false}
        isDeleted: {_eq: false}
        payerCareProgram: {careProgramType: {id: {_eq: $careProgramTypeId}}}
      }
      distinct_on: [careProgramStepTypeId]
    ) {
      subjectTypeCode
      title
      id
    }
  }
`;

export default {
  GET_CONTACT_CARE_PROGRAM_CONSENT,
  GET_CONTACT_CARE_PROGRAM_CONSENT_BY_ID,
  GET_CONTACT_IDS_BY_PROGRAM_STEPS_ID,
  UPDATE_CONTACT_CARE_PROGRAM_CONSENT,
  GET_CONTACT_CARE_PROGRAM_DATA,
  UPDATE_CONTACT_CARE_PROGRAM_STEP_STATUS,
  UPDATE_CONTACT_CARE_PROGRAM_COMPLEXITY,
  UPDATE_CONTACT_CARE_PROGRAM_AWV_TYPE,
  GET_CONTACT_CARE_PROGRAM_ADDITIONAL_ATTRIBUTES,
  GET_BILLABLE_CPT_CODES,
  UPDATE_CONTACT_CARE_PROGRAM_STATUS,
  GET_STEP_STATUS_OF_LATEST_CARE_PROGRAM,
  GET_CONTACT_CARE_PROGRAM_LIST,
  GET_CONTACT_IDS_BY_PROGRAM_STEPS_IDS,
  GET_RESOURCE_MAPS,
  GET_CONTACT_IDS_BY_PROGRAM_IDS,
  GET_CONTACT_IDS_BY_PAYER_CARE_PROGRAM_ID,
  GET_CONTACT_IDS_BY_PAYER_CARE_PROGRAM_ID_TCM,
  GET_CONTACT_IDS_BY_PAYER_CARE_PROGRAM_ID_FOR_TCM,
  GET_CONTACT_IDS_BY_PROGRAM_IDS_FOR_TCM,
  GET_CONTACT_CARE_PROGRAM_CONSENT_LOG_DATA,
  GET_CONTACT_CARE_PROGRAM_ASSESSMENT_LOG_DATA,
  GET_CONSENT_ASSESSMENT_FORM_LOG_DATA,
  GET_CONTACT_CARE_PROGRAM_DETAILS,
  GET_CONTACT_CARE_PROGRAM_LIST_BY_CARE_PROGRAM_TYPE_ID,
  UPDATE_CARE_PROGRAM,
  GET_CARE_PROGRAM_LAST_ACTIVITY_LOGS,
  ARE_ALL_STEPS_COMPLETED,
  GET_ADDITIONAL_ATTRIBUTES_DATA_BY_CONTACT_CARE_PROGRAM_ID,
  GET_CARE_PROGRAM_CONSENT,
  ADD_OR_UPDATE_CARE_PROGRAM_CONSENT,
  GET_CONTACT_CARE_PROGRAM_WITH_STEPS_LOG_BY_CARE_PROGRAM_TYPE_ID,
  GET_ACTIVE_CARE_PROGRAMS_BY_CARE_PROGRAM_TYPE_ID,
  GET_CARE_PROGRAM_STEPS_DATA_BY_STEP_ID,
  GET_CARE_PROGRAM_STEPS_BY_CARE_PROGRAM_TYPE_ID,
  CHECK_REQUIRED_STEPS_COMPLETED_WITH_EXCLUSIONS
};
