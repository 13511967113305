import {Text, Dimensions} from 'react-native';
import React, {useState} from 'react';
import {Checkbox, Drawer, Radio, Skeleton} from 'antd';
import {useMediaQuery, View} from 'native-base';
import {
  DATE_FORMATS,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  MLOV_CATEGORY,
  SMALL_WINDOW_1700,
} from '../../../constants';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import {Colors} from '../../../styles';
import Stack from '../LayoutComponents/Stack';
import {ModalActionInput} from '../ModalActionCommonComponent/ModalActionInput';
import {
  getMlovCodeFromId,
  getMlovIdFromCode,
  getMlovListByCategory,
} from '../../../utils/mlovUtils';
import {
  CARE_PROGRAM_CONSENT_TYPE,
  CARE_PROGRAM_TYPE_CODES,
} from '../../../constants/MlovConst';
import {useIntl} from 'react-intl';
import {Pressable, StyleSheet} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useCareProgramConsent} from './CustomHooks/useCareProgramConsent';
import {ICareProgramConsentProps, IUser} from './interface';
import {CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE} from '../../RightSideContainer/Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';
import {ToastType} from '../../../utils/commonViewUtils';
import {useCustomToast} from '../../Toast/ToastProvider';
import {showToast} from '../../../utils/commonViewUtils';
import CustomConfirmationModal from '../CustomConfirmationModal/CustomConfirmationModal';
import WarningSvgV2 from '../Svg/WarningSvgV2';
import './CareProgramConsent.css';
import {getDateStrFromFormat} from '../../../utils/DateUtils';
import RevokeConsentReasonList from './RevokeConsentReasonList';

const CareProgramConsent = (props: ICareProgramConsentProps) => {
  const intl = useIntl();
  const customToast = useCustomToast();
  const {
    getCareProgramConsentData,
    addOrUpdateConsent,
    getUsersDataByUserUuid,
  } = useCareProgramConsent();
  // Constants
  const {contactId, isVisible, contactCareProgramId, onClose} = props;
  const screenDimensions = Dimensions.get('window');
  const smallWindow = screenDimensions?.width < SMALL_WINDOW_1700;
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const initialConsentTexts = {
    CCM: [
      'I understand that my PCP is willing to provide me CCM services covered under Medicare per calendar month.',
      'I understand these CCM services are subject to the usual Medicare deductible and coinsurance applied to physician services.',
      'I also understand that I can revoke this agreement at any time (effective at the end of calendar month) and can choose, instead, to receive these services from another health care professional after the calendar month in which I revoke this agreement.',
      'I understand that Medicare will pay one physician or health care professional to furnish care program services within a given calendar month.',
    ],
    general: [
      'I understand that my PCP is willing to provide me care program services covered under Medicare per calendar month.',
      'I understand these care program services are subject to the usual Medicare deductible and coinsurance applied to physician services.',
      'I also understand that I can revoke this agreement at any time (effective at the end of calendar month) and can choose, instead, to receive these services from another health care professional after the calendar month in which I revoke this agreement.',
      'I understand that Medicare will pay one physician or health care professional to furnish care program services within a given calendar month.',
    ],
  };
  const consentTypeList = getMlovListByCategory(
    MLOV_CATEGORY.CONTACT_CARE_PROGRAM_CONSENT_TYPE
  );
  const declineConsentTypeId = getMlovIdFromCode(
    consentTypeList,
    CARE_PROGRAM_CONSENT_TYPE.DECLINED
  );
  const careProgramTypeList = getMlovListByCategory(
    MLOV_CATEGORY.CARE_PROGRAM_TYPES
  );

  const subjectId =
    props.subjectTypeCode ===
      CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE.CONTACT_CARE_PROGRAM &&
    contactCareProgramId
      ? contactCareProgramId
      : contactId;

  const subjectTypeCode =
    props.subjectTypeCode ===
    CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE.CONTACT_CARE_PROGRAM
      ? CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE.CONTACT_CARE_PROGRAM
      : CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE.CONTACT;

  const [componentState, setComponentState] = useState<{
    loading: boolean;
    initialConsents: {text: string; value: boolean}[];
    isAlreadyDeclinedInApi: boolean;
    selectedConsentTypeId: string | undefined;
    consentTypeId: string | undefined;
    hasConsent: boolean;
    notes?: string;
    showRevokeConfirmation: boolean;
    updatedBy?: string;
    updatedAt?: string;
    updatedByUser?: IUser;
    revokeImpactDate?: string;
    revokeReasonId?: string;
  }>({
    loading: false,
    initialConsents: [],
    notes: '',
    isAlreadyDeclinedInApi: false,
    consentTypeId: undefined,
    selectedConsentTypeId: undefined,
    hasConsent: false,
    showRevokeConfirmation: false,
    revokeImpactDate: undefined,
    revokeReasonId: undefined
  });

  const fetchInitialConsentData = async () => {
    try {
      setComponentState((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      const response = await getCareProgramConsentData({
        subjectId: subjectId
      });
      const userData = response?.updatedBy ? await getUsersDataByUserUuid([response?.updatedBy]) : undefined;
      if (response?.id) {
        setComponentState((prev) => {
          return {
            ...prev,
            loading: false,
            hasConsent: response?.hasConsent || false,
            selectedConsentTypeId: response?.typeId || undefined,
            consentTypeId: response?.typeId || undefined,
            notes: response?.notes || undefined,
            updatedBy: response?.updatedBy || undefined,
            updatedAt: response?.updatedOn || undefined,
            updatedByUser: userData || undefined,
            revokeImpactDate: response?.revokeImpactDate || undefined
          };
        });
        setInitialConsentData(response.hasConsent);
      } else {
        setComponentState((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
        setInitialConsentData();
      }
    } catch (error) {
      showToast(
        customToast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
        1000,
        true
      );
      setComponentState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };
  // Life cycle methods
  React.useEffect(() => {
    fetchInitialConsentData();
  }, [contactId]);

  const onAddOrUpdateConsent = async () => {
    const updateConsent = !(
      componentState.hasConsent ||
      componentState.selectedConsentTypeId === declineConsentTypeId
    );

    try {
      if (!isValidDataAvailable() || !componentState.selectedConsentTypeId) {
        return;
      }
      if (componentState.hasConsent && componentState.consentTypeId) {
        await addOrUpdateConsent({
          subjectId: subjectId,
          subjectTypeCode: subjectTypeCode,
          consentTypeId: componentState.consentTypeId,
          hasConsent: false,
          revokeReasonId: componentState.revokeReasonId
        });
      } else {
        await addOrUpdateConsent({
          subjectId: subjectId,
          subjectTypeCode: subjectTypeCode,
          consentTypeId: componentState.selectedConsentTypeId,
          hasConsent: updateConsent,
          consentNotes: componentState.notes,
        });
      }
      if (props.onAddOrUpdateConsent) {
        props.onAddOrUpdateConsent();
      }
      onClose(true);
      showToast(
        customToast,
        intl.formatMessage({
          id: updateConsent
            ? 'consentSaveSuccessMsg'
            : 'consentRevokeSuccessMsg',
        }),
        ToastType.success,
        1000,
        true
      );
    } catch (error) {
      onClose(true);
      showToast(
        customToast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
        1000,
        true
      );
    }
  };
  
  const onRemoveReasonSelect  = (revokeReasonId: string) => {
    setComponentState((prev) => {
      return {
        ...prev,
        revokeReasonId: revokeReasonId,
      };
    });
  }
  const modalSubContent = React.useMemo(() => (
    <RevokeConsentReasonList 
      onRemoveReasonSelect={onRemoveReasonSelect} 
    />
  ), [onRemoveReasonSelect])

  const getUpdatedLogText = () => {
    const updateLogText = componentState.hasConsent
      ? 'Acquired on'
      : componentState.selectedConsentTypeId === declineConsentTypeId
      ? 'Declined on'
      : !componentState.hasConsent ? 'Revoked on' : '';
    return updateLogText;
  };

  const renderInitialConsent = () => {
    const primaryConsentText = 'Inform patient that:';
    return (
      <Stack direction="column" space={8}>
        <Text style={{fontSize: 16, fontWeight: '600'}}>
          {intl.formatMessage({id: 'consentMessageCheck'})}
        </Text>
        <Checkbox
          disabled={componentState.hasConsent}
          className="consent-checkbox"
          checked={!componentState.initialConsents.some((item) => !item.value)}
          onChange={(event) => {
            setComponentState((prev) => {
              const initialConsents = prev.initialConsents;
              initialConsents.forEach(
                (item) => (item.value = event.target.checked)
              );
              return {
                ...prev,
                initialConsents,
              };
            });
          }}
        >
          <Text
            style={{
              fontSize: 14,
              fontWeight: '400',
              color: Colors.Custom.Gray500,
            }}
          >
            {primaryConsentText}
          </Text>
        </Checkbox>
        <Stack direction="column" space={8} style={{marginLeft: 8}}>
          {componentState.initialConsents.map((consentItem, index) => {
            return (
              <Checkbox
                key={`InitialConsent${index}`}
                checked={consentItem.value}
                className="consent-checkbox"
                disabled={componentState.hasConsent}
                onChange={(event) => {
                  setComponentState((prev) => {
                    const initialConsents = prev.initialConsents;
                    initialConsents[index].value = event.target.checked;
                    return {
                      ...prev,
                      initialConsents,
                    };
                  });
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: '400',
                    color: Colors.Custom.Gray500,
                  }}
                >
                  {consentItem.text}
                </Text>
              </Checkbox>
            );
          })}
        </Stack>
      </Stack>
    );
  };

  const renderConsentType = () => {
    return (
      <Stack direction="column" space={8}>
        <Text style={{fontSize: 16, fontWeight: '600'}}>
          {intl.formatMessage({id: 'consentMessage'})}
        </Text>
        <Stack direction="column" space={8} style={{marginLeft: 8}}>
          {consentTypeList.map((consentItem, index) => {
            return (
              <Radio
                key={`InitialConsent${index}`}
                disabled={componentState.hasConsent}
                className="consent-radio"
                checked={
                  componentState.selectedConsentTypeId === consentItem.id
                }
                onChange={(event) => {
                  const isSelected =
                    componentState.selectedConsentTypeId === consentItem.id;
                  if (event.target.checked) {
                    setComponentState((prev) => {
                      return {
                        ...prev,
                        selectedConsentTypeId: consentItem.id,
                      };
                    });
                  } else if (isSelected) {
                    setComponentState((prev) => ({
                      ...prev,
                      consentTypeId: undefined,
                    }));
                  }
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: '400',
                    color: Colors.Custom.Gray500,
                  }}
                >
                  {consentItem.value}
                </Text>
              </Radio>
            );
          })}
        </Stack>
      </Stack>
    );
  };

  const renderNotes = () => {
    return (
      <Stack direction="column" space={6}>
        <Text style={{fontSize: 16, fontWeight: '600'}}>Notes</Text>
        <ModalActionInput
          label={''}
          leftMargin={'0'}
          isRequired={false}
          isInvalid={false}
          value={componentState.notes}
          isDisabled={componentState.hasConsent}
          onChangeText={(text: any) => {
            setComponentState((prev) => {
              return {
                ...prev,
                notes: text,
              };
            });
          }}
          extraStyle={{flex: 1, Height: 88}}
        />
      </Stack>
    );
  };

  const renderConsentInfo = (): JSX.Element => {
    return (
      <View style={styles.consentInfoContainer}>
        { 
         componentState.updatedByUser?.id &&
         (componentState.updatedAt) && (
          <View>
            <Text style={styles.consentLabel}>{getUpdatedLogText()}</Text>
            <Text style={styles.consentInfo}>
              {`${getDateStrFromFormat(
                componentState.updatedAt,
                DATE_FORMATS.REPORT_DATE_FORMAT
              )} , by ${componentState.updatedByUser.name}`}
            </Text>
          </View>
        )}
        {componentState.revokeImpactDate && (
          <View style={{marginTop: 8}}>
            <Text style={styles.consentLabel}>Revoke Impact Date</Text>
            <Text style={styles.consentInfo}>
              {getDateStrFromFormat(
                componentState.revokeImpactDate,
                DATE_FORMATS.REPORT_DATE_FORMAT
              )}
            </Text>
          </View>
        )}
      </View>
    );
  };

  const isValidDataAvailable = () => {
    if (componentState.selectedConsentTypeId === declineConsentTypeId) {
      return true;
    }
    return (
      !componentState.initialConsents.some((item) => !item.value) &&
      componentState.selectedConsentTypeId
    );
  };

  const setInitialConsentData = (
    hasConsent?: boolean,
    careProgramTypeId?: string
  ) => {
    const code = careProgramTypeId
      ? getMlovCodeFromId(careProgramTypeList, careProgramTypeId)
      : undefined;
    let consentList: string[] = [];
    if (code === CARE_PROGRAM_TYPE_CODES.CCM) {
      consentList = initialConsentTexts.CCM;
    } else {
      consentList = initialConsentTexts.general;
    }
    if (consentList.length) {
      setComponentState((prev) => {
        return {
          ...prev,
          initialConsents: consentList.map((item) => {
            return {
              text: item,
              value: hasConsent || false,
            };
          }),
        };
      });
    }
  };

  const isSaveButtonDisabled = () => {
    return componentState.loading || !isValidDataAvailable();
  };

  const getConsentDetailView = (): JSX.Element => {
    return (
      <>
        {componentState.loading && <Skeleton active />}
        {!componentState.loading && (
          <Stack direction="column" space={16}>
            {(( componentState?.updatedByUser?.id &&
              componentState.updatedAt) || componentState.revokeImpactDate ) &&
              renderConsentInfo()}
            {renderInitialConsent()}
            {renderConsentType()}
            {renderNotes()}
          </Stack>
        )}
      </>
    );
  };

  const handleConfirmationModal = (value: boolean) => {
    setComponentState((prev) => {
      return {
        ...prev,
        showRevokeConfirmation: value,
      };
    });
  };

  return (
    <View>
      <Drawer
        destroyOnClose
        placement="right"
        open={isVisible}
        closable
        width={
          isIPadScreen || isIPadMiniScreen ? '60%' : smallWindow ? '50%' : '40%'
        }
        onClose={() => {
          onClose(false);
        }}
        title={
          <Stack direction="row" style={styles.titleContainer}>
            <ModalActionTitle
              title={
                props?.programName
                  ? `${props.programName} Consent`
                  : 'Care Program Consent'
              }
              titleColor=""
            />
            <Stack direction="row" style={styles.titleButtonsContainer}>
              <View style={styles.sendButtonContainer}>
                <Pressable
                  disabled={isSaveButtonDisabled()}
                  style={
                    isSaveButtonDisabled()
                      ? styles.disabledSendButton
                      : componentState.hasConsent
                      ? styles.revokeButton
                      : styles.sendButton
                  }
                  onPress={() => {
                    if (componentState.hasConsent) {
                      handleConfirmationModal(true);
                    } else {
                      onAddOrUpdateConsent();
                    }
                  }}
                >
                  <Text
                    style={
                      componentState.hasConsent
                        ? styles.revokeButtonText
                        : styles.saveButtonText
                    }
                  >
                    {intl.formatMessage({
                      id: componentState.hasConsent ? 'Revoke' : 'Save',
                    })}
                  </Text>
                </Pressable>
              </View>
              <View style={styles.closeButtonContainer}>
                <Pressable onPress={() => onClose(false)}>
                  <AntIcon
                    name="close"
                    size={20}
                    color={Colors.Custom.Gray400}
                  />
                </Pressable>
              </View>
            </Stack>
          </Stack>
        }
      >
        {getConsentDetailView()}
      </Drawer>
      {componentState.showRevokeConfirmation && (
        <CustomConfirmationModal
          isOpen={componentState.showRevokeConfirmation}
          headerText={'revokeConsentHeaderText'}
          message={'revokeConsentMsg'}
          customCancelBtnText={'Discard'}
          customOkBtnText={'Revoke Consent'}
          width={350}
          customIcon={
            <WarningSvgV2 strokeColor={Colors.Custom.crossIconColor} />
          }
          onConfirm={() => {
            onAddOrUpdateConsent();
          }}
          modalSubContent={modalSubContent}
          onCancel={() => {
            handleConfirmationModal(false);
          }}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  titleContainer: {
    alignItems: 'center',
  },
  titleButtonsContainer: {
    alignItems: 'center',
  },
  sendButtonContainer: {
    paddingRight: 12,
  },
  disabledSendButton: {
    backgroundColor: Colors.Custom.Gray300,
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
  revokeButton: {
    backgroundColor: Colors.Custom.Red100,
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#EB8F88',
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
  sendButton: {
    backgroundColor: Colors.Custom.mainPrimaryPurple,
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
  saveButtonText: {
    color: 'white',
    fontSize: 14,
    fontWeight: '600',
  },
  revokeButtonText: {
    color: Colors.Custom.crossIconColor,
    fontSize: 14,
    fontWeight: '500',
  },
  closeButtonContainer: {
    paddingLeft: 10,
    borderLeftWidth: 1,
    borderLeftColor: Colors.Custom.Gray300,
    marginVertical: 5,
  },
  consentInfoContainer: {
    backgroundColor: Colors.Custom.White,
    padding: 12,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.Custom.GRAY150,
  },
  consentLabel: {
    fontSize: 12,
    fontWeight: '400',
    color: Colors.Custom.Gray400,
  },
  consentInfo: {
    fontSize: 14,
    fontWeight: '400',
    color: Colors.Custom.Gray500,
  },
});

export default CareProgramConsent;
