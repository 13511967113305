import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Input, Table } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { ColumnType, ICustomTableProps, ITableColumn, ITableRow } from './interfaces';
import { Colors } from '../../../../../../styles';
import { getDateStrFromFormat, getMomentObj } from '../../../../../../utils/DateUtils';
import { DISPLAY_DATE_FORMAT } from '../../../../../../constants/StringConst';
import { PlusOutlined } from '@ant-design/icons';
import { HStack, Text, VStack } from 'native-base';
import { testID } from '../../../../../../testUtils';
import TrashBinSvg from '../../../../../common/Svg/TrashBinSvg';
import TrashSvg from '../../../../../common/Svg/TrashSvg';

const generateColumnKey = (name: string): string => {
  return name.toLowerCase()
    .replace(/\s+/g, '_')
    .replace(/[^a-z0-9_]/g, '')
    .trim();
};

export const CustomTable: React.FC<ICustomTableProps> = ({
  component,
  tableColumns,
  value = [],
  onChange,
  isPreview = false,
  disabled = false,
}) => {
  // Transform columns to include generated keys
  const columnsWithKeys = tableColumns.map(column => ({
    ...column,
    key: generateColumnKey(column.name)
  }));

  const [rows, setRows] = useState<ITableRow[]>(value);

  useEffect(() => {
    setRows(value);
  }, [value]);

  const handleAddRow = () => {
    const newRow: ITableRow = {
      id: uuidv4(),
    };
    columnsWithKeys.forEach(column => {
      newRow[column.key] = '';
    });

    const updatedRows = [...rows, newRow];
    setRows(updatedRows);
    onChange?.(updatedRows);
  };

  const handleDeleteRow = (rowId: string) => {
    const updatedRows = rows.filter(row => row.id !== rowId);
    setRows(updatedRows);
    onChange?.(updatedRows);
  };

  const handleCellChange = (rowId: string, columnKey: string, value: any) => {
    const updatedRows = rows.map(row => {
      if (row.id === rowId) {
        return {
          ...row,
          [columnKey]: value
        };
      }
      return row;
    });

    setRows(updatedRows);
    onChange?.(updatedRows);
  };

  const renderCell = (row: ITableRow, column: ITableColumn & { key: string }) => {
    if (isPreview) {
      const value = row[column.key];
      if (column.type === ColumnType.DATE && value) {
        return getDateStrFromFormat(value, DISPLAY_DATE_FORMAT);
      }
      return value;
    }

    switch (column.type) {
      case ColumnType.DATE:
        return (
          <DatePicker
            {...testID(`${column.key}-date-picker`)}
            disabled={disabled}
            value={row[column.key] ? getMomentObj(row[column.key]) : null}
            onChange={(date) => {
              handleCellChange(row.id, column.key, date?.toISOString());
            }}
            format={DISPLAY_DATE_FORMAT}
            style={{ width: '100%' }}
          />
        );
      case ColumnType.TEXT:
      default:
        return (
          <Input
            {...testID(`${column.key}-input`)}
            disabled={disabled}
            value={row[column.key]}
            onChange={(e) => handleCellChange(row.id, column.key, e.target.value)}
          />
        );
    }
  };

  const columns = [
    ...columnsWithKeys.map(column => ({
      title: column.name,
      dataIndex: column.key,
      key: column.key,
      render: (_: any, row: ITableRow) => renderCell(row, column)
    })),
    !isPreview && !disabled ? {
      title: 'Actions',
      key: 'actions',
      width: 100,
      render: (_: any, row: ITableRow) => (
        <div
          {...testID('delete-row-button')}
          onClick={() => handleDeleteRow(row.id)}
          style={{ cursor: 'pointer' }}
        >
          <TrashSvg size={16} />
        </div>
      )
    } : {}
  ].filter(Boolean);

  return (
    <VStack space={2}>
      {!isPreview && (
        <HStack alignItems="center" mb={2} mt={1}>
          <Text fontSize={16} fontWeight="bold" flex={1}>
            {component.label}
          </Text>
        </HStack>
      )}
      {isPreview && (
        <Text fontSize={16} fontWeight="bold" mb={2}>
          {component.label}
        </Text>
      )}

      <Table
        {...testID('custom-table')}
        dataSource={rows}
        columns={columns}
        pagination={false}
        rowKey="id"
        size="small"
        bordered
      />
      {!isPreview && !disabled && (
        <HStack justifyContent="flex-end">
          <Button
            {...testID('add-row-button')}
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddRow}
            style={{
              backgroundColor: Colors.Custom.mainPrimaryPurple,
              borderColor: Colors.Custom.mainPrimaryPurple
            }}
          >
            New
          </Button>
        </HStack>
      )}
    </VStack>
  );
};
