import { useState, useContext, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { DayOptimizerContext } from '../../../../../../context-provider/DayOptimizerContext/context';
import { changeDate as changeDateHelper } from '../../DayOptimizerHelper';
import { COLUMN_SHUFFLE_DROP_DOWN_LIST, DATE_CHANGE, FILTER_CHANGED, GRID_CONFIGURATION_DATA, USER_FETCH_LIST_SUCCESS, USER_SELECT } from '../../../../../../context-provider/DayOptimizerContext/actionTypes';
import { UserQueries } from '../../../../../../services';
import { CARE_TEAM_MEMBER_TYPE } from '../../../../../../constants/MlovConst';
import { IDoFilter, IProvider } from '../../interfaces';
import { ALLOWED_USER_ROLES_CARE_TEAM_ROLES_MAP } from '../../../../../PersonOmniView/RightContainer/CareTeamView/CareTeamUtils';
import { getUserUUID } from '../../../../../../utils/commonUtils';
import { ColumnShuffleDropDownItem } from '../../../../../../context-provider/DayOptimizerContext/interfaces';
import { useGridConfigurations } from '../../../../MemebersView/customHook/useGridConfigurations';
import { DAY_OPTIMIZER_TABLE_CODE, DAY_OPTIMIZER_ENTITY_CODE, DayOptimizerColumnChangeType, DayOptimizerColumnCode } from '../../DayOptimizerConstants';
import { IGridColumn } from '../../../../MemebersView/interface';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';

const useListingHeader = () => {
  const { userList, columnShuffleDropDownList, gridConfigurationData, filter } = useContext(DayOptimizerContext).state.patientDashboard;  
  const dispatch = useContext(DayOptimizerContext).dispatch;
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedProvider, setSelectedProvider] = useState<IProvider | null>(null);
  const [userFetchLoading, setUserFetchLoading] = useState(false);
  const [columnChangeLoading, setColumnChangeLoading] = useState(false);
  const [tempColumns, setTempColumns] = useState<ColumnShuffleDropDownItem[]>([]);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [filterPopoverVisible, setFilterPopoverVisible] = useState(false);
  const [resetButtonEnabled, setResetButtonEnabled] = useState(false);

  const loggedInUserId = getUserUUID();
  const commonContextData = useContext(CommonDataContext);
  const { postGridConfigurationData, putGridConfigurationData, putGridConfigurationDataToDefault } = useGridConfigurations();

  useEffect(() => {
    setTempColumns(columnShuffleDropDownList?.map(column => ({ ...column })));
  }, [columnShuffleDropDownList]);

  useEffect(() => {
    const loggedInUserData = commonContextData?.userData;
    if (loggedInUserData) {
      setSelectedProvider({
        id: loggedInUserData.id,
        uuid: loggedInUserData.uuid,
        email: loggedInUserData.email,
        name: loggedInUserData.name,
        userPracticeLocations: [],
        userRoles: []
      });
    }
  }, []);

  useEffect(() => {
    setResetButtonEnabled(!!gridConfigurationData?.userUuid);
  }, [gridConfigurationData]);

  const isColumnChanged = () => {
    if (!tempColumns || !columnShuffleDropDownList) {
      return false;
    }
    try {
      const filteredTempColumns = tempColumns?.filter(column => column?.columnCode !== DayOptimizerColumnCode.NAME && column?.columnCode !== DayOptimizerColumnCode.ACTIONS);
      const filteredShuffleColumns = columnShuffleDropDownList?.filter(column => column?.columnCode !== DayOptimizerColumnCode.NAME && column?.columnCode !== DayOptimizerColumnCode.ACTIONS);
      return JSON.stringify(filteredTempColumns) !== JSON.stringify(filteredShuffleColumns);
    } catch (error) {
      console.error('Error comparing columns:', error);
      return false;
    }
  };  

  const handleApplyForColumnFilter = async () => {
    if (!isColumnChanged()) return;
    if (gridConfigurationData?.accountUuid && gridConfigurationData?.userUuid) {
      putGridConfigurationData(gridConfigurationData?.id, tempColumns as IGridColumn[]);
      dispatch({ type: COLUMN_SHUFFLE_DROP_DOWN_LIST, payload: tempColumns });
    } else {
      const response = await postGridConfigurationData(DAY_OPTIMIZER_TABLE_CODE, DAY_OPTIMIZER_ENTITY_CODE, tempColumns as IGridColumn[]);
      dispatch({ type: GRID_CONFIGURATION_DATA, payload: response });
      setResetButtonEnabled(true);
    }
    setPopoverVisible(false);
  };

  const handleColumnChange = async (changeType: DayOptimizerColumnChangeType, columnCode?: string, result?: any) => {
    let updatedList = tempColumns.filter(column => column.columnCode !== DayOptimizerColumnCode.NAME && column.columnCode !== DayOptimizerColumnCode.ACTIONS);
    const nameColumn = tempColumns.find(column => column.columnCode === DayOptimizerColumnCode.NAME);
    const actionsColumn = tempColumns.find(column => column.columnCode === DayOptimizerColumnCode.ACTIONS);
    
    switch (changeType) {
      case DayOptimizerColumnChangeType.PRIORITY:
        if (result && result.destination) {
          const [reorderedItem] = updatedList?.splice(result.source.index, 1);
          updatedList?.splice(result.destination.index, 0, reorderedItem);
          updatedList = updatedList?.map((item, idx) => ({
            ...item,
            sequence: idx + 1
          }));
        }
        break;
      case DayOptimizerColumnChangeType.VISIBILITY:
        if (columnCode !== undefined && columnCode !== DayOptimizerColumnCode.NAME && columnCode !== DayOptimizerColumnCode.ACTIONS) {
          const index = updatedList?.findIndex(item => item?.columnCode === columnCode);
          if (index !== -1) {
            updatedList[index] = { ...updatedList[index], isVisible: !updatedList[index].isVisible };
          }
        }
        break;
      case DayOptimizerColumnChangeType.RESET_DEFAULT:
        if (gridConfigurationData?.id) {
          setColumnChangeLoading(true);
          try {
            const response = await putGridConfigurationDataToDefault(DAY_OPTIMIZER_TABLE_CODE, gridConfigurationData?.id, DAY_OPTIMIZER_ENTITY_CODE);
            dispatch({ type: GRID_CONFIGURATION_DATA, payload: response });
            setTempColumns(response?.gridColumns?.map((column: ColumnShuffleDropDownItem) => ({ ...column })));
          } finally {
            setColumnChangeLoading(false);
            setPopoverVisible(false);
            setResetButtonEnabled(true);
          }
        }
        return;
    }
    
    if (nameColumn) {
      nameColumn.sequence = 0;
      updatedList.unshift(nameColumn); 
    }
    
    if (actionsColumn) {
      actionsColumn.sequence = updatedList.length + 1;
      updatedList.push(actionsColumn);
    }
    
    setTempColumns(updatedList);
  };

  const getUserSearchParams = (searchString: string) => ({
    isActive: true,
    name: searchString ? `%${searchString}%` : '',
    limit: 20,
    userRoleCodes: ALLOWED_USER_ROLES_CARE_TEAM_ROLES_MAP[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER],
    orderBy: { name: 'asc' }
  });

  const [getUsers] = useLazyQuery(UserQueries.GET_USERS_BY_RESOURCE_CODES, {
    onCompleted: (data: any) => {
      let users = data?.searchUsers?.users || data?.users || [];
      const loggedInUser = users?.find((user: { uuid: string }) => user?.uuid === loggedInUserId);
      users = users?.filter((user: { uuid: string }) => user?.uuid !== loggedInUserId);
      
      if (loggedInUser) {
        const modifiedLoggedInUser = {
          ...loggedInUser,
          userRoles: [{
            userRole: {
              customRoleCode: 'YOU',
              roleName: 'You'
            }
          }]
        };
        users.unshift(modifiedLoggedInUser);
      }
      dispatch({
        type: USER_FETCH_LIST_SUCCESS,
        payload: users
      });
      setUserFetchLoading(false);
    },
    onError: (error) => {
      setUserFetchLoading(false);
    },
  });

  const fetchUsers = (searchText: string) => {
    setUserFetchLoading(true);
    getUsers({
      variables: {
        params: getUserSearchParams(searchText),
      },
    });
  };

  const handleUserSelect = (selectedUser: any) => {
    setSelectedProvider(selectedUser);
    dispatch({ type: USER_SELECT, payload: selectedUser });
  };

  const walkDate = (direction: number) => {
    const newDate = changeDateHelper(currentDate, direction);
    setCurrentDate(newDate);
    dispatch({ type: DATE_CHANGE, payload: newDate });
  };

  const changeDate = (date: Date) => {
    setCurrentDate(date);
    dispatch({ type: DATE_CHANGE, payload: date });
  };

  const onFilterChange = (filter: IDoFilter) => {
    dispatch({ type: FILTER_CHANGED, payload: filter });
  }

  return {
    currentDate,
    selectedProvider,
    userList,
    handleUserSelect,
    walkDate,
    changeDate,
    fetchUsers,
    loading : userFetchLoading,
    handleColumnChange,
    handleApplyForColumnFilter,
    columns: tempColumns,
    columnChangeLoading,
    isColumnChanged,
    popoverVisible,
    setPopoverVisible,
    setFilterPopoverVisible,
    filterPopoverVisible,
    onFilterChange,
    filter,
    resetButtonEnabled
  };
};

export default useListingHeader;