import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useIntl} from 'react-intl';
import {
  Box,
  Divider,
  HStack,
  useToast,
  View,
  VStack,
  Stack,
  Text,
  IconButton,
  Alert,
  InfoOutlineIcon,
  Icon,
  Pressable,
  FlatList,
  Spinner,
  Skeleton,
} from 'native-base';
import {notification, Popover} from 'antd';
import Feather from 'react-native-vector-icons/Feather';
import {cloneDeep, debounce, get, isEqual} from 'lodash';
import ReactToPrint from 'react-to-print';
import {
  IAddOrUpdateNoteProps,
  ICategoryWiseOrder,
  INoteAuditTrail,
  INotesFormattedDataProps,
  IPatientNoteCardProps,
  NoteOperation,
  ViewType,
  IAction,
} from '../interfaces';
import {allowToCreateTaskForNote, getFoldAccessToken, getAccountId, getAccountUUID, getUserData, getUserUUID, isLoggedInUserHealthCoach, isPatientNoteMandatory, numericStringMask, allowToShareForNoteWithPatient, getSanitizeString, isSideCardEnabled, getNoteConfigurationKey, getLocationGroupIdFromLocationId} from '../../../../utils/commonUtils';
import {OrderType} from '../Orders/OrdersAndReports/OrderConstants';
import {CodeableConcept, DocumentReferenceContent, MedicationRequest, RequestGroup, DocumentReference} from 'fhir/r4';
import {BottomAction} from '../../../common/BottomActionsBar/interface';
import {FormContext, FormViewType} from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import {
  ICodeableParam,
  ICondition,
} from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/Conditions/interfaces';
import {IFormState} from '../PatientNotes/components/DocumentationView';
import {
  getDiagnosisFromHealthGorila,
  getIsShareWithEHRFlag,
  getPostDataForNotes,
} from '../PatientNotes/components/AddOrUpdateTemplate/helper';
import {forEachExtensiveFormComponent} from '../../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import {DocStatus, NoteEntry, IFormComponent, NoteActions} from '../PatientNotes/interfaces';
import {
  getExtensionValue,
  getMedOrdersFormattedForDocRef,
  getOrdersFormattedForDocRef,
  getOrderType,
  getOrderTypeFromCode,
  isPendingDraftOrder,
  isPendingMedicationOrder,
} from '../Orders/OrdersAndReports/OrderUtils';
import {
  getEHRName,
  getResourceAbilities, isOrderTypeEnabled, isOrderTypeEnabledWithCapability
} from '../../../../utils/capabilityUtils';
import {PatientNotePrintPreview} from './PatientNotePrintPreview';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {Colors} from '../../../../styles/Colors';
import SignIcon from '../../../../assets/Icons/SignIcon';
import {
  ELATION_NON_VISIT_NOTE_CODE,
  filterOrderContentFromDocumentRef,
  findUserById,
  getFilterResourceAudit,
  getFormattedNoteForCareTimeline,
  getFormContentByEhrAndFormData,
  getNoteHeaderText,
  getNoteSubHeaderText,
  hasChiefComplaintComponent,
  hasJsonStructure,
  isOrderTypeDataExist,
} from '../PatientNotes/PatientNotesHelper';
import {
  ELATION_NOTE_TYPE,
  PATIENT_NOTE_OPERATION,
} from '../PatientNotes/constants';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {BUTTON_TYPE, DATE_FORMATS, HTTP_ERROR_CODE, ORDER_TYPE, PHONE_NUMBER_MASK} from '../../../../constants/StringConst';
import Header from '../PatientNotes/components/Header/Header';
import {EXTENSION_URLS} from '../PatientNotes/components/AddOrUpdateTemplate/constant';
import {
  appointmentDataExist,
  getCapabilitiesFormComponents,
  getFormComponents,
  getSubmittedResponse,
} from '../PatientNotes/components/DocumentationViewHelper';
import {
  getAuditTrailByResourceId,
  getDocumentRefByReferenceId,
  getMedicationForNote,
  getOrderByRefAndPatientId,
} from '../../../../services/CommonService/OrderService';
import {GET_BOOKED_APPOINTMENT_BY_EXTERNAL_ID} from '../../../../services/Appointment/AppointmentQueries';
import {useLazyQuery, useMutation} from '@apollo/client';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {GET_FORM_BY_ID} from '../../../../services/Forms/FormsQueries';
import {FHForm} from '../../../RightSideContainer/Forms/FHFormio';
import TimeLineSkeletonLoader from '../../../common/TimeLineSkeletonLoader/TimeLineSkeletonLoader';
import {deleteDocumentReference, postDocumentsReferenceWithPatientId} from '../../../../services/CommonService/AidBoxService';
import {IFormSelectedValue} from '../PatientNotes/components/AddOrUpdateTemplate/interfaces';
import BottomActionsBar from '../../../common/BottomActionsBar/BottomActionsBar';
import MedOrderList from '../Orders/OrdersAndReports/MedOrderList/MedOrderList';
import OrderList from '../Orders/OrdersAndReports/OrderList/OrderList';
import NoNotes from '../PatientNotes/components/NoNotes';
import AppointmentBanner from '../PatientNotes/components/AppointmentBanner';
import { AppointmentTasksDrawer } from '../../../common/AppointmentTaskCheckList/AppointmentTasksDrawer';
import { FHAlertDialog } from '../../../common/FHAlertDialog';
import { FHIR_RESOURCE } from '../../../../constants/FhirConstant';
import { IRequestGroup } from '../../LeftContainer/RecentActivity/OrdersInterface';
import AddOrUpdateOrders from '../Orders/OrdersAndReports/AddOrUpdateOrders/AddOrUpdateOrders';
import { isWeb } from '../../../../utils/platformCheckUtils';
import PatientNoteFormView from './PatientNoteFormView';
import { ComponentToPrint } from '../../../common/PrintComponent/ComponentToPrint';
import { allowNoteOperation, getBroadcastEventCode } from './CareTimelineUtils';
import { BOOTSTRAP_CDN_URL, FORM_HTML_STYLES, NOTE_ACTION_ERROR_CODES } from './CareTimelineConstant';
import EmptyFormForNoteIcon from '../../../../assets/svg/EmptyNotes';
import { CommonDataContext, ICommonData } from '../../../../context/CommonDataContext';
import ProviderSelectionForNoteSave from './components/ProviderSelectionForNoteSave/ProviderSelectionForNoteSave';
import { IMlov, IUser } from '../../../../Interfaces';
import ReactHtmlParser from 'react-html-parser';
import { getCurrentTimeZoneAbbr, getDateStrFromFormat, getDateToMomentISOString, getDefaultStartAndEndDateForNoteTask } from '../../../../utils/DateUtils';
import DetailPreview from '../PersonDetailsView/DetailPreview/DetailPreview';
import TaskQueries, { GET_TASK_LABELS } from '../../../../services/Task/TaskQueries';
import WriteInfoBackBanner from './components/WriteInfoBackBanner';
import { getComponentKey } from '../../../PublicPages/PublicForm/PublicFormHelper';
import { EventBus } from '../../../../utils/EventBus';
import ShowToPatientSwitch from './components/ShowToPatientSwitch';
import { IUserPracticeLocation } from '../../../../services/Location/interfaces';
import { getAddressLine } from '../../../RightSideContainer/EmployerManagement/Helper';
import { PatientNotePrintPreviewV2 } from './PatientNotePreviewV2';
import FoldButtonV2, { ButtonType } from '../CarePlan/components/FoldButtonV2';
import SelectDownArrowSvg from '../../../common/Svg/SelectDownArrowSvg';
import SignIconSvg from '../../../common/Svg/SignIconSvg';
import PrintIconSvg from '../../../common/Svg/PrintIconSvg';
import { StyleSheet } from 'react-native';
import { getComponentsForShareablePrint } from './NoteUtils';
import { MD_CONFIG, SM_CONFIG } from '../../../../theme/FontConfig/TextFontSizeConfig';

export enum SaveType {
  SAVE = 'save',
  SAVE_AND_SIGN = 'saveAndSign',
}


const useScroll = () => {
  const elRef: any = useRef(null);
  const executeScroll = () => {
    setTimeout(() => {
      if (
        elRef?.current &&
        elRef.current.scroll &&
        typeof elRef.current.scroll === 'function'
      ) {
        elRef.current.scroll(0, elRef.current.scrollHeight);
      }
    }, 2000);
  };

  return [executeScroll, elRef];
};

const AddOrUpdatePatientNote = (props: IAddOrUpdateNoteProps) => {
  const intl = useIntl();
  const userUUID = getUserUUID();
  const accountUUID = getAccountUUID();
  const toast = useToast();
  const context = useContext(CommonDataContext);
  const foldAccessToken = getFoldAccessToken();
  const {
    header,
    footer,
    isPhysicianUser,
    defaultTemplate,
    noteData,
    isNewNote,
    personData,
    unFormattedContactData,
    ehrConfig,
    ehrCapabilities,
    accountUserList,
    currentUserData,
    elationFormData,
    hideBottomOrderActionBar,
    prefillData,
    viewType,
    disableDefaultTemplateChange,
    navigation,
    onTemplateChangeCallback,
    noteLabels,
    isNewView
  } = props;

  const isSidecarContext = context.sidecarContext?.isSidecar;
  const renderNewView = isSidecarContext || props?.isNewView;

  const ref = useRef<HTMLDivElement>(null);
  const shareComponentRef = useRef<HTMLDivElement>(null);
  const formInstance = useRef<any>(null);
  const componentViewType = viewType || ViewType.INLINE;
  const { isFold, isAthena } = ehrConfig;
  const currentEHR: any = getEHRName(personData?.accountLocationUuid, '');
  const enableScroll = false;
  const isHealthCoach = isLoggedInUserHealthCoach();
  const resourceId = noteData?.resourceId;
  const userSettings = context.userSettings;
  const formOption = {
    userSettings,
    accountUUID,
    ehrCapabilities,
    formContext: FormContext.patientNotes,
    MLOV: context.MLOV,
    CARE_STUDIO_MLOV: context.CARE_STUDIO_MLOV,
    CLOUD_TELEPHONY_MLOV: context.CLOUD_TELEPHONY_MLOV,
  };
  const [onSaveLoading, setOnSaveLoading] = useState({
    save: false,
    saveAndSign: false,
  });
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    personData?.accountLocationUuid
  );
  const elationFormDataDetail = {
    categories: resourceAbilities?.allowedCategories,
    noteComponents: resourceAbilities?.allowedNoteComponents,
  };
  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled;
  const [readOnlyFH ,setReadOnly] = useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [
    isAppointmentChecklistDrawerOpen,
    setIsAppointmentChecklistDrawerOpen,
  ] = useState(false);
  const [documentReferenceId, setDocumentReferenceId] = useState<string>(
    `${noteData?.resourceId || ''}`
  );
  const linkTaskId = noteData?.linkTasks?.length ? noteData.linkTasks[0]?.id : '';
  const [documentReferenceTaskId, setDocumentReferenceTaskId] = useState<string>(linkTaskId);
  const loggedInUser = findUserById(userUUID, accountUserList);
  const [formComponentLoadingStatus, setFormComponentLoadingStatus] = useState<{
    map: {[key: string]: boolean};
    isAnyFormLoading: boolean;
  }>({
    map: {},
    isAnyFormLoading: true,
  });
  const patientNoteIsMandatory: boolean = isPatientNoteMandatory(userSettings);
  const allowToCreateTask: boolean = allowToCreateTaskForNote(userSettings);
  const patientLocation = personData?.accountLocationUuid;
  const locationGroupId = getLocationGroupIdFromLocationId(
    context?.accountLocationListWithEHR,
    patientLocation
  );

  const allowToSharePatientHtml = allowToShareForNoteWithPatient(userSettings);
  // TODO :  create new setting to share patient html
  const [noteTitle, setNoteTitle] =useState({
    title:  noteData?.description,
    isValid: !patientNoteIsMandatory ? true : patientNoteIsMandatory && noteData?.description && noteData?.description?.trim().length ? true : false,
    noteUpdated: false,
  });
  // const [createTask] = useMutation(TaskQueries.ADD_OR_UPDATE_TASK);
  // const [updateTask] = useMutation(TaskQueries.UPDATE_TASK);
  // const taskStatusList: IMlov[] = getMlovListFromCategory(context.CARE_STUDIO_MLOV, MLOV_CATEGORY.TASK_STATUS) || [];
  // const defaultStatusId: string = getDefaultTaskStatusId(taskStatusList);
  // const missedStatusId: string = getMissedTaskStatusId(taskStatusList);
  // const completedStatusId = getCompletedTaskStatusId(taskStatusList);
  // const taskPriorityList: IMlov[] = getMlovListFromCategory(context.CARE_STUDIO_MLOV, MLOV_CATEGORY.TASK_PRIORITY, false);
  // const defaultPriorityId = getDefaultPriorityId(taskPriorityList);
  const loginUserData = getUserData();

  const updateFormComponentsLoadingStatus =
    (componentKey: string, isLoading: boolean) => {
      if (componentKey) {
        setFormComponentLoadingStatus((prev) => {
          const formLoadingStatus = {
            ...prev.map,
            [componentKey]: isLoading,
          };
          const isAnyFormLoading = !!Object.values(
            formLoadingStatus || {}
          ).some((isFormLoading) => isFormLoading);
          return {
            ...prev,
            isAnyFormLoading: isAnyFormLoading,
            map: {
              ...formLoadingStatus,
            },
          };
        });
      }
    };

  const getShareComponents = (components: any[]): any[] => {
    return (components || []).filter(component => {
      return component?.allowToShare;
    })
  };

  const checkIsElationNotes = () => {
    if(props.isNewNote) {
      return !props.noteData?.isFoldDrivenNote && !props.defaultTemplate?.formId
    }
    return !props.noteData?.isFoldDrivenNote;
  }

  const isElationTemplate = checkIsElationNotes()

  const [draftChanges, setDraftChanges] = useState<any>();
  const [submitData, setSubmitData] = useState<any>();
  const [addOrUpdateNoteState, setAddOrUpdateTemplateState] = useState<{
    labOrders: DocumentReferenceContent[];
    radOrders: DocumentReferenceContent[];
    medOrders: DocumentReferenceContent[];
    firstRender: boolean;
    currentAvailableActions: BottomAction[];
    activeActions?: {
      [index: string]: IFormComponent[];
    };
    isAddNewOrderModalOpen: boolean;
    selectedOrderType: any;
    renderLabOrder: boolean;
    renderMedOrder: boolean;
    renderRadOrder: boolean;
    startRenderPrintComponent: boolean;
    isPrintComponentRender: boolean;
    templateData: IFormState;
    appointmentData: any;
    defaultNote: any;
    selectedTemplateId: any;
    isLoading: boolean;
    formOption: any;
    noteType: any;
    isProcessing: boolean;
    isNoNoteSelected: boolean;
    isReadOnly: boolean;
    isConfirmationModalOpen: boolean;
    alreadySignByUserName: string;
    updatedSignedNote: any;
    labOrderFlatList: IRequestGroup[];
    radOrderFlatList: IRequestGroup[];
    medOrderFlatList: IRequestGroup[];
    isDraftOrderConfirmationModalOpen: boolean;
    draftOrderNoteParams: any;
    resourceAudit: INoteAuditTrail[];
    htmlGenerateFormOptions: any,
    sharedComponents: any[],
    printShareable: boolean,
    shownToPatient: boolean;
    isShareWithEHR: boolean;
    isElationTemplate: boolean;
    notePrintPreviewComponents?: any[];
    notePrintPreviewAnswers?: any;
    printHtmlString: string;
    manualActionCode?: string;
    manualActionData?: any;
  }>({
    labOrders: [],
    radOrders: [],
    medOrders: [],
    firstRender: true,
    currentAvailableActions: [],
    activeActions: {},
    isAddNewOrderModalOpen: false,
    selectedOrderType: '',
    renderLabOrder: false,
    renderMedOrder: false,
    renderRadOrder: false,
    startRenderPrintComponent: false,
    isPrintComponentRender: false,
    templateData: {} as IFormState,
    appointmentData: undefined,
    defaultNote: defaultTemplate,
    selectedTemplateId: noteData?.formId || '',
    isLoading: true,
    formOption: {
      userSettings,
      accountUUID,
      ehrCapabilities,
      formContext: FormContext.patientNotes,
      patientId: personData?.patientId,
      contactId: personData?.contactUUID,
      foldAccessToken,
      MLOV: context.MLOV,
      CARE_STUDIO_MLOV: context.CARE_STUDIO_MLOV,
      CLOUD_TELEPHONY_MLOV: context.CLOUD_TELEPHONY_MLOV,
      accountLocationUuid: patientLocation,
    },
    htmlGenerateFormOptions: {
      userSettings,
      accountUUID,
      ehrCapabilities,
      formContext: FormContext.patientNotes,
      patientId: personData?.patientId,
      contactId: personData?.contactUUID,
      foldAccessToken,
      //updateLoadingStatus: updateFormComponentsLoadingStatus,
      MLOV: context.MLOV,
      CARE_STUDIO_MLOV: context.CARE_STUDIO_MLOV,
      CLOUD_TELEPHONY_MLOV: context.CLOUD_TELEPHONY_MLOV,
      isDoNotAllowToReplaceNextLine: true,
      accountLocationUuid: patientLocation,
    },
    noteType: '',
    isProcessing: false,
    isNoNoteSelected: false,
    isReadOnly: isElationTemplate,
    isConfirmationModalOpen: false,
    alreadySignByUserName: '',
    updatedSignedNote: undefined,
    labOrderFlatList: [],
    radOrderFlatList: [],
    medOrderFlatList: [],
    isDraftOrderConfirmationModalOpen: false,
    draftOrderNoteParams: undefined,
    resourceAudit: [],
    shownToPatient: false,
    sharedComponents: [],
    printShareable: false,
    isShareWithEHR: true,
    isElationTemplate: isElationTemplate,
    notePrintPreviewComponents: [],
    notePrintPreviewAnswers: {},
    printHtmlString: '',
    manualActionCode: '',
    manualActionData: {}
  });

  const isFoldFormDrivenNotes = !addOrUpdateNoteState.isElationTemplate;
  const allowWriteBack = isFoldFormDrivenNotes;

  const allowToToggleShowToPatient: boolean = isWeb() && isFoldFormDrivenNotes;

  const getNoteLinkTaskComponentValueLabel = (): { value: string | undefined, isTaskOrNoteLabelComponentExist: boolean } => {
    let noteLinkTaskComponent: any = undefined;
    let selectedValueLabel: string | undefined = undefined;
    if (addOrUpdateNoteState?.templateData?.components?.length) {
      forEachExtensiveFormComponent(
        addOrUpdateNoteState?.templateData?.components,
        (component) => {
          if (component?.allowToChangeNoteTaskTitle) {
            noteLinkTaskComponent = component;
          }
        }
      );
    }
    if (noteLinkTaskComponent) {
      const selectedValue = noteLinkTaskComponent?.selectedValue || undefined;
      const dataValuesArray =  noteLinkTaskComponent?.data?.values || [];
      selectedValueLabel = dataValuesArray?.find(
        (item: any) => item?.value === selectedValue
      )?.label;
    }
    return {value: selectedValueLabel, isTaskOrNoteLabelComponentExist: !!noteLinkTaskComponent};
  };

  const {sharedComponents: shareComponents} = addOrUpdateNoteState;
  const isFormEmpty = !addOrUpdateNoteState?.templateData?.components || addOrUpdateNoteState?.templateData?.components?.length === 0;
  const linkTaskComponent: { value: string | undefined, isTaskOrNoteLabelComponentExist: boolean } = getNoteLinkTaskComponentValueLabel();
  const isTaskOrNoteLabelComponentExist = linkTaskComponent?.isTaskOrNoteLabelComponentExist;

  let notesTitle: string | undefined = '';
  if (isTaskOrNoteLabelComponentExist && linkTaskComponent?.value) {
    notesTitle = linkTaskComponent?.value;
  } else if (isTaskOrNoteLabelComponentExist && !linkTaskComponent?.value) {
    notesTitle = noteData?.formName || noteData?.description;
  } else {
    notesTitle = documentReferenceId && noteData?.description ? noteData?.description : noteData?.formName;
  }
  const handleSaveLoading = (type: SaveType, value: boolean) => {
    setOnSaveLoading((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };

  const [executeScroll, elRef] = useScroll();
  useEffect(() => {
    if (enableScroll) {
      executeScroll;
    }
  }, []);
  const componentRef = useRef(null);
  const printRef: any = useRef(null);
  const isActionListEmpty = () =>
    addOrUpdateNoteState.currentAvailableActions.length === 0;
  const getCategoryWiseOrderData = (
    responseList: any,
    orderBySequence: any[],
  ): ICategoryWiseOrder => {
    const categoryWiseOrders: ICategoryWiseOrder = {
      labTest: [],
      radiology: [],
      medications: [],
    };
    if (responseList?.length) {
      (orderBySequence || []).forEach((sequence, index) => {
        const response = responseList[index];
        const resourceList = response?.data?.entry || [];
        switch (sequence) {
          case ORDER_TYPE.LAB:
            categoryWiseOrders.labTest = resourceList;
            break;
          case ORDER_TYPE.RAD:
            categoryWiseOrders.radiology = resourceList;
            break;
          case ORDER_TYPE.MED:
            categoryWiseOrders.medications = resourceList;
            break;
        }
      });
    }
    return categoryWiseOrders;
  };
  const isReadOnly = () => {
    if (!!documentReferenceId) {
      return !isFoldFormDrivenNotes || props.noteData?.status === DocStatus.FINAL;
    } else {
      return false;
    }
  };
  const debouncedSave = useCallback(
    debounce(newData => {
      if (!isEqual(newData, draftChanges)) {
        setDraftChanges((prev: any) => ({...prev, ...newData}));
      }
    }, 1000),
    [setDraftChanges],
  );
  const handleFormChange = useCallback((data: any) => {
    setSubmitData((prev: any) => ({...prev, ...cloneDeep(data)}));
    if (noteData?.isAllowToAutoSave) {
      debouncedSave({...data});
    } else {
      if (!isEqual(data, draftChanges)) {
        setDraftChanges((prev: any) => ({...prev, ...data}));
      }
    }
  }, []);

  const getExistingDiagnosis = () => {
    const conditions: ICondition[] = addOrUpdateNoteState?.templateData?.answers?.data?.diagnosis?.conditions || [];
    return conditions.map((item) => item.condition) as CodeableConcept[];
  }

  const addDiagnosisFormOrderList = (data: ICodeableParam[]) => {
    if (!data) {
      return;
    }
    const diagnosis: ICondition[] = getDiagnosisFromHealthGorila(
      data,
      addOrUpdateNoteState?.templateData?.answers,
    );
    const newDiagnosis = new Map();
    [
      ...diagnosis,
      ...(addOrUpdateNoteState?.templateData?.answers?.data?.diagnosis
        ?.conditions || []),
    ].forEach(item => newDiagnosis.set(item.name, item));
    const updatedDiagnosis = {
      data: {
        diagnosis: {
          conditions: [...newDiagnosis.values()],
        },
        metadata: {},
      },
    };
    const updatedComponents =
      addOrUpdateNoteState?.templateData?.components || [];
    let hasDiagnosisField = false;
    forEachExtensiveFormComponent(updatedComponents, item => {
      if (item?.key === 'diagnosis') {
        item.selectedValue = updatedDiagnosis?.data?.diagnosis;
        hasDiagnosisField = true;
      }
    });
    if (!hasDiagnosisField) {
      return;
    }
    // TODO - update diagnosis
    // handleFormStateChange({
    //   ...formState,
    //   components: updatedComponents,
    //   answers:{data:{...formState?.answers?.data, ...updatedDiagnosis.data}},
    // });
    setAddOrUpdateTemplateState((prev) => ({
      ...prev,
      templateData: {
        ...prev.templateData,
        components: updatedComponents,
        sharedComponents: updatedComponents.filter(component => {
          return component.allowToShare;
        }),
        answers: {
          data: {
            ...prev?.templateData?.answers?.data,
            ...updatedDiagnosis.data,
          },
        },
      },
    }));
  };

  function getStatusWiseCountOfAppointmentTasks() {
    let pendingTasks = 0;
    let completedTasks = 0;

    if (!addOrUpdateNoteState?.appointmentData?.tasks?.length) {
      return {
        pendingTasks,
        completedTasks,
      };
    }

    for (const task of addOrUpdateNoteState?.appointmentData.tasks) {
      if (task.isCompleted) {
        completedTasks += 1;
      } else {
        pendingTasks += 1;
      }
    }

    return {
      pendingTasks,
      completedTasks,
    };
  }

  function areAllAppointmentTasksAreCompleted() {
    const {pendingTasks} = getStatusWiseCountOfAppointmentTasks();
    return pendingTasks ? false : true;
  }


  const processOnFormAndNoteData = (
    formData: any,
    documentRefData: any,
    appointmentData?: any,
    resourceAuditData?: any
  ) => {
    const orderBySequence: any[] = [];
    const orderPromiseList: any[] = [];
    const initData = {
      formContent: [] as any[],
    };
    let formComponents: any[] = [];
    let formAnswer: any = {};
    let sequence = -1;

    const auditTrailList = resourceAuditData &&  resourceAuditData?.length ? getFilterResourceAudit(resourceAuditData) : [];
    // process on reference data
    const patientContactId = personData?.id || '';
    const patientId = personData?.patientId || personData?.patientUuid || '';
    const documentRefContent =
      filterOrderContentFromDocumentRef(documentRefData);
    const additionalSections = getExtensionValue(
      documentRefData,
      EXTENSION_URLS.docRefAdditionalSection,
    );
    const formContent: IFormComponent[] = getFormContentByEhrAndFormData(
      documentRefData?.content || [],
      isFoldFormDrivenNotes,
    );
    if (formData?.form) {
      initData.formContent = formContent;
      formComponents = getFormComponents(
        formData?.form?.components,
        appointmentData,
        initData,
        !!appointmentData?.externalAppointmentId,
        !!prefillData && !!prefillData?.length ? prefillData : undefined,
      );
      formAnswer = getSubmittedResponse(
        initData,
        appointmentData,
        !!appointmentData?.externalAppointmentId,
        hasChiefComplaintComponent(formData),
        !!prefillData && !!prefillData?.length ? prefillData : undefined,
      );
    }
    if (documentRefContent?.length || additionalSections?.length) {
      if (
        isOrderTypeDataExist(ORDER_TYPE.LAB, documentRefContent) ||
        additionalSections?.includes(ORDER_TYPE.LAB)
      ) {
        sequence += 1;
        orderBySequence[sequence] = ORDER_TYPE.LAB;
        orderPromiseList.push(
          getOrderByRefAndPatientId(
            OrderType.LAB,
            patientId,
            `${resourceId || ''}`,
            personData?.accountLocationUuid,
          ),
        );
      }
      if (
        isOrderTypeDataExist(ORDER_TYPE.RAD, documentRefContent) ||
        additionalSections?.includes(ORDER_TYPE.RAD)
      ) {
        sequence += 1;
        orderBySequence[sequence] = ORDER_TYPE.RAD;
        orderPromiseList.push(
          getOrderByRefAndPatientId(
            OrderType.RAD,
            patientId,
            `${resourceId || ''}`,
            personData?.accountLocationUuid,
          ),
        );
      }
      if (
        isOrderTypeDataExist(ORDER_TYPE.MED, documentRefContent) ||
        additionalSections?.includes(ORDER_TYPE.MED)
      ) {
        sequence += 1;
        orderBySequence[sequence] = ORDER_TYPE.MED;
        orderPromiseList.push(
          getMedicationForNote(patientContactId, `${resourceId || ''}`),
        );
      }
    }

    Promise.all(orderPromiseList)
      .then((responseList: any) => {
        const categoryWiseOrderData: ICategoryWiseOrder =
          getCategoryWiseOrderData(responseList, orderBySequence);
        const templateData = {
          components: formComponents,
          answers: formAnswer,
          name: formData?.form?.name,
          id: formData?.form?.id,
        };
        const updatedSharedComponents = getShareComponents(formComponents);

        const labOrders = categoryWiseOrderData?.labTest || [];
        const radOrders = categoryWiseOrderData?.radiology || [];
        const medOrders = categoryWiseOrderData?.medications || [];
        const activeActions: any = {};
        const renderLabOrder = labOrders?.length > 0;
        if (!renderLabOrder) {
          activeActions[ORDER_TYPE.LAB as keyof typeof activeActions] = true;
        }
        const renderRadOrder = radOrders?.length > 0;
        if (!renderRadOrder) {
          activeActions[ORDER_TYPE.RAD as keyof typeof activeActions] = true;
        }
        const renderMedOrder = medOrders?.length > 0;
        if (!renderMedOrder) {
          activeActions[ORDER_TYPE.MED as keyof typeof activeActions] = true;
        }
        const currentAvailableActions =
          getCurrentAvailableActions(activeActions);
          if (formData?.form?.name && !noteData?.resourceId) {
            noteData.description = formData?.form?.name;
            setNoteTitle((prev) => {
              return {
                ...prev,
                title: formData?.form?.name,
                noteUpdated: true,
              };
            });
          }

        setAddOrUpdateTemplateState((prev: any) => {
          return {
            ...prev,
            templateData,
            categoryWiseOrderData,
            // This was causing to set RequestGroup items to labOrders, radOrders etc causing an error on auto save, which was giving note already deleted toast
            // labOrders,
            // radOrders,
            // medOrders,
            labOrders: getOrdersFormattedForDocRef(labOrders.map((item: any) => item.resource ? item.resource : item), OrderType.LAB),
            radOrders: getOrdersFormattedForDocRef(radOrders.map((item: any) => item.resource ? item.resource : item), OrderType.RAD),
            medOrders: getOrdersFormattedForDocRef(medOrders.map((item: any) => item.resource ? item.resource : item), OrderType.MED),
            isLoading: false,
            isProcessing: false,
            renderLabOrder,
            renderRadOrder,
            renderMedOrder,
            currentAvailableActions,
            appointmentData,
            resourceAudit: auditTrailList || [],
            shownToPatient: formData?.form.isShownToPatient,
            sharedComponents: updatedSharedComponents,
            isShareWithEHR: getIsShareWithEHRFlag(formData?.form.isShareWithEHR),
          };
        });
      })
      .catch(error => {
        setAddOrUpdateTemplateState((prev: any) => {
          return {
            ...prev,
            isLoading: false,
            isProcessing: false,
          };
        });
      });
  };

  const [getAppointmentByExtId] = useLazyQuery(
    GET_BOOKED_APPOINTMENT_BY_EXTERNAL_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      variables: {
        externalAppointmentId: noteData?.linkedAppointmentId,
      },
    },
  );

  const [getFormById] = useLazyQuery(GET_FORM_BY_ID, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const [getTaskLabels] = useLazyQuery(GET_TASK_LABELS, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const isAllowToShowAudit = () => {
    return noteData?.status === DocStatus.FINAL || noteData?.signedByUserId || noteData?.signedDate;
  }

  const fetchFormAndNoteOrders = async () => {
    try {
      const promiseList: any[] = [
        getFormById({variables: {id: addOrUpdateNoteState.selectedTemplateId}}),
        getDocumentRefByReferenceId(
          `${documentReferenceId}`,
          !addOrUpdateNoteState.isElationTemplate && foldVisitNoteWithEncountersEnabled,
          personData?.accountLocationUuid,
        ),
      ];
      if (noteData?.linkedAppointmentId) {
        promiseList.push(
          getAppointmentByExtId({
            variables: {externalAppointmentId: noteData?.linkedAppointmentId},
          }),
        );
      }
      if (noteData?.status === DocStatus.FINAL ||
          noteData?.signedByUserId || noteData?.signedDate) {
        promiseList.push(getAuditTrailByResourceId(`${documentReferenceId}`, personData?.accountLocationUuid));
      }
      const noteResponse = await Promise.all(promiseList);
      const formData = noteResponse?.[0]?.data || undefined;
      const documentRefData = noteResponse?.[1]?.data || undefined;
      let appointmentData = undefined;
      let resourceAuditData = undefined;
      if (noteData?.linkedAppointmentId) {
        appointmentData = noteResponse?.[2]?.data || undefined;
        if (isAllowToShowAudit()) {
          resourceAuditData = noteResponse?.[3]?.data || undefined;
        }
      } else if (isAllowToShowAudit()) {
        resourceAuditData = noteResponse?.[2]?.data || undefined;
      }
      await processOnFormAndNoteData(
        formData,
        documentRefData,
        appointmentData?.appointments?.[0],
        resourceAuditData
      );
    } catch (error: any) {
      if (error && error?.response?.status === HTTP_ERROR_CODE.INVALID_INPUT) {
        showToast(
          toast,
          `This note has already deleted`,
          ToastType.error,
          2000
        );
        handleGoBack(true);
        return;
      }
      setAddOrUpdateTemplateState(prev => ({...prev, isLoading: false}));
    }
  };

  const makeDatabaseEntry = (form: {id: string, name: string, isShownToPatient?: boolean, isShareWithEHR?: boolean}) => {
    const {id , name, isShownToPatient, isShareWithEHR} = form;
    handleSaveLoading(SaveType.SAVE, true);
    const selectedUser = findUserById(userUUID, accountUserList);
    const data = getPostDataForNotes([], personData, {
      associatedTaskId: documentReferenceTaskId,
      currentEHR,
      appointmentId:
        noteData?.linkedAppointmentId ||
        addOrUpdateNoteState?.appointmentData?.externalAppointmentId ||
        '',
      userUUID: !isFoldFormDrivenNotes
        ? selectedUser?.externalUserId || ''
        : selectedUser?.userUUID,
      isSign: false,
      formId: id,
      formName: name,
      prevAuthorId: noteData?.authorUuid,
      prevResourceDate: noteData?.createdDate,
      orders: [],
      id: documentReferenceId || '',
      isAmend: false,
      foldVisitNoteWithEncountersEnabled,
      noteTitle: noteTitle?.title || name,
      allowToShowToPatient: isShownToPatient,
      isShareWithEHR: getIsShareWithEHRFlag(isShareWithEHR),
      isElationTemplate: addOrUpdateNoteState.isElationTemplate
    });
    if (
      isFoldFormDrivenNotes &&
      !!data?.extension?.filter((item: any) => item?.valueString === '')?.length
    ) {
      showToast(toast, `Something went wrong`, ToastType.error);
      return;
    }
    handleSaveLoading(SaveType.SAVE, true);
    postDocumentsReferenceWithPatientId(personData.patientId, data, {
      id: documentReferenceId || '',
      isUpdate: !!documentReferenceId,
      currentEHR: currentEHR,
      locationId: personData?.accountLocationUuid,
    }, isFoldFormDrivenNotes)
      .then(res => {
        if (res?.data?.id) {
          // check allow to create task when note create
          // if (!documentReferenceId && allowToCreateTask) {
          //   const labels: any = [];
          //   const noteStatusWiseLabels : string[] = getLabelByNotStatusAndSubStatus() || [];
          //   if (noteStatusWiseLabels?.length) {
          //     noteStatusWiseLabels.forEach(labelUuid => {
          //       labels.push({
          //         labelId: labelUuid,
          //       });
          //     })
          //   }
          //   createTaskForNote(res?.data, labels);
          // }
          setDocumentReferenceId(res?.data?.id);
          const documentRef: DocumentReference = res?.data;
          const taskId = documentRef.extension?.find(extension => extension.url === EXTENSION_URLS.taskId)?.valueString;
          setDocumentReferenceTaskId(taskId || '');
          handleSaveLoading(SaveType.SAVE, false);
          if (!noteData?.description) {
            noteData.description = name;
            setNoteTitle(prev => {
              return {
                ...prev,
                title: name || noteTitle?.title,
                isValid: true
              }
            });
          }
        }
      })
      .catch(err => {
        setAddOrUpdateTemplateState(prev => ({...prev, isLoading: false}));
        handleSaveLoading(SaveType.SAVE, false);
        showToast(toast, `Something went wrong`, ToastType.error);
      });
  };

  const getFormByTemplateId = async (templateId: string) => {
    await getFormById({
      variables: {id: templateId},
      onCompleted: (data: any) => {
        if (!documentReferenceId && data?.form) {
          makeDatabaseEntry(data?.form);
        }
      },
    });
  };
  useEffect(() => {
    if (addOrUpdateNoteState.firstRender) {
      setAddOrUpdateTemplateState(prev => ({...prev, firstRender: false}));
      if (enableScroll) {
        executeScroll();
      }
    } else if (
      (draftChanges?.changed || !documentReferenceId) &&
      isFoldFormDrivenNotes && addOrUpdateNoteState.selectedTemplateId
    ) {
      saveForm({isSign: false, isAutoSave: true});
    }
  }, [draftChanges, noteTitle?.title]);

  const getLocalFormComponents = () => {
    const data = getCapabilitiesFormComponents(
      elationFormDataDetail,
      addOrUpdateNoteState.selectedTemplateId,
      {
        formContent: noteData?.formContent || [],
        formName: noteData?.formName || '',
      },
      !!prefillData && !!prefillData?.length ? prefillData : undefined,
    );
    setAddOrUpdateTemplateState(prev => ({
      ...prev,
      templateData: data,
      isLoading: false,
    }));
  };

  useEffect(() => {
    if (documentReferenceId) {
      if(addOrUpdateNoteState.isElationTemplate) {
        getLocalFormComponents();
        return;
      }
      setAddOrUpdateTemplateState(prev => ({
        ...prev,
        isLoading: true,
      }));
      fetchFormAndNoteOrders();
    } else {
      if (addOrUpdateNoteState.isElationTemplate) {
        setAddOrUpdateTemplateState(prev => ({
          ...prev,
          isNoNoteSelected: addOrUpdateNoteState?.selectedTemplateId ? false : true,
          isLoading: true,
        }));
        getLocalFormComponents();
        return;
      } else {
        if (addOrUpdateNoteState?.selectedTemplateId) {
          getFormByTemplateId(addOrUpdateNoteState?.selectedTemplateId);
          setAddOrUpdateTemplateState(prev => ({
            ...prev,
            isNoNoteSelected: false,
          }));
        } else if (props.defaultTemplate?.formId) {
          getFormByTemplateId(props.defaultTemplate?.formId);
        } else {
          setAddOrUpdateTemplateState(prev => ({
            ...prev,
            isNoNoteSelected: true,
            isLoading: false,
          }));
        }
      }
    }
  }, [documentReferenceId, addOrUpdateNoteState?.selectedTemplateId]);

  const showOrderModalByCode = (code: string) => {
    switch (code) {
      case getOrderType(OrderType.LAB):
        setAddOrUpdateTemplateState(prev => {
          return {
            ...prev,
            // As we have external lab and HG lab as 2 options, we cannot open directly the modal drawer
            // isAddNewOrderModalOpen: true,
            selectedOrderType: OrderType.LAB,
            renderLabOrder: true,
          };
        });
        break;
      case getOrderType(OrderType.MED):
        setAddOrUpdateTemplateState(prev => {
          return {
            ...prev,
            isAddNewOrderModalOpen: true,
            selectedOrderType: OrderType.MED,
            renderMedOrder: false,
          };
        });
        break;
      case getOrderType(OrderType.RAD):
        setAddOrUpdateTemplateState(prev => {
          return {
            ...prev,
            // As we have external lab and HG rad as 2 options, we cannot open directly the modal drawer
            // isAddNewOrderModalOpen: true,
            selectedOrderType: OrderType.RAD,
            renderRadOrder: true,
          };
        });
        break;
    }
  };

  const syncHealthComponents = () => {
    if (!allowWriteBack) {
      return;
    }
    const flatFormComponents: IFormSelectedValue[] = [];

    const updatedComponents =
      addOrUpdateNoteState.templateData?.components || [];
    forEachExtensiveFormComponent(updatedComponents || [], component => {
      flatFormComponents.push({
        selectedValue: component.selectedValue,
        type: component.type,
        key: component.key,
      });
    });

    const eventBus = EventBus.getEventBusInstance();
    const eventIds = new Set<string>();
    flatFormComponents.forEach((item: any) => {
      const key = getComponentKey(item.key);
      const eventCode = getBroadcastEventCode(key);
      if (eventCode && !eventIds.has(eventCode)) {
        eventBus.broadcastEvent(eventCode, FormViewType.note);
        eventIds.add(eventCode);
      }
    });
  }

  const handleGoBack = (isRefetch: boolean, noteResourceId?: string, isUpdate?: boolean) => {
    if(!isWeb()){
      navigation?.goBack();
    }
    if (props?.goBackHandler && typeof props?.onActionPerformed === 'function') {
      props.goBackHandler();
    } else if (props?.onActionPerformed && typeof props?.onActionPerformed === 'function') {
      props?.onActionPerformed(PATIENT_NOTE_OPERATION.REFETCH, {resourceId: noteResourceId} as IPatientNoteCardProps, isUpdate);
    } else {
      props?.goBackHandler?.();
    }
  };

  const handleActionSelect = (code: string) => {
    const actions = addOrUpdateNoteState.activeActions || {};
    actions[code] = [];
    setAddOrUpdateTemplateState(prev => {
      return {
        ...prev,
        activeActions: actions,
        currentAvailableActions: prev.currentAvailableActions.filter(
          item => item.code !== code,
        ),
      };
    });
    showOrderModalByCode(code);
  };

  const actionList: BottomAction[] = [
    {
      label: 'Labs',
      code: ORDER_TYPE.LAB,
      onSelect: code => handleActionSelect(code),
    },
    {
      label: 'Imaging',
      code: ORDER_TYPE.RAD,
      onSelect: code => handleActionSelect(code),
    },
    {
      label: 'eRx',
      code: ORDER_TYPE.MED,
      onSelect: code => handleActionSelect(code),
    },
  ];

  const handleAlreadySignNoteError = async () => {
    const documentReference = await getDocumentRefByReferenceId(`${documentReferenceId}`, false, personData?.accountLocationUuid);
    const resource = documentReference?.data;
    const signByUserId = getExtensionValue(resource, EXTENSION_URLS.signedBy);
    const selectedUser = findUserById(signByUserId, accountUserList);
    const noteEntry: NoteEntry = {resource} as NoteEntry;
    const formatResponseParam: INotesFormattedDataProps = {
      noteResponse: [noteEntry],
      ehrConfig,
      loginUserId: currentUserData?.uuid,
      contextData: context,
      accountUserList: accountUserList || [],
      elationFormData: elationFormDataDetail,
      additionalFlags:{
        foldVisitNoteEnabled: isFoldFormDrivenNotes,
      }
    };
    const patientNotes = getFormattedNoteForCareTimeline(formatResponseParam);
    setAddOrUpdateTemplateState(prev => {
      return {
        ...prev,
        isProcessing: false,
        isConfirmationModalOpen: true,
        alreadySignByUserName: selectedUser?.name,
        updatedSignedNote: patientNotes?.length ? patientNotes[0] : undefined,
      };
    });
  };

  const getSaveNoteFormInfoMessage = (params: {
    isAmend?: boolean;
    isSign?: boolean;
    isAutoSave?: boolean;
    activeActions?: {[index: string]: IFormComponent[]};
    isPrint?: boolean;
  }) => {
    if (!params?.isAutoSave) {
      if (params?.isSign) {
        return 'Note Signed Successfully.';
      } else if (params?.isAmend) {
        return 'Note Amended Successfully.';
      } else {
        return 'Note Saved Successfully.';
      }
    }
    return '';
  }

  const isAnyOderInDraftState = () => {
    const pendingDraftLabOrder =(addOrUpdateNoteState?.labOrderFlatList || []).some(order => {
      return isPendingDraftOrder(order as RequestGroup);
    });
    const pendingDraftRadOrder =(addOrUpdateNoteState?.radOrderFlatList || []).some(order => {
      return isPendingDraftOrder(order as RequestGroup);
    });
    const pendingDraftErxOrder =(addOrUpdateNoteState?.radOrderFlatList || []).some(order => {
      return isPendingMedicationOrder(order as MedicationRequest);
    });
    return pendingDraftLabOrder || pendingDraftRadOrder || pendingDraftErxOrder;
  };

  const disabledLoader = () => {
    handleSaveLoading(SaveType.SAVE_AND_SIGN, false)
    handleSaveLoading(SaveType.SAVE, false);
    setAddOrUpdateTemplateState(prev => {
      return {
          ...prev,
          isProcessing: false,
      };
    });
  }

  const getShareNoteHtml = () => {
    let elem = shareComponentRef?.current?.innerHTML;
    if (elem?.indexOf('&lt;') && elem?.indexOf('&gt;')) {
      elem = elem.replaceAll('&lt;', '<').replaceAll('&gt;', '>');
    }
    return `<html><head><meta http-equiv="Content-type" content="text/html; charset=utf-8" /><meta charset="UTF-8" />${BOOTSTRAP_CDN_URL}<style>${FORM_HTML_STYLES}</style></head><body style="margin:10mm;">${elem}</body></html>`;
  }

  const generateHTML = () => {
    let elem = ref?.current?.innerHTML;
    if (elem?.indexOf('&lt;') && elem?.indexOf('&gt;')) {
      elem = elem.replaceAll('&lt;', '<').replaceAll('&gt;', '>');
    }
    return `<html><head><meta http-equiv="Content-type" content="text/html; charset=utf-8" /><meta charset="UTF-8" />${BOOTSTRAP_CDN_URL}<style>${FORM_HTML_STYLES}</style></head><body style="margin:10mm;">${elem}</body></html>`;
  }

  const getShareNoteHtmlV2 = (innerHTML: any) => {
    let elem = innerHTML;
    if (innerHTML?.indexOf('&lt;') && innerHTML?.indexOf('&gt;')) {
      elem = innerHTML.replaceAll('&lt;', '<').replaceAll('&gt;', '>');
    }
    return `<html><head><meta http-equiv="Content-type" content="text/html; charset=utf-8" /><meta charset="UTF-8" />${BOOTSTRAP_CDN_URL}<style> .disallow-to-share { display: none !important; } ${FORM_HTML_STYLES}</style></head><body style="margin:10mm;">${elem}</body></html>`;
  }

  const generateHtmlV2 = (innerHTML: any) => {
    let elem = innerHTML;
    if (innerHTML?.indexOf('&lt;') && innerHTML?.indexOf('&gt;')) {
      elem = innerHTML.replaceAll('&lt;', '<').replaceAll('&gt;', '>');
    }
    return `<html><head><meta http-equiv="Content-type" content="text/html; charset=utf-8" /><meta charset="UTF-8" />${BOOTSTRAP_CDN_URL}<style>${FORM_HTML_STYLES}</style></head><body style="margin:10mm;">${elem}</body></html>`;
  }

  const [saveFunctionParams, setSaveFunctionParams] = useState<{
    params: {
      isAmend?: boolean;
      isSign?: boolean;
      isAutoSave?: boolean;
      activeActions?: {[index: string]: IFormComponent[]};
      isPrint?: boolean;
      waitForHTML?: boolean;
    };
    isAllowToSignDraftNote?: boolean;
  }>({params: {}});

  useEffect(() => {
    if (!formComponentLoadingStatus.isAnyFormLoading && readOnlyFH && !onSaveLoading.saveAndSign) {
      saveForm(
        {...saveFunctionParams?.params, waitForHTML: false},
        saveFunctionParams.isAllowToSignDraftNote
      );
    }
  }, [formComponentLoadingStatus.isAnyFormLoading, saveFunctionParams, readOnlyFH]);

  const getAuthorOrSigner = (customSelectedUser?: any) => {
    if (isFoldFormDrivenNotes) {
      return loggedInUser;
    }
    return !!loggedInUser.externalUserId && loggedInUser.externalUserId !== 'null' ? loggedInUser : customSelectedUser;
  }

  const getNoteLabelComponentExist = (): { noteStatus: boolean, noteSubStatus: boolean } => {
    const updatedComponents = addOrUpdateNoteState.templateData?.components || [];
    const noteLabelComponent = {
      noteStatus: false,
      noteSubStatus: false,
    }
    forEachExtensiveFormComponent(updatedComponents || [], component => {
      if (component.type === 'noteStatus') {
        noteLabelComponent.noteStatus = true;
      }
      if (component.type === 'noteSubStatus') {
        noteLabelComponent.noteSubStatus = true;
      }
    });
    return noteLabelComponent;
  };

  const saveForm = async (params: {
    isAmend?: boolean;
    isSign?: boolean;
    isAutoSave?: boolean;
    activeActions?: {[index: string]: IFormComponent[]};
    isPrint?: boolean;
    waitForHTML?: boolean;
    customSelectedUser?: IUser;
    printShareable?: boolean;
  }, isAllowToSignDraftNote?: boolean) => {
    if (addOrUpdateNoteState?.isElationTemplate) {
      if (!params?.customSelectedUser?.externalUserId && !loggedInUser?.externalUserId) {
        disabledLoader();
        showToast(
          toast,
          'Please select a provider to sign the note',
          ToastType.error
        );
        return;
      }
    }
    // check note title is required or not. if required then and not filled then not auto save pr save note
    const isNoteTitleIsValid = !patientNoteIsMandatory || (patientNoteIsMandatory && (noteTitle?.title && noteTitle.title?.trim()?.length));
    if (!isNoteTitleIsValid) {
      setNoteTitle(prev => {
        return {
          ...prev,
          isValid: false
        }
      });
      return;
    }
    // try {
    //   if (!params.isAutoSave && formInstance.current) {
    //     // If no error is thrown then all mandatory fields are filled so we can proceed ahead else added return in catch
    //     await formInstance.current?.executeSubmit();
    //   }
    // } catch {
    //   // executeSubmit will throw error if any mandatory fields are not filled
    //   return;
    // }
    //We need to wait for all form components to complete their loading so we trigger above useEffect
    if (!params.isAutoSave && (params.isSign || params.isAmend)) {
      // setFormComponentLoadingStatus({
      //   isAnyFormLoading: false,
      //   map: {}
      // })
      setReadOnly(true);
      // need to check flag also
      // setSaveFunctionParams({params,isAllowToSignDraftNote});
      // return;
    }
    (params.isSign || params.isAmend)
    ? handleSaveLoading(SaveType.SAVE_AND_SIGN, true)
    : handleSaveLoading(SaveType.SAVE, true);
    setAddOrUpdateTemplateState(prev => {
      return {
        ...prev,
        isProcessing: true,
        draftOrderNoteParams: undefined,
      };
    });
    let noteHtml = '';
    let patientShareHtml = '';
    // need to add check flag here
    if (!params.isAutoSave && (params.isSign || params.isAmend)) {
     noteHtml = await new Promise((resolve, reject) => {
       setTimeout(() => {
        if (allowToSharePatientHtml && shareComponents?.length > 0) {
          patientShareHtml = getShareNoteHtml();
        }
         resolve(generateHTML());
       }, 3000);
     });
      // const newWindow = window.open('');
      // newWindow?.document.write(noteHtml);
    }
    const formChanges = draftChanges?.data;

    if (!params.isAutoSave && !draftChanges?.isValid) {
      if(isWeb()){
        notification.error({
          message: intl.formatMessage({id: 'mandatoryDetailsError'}),
          duration: 2,
        });
      } else {
        showToast(
          toast,
          intl.formatMessage({id: 'mandatoryDetailsError'}),
          ToastType.info,
        );
      }
      disabledLoader();
      return;
    }
    if (params.isSign && !areAllAppointmentTasksAreCompleted()) {
      showToast(
        toast,
        'All instructions are not marked as complete. Please check and try again.',
        ToastType.error,
      );
      disabledLoader();
      return;
    }
    if (!isAllowToSignDraftNote && (params?.isSign || params?.isAmend) && isAnyOderInDraftState()) {
      setAddOrUpdateTemplateState(prev => {
        return {
          ...prev,
          isDraftOrderConfirmationModalOpen: true,
          draftOrderNoteParams: params,
        }
      });
      disabledLoader();
      return;
    }
    const updatedComponents =
      addOrUpdateNoteState.templateData?.components || [];
    let formSelectedValues: IFormSelectedValue[] = [];
    forEachExtensiveFormComponent(updatedComponents || [], component => {
      if (formChanges && formChanges.hasOwnProperty(component.key)) {
        component.selectedValue = formChanges[component.key];
        formSelectedValues.push({
          selectedValue: component.selectedValue,
          type: component.type,
          key: component.key,
        });
      }
    });

    const updatedFormComponents = addOrUpdateNoteState.templateData?.components || [];
    forEachExtensiveFormComponent(updatedFormComponents, (component) => {
      if (formChanges && component.key && (formChanges[component.key] || formChanges[component.key] == 0)) {
        component.selectedValue = formChanges[component.key];
      }
    });
    // ELATION SPECIFIC CHANGE
    // NOTE: If loggedin user doesnot have externalUserId then we will use the selected user from the provider dropdown
    const selectedUser = getAuthorOrSigner(params?.customSelectedUser);

    if (!isFoldFormDrivenNotes) {
      // filter empty field response
      formSelectedValues = formSelectedValues.filter(formComponent => {
        return formComponent?.selectedValue !== '';
      });
    }

    // const copyNoteComponentSelectedValue = cloneDeep(formSelectedValues || []);

    const noteLabelComponent = getNoteLabelComponentExist();
    const postData = getPostDataForNotes(formSelectedValues, personData,
      {
      associatedTaskId: documentReferenceTaskId,
      currentEHR,
      appointmentId:
        addOrUpdateNoteState?.appointmentData?.externalAppointmentId || '',
      userUUID: isFoldFormDrivenNotes
        ? selectedUser?.uuid || ''
        : selectedUser?.externalUserId,
      isSign: !!params.isSign,
      isAmend: params?.isAmend || false,
      formId: addOrUpdateNoteState?.templateData?.id,
      formName: addOrUpdateNoteState?.templateData?.name,
      id: documentReferenceId,
      prevAuthorId: props.isNewNote ? "" : noteData?.authorUuid,
      prevResourceDate: props.isNewNote ? "" :  noteData?.createdDate,
      orders: [
        ...addOrUpdateNoteState.labOrders,
        ...addOrUpdateNoteState.radOrders,
        ...addOrUpdateNoteState.medOrders,
      ],
      noteType:
        ELATION_NOTE_TYPE[
          addOrUpdateNoteState.templateData
            ?.categoryId as keyof typeof ELATION_NOTE_TYPE
        ],
      activeActions: addOrUpdateNoteState.activeActions,
      prevSignedByUserId: noteData?.signedByUserId,
      prevSignDate: noteData?.signedDate,
      foldVisitNoteWithEncountersEnabled: foldVisitNoteWithEncountersEnabled,
      noteFormResponse: updatedFormComponents,
      noteHTML: noteHtml?.length > 0 ? noteHtml : undefined,
      noteTitle: noteTitle?.title,
      ...(patientShareHtml && { patientShareHtml: patientShareHtml}),
      allowToShowToPatient: addOrUpdateNoteState.shownToPatient,
      allowWriteBack: allowWriteBack,
      formLogId: noteData?.formLogId,
      formResponseId: noteData?.formResponseId,
      isShareWithEHR: addOrUpdateNoteState.isShareWithEHR,
      isElationTemplate: addOrUpdateNoteState.isElationTemplate,
      noteLabelComponent: noteLabelComponent
    });
    if (
      isFoldFormDrivenNotes &&
      !!postData?.extension?.filter((item: any) => item?.valueString === '')?.length
    ) {
      return;
    }
    postDocumentsReferenceWithPatientId(
      personData.patientId,
      postData,
      {
      id: documentReferenceId || '',
      isUpdate: isFoldFormDrivenNotes ? !!documentReferenceId : !!noteData?.resourceId,
      currentEHR,
      locationId: personData?.accountLocationUuid,
    },
      isFoldFormDrivenNotes,
      addOrUpdateNoteState.isElationTemplate
    )
      .then(async (res: any) => {
        if (res?.data?.id) {
          // check allow to create task when note create
          // if (allowToCreateTask) {
          //   await postUpdateDocumentReference(postData, copyNoteComponentSelectedValue);
          // }
          if (!params.isAutoSave) {
            (allowWriteBack && (params?.isAmend || params?.isSign) && syncHealthComponents())
            if(props?.onAddNoteSuccessCallback && typeof props?.onAddNoteSuccessCallback === 'function') {
              props?.onAddNoteSuccessCallback({
                id: res?.data?.id,
                formId: addOrUpdateNoteState.selectedTemplateId,
                docStatus: res?.data?.docStatus,
                successMessage: getSaveNoteFormInfoMessage(params),
                noteData: res?.data
              });
              return;
            }
            showToast(toast, getSaveNoteFormInfoMessage(params), ToastType.success);
            handleGoBack(true, res?.data?.id, !!noteData?.resourceId);
          }
        }
        (params.isSign || params.isAmend)
          ? handleSaveLoading(SaveType.SAVE_AND_SIGN, false)
          : handleSaveLoading(SaveType.SAVE, false);
        setAddOrUpdateTemplateState(prev => {
          return {
            ...prev,
            isProcessing: false,
          };
        });
      })
      .catch(async (error: any) => {
        (params.isSign || params.isAmend)
          ? handleSaveLoading(SaveType.SAVE_AND_SIGN, false)
          : handleSaveLoading(SaveType.SAVE, false);

        if (error?.response?.data?.errorCode === NOTE_ACTION_ERROR_CODES.UNAUTH_USER) {
            showToast(
              toast,
              'User is not authorized to sign this note.',
              ToastType.error,
              2000,
            );
            handleGoBack(true);
            return;
          }
        if (error && error?.response?.status === NOTE_ACTION_ERROR_CODES.SIGNED_NOTE_DELETED) {
          showToast(
            toast,
            `This note has already deleted`,
            ToastType.error,
            2000
          );
          handleGoBack(true);
          return;
        }
        if (error && error?.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED) {
          handleAlreadySignNoteError();
          return;
        }
        if (!params.isAutoSave) {
          showToast(toast, `Something went wrong`, ToastType.error);
        }
        setAddOrUpdateTemplateState(prev => {
          return {
            ...prev,
            isProcessing: false,
          };
        });
      });
  };

  const getCurrentAvailableActions = (data: any) => {
    const list: any = [];
    try {
      if (data) {
        const activeActionNames = Object.keys(data).map((key) => key);
        actionList.forEach((item) => {
          const orderType = getOrderTypeFromCode(item.code);
          if (
            activeActionNames.includes(item.code) &&
            orderType !== undefined &&
            isOrderTypeEnabledWithCapability(
              orderType,
              ehrCapabilities,
              personData?.accountLocationUuid
            )
          ) {
            list.push(item);
          }
        });
        return list;
      } else {
        return actionList;
      }
    } catch (error) {

      return list;
    }
  };

  const resetPrintComponentStatus = () => {
    setAddOrUpdateTemplateState(prev => {
      return {
        ...prev,
        isPrintComponentRender: false,
        startRenderPrintComponent: false,
      };
    });
  };

  const printPatientNotes = async () => {
    if (printRef?.current && printRef?.current?.handleClick) {
      printRef.current.handleClick();
    }
  };

  const validateNoteDataBeforeSignOrAmend = (params: any, isAllowToSignDraftNote?: boolean) => {
    // # Note - copy paste old code
    // check note title is required or not. if required then and not filled then not auto save pr save note
    const isNoteTitleIsValid = !patientNoteIsMandatory || (patientNoteIsMandatory && (noteTitle?.title && noteTitle.title?.trim()?.length));
    if (!isNoteTitleIsValid) {
      setNoteTitle(prev => {
        return {
          ...prev,
          isValid: false
        }
      });
      return false;
    }
    handleSaveLoading(SaveType.SAVE_AND_SIGN, true);
    setAddOrUpdateTemplateState(prev => {
      return {
        ...prev,
        isProcessing: true,
        draftOrderNoteParams: undefined,
      };
    });
    if (!params.isAutoSave && !draftChanges?.isValid) {
      if(isWeb()){
        notification.error({
          message: intl.formatMessage({id: 'mandatoryDetailsError'}),
          duration: 2,
        });
      } else {
        showToast(
          toast,
          intl.formatMessage({id: 'mandatoryDetailsError'}),
          ToastType.info,
        );
      }
      disabledLoader();
      return false;
    }
    if (params.isSign && !areAllAppointmentTasksAreCompleted()) {
      showToast(
        toast,
        'All instructions are not marked as complete. Please check and try again.',
        ToastType.error,
      );
      disabledLoader();
      return false;
    }
    if (!isAllowToSignDraftNote && (params?.isSign || params?.isAmend) && isAnyOderInDraftState()) {
      setAddOrUpdateTemplateState(prev => {
        return {
          ...prev,
          isDraftOrderConfirmationModalOpen: true,
          draftOrderNoteParams: params,
        }
      });
      disabledLoader();
      return false;
    }
    return true;
  }

  const getNoteDataForPrint = () => {
    // to preserve the resource id and form id when adding new note
    const updatedNotedData = addOrUpdateNoteState.updatedSignedNote;
    const noteEntry: NoteEntry = {
      resource: updatedNotedData,
    };
    const formatResponseParam: INotesFormattedDataProps = {
      noteResponse: [noteEntry],
      ehrConfig,
      loginUserId: currentUserData?.uuid,
      contextData: context,
      accountUserList: accountUserList || [],
      elationFormData: elationFormDataDetail,
      additionalFlags:{
        foldVisitNoteEnabled: isFoldFormDrivenNotes
      }
    };
    const patientNotes = getFormattedNoteForCareTimeline(formatResponseParam);
    return patientNotes?.length
      ? patientNotes[0]
      : ({} as IPatientNoteCardProps);
  };

  const renderPrintComponent = useMemo(() => {
    const signedByUserName = loginUserData?.name;
    const signedByDate = getDateStrFromFormat(getDateToMomentISOString(), DATE_FORMATS.SIGNED_NOTE_DATE);
    const printNoteData = {
      signedByUserName: signedByUserName,
      signedDate: signedByDate,
      createdDate: noteData?.createdDate,
      linkedAppointmentId: noteData?.linkedAppointmentId
    }
    return (
      <>
        {addOrUpdateNoteState.startRenderPrintComponent && (
          <PatientNotePrintPreviewV2
            components={addOrUpdateNoteState.notePrintPreviewComponents || []}
            answers={addOrUpdateNoteState.notePrintPreviewAnswers || { data: {} }}
            optionData={addOrUpdateNoteState.htmlGenerateFormOptions}
            isPreviewMode={true}
            readOnly={true}
            formattedContactData={props.unFormattedContactData}
            personData={props.personData}
            appointmentData={addOrUpdateNoteState?.appointmentData}
            notesData={printNoteData}
            onRenderComponent={(error?: any) => {
              if (error) {
                setAddOrUpdateTemplateState(prev => {
                  return {
                    ...prev,
                    isPrintComponentRender: false,
                    startRenderPrintComponent: false,
                  };
                });
                setOnSaveLoading({ save: false, saveAndSign: false });
                showToast(
                  toast,
                  'Facing issue while printing. Please check and try again.',
                  ToastType.error
                );
                return;
              }
              printPatientNotes();
            } }
            accountUserList={accountUserList}
            header={header}
          />
        )}
      </>
    );
  }, [addOrUpdateNoteState.startRenderPrintComponent]);

  const postValidationSaveNoteData =  (params: any) => {
    // copy paste old code
    const formChanges = draftChanges?.data;
    const updatedComponents = addOrUpdateNoteState.templateData?.components || [];
    let formSelectedValues: IFormSelectedValue[] = [];
    forEachExtensiveFormComponent(updatedComponents || [], component => {
      if (formChanges && formChanges.hasOwnProperty(component.key)) {
        component.selectedValue = formChanges[component.key];
        formSelectedValues.push({
          selectedValue: component.selectedValue,
          type: component.type,
          key: component.key,
        });
      }
    });
    const updatedFormComponents = addOrUpdateNoteState.templateData?.components || [];
    forEachExtensiveFormComponent(updatedFormComponents, (component) => {
      if (formChanges && component.key && (formChanges[component.key] || formChanges[component.key] == 0)) {
        component.selectedValue = formChanges[component.key];
      }
    });
    // ELATION SPECIFIC CHANGE
    // NOTE: If loggedin user doesnot have externalUserId then we will use the selected user from the provider dropdown
    const selectedUser = getAuthorOrSigner(params?.customSelectedUser);

    if (!isFoldFormDrivenNotes) {
      // filter empty field response
      formSelectedValues = formSelectedValues.filter(formComponent => {
        return formComponent?.selectedValue !== '';
      });
    }

    // const copyNoteComponentSelectedValue = cloneDeep(formSelectedValues || []);
    const noteLabelComponent = getNoteLabelComponentExist();
    const postData = getPostDataForNotes(formSelectedValues, personData,
      {
      associatedTaskId: documentReferenceTaskId,
      currentEHR,
      appointmentId:
        addOrUpdateNoteState?.appointmentData?.externalAppointmentId || '',
      userUUID: !isFoldFormDrivenNotes
        ? selectedUser?.externalUserId || ''
        : selectedUser?.uuid,
      isSign: !!params.isSign,
      isAmend: params?.isAmend || false,
      formId: addOrUpdateNoteState?.templateData?.id,
      formName: addOrUpdateNoteState?.templateData?.name,
      id: documentReferenceId,
      prevAuthorId: props.isNewNote ? "" : noteData?.authorUuid,
      prevResourceDate: props.isNewNote ? "" :  noteData?.createdDate,
      orders: [
        ...addOrUpdateNoteState.labOrders,
        ...addOrUpdateNoteState.radOrders,
        ...addOrUpdateNoteState.medOrders,
      ],
      noteType:
        ELATION_NOTE_TYPE[
          addOrUpdateNoteState.templateData
            ?.categoryId as keyof typeof ELATION_NOTE_TYPE
        ],
      activeActions: addOrUpdateNoteState.activeActions,
      prevSignedByUserId: noteData?.signedByUserId,
      prevSignDate: noteData?.signedDate,
      foldVisitNoteWithEncountersEnabled: foldVisitNoteWithEncountersEnabled,
      noteFormResponse: updatedFormComponents,
      noteHTML: params?.noteHtml?.length > 0 ? params?.noteHtml : undefined,
      noteTitle: noteTitle?.title,
      ...(params?.patientShareHtml && { patientShareHtml: params?.patientShareHtml}),
      allowToShowToPatient: addOrUpdateNoteState.shownToPatient,
      allowWriteBack: allowWriteBack,
      formLogId: noteData?.formLogId,
      formResponseId: noteData?.formResponseId,
      isShareWithEHR: addOrUpdateNoteState.isShareWithEHR,
      isElationTemplate: addOrUpdateNoteState.isElationTemplate,
      noteLabelComponent: noteLabelComponent
    });
    if (
      isFoldFormDrivenNotes &&
      !!postData?.extension?.filter((item: any) => item?.valueString === '')?.length
    ) {
      return;
    }
    // postUpdateDocumentReference(postData, copyNoteComponentSelectedValue);
    postDocumentsReferenceWithPatientId(
      personData.patientId,
      postData,
      {
        id: documentReferenceId || '',
        isUpdate: !!documentReferenceId,
        currentEHR,
        locationId: personData?.accountLocationUuid,
      },
      false,
      addOrUpdateNoteState.isElationTemplate
    )
      .then((res: any) => {
        if (res?.data?.id) {
          // check allow to create task when note create
          if (!params.isAutoSave) {
            (allowWriteBack && (params?.isAmend || params?.isSign) && syncHealthComponents())
            if(props?.onAddNoteSuccessCallback && typeof props?.onAddNoteSuccessCallback === 'function') {
              props?.onAddNoteSuccessCallback({
                id: res?.data?.id,
                formId: addOrUpdateNoteState.selectedTemplateId,
                docStatus: res?.data?.docStatus,
                successMessage: getSaveNoteFormInfoMessage(params),
                noteData: res?.data
              });
              return;
            }
            showToast(toast, getSaveNoteFormInfoMessage(params), ToastType.success);
            handleGoBack(true, res?.data?.id, !!noteData?.resourceId);
          }
        }
        setAddOrUpdateTemplateState(prev => {
          return {
            ...prev,
            isProcessing: false,
            isPrintComponentRender: true
          };
        });
        handleSaveLoading(SaveType.SAVE_AND_SIGN, false)
      })
      .catch((error: any) => {
        handleSaveLoading(SaveType.SAVE_AND_SIGN, false)
        if (error?.response?.data?.errorCode === NOTE_ACTION_ERROR_CODES.UNAUTH_USER) {
            showToast(toast, 'User is not authorized to sign this note.', ToastType.error, 2000);
            handleGoBack(true);
            return;
          }
        if (error && error?.response?.status === NOTE_ACTION_ERROR_CODES.SIGNED_NOTE_DELETED) {
          showToast(toast, `This note has already deleted`, ToastType.error, 2000);
          handleGoBack(true);
          return;
        }
        if (error && error?.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED) {
          handleAlreadySignNoteError();
          return;
        }
        if (!params.isAutoSave) {
          showToast(toast, `Something went wrong`, ToastType.error);
        }
        setAddOrUpdateTemplateState(prev => {
          return {
            ...prev,
            isProcessing: false,
          };
        });
      });
  }

  const handleHeaderAction = async (action: string, customSelectedUser?: IUser) => {
    switch (action) {
      // case 'addendum':
      //   saveForm({isAmend: noteData?.isAllowToSign, waitForHTML: true});
      //   break;
      // case 'addendumAndPrint':
      //   saveForm({isAmend: noteData?.isAllowToSign, isPrint: true, waitForHTML: true});
      //   break;
      // case 'addendumAndPrintShareable':
      //   saveForm({isAmend: noteData?.isAllowToSign, isPrint: true, waitForHTML: true, printShareable: true});
      //   break;
      case 'saveAndSign':
      case 'addendum':
      case 'addendumAndPrint':
      case 'addendumAndPrintShareable':
      case 'signAndPrint':
      case 'signAndPrintShareable':
        let isAmend = false;
        let isSign = false;
        let printShareable = false;
        let isPrint = false;
        if (action === 'addendum' || action === 'addendumAndPrint' || action === 'addendumAndPrintShareable') {
          isAmend = true;
        }
        if (action === 'signAndPrint' || action === 'signAndPrintShareable' || action === 'saveAndSign') {
          isSign = true;
        }
        if (action === 'addendumAndPrintShareable' || action === 'signAndPrintShareable') {
          printShareable = true;
          isPrint = true;
        }
        if (action === 'signAndPrint' || action === 'addendumAndPrint') {
          isPrint = true;
        }
        const isValid = validateNoteDataBeforeSignOrAmend({isAmend, isSign, printShareable, isPrint}, false);
        if (!isValid) {
          return;
        }
        if (!isWeb()) {
          const params = {
            isAmend,
            isSign,
            printShareable,
            isPrint,
            customSelectedUser
          };
          postValidationSaveNoteData(params);
          return;
        }
        const formChanges = draftChanges?.data;
        let filterComponents = addOrUpdateNoteState.templateData?.components || [];
        const formSelectedValues: IFormSelectedValue[] = [];
        const data: any = {};
        forEachExtensiveFormComponent(filterComponents || [], component => {
          if (formChanges && formChanges.hasOwnProperty(component.key)) {
            component.selectedValue = formChanges[component.key];
            formSelectedValues.push({
              selectedValue: component.selectedValue,
              type: component.type,
              key: component.key,
            });
          }
        });
        if (printShareable) {
          filterComponents = getComponentsForShareablePrint(cloneDeep(addOrUpdateNoteState.templateData?.components || []));
        }
        (formSelectedValues || []).forEach((item: any) => {
          // Sanitizing string to avoid breaking of JSON.parse
          const valueData = typeof item.selectedValue === 'string' ? getSanitizeString(item.selectedValue) : item.selectedValue;
          data[item.key] = hasJsonStructure(valueData) ? JSON.parse(valueData) : valueData;
          // title is component type
          if (item.title === 'checkbox') {
            data[item.key] = valueData === 'true';
          }
        });
        setAddOrUpdateTemplateState(prev => {
          return {
            ...prev,
            notePrintPreviewAnswers: {data : data},
            notePrintPreviewComponents: filterComponents,
            startRenderPrintComponent: true,
            printShareable: false,
            isPrintComponentRender: false,
            manualActionData: {isAmend, isSign, printShareable, isPrint, customSelectedUser},
            manualActionCode: action,
          };
        });
        break;
      // case 'signAndPrint':
      //   saveForm({isSign: noteData?.isAllowToSign, isPrint: true, waitForHTML: true});
      //   break;
      // case 'signAndPrintShareable':
      //   saveForm({isSign: noteData?.isAllowToSign, isPrint: true, waitForHTML: true, printShareable: true});
      //   break;
      case 'save':
        saveForm({isSign: false, customSelectedUser: customSelectedUser});
        break;
      default:
        break;
    }
  };

  // const getLabelByStatusValue = (value: string, isSubStatusComponent: boolean) => {
  //   return (noteLabels || []).find(noteLabel => {
  //     if (isSubStatusComponent) {
  //       switch(value) {
  //         case 'Open':
  //           return noteLabel?.title === 'Sub Status Open';
  //         case 'Closed':
  //           return noteLabel?.title === 'Sub Status Closed';
  //         case 'In-Progress':
  //           return noteLabel?.title === 'Sub Status In-Progress';
  //         default:
  //           return noteLabel?.title === value;
  //       }
  //     }
  //     return noteLabel?.title === value;
  //   })?.uuid || undefined;
  // }

  // const getStatusAndSubStatusComponent = (component: any, componentKey: string) => {
  //   if (component?.key === componentKey || component?.key == componentKey) {
  //     return component;
  //   } else {
  //     const components = (component?.components || []);
  //     if (components?.length) {
  //       return (components || []).find((component: any) => {
  //         return getStatusAndSubStatusComponent(component, componentKey);
  //       });
  //     } else {
  //       return undefined;
  //     }
  //   }
  // }

  // const getLabelByNotStatusAndSubStatus = (formSelectedValues?: IFormSelectedValue[]) => {
  //   let noteStatusLabel: string | undefined = '';
  //   let noteSubStatusLabel: string | undefined = '';
  //   const labels: string[] = [];
  //   if (formSelectedValues?.length) {
  //     formSelectedValues.forEach(formValue => {
  //       if (formValue?.type === 'noteStatus') {
  //         noteStatusLabel = getLabelByStatusValue(formValue?.selectedValue, false);
  //         if (noteStatusLabel) {
  //           labels.push(noteStatusLabel);
  //         }
  //       }
  //       if (formValue?.type === 'noteSubStatus') {
  //         noteSubStatusLabel = getLabelByStatusValue(formValue?.selectedValue, true);
  //         if (noteSubStatusLabel) {
  //           labels.push(noteSubStatusLabel);
  //         }
  //       }
  //     });
  //   } else {
  //     (addOrUpdateNoteState?.templateData?.components || []).forEach(component => {
  //       const noteComponent: any = getStatusAndSubStatusComponent(component, 'noteStatus');
  //       const noteSubStatus: any = getStatusAndSubStatusComponent(component, 'noteSubStatus');
  //       if (noteComponent?.type) {
  //         noteStatusLabel = getLabelByStatusValue(noteComponent?.selectedValue, false);
  //         !!noteStatusLabel && labels.push(noteStatusLabel);
  //       }
  //       if (noteSubStatus?.type) {
  //         noteSubStatusLabel = getLabelByStatusValue(noteSubStatus?.selectedValue, true);
  //         !!noteSubStatusLabel && labels.push(noteSubStatusLabel);
  //       }
  //     });
  //   }
  //   return labels;
  // };

  // const postUpdateDocumentReference = async (postData: any, formSelectedValues: IFormSelectedValue[]) => {
  //   const isNoteStatusUpdated = props.noteData?.status != postData?.docStatus;
  //   const labels: any = [];
  //   if (documentReferenceTaskId && documentReferenceId && allowToCreateTask) {
  //     let updatedTaskStatusId: string | undefined = undefined;
  //     let isPassDate = false;
  //     const linkTask = noteData?.linkTasks?.length ? noteData.linkTasks?.[0] : undefined;
  //     if (linkTask?.id && linkTask?.endDateTime) {
  //       isPassDate = isTaskDueDateIsPass(linkTask?.endDateTime);
  //     }

  //     const taskLabelResponse = await getTaskLabels({ variables: {
  //       taskId: linkTask?.id || documentReferenceTaskId
  //     }});
  //     let existTaskLabels: ILabelTask[] = [];
  //     if (taskLabelResponse?.data?.taskLabels?.length) {
  //       existTaskLabels = taskLabelResponse?.data?.taskLabels || [];
  //     }
  //     const noteStatusWiseLabels : string[] = getLabelByNotStatusAndSubStatus(formSelectedValues) || [];
  //     const existMapLinkLabels = (existTaskLabels || []).filter(label => {
  //       return (noteLabels || []).some(noteLabel => {
  //         return noteLabel?.uuid === label?.labelId;
  //       });
  //     });
  //     (noteStatusWiseLabels || []).forEach(labelUuid => {
  //       const findLabel = (existMapLinkLabels || []).find((label: any) => {
  //         return label?.labelId === labelUuid;
  //       });
  //       // if not added on task then add it
  //       if (!findLabel || !findLabel?.id) {
  //         labels.push({
  //           labelId: labelUuid,
  //         });
  //       }
  //     });
  //     (existMapLinkLabels || []).forEach((label: ILabelTask) => {
  //       const isNoteStatusWiseLabelNotExist = (!noteStatusWiseLabels || !noteStatusWiseLabels?.length);
  //       const isExistLabelNotMapWithNewStatus = label?.labelId && !(noteStatusWiseLabels || []).includes(label?.labelId);
  //       if (isNoteStatusWiseLabelNotExist || isExistLabelNotMapWithNewStatus) {
  //         labels.push({
  //           id: label?.id,
  //           isDeleted: true
  //         });
  //       }
  //     });

  //     if (isNoteStatusUpdated) {
  //       switch(postData?.docStatus) {
  //         case DocStatus.AMENDED:
  //         case DocStatus.FINAL:
  //           updatedTaskStatusId = completedStatusId;
  //           break;
  //         case DocStatus.PRELIMINARY:
  //           updatedTaskStatusId = isPassDate ? missedStatusId : defaultStatusId;
  //           break;
  //       }
  //     }
  //     const updateTaskData = {
  //       ...(isNoteConfigurationKey && selectedValueLabel && notesTitle && { title: notesTitle }),
  //       ...(isNoteStatusUpdated && updatedTaskStatusId && { statusId: updatedTaskStatusId }),
  //       ...(labels?.length > 0 && { labels: labels }),
  //     };
  //     if (Object.keys(updateTaskData).length > 0) {
  //       await updateTask({
  //         context: { service: CARESTUDIO_APOLLO_CONTEXT },
  //         variables: {
  //           params: {
  //             id: documentReferenceTaskId,
  //             data: updateTaskData,
  //           },
  //         },
  //       });
  //       setNoteTitle(prev => {
  //         return {
  //           ...prev,
  //           noteUpdated: false
  //         }
  //       });
  //     }
  //   }
  // }

  // const updateNoteTaskTitle = async (templateName: string) => {
  //   if (documentReferenceTaskId) {
  //     await updateTask({
  //       context: {service: CARESTUDIO_APOLLO_CONTEXT},
  //       variables: {
  //         params: {
  //           id: documentReferenceTaskId,
  //           data: {
  //             title: templateName,
  //           },
  //         },
  //       },
  //     });
  //   }
  // }
  const getActions = () => {
   const actions: IAction[] = [];
   const isAlreadySignedNote =
      noteData?.signedDate && noteData?.signedByUserId;
    if (noteData?.isAllowToSign && isFoldFormDrivenNotes) {
      actions.push({
        btnText: intl.formatMessage({
          id: isAlreadySignedNote ? 'addendumAndSign' : 'saveAndSign',
        }),
        icon: <View width={5} height={5}>
          <SignIconSvg />
        </View>,
        action: isAlreadySignedNote ? 'addendum' : 'saveAndSign',
        isDisabled: addOrUpdateNoteState.startRenderPrintComponent || isFormEmpty,
      });
    }
    if (noteData?.isAllowToSign && isFoldFormDrivenNotes) {
      actions.push({
        btnText: intl.formatMessage({
          id: isAlreadySignedNote ? 'addendumAndSignAndPrint' : 'signAndPrint',
        }),
        icon: (
          <View width={5} height={5}>
            <PrintIconSvg />
          </View>
        ),
        action: isAlreadySignedNote ? 'addendumAndPrint' : 'signAndPrint',
        isDisabled: addOrUpdateNoteState.startRenderPrintComponent || isFormEmpty,
      });
      if (allowToSharePatientHtml) {
        actions.push({
          btnText: intl.formatMessage({
            id: isAlreadySignedNote
              ? 'addendumAndSignAndPrintShareable'
              : 'signAndPrintShareable',
          }),
          icon: (
            <View width={5} height={5}>
              <PrintIconSvg />
            </View>
          ),
          action: isAlreadySignedNote
            ? 'addendumAndPrintShareable'
            : 'signAndPrintShareable',
          isDisabled:
            addOrUpdateNoteState.startRenderPrintComponent || isFormEmpty,
        });
      }
    }
    return actions;
};

  const renderActionMenu = () => {
    const actions = getActions();
    return (
      actions.length > 0 && (
        <FlatList
          data={actions}
          renderItem={({item, index}) => (
            <>
              <Pressable
                disabled={item.isDisabled}
                flexDirection="row"
                alignItems="center"
                my={1.5}
                onPress={() => handleHeaderAction(item.action)}>
                {item.icon}
                <Text
                  color={Colors.Custom.Gray500}
                  fontSize={12}
                  fontWeight="400"
                  marginLeft={4}>
                  {item.btnText}
                </Text>
              </Pressable>
              {index !== actions.length - 1 && <Divider />}
            </>
          )}
        />
      )
    );
  };
  const {pendingTasks: pendingAppointmentTaskCount} = getStatusWiseCountOfAppointmentTasks();

  const handleDefaultNoteStateChange = (templateId: string) => {
    setAddOrUpdateTemplateState(prev => {
      const defaultTemplate = prev.defaultNote || {};
      defaultTemplate.formId = templateId;
      return {
        ...prev,
        defaultTemplate,
        selectedTemplateId: templateId,
      };
    });
  };

  const handleSelectedTemplateChange = (templateId: string, templateName?: string, isFoldTemplate?: boolean) => {
    setAddOrUpdateTemplateState((prev) => {
      return {
        ...prev,
        ...(isFoldTemplate && {isProcessing: true}),
        isLoading: true,
        selectedTemplateId: templateId,
        isElationTemplate: !isFoldTemplate
      };
    });
    onTemplateChangeCallback?.(templateId);
    if (isNewNote && !isFoldTemplate && documentReferenceId) {
      deleteDocumentReference(documentReferenceId, foldVisitNoteWithEncountersEnabled, personData?.accountLocationUuid)
        .then(() => {
          setDocumentReferenceId("");
        })
        .catch((error) => {
        });
    }
  };

  const handleNoteOperation = (operationCode: string) => {
    if (
      props?.onActionPerformed &&
      typeof props?.onActionPerformed === 'function'
    ) {
      props?.onActionPerformed(
        operationCode,
        noteData || ({} as IPatientNoteCardProps),
      );
    }
  };

  const getButtonsPopover = () => {
    return (
      <Popover
        overlayClassName="custom-popover-styles"
        showArrow={true}
        destroyTooltipOnHide
        placement="bottomRight"
        content={renderActionMenu}
        trigger="click"
        overlayInnerStyle={{
          borderRadius: 16,
          width: 140,
          padding: 0,
        }}
      >
        <Box>
         {renderNewView ? (
           <View style={styles.selectDownArrowContainer}>
             <SelectDownArrowSvg />
           </View>
         ) : (
           <Feather
             name={'chevron-down'}
             size={20}
             color={Colors.Custom.mainPrimaryPurple}
             style={styles.featherIcon}
           />
         )}
        </Box>
      </Popover>
    );
  }

  const getHeaderTitle = () => {
    const headerText = getNoteHeaderText(noteData);
    const subHeaderTextArray = getNoteSubHeaderText(noteData);
    return (
      <VStack>
        <Text
          style={{
            fontSize: renderNewView ? SM_CONFIG.smMedium.fontSize : MD_CONFIG.mdMedium.fontSize,
            fontWeight: renderNewView ? '400' : '500',
            color: Colors.FoldPixel.GRAY400,
            marginLeft: 10,
          }}>
          {headerText}
        </Text>
        {(subHeaderTextArray || []).map((subHeaderText, index) => {
          return (
            <Text
              size={'smRegular'}
              key={`${subHeaderText}_${index}`}
              style={styles.subHeaderText}>
              {subHeaderText}
            </Text>
          );
        })}
      </VStack>
    );
  };
  const actions = getActions();
  const defaultSaveButton = (
    renderNewView ?
      <FoldButtonV2
        buttonType={ButtonType.tertiary}
        label={intl.formatMessage({
          id: onSaveLoading.saveAndSign ? 'signing' : 'save',
        })}
        height={28}
        onTextClick={() => {
          handleHeaderAction('save');
        }}
        // textSize={'16px'}
        rightIcon = {
          onSaveLoading.save || onSaveLoading.saveAndSign ? (
            <Spinner size={'sm'} />
          ) : (
            actions.length > 0 ? (
              getButtonsPopover()
            ): undefined
        )}
        showRightBorder={actions.length > 0 ? true : false}
      />
      : <FoldButton
      nativeProps={{
        backgroundColor: Colors.Custom.BackgroundColor,
        isDisabled:
          isFormEmpty ||
          addOrUpdateNoteState.startRenderPrintComponent ||
          addOrUpdateNoteState.isProcessing ||
          onSaveLoading.saveAndSign ||
          onSaveLoading.save,
        isLoading: onSaveLoading.saveAndSign || onSaveLoading.save,
        variant: BUTTON_TYPE.PRIMARY,
        onPress: () => {
          handleHeaderAction('save');
        },
        rightIcon:
          onSaveLoading.save || onSaveLoading.saveAndSign ? (
            <Spinner size={'sm'} />
          ) : (
            actions.length > 0 ? (
              getButtonsPopover()
            ): undefined
          ),
        style: {minWidth: 'auto'},
      }}
      customProps={{
        btnText: intl.formatMessage({
          id: onSaveLoading.saveAndSign ? 'signing' : 'save',
        }),
        withRightBorder: !(onSaveLoading.save || onSaveLoading.saveAndSign) && actions.length > 0,
        isDisableOnPress: true,
      }}
    />
  );

  const renderSaveButtonByEHR = () => {
   if (!isFoldFormDrivenNotes && (!loggedInUser?.externalUserId || loggedInUser?.externalUserId === 'null')) {
     return (
       <ProviderSelectionForNoteSave
         loading={onSaveLoading.save || onSaveLoading.saveAndSign}
         disabled={
           onSaveLoading.save ||
           onSaveLoading.saveAndSign ||
           isFormEmpty ||
           addOrUpdateNoteState.startRenderPrintComponent ||
           addOrUpdateNoteState.isProcessing ||
           addOrUpdateNoteState.selectedTemplateId === ''
         }
         locationGroupId={locationGroupId}
         isSidecarContext={isSidecarContext}
         isAllowToSign={noteData?.isAllowToSign}
         onHeaderAction={handleHeaderAction}
       />
     );
   }
   return defaultSaveButton;
  }
  const renderSignButtonForElationNote = () => {
    const isInProgressElationNote = !props.noteData?.isFoldDrivenNote &&
      (!loggedInUser?.externalUserId || loggedInUser?.externalUserId === 'null') &&
      props.noteData?.status === DocStatus.PRELIMINARY &&
      isElationTemplate;
    if (!isInProgressElationNote) return undefined;
    return (
      <ProviderSelectionForNoteSave
        loading={onSaveLoading.saveAndSign}
        disabled={
          onSaveLoading.saveAndSign ||
          isFormEmpty ||
          addOrUpdateNoteState.startRenderPrintComponent ||
          addOrUpdateNoteState.isProcessing ||
          addOrUpdateNoteState.selectedTemplateId === ''
        }
        locationGroupId={locationGroupId}
        isAllowToSign={noteData?.isAllowToSign || true}
        onHeaderAction={handleHeaderAction}
        signOnlyMode={true}
      />
    );
  };

  const onShowToPatientChange = (value: boolean) => {
    if (value) {
      showToast(
        toast,
        intl.formatMessage({id: 'displayToMemberOnMessage'}),
        ToastType.success,
        3000
      );
    };
    setAddOrUpdateTemplateState((prev) => ({...prev, shownToPatient: value}));
  }

  const getTemplateRenderActions = (): JSX.Element => {
    const isAlreadySignedNote = noteData?.signedDate && noteData?.signedByUserId;
    return (
      <>
        {allowToToggleShowToPatient && (
          <ShowToPatientSwitch
            loading={false}
            value={addOrUpdateNoteState.shownToPatient}
            onChange={onShowToPatientChange}
            message={intl.formatMessage({id: 'displayToMember'})}
            tooltipMessage={intl.formatMessage({
              id: 'displayToMemberInfoMessage',
            })}
          />
        )}
        {componentViewType === ViewType.DRAWER && !renderNewView && (
          <FoldButton
            customProps={{
              btnText: intl.formatMessage({id: 'close'}),
            }}
            nativeProps={{
              backgroundColor: Colors.Custom.BackgroundColor,
              variant: BUTTON_TYPE.SECONDARY,
              color: Colors.Custom.mainPrimaryPurple,
              onPress: () => {
                if (
                  props?.goBackHandler &&
                  typeof props?.goBackHandler === 'function'
                ) {
                  props?.goBackHandler();
                }
              },
            }}
          />
        )}
        {renderNewView && <View style={styles.sidecarContextSpacer}/>}
        {(isNewNote || noteData?.isAllowToSave) && renderSaveButtonByEHR()}
        {!isNewNote && renderSignButtonForElationNote()}
      </>
    );
  };

  const getDocumentTitle = () => {
    return `${personData.name}`;
  };

  const existingDiagnosis = getExistingDiagnosis();
  const showOrdersBottomActionBar = hideBottomOrderActionBar
    ? !hideBottomOrderActionBar
    : !isActionListEmpty() &&
      documentReferenceId &&
      allowNoteOperation(NoteOperation.ORDERS, ehrConfig, {
        contextData: {} as ICommonData,
      });

  const renderHeaderView = () => (
    <Header
      locationList={[patientLocation]}
      disableDefaultTemplateSelection={disableDefaultTemplateChange}
      showBackButton={componentViewType !== ViewType.DRAWER}
      handleDefaultNoteStateChange={handleDefaultNoteStateChange}
      handleNewlyCreatedNote={(noteId, templateName) => handleSelectedTemplateChange(noteId, templateName, true)}
      backButtonDisabled={onSaveLoading.saveAndSign || onSaveLoading.save}
      headerAction={getTemplateRenderActions()}
      form={{
        name: addOrUpdateNoteState?.templateData?.name,
        id: addOrUpdateNoteState?.templateData?.id,
      }}
      showTemplateSelection={!noteData?.resourceId}
      backButtonAction={() => {
        if (addOrUpdateNoteState?.isElationTemplate && !documentReferenceId) {
          setShowCloseConfirmation(true);
        } else {
          if (props?.goBackHandler && typeof props?.goBackHandler === 'function') {
            props?.goBackHandler();
          } else {
            handleNoteOperation(PATIENT_NOTE_OPERATION.REFETCH);
          }
        }
      }}
      noteTitle={notesTitle}
      title={getHeaderTitle()}
      showNoteTitleInput={isFoldFormDrivenNotes && !isFormEmpty}
      onNoteTitleChange={(text)=>{
        const trimText = text?.trim();
        setNoteTitle(prev => {
          return {
            ...prev,
            title: trimText,
            isValid: trimText?.length ? true : false,
            noteUpdated: true
          }
        });
        isFoldFormDrivenNotes && debouncedSave({changed: {}});
      }}
      handleSelectedTemplateChange={handleSelectedTemplateChange}
      currentTemplateId={addOrUpdateNoteState?.selectedTemplateId}
      defaultNote={addOrUpdateNoteState.defaultNote}
      navigation={isWeb() ? undefined : navigation}
      showNoteTitleErrorText={!noteTitle?.isValid}
      isTaskOrNoteLabelComponentExist={isTaskOrNoteLabelComponentExist}
      locationId={personData?.accountLocationUuid}
      isNewView={props?.isNewView}
      isElationTemplate={addOrUpdateNoteState?.isElationTemplate}
      documentReferenceId={documentReferenceId}
      templateId={addOrUpdateNoteState?.templateData?.id}
    />
  );

  const onNoteFormReady = useCallback((instance: any) => {
    formInstance.current = instance;
  }, []);

  const renderFHForm = () => {
    const isVisitNote = addOrUpdateNoteState.templateData?.categoryId === ELATION_NON_VISIT_NOTE_CODE
    return (
      <div
        className={(props?.isClinicalNoteFromMessage && isVisitNote) ? `messaging-window` : ``}
      >
        <FHForm
          canUpdateOnChangeRef
          components={addOrUpdateNoteState?.templateData?.components}
          submittedResponse={addOrUpdateNoteState?.templateData?.answers}
          optionData={addOrUpdateNoteState.formOption}
          onChange={handleFormChange}
          readOnly={isReadOnly()}
          isPreviewMode={false}
          onFormReady={onNoteFormReady}
        />
      </div>
    );
  };

  if(!isWeb()){
    return (
      <Box safeAreaTop background={Colors.Custom.Gray100} flex={1}>
        {addOrUpdateNoteState.isLoading && (
          <View flex={1} justifyContent={'center'}>
            <Spinner size="lg" />
          </View>
        )}
        {!addOrUpdateNoteState.isLoading && (
          <VStack backgroundColor={Colors.Custom.BackgroundColor} flex={1}>
            {renderHeaderView()}
            {addOrUpdateNoteState.isNoNoteSelected && (
              <NoNotes title="selectNoteTemplate" />
            )}
            <PatientNoteFormView
              readOnly={isReadOnly()}
              submittedResponse={addOrUpdateNoteState?.templateData?.answers}
              components={addOrUpdateNoteState?.templateData?.components}
              onChange={handleFormChange}
            />
            {!!addOrUpdateNoteState?.templateData?.id && showCloseConfirmation && (
              <FHAlertDialog
                showVerticalButtons={true}
                isOpen={showCloseConfirmation}
                header={'Close note?'}
                message={intl.formatMessage({id: 'closeNoteConfirmationMessage'})}
                buttonActions={[
                  {
                    textLocalId: 'cancel',
                    buttonProps: {
                      variant: BUTTON_TYPE.SECONDARY,
                      flex: 1,
                    },
                    onPress: () => {
                      setShowCloseConfirmation(false);
                    },
                  },
                  {
                    textLocalId: 'proceed',
                    buttonProps: {
                      variant: BUTTON_TYPE.PRIMARY,
                    },
                    onPress: () => {
                      setShowCloseConfirmation(false);
                      navigation?.goBack();
                    },
                  },
                ]}
              />
            )}
          </VStack>
        )}
      </Box>
    );
  }

  const renderHeader = (header: any[]) => {
    if (header && header?.length) {
      const tenantHeader = header?.[0];
      if (tenantHeader && typeof tenantHeader === 'string') {
        return ReactHtmlParser(tenantHeader);
      } else {
        return tenantHeader;
      }
    }
  };

  const renderSignedByInfo = () => {
    const currentUserData = getUserData();
    const signedByUserName = currentUserData?.name;
    const signedByDate = getDateStrFromFormat(getDateToMomentISOString(), DATE_FORMATS.SIGNED_NOTE_DATE);
    const timezoneAbbr = getCurrentTimeZoneAbbr();
    if(signedByUserName && signedByDate) {
      return (
        <div className="page-break">
            <span className='font-size-18 font-weight-16' style={reactStyles.signedByText}>
              {`Signed by ${signedByUserName} on ${signedByDate} (${timezoneAbbr})`}
            </span>
        </div>
      );
    }
   }

   const renderPatientDemographics = () => {
    return (
      <div className="page-break" style={reactStyles.flexP5}>
        <Stack direction={'column'}>
          <span className='patient-name-text-style'>
            {personData.name}
          </span>
          <Stack direction={'row'} flex={1}>
            <Stack direction={'column'} flex={5} alignItems="flex-start">
              {!!personData?.phone && (
                <span className="font-size-16">{`${intl.formatMessage({id: 'phone'})} : ${
                  personData.phone &&
                  numericStringMask(personData.phone || '', PHONE_NUMBER_MASK)
                }`}</span>
              )}
              {!!personData?.email && (
                <span className="font-size-16"> {`${intl.formatMessage({id: 'email'})} : ${personData.email}`}</span>
              )}
              {!!personData?.birthDate && (
                <span className="font-size-16">
                {`${intl.formatMessage({id: 'birthDate'})} : ${getDateStrFromFormat(personData.birthDate, DATE_FORMATS.CONVERSATION_DATE_PICKER)}`} </span>
              )}
            </Stack>
          </Stack>
        </Stack>
      </div>
    );
  };

  const renderProviderInfo = () => {
    const providerData = getUserData();
    const appointmentAccountLocationUuid = addOrUpdateNoteState?.appointmentData?.accountLocationId;
    let practiceLocation = []

    if (appointmentAccountLocationUuid) {
      practiceLocation = loggedInUser?.userPracticeLocations?.find((item: IUserPracticeLocation) =>
          item?.accountLocation?.uuid === appointmentAccountLocationUuid
      ) || null;
    }
    const providerPhone = loggedInUser?.persons?.personContactDetails?.reduce((acc: string | null, contact: any) => {
      const phoneDetail: any | undefined = contact.contactDetails?.find((detail: any) => detail?.relationType?.code === 'phone');
      return phoneDetail ? phoneDetail.value : acc;
    }, null);

    const practiceLocationData = practiceLocation?.accountLocation?.practiceLocation
    return (
      <div className="page-break" style={reactStyles.flexP5}>
        <Stack direction={'column'}>
          <Stack direction={'row'}>
            <Stack direction={'column'} flex={5} alignItems="flex-start">
              {practiceLocationData && (
                <span className="font-size-16">{`${getAddressLine(practiceLocationData)
                  }`}</span>
              )}
              <Stack direction={'row'} flexWrap={'wrap'}>
                {!!providerData?.email && (
                  <span className="font-size-16"> {`${providerData.email}`}</span>
                )}
                 {(!!providerPhone && !!providerData?.email) &&(
                  <span className="font-size-16"> {`, `}</span>)}
                {!!providerPhone && (
                  <span className="font-size-16"> {`${providerPhone}`}</span>
                )}
              </Stack>

              {providerData?.name && (
                <span className="font-size-16">
                  {`Provider : ${providerData.name}`} </span>
              )}
            </Stack>
          </Stack>
        </Stack>
      </div>
    );
  };

  const renderVisitInfo = () => {
    if (addOrUpdateNoteState.appointmentData?.id) {
      return (
        <div className="page-break">
          <Stack direction={'column'}>
            <DetailPreview titleLocalId={`Visit details`}>
              <View>
              <Text fontWeight="bold">{addOrUpdateNoteState.appointmentData?.name}</Text>
              <Text>
                {`Date Of Visit: ${getDateStrFromFormat(addOrUpdateNoteState.appointmentData?.startDateTime,
                  DATE_FORMATS.MESSAGE_DATE_FORMAT
                )}`}
              </Text>
              {
                !!noteData.linkedAppointmentId && addOrUpdateNoteState.appointmentData?.reasonForVisit?.displayName &&
                <Text fontSize={16}>
                  {`Reason For Visit : ${addOrUpdateNoteState.appointmentData?.reasonForVisit?.displayName || '-'}`}
                </Text>
              }
              </View>
            </DetailPreview>
          </Stack>
        </div>
      );
    }
    return (<></>);
  }

  // const createTaskForNote = async (noteResponse: DocumentReference, labels?: any[]) => {
  //   try {
  //     const loginUserId: string = userUUID;
  //     const contactUuid: string = personData?.contactUUID || unFormattedContactData?.uuid;
  //     const taskDefaultTime: { startDateTime: string, endDateTime: string } = getDefaultStartAndEndDateForNoteTask();
  //     const addTaskVariables = {
  //       title: noteResponse.description,
  //       assigneeId: loginUserId,
  //       assignedById: loginUserId,
  //       contactId: contactUuid,
  //       priorityId: defaultPriorityId,
  //       startDateTime: taskDefaultTime.startDateTime,
  //       endDateTime: taskDefaultTime.endDateTime,
  //       assigneeTypeCode: 'USER',
  //       statusId: defaultStatusId,
  //       contextData: {
  //         documentReferenceId: noteResponse?.id,
  //         resourceType: noteResponse?.resourceType
  //       },
  //       ...(labels?.length && {labels : labels}),
  //     };

  //     const taskResponse = await createTask({
  //       context: {service: CARESTUDIO_APOLLO_CONTEXT},
  //       variables: {
  //         data: addTaskVariables,
  //       }
  //     });
  //     if (taskResponse?.data?.addOrUpdateTask?.id) {
  //       noteData.linkTasks = [taskResponse?.data?.addOrUpdateTask];
  //     }
  //     setDocumentReferenceTaskId(taskResponse?.data?.addOrUpdateTask?.id);
  //   } catch(error) {
  //   }
  // }

  const vStackContainerStyle = useMemo(() => ({
    backgroundColor: '#fff',
    paddingHorizontal: isFoldFormDrivenNotes ? 16 : props.paddingHorizontal ? props.paddingHorizontal : 0,
  }), [isFoldFormDrivenNotes, props.paddingHorizontal])

  return (
    <>
      {addOrUpdateNoteState.isLoading && <TimeLineSkeletonLoader />}
      {!addOrUpdateNoteState.isLoading && (
        <>
          <VStack
            h={'100%'}
            flex={1}
            backgroundColor={Colors.Custom.Gray50}
            borderRadius={4}>
            {renderHeaderView()}
            <VStack flex={1}>
              {addOrUpdateNoteState.isNoNoteSelected && (
                <NoNotes title="selectNoteTemplate" />
              )}
              {!addOrUpdateNoteState.isNoNoteSelected && (
                <VStack
                  flex={1}
                  h="100%"
                  style={vStackContainerStyle}>
                  <View ref={elRef} overflowY={'scroll'} height={'100%'}>
                    <Box h={3} />
                    {appointmentDataExist(addOrUpdateNoteState?.appointmentData) && (
                      <AppointmentBanner
                        appointmentId={addOrUpdateNoteState.appointmentData?.id}
                        name={addOrUpdateNoteState.appointmentData?.name}
                        location={addOrUpdateNoteState.appointmentData?.appointmentType?.locationType?.value || ''}
                        statusId={addOrUpdateNoteState.appointmentData?.statusId}
                        date={addOrUpdateNoteState.appointmentData?.startDateTime}
                        reasonForVisit={
                          addOrUpdateNoteState.appointmentData?.reasonForVisit?.displayName
                        }
                        extraStyles={{marginBottom: 22}}
                      />
                    )}
                    {allowWriteBack && <WriteInfoBackBanner />}
                    {addOrUpdateNoteState?.appointmentData?.id &&
                      addOrUpdateNoteState?.appointmentData?.tasks?.length > 0 &&
                      pendingAppointmentTaskCount > 0 && (
                      <VStack
                        borderRadius={8}
                        borderWidth={'1px'}
                        borderColor={Colors.Custom.Gray300}
                        padding={4}
                        marginBottom={4}
                        mx={5}
                      >
                        <View marginBottom={2}>
                          <Text
                            size={'mdBoldNew'}
                          >
                            {'Need Your Attention'}
                          </Text>
                        </View>

                        <Pressable
                          onPress={() => {
                            setIsAppointmentChecklistDrawerOpen(true);
                          }}
                        >
                          <Alert
                            w={'full'}
                            status={'error'}
                            height={'40px'}
                            backgroundColor={Colors.Custom.Danger50}
                          >
                            <VStack space={2} flexShrink={1} w={'full'}>
                              <HStack
                                flexShrink={1}
                                space={2}
                                justifyContent="space-between"
                                alignItems={'center'}
                              >
                                <HStack
                                  space={2}
                                  flexShrink={1}
                                  alignItems={'center'}
                                >
                                  <InfoOutlineIcon
                                    style={styles.infoIcon}
                                    size="xs"
                                  />
                                  <Text color={Colors.Custom.Danger700}>
                                    {`${pendingAppointmentTaskCount} pending action(s) need your attention`}
                                  </Text>
                                </HStack>
                                <IconButton
                                  onPress={() => {
                                    setIsAppointmentChecklistDrawerOpen(true);
                                  }}
                                  variant={'unstyled'}
                                  icon={
                                    <Icon
                                      as={AntIcon}
                                      name="right"
                                      size="xs"
                                      color={Colors.Custom.Danger700}
                                    />
                                  }
                                />
                              </HStack>
                            </VStack>
                          </Alert>
                        </Pressable>

                        {isAppointmentChecklistDrawerOpen && (
                          <AppointmentTasksDrawer
                            appointmentId={addOrUpdateNoteState?.appointmentData?.id}
                            isVisible={isAppointmentChecklistDrawerOpen || false}
                            canAddNewTasks={false}
                            canDeleteTasks={false}
                            canEditStatus={true}
                            canEditTitle={false}
                            onChange={(tasks) => {
                              addOrUpdateNoteState.appointmentData.tasks = tasks;
                            }}
                            onClose={() => {
                              setIsAppointmentChecklistDrawerOpen(false);
                            }}
                          />
                        )}
                      </VStack>
                    )}
                    <div className={`custom-form-styles`}>
                      {!isFormEmpty ? (
                        renderFHForm()
                      ) : (
                        <VStack h='100%' flex={1} alignItems={'center'} mt={16} justifyContent={'center'} px={'20%'}>
                          <EmptyFormForNoteIcon />
                          <Text fontSize={'lg'} textAlign={'center'} mt={10} color={Colors.Custom.Gray400}>
                            {intl.formatMessage({id:'emptyNoteMessage'})}
                          </Text>
                        </VStack>
                      )}
                    </div>
                    {readOnlyFH && (
                      <div style={reactStyles.displayNone} ref={ref}>
                        <div
                          className={`note-preview-styles custom-form-styles`}
                        >
                          {!!header && renderHeader(header)}
                          <div style={reactStyles.flex}>
                            {renderPatientDemographics()}
                            {renderProviderInfo()}
                          </div>
                          {renderVisitInfo()}
                          <FHForm
                            components={
                              addOrUpdateNoteState?.templateData?.components
                            }
                            submittedResponse={submitData}
                            optionData={addOrUpdateNoteState.htmlGenerateFormOptions}
                            readOnly={true}
                            isPreviewMode={readOnlyFH}
                          />
                          {renderSignedByInfo()}
                        </div>
                      </div>
                    )}
                    {(readOnlyFH && allowToCreateTask && shareComponents?.length > 0) && (
                        <div style={reactStyles.displayNone} ref={shareComponentRef}>
                          <div
                            className={`note-preview-styles custom-form-styles`}
                          >
                            {!!header && renderHeader(header)}
                            <FHForm
                              components={shareComponents}
                              submittedResponse={submitData}
                              optionData={addOrUpdateNoteState.htmlGenerateFormOptions}
                              readOnly={true}
                              isPreviewMode={true}
                            />
                            {renderSignedByInfo()}
                          </div>
                        </div>
                    )}

                    {allowNoteOperation(NoteOperation.ORDERS,ehrConfig,{
                      contextData: {} as ICommonData
                    }) && (
                      <VStack mb={2} space={4}>
                        {addOrUpdateNoteState.renderLabOrder && (
                          <OrderList
                            isPreviewMode={false}
                            existingDiagnosis={existingDiagnosis}
                            addDiagnosisFormOrderList={
                              addDiagnosisFormOrderList
                            }
                            title={'Lab'}
                            orderType={OrderType.LAB}
                            patientContactId={personData?.id || ''}
                            patientId={
                              personData?.patientId ||
                              personData?.patientUuid ||
                              ''
                            }
                            documentReferenceId={documentReferenceId || ''}
                            unFormattedContactData={
                              props.unFormattedContactData
                            }
                            personData={props.personData}
                            onOrderListUpdate={(list: any[]) => {
                              const actions = addOrUpdateNoteState.activeActions || {};
                              actions[ORDER_TYPE.LAB] = [];
                              setAddOrUpdateTemplateState(prev => {
                                return {
                                  ...prev,
                                  activeActions: actions,
                                  labOrderFlatList: list,
                                  labOrders: getOrdersFormattedForDocRef(list, OrderType.LAB)
                                };
                              });
                            }}
                            disabled={false}
                          />
                        )}

                        {addOrUpdateNoteState.renderRadOrder && (
                          <OrderList
                            isPreviewMode={false}
                            existingDiagnosis={existingDiagnosis}
                            addDiagnosisFormOrderList={
                              addDiagnosisFormOrderList
                            }
                            title={'Imaging'}
                            orderType={OrderType.RAD}
                            patientContactId={personData?.id || ''}
                            unFormattedContactData={
                              props.unFormattedContactData
                            }
                            personData={props.personData}
                            patientId={
                              personData?.patientId ||
                              personData?.patientUuid ||
                              ''
                            }
                            documentReferenceId={documentReferenceId || ''}
                            onOrderListUpdate={(list: any[]) => {
                              const actions = addOrUpdateNoteState.activeActions || {};
                              actions[ORDER_TYPE.RAD] = [];
                              setAddOrUpdateTemplateState(prev => {
                                return {
                                  ...prev,
                                  activeActions: actions,
                                  radOrderFlatList: list,
                                  radOrders: getOrdersFormattedForDocRef(list, OrderType.RAD)
                                };
                              });
                            }}
                            disabled={false}
                          />
                        )}

                        {addOrUpdateNoteState.renderMedOrder && (
                          <MedOrderList
                            isPreviewMode={false}
                            addDiagnosisFormOrderList={
                              addDiagnosisFormOrderList
                            }
                            patientContactId={personData?.id || ''}
                            patientId={
                              personData?.patientId ||
                              personData?.patientUuid ||
                              ''
                            }
                            documentReferenceId={documentReferenceId || ''}
                            unFormattedContactData={
                              props.unFormattedContactData
                            }
                            personData={props.personData}
                            onOrderListUpdate={(list: any[]) => {
                              const actions = addOrUpdateNoteState.activeActions || {};
                              actions[ORDER_TYPE.MED] = [];
                              setAddOrUpdateTemplateState(prev => {
                                return {
                                  ...prev,
                                  activeActions: actions,
                                  medOrderFlatList: list,
                                  medOrders: getMedOrdersFormattedForDocRef(list, OrderType.MED)
                                };
                              });
                            }}
                            disabled={false}
                          />
                        )}
                      </VStack>
                    )}
                  </View>
                </VStack>
              )}
            </VStack>
            {showOrdersBottomActionBar && (
              <Box borderBottomRadius={8}>
                <Divider w="100%" mb={2} />
                <HStack px={5}>
                  <BottomActionsBar
                    height={'6%'}
                    actionList={addOrUpdateNoteState.currentAvailableActions}
                  />
                </HStack>
              </Box>
            )}
          </VStack>
          {/* This component will be used for Med only, thus isExternalOrder is false */}
          {addOrUpdateNoteState.isAddNewOrderModalOpen && (
            <AddOrUpdateOrders
              isVisible={addOrUpdateNoteState.isAddNewOrderModalOpen}
              isExternalOrder={false}
              existingDiagnosis={existingDiagnosis}
              orderType={addOrUpdateNoteState.selectedOrderType}
              patientContactId={personData?.id}
              documentReferenceId={documentReferenceId}
              unFormattedContactData={unFormattedContactData}
              personData={personData}
              onClose={() => {
                switch (addOrUpdateNoteState.selectedOrderType) {
                  case OrderType.LAB:
                    setAddOrUpdateTemplateState(prev => {
                      return {
                        ...prev,
                        isAddNewOrderModalOpen: false,
                        selectedOrderType: '',
                        renderLabOrder: true,
                      };
                    });
                    break;
                  case OrderType.MED:
                    setAddOrUpdateTemplateState(prev => {
                      return {
                        ...prev,
                        isAddNewOrderModalOpen: false,
                        selectedOrderType: '',
                        renderMedOrder: true,
                      };
                    });
                    break;
                  case OrderType.RAD:
                    setAddOrUpdateTemplateState(prev => {
                      return {
                        ...prev,
                        isAddNewOrderModalOpen: false,
                        selectedOrderType: '',
                        renderRadOrder: true,
                      };
                    });
                    break;
                }
                saveForm({isSign: false, isAutoSave: true, activeActions: addOrUpdateNoteState.activeActions});
                if (enableScroll) {
                  executeScroll();
                }
              }}
            />
          )}

          {addOrUpdateNoteState.isConfirmationModalOpen && (
            <FHAlertDialog
                    isOpen={addOrUpdateNoteState.isConfirmationModalOpen}
                    header={'Sign note?'}
                    message={` This note has already been signed by ${addOrUpdateNoteState.alreadySignByUserName}`}
                    buttonActions={[
                      {
                        textLocalId: 'goBackDashboardMessage',
                        buttonProps: {
                          variant: BUTTON_TYPE.PRIMARY,
                        },
                        onPress: () => {
                          if (props?.onActionPerformed && typeof props?.onActionPerformed === 'function') {
                            props?.onActionPerformed(PATIENT_NOTE_OPERATION.REFETCH, addOrUpdateNoteState.updatedSignedNote || {} as IPatientNoteCardProps);
                          }
                        },
                      },
                      {
                        textLocalId: 'viewSignedMessage',
                        buttonProps: {
                          variant: BUTTON_TYPE.PRIMARY,
                        },
                        onPress: async () => {
                          setAddOrUpdateTemplateState(prev => ({...prev, isLoading: true}));
                          if (props?.onActionPerformed && typeof props?.onActionPerformed === 'function') {
                            props?.onActionPerformed(PATIENT_NOTE_OPERATION.VIEW_SIGNED_NOTE, addOrUpdateNoteState.updatedSignedNote || {} as IPatientNoteCardProps);
                          }
                        },
                      },
                    ]}
                  />
          )}

          {addOrUpdateNoteState.isDraftOrderConfirmationModalOpen && (
            <FHAlertDialog
                    isOpen={addOrUpdateNoteState.isDraftOrderConfirmationModalOpen}
                    header={'Sign note?'}
                    message={`This note includes orders in draft state which have not been submitted to any facility/pharmacy. You can still submit these orders from orders section if you sign the note.`}
                    buttonActions={[
                      {
                        textLocalId: 'cancel',
                        buttonProps: {
                          variant: BUTTON_TYPE.SECONDARY,
                        },
                        onPress: () => {
                          setAddOrUpdateTemplateState(prev => {
                            return {
                              ...prev,
                              isDraftOrderConfirmationModalOpen: false,
                              draftOrderNoteParams: undefined,
                            }
                          });
                        },
                      },
                      {
                        textLocalId: 'continue',
                        buttonProps: {
                          variant: BUTTON_TYPE.PRIMARY,
                        },
                        onPress: async () => {
                          setAddOrUpdateTemplateState(prev => {
                            return {
                              ...prev,
                              isDraftOrderConfirmationModalOpen: false,
                            }
                          });
                          saveForm(addOrUpdateNoteState.draftOrderNoteParams, true);
                        },
                      },
                    ]}
                  />
          )}
          {showCloseConfirmation && (
            <FHAlertDialog
              showVerticalButtons={!isWeb()}
              isOpen={showCloseConfirmation}
              header={'Close note?'}
              message={intl.formatMessage({id: 'closeNoteConfirmationMessage'})}
              buttonActions={[
                {
                  textLocalId: 'cancel',
                  buttonProps: {
                    variant: BUTTON_TYPE.SECONDARY,
                    flex: 1,
                  },
                  onPress: () => {
                    setShowCloseConfirmation(false);
                  },
                },
                {
                  textLocalId: 'proceed',
                  buttonProps: {
                    variant: BUTTON_TYPE.PRIMARY,
                  },
                  onPress: () => {
                    setShowCloseConfirmation(false);
                    if(!isWeb()){
                      navigation?.goBack();
                    }
                    if (props?.goBackHandler && typeof props?.goBackHandler === 'function') {
                      props?.goBackHandler();
                    } else {
                      handleNoteOperation(PATIENT_NOTE_OPERATION.REFETCH);
                    }
                  },
                },
              ]}
            />
          )}
          <ReactToPrint
            documentTitle={getDocumentTitle()}
            trigger={() => <></>}
            content={() => componentRef.current}
            ref={printRef}
            onAfterPrint={() => {
              if (addOrUpdateNoteState.isPrintComponentRender) {
                if (props?.goBackHandler && typeof props?.goBackHandler === 'function') {
                  props?.goBackHandler();
                } else {
                  handleNoteOperation(PATIENT_NOTE_OPERATION.REFETCH);
                }
              } else {
                resetPrintComponentStatus();
              }
            }}
            print={async (target) => {
              return new Promise<any>((resolve, reject) => {
                let outerHTML = '';
                if (target.contentDocument) {
                  target.contentDocument.title = getDocumentTitle();
                  outerHTML = target.contentDocument.body.innerHTML;
                }
                let sharedNoteHtml = '';
                if (allowToSharePatientHtml && shareComponents?.length > 0) {
                  sharedNoteHtml = getShareNoteHtmlV2(outerHTML);
                }
                const noteHtml = generateHtmlV2(outerHTML);
                const params = {
                  ...addOrUpdateNoteState?.manualActionData,
                  noteHtml: noteHtml,
                  patientShareHtml: sharedNoteHtml,
                };
                postValidationSaveNoteData(params);
                if (addOrUpdateNoteState?.manualActionData?.isPrint || addOrUpdateNoteState?.manualActionData?.printShareable) {
                  target?.contentWindow?.print?.();
                }
                resolve(true);
              });
            }}
            removeAfterPrint={true}
          />
          <div style={reactStyles.displayNone}>
            <ComponentToPrint
              ref={componentRef}
              header={header}
              footer={footer}>
              {renderPrintComponent}
            </ComponentToPrint>
          </div>
        </>
      )}
    </>
  );
};

const reactStyles: Record<string, React.CSSProperties> = {
  displayNone: {
    display: 'none'
  },
  flex: {
    display: 'flex'
  },
  signedByText: {
    fontWeight: 'bold',
  },
  flexP5: {
    flex: 0.5
  }
};

const styles = StyleSheet.create({
  selectDownArrowContainer: {
    width: 12,
    height: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  featherIcon: {
    paddingHorizontal: 10,
    alignSelf: 'center',
  },
  subHeaderText: {
    marginLeft: 10,
    color: Colors.FoldPixel.GRAY250,
  },
  sidecarContextSpacer: {
    height: 20,
    width: 1,
  },
  signedByInfoText: {
    fontWeight: 'bold',
  },
  patientDemographicsContainer: {
    flex: 0.5,
  },
  infoIcon: {
    color: Colors.Custom.Danger700,
  },
});

export default AddOrUpdatePatientNote;
