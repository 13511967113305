import React, { useEffect, useMemo, useRef } from 'react';
import { Table, Skeleton, Drawer } from 'antd';
import { Text as NativeText, Spinner, Text, useToast } from 'native-base';
import { PatientListingTableProps } from '../../interfaces';
import { cssPropertiesAntD } from '../../style';
import { DayOptimizerBulkActions, DayOptimizerMenuItemKeys, getColumns, getSelectedItemsForAutomation, onTableChange } from '../../DayOptimizerHelper';
import useDOPatientListing from './useDOPatientListing';
import '../../styles.css';
import DayOptimizerLoadingSvg from '../../../../../../assets/Icons/DayOptimizerLoadingSvg';
import NoPatientFoundSvg from '../../../../../../assets/Icons/NoPatientFoundSvg';
import { Colors } from '../../../../../../styles';
import { useIntl } from 'react-intl';
import { useWindowDimensions, View } from 'react-native';
import { RIGHT_SIDE_CONTAINER_CODE, SMALL_WINDOW_1500 } from '../../../../../../constants';
import AddContactRelationView from '../../../../AddContactRelationView/AddContactRelationView';
import { DayOptimizerActionStatus, DayOptimizerViewAction } from '../../DayOptimizerConstants';
import { ParticipantType } from '../../../../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import { AddTagView } from '../../../../TagViews/AddTagView';
import AppointmentBooking from '../../../../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBooking';
import { AutomationWorkflowEmployee } from '../../../../../RightSideContainer/MembersManagement/AutomationWorkflowEmployee';
import ContactSendForm from '../../../../ContactSendForm/ContactSendForm';
import { getUserUUID } from '../../../../../../utils/commonUtils';
import { AddOrUpdateLead } from '../../../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import SendEdicationContent from './SendEducationContent';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import { isEnableCareProgram } from '../../../../../../utils/commonUtils';
import { showToast, ToastType } from '../../../../../../utils/commonViewUtils';
import { COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES } from '../../../../../../constants/ActionConst';
import AddTaskWrapper from './AddTaskWrapper';

import { patientDashboardStyles } from '../PatientDashboardStyles';
import Stack from '../../../../LayoutComponents/Stack';
import CreateSmsConversationDrawer from '../../../../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import { SMS_INBOX_ACTION_CODES } from '../../../../../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import InstantChatView from '../../../../ChatDrawer/InstantChatView';
import { EmailDrawerCommonV2 } from '../../../../EmailDrawerCommonV2';
import InstantChat from '../../../../ChatDrawer/InstantChat';
import { createPortal } from 'react-dom';
import { BulkAssignCareManagerView } from '../../../../../PersonOmniView/RightContainer/CareTeamView/components/BulkAssignCareManagerView/BulkAssignCareManagerView';
import BulkActionWrapper from './BulkActionWrapper';
const PatientListingTable = ({
  isScheduleManagerVisible,
  containerHeight,
  onViewAction,
  isPatientDetailViewVisible,
}: PatientListingTableProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { width, height } = useWindowDimensions();
  const isSmallScreen = width < SMALL_WINDOW_1500;
  const userUUID = getUserUUID();
  const commonData = React.useContext(CommonDataContext);
  const isCareProgramEnabled = isEnableCareProgram(commonData.userSettings);
  const toast = useToast();

  const containerRef = useRef(document.createElement('div'));
  containerRef.current.style.position = 'fixed';
  containerRef.current.style.zIndex = '100';
  const containerRef2 = useRef(document.createElement('div'));
  containerRef2.current.style.position = 'fixed';
  containerRef2.current.style.zIndex = '105';
  useEffect(() => {
    document.body.appendChild(containerRef.current);
    document.body.appendChild(containerRef2.current);
    return () => {
      try {
        document.body.removeChild(containerRef.current);
        document.body.removeChild(containerRef2.current);
      } catch {}
    }
  }, []);

  const filterFlagList = {
    isCareProgramEnabled: isCareProgramEnabled,
  }

  const {
    patients,
    isLoading,
    isLoadingSkeleton,
    hasMore,
    fetchPatients,
    formattedDate,
    updatePatientStatus,
    columnShuffleDropDownList,
    onMenuItemActionPerformed,
    onDayOptimizerActions,
    selectedBulkActionMembers,
    selectedConversation,
    selectedPatient,
    activeDrawer,
    updatePatients,
    handleSortChange,
    currentSortingValue,
    currentSortingOrderColumnId,
    selectedDate,
    loadingActions,
    typeNodes,
  } = useDOPatientListing({onViewAction});

  const tableRef = useRef(null);
  const intl = useIntl();
  const tableContainerHeight = containerHeight ? containerHeight - 90 : height;  

  const onActionPerformed = (
    action: DayOptimizerMenuItemKeys | DayOptimizerViewAction | string,
    record: any,
    popover?: {open: boolean, closePopover: () => void}
  ) => {
    switch (action) {
      case DayOptimizerMenuItemKeys.ADD_RELATIVES:
      case DayOptimizerMenuItemKeys.EDIT_PATIENT:
      case DayOptimizerMenuItemKeys.ADD_TASK:
      case DayOptimizerMenuItemKeys.CONTACT_TAG_VIEW:
      case DayOptimizerMenuItemKeys.SCHEDULE_APPOINTMENT:
      case DayOptimizerMenuItemKeys.RUN_AUTOMATION:
      case DayOptimizerMenuItemKeys.SEND_ASSESSMENT:
      case DayOptimizerMenuItemKeys.INITIATE_PROTOCOL_AWV:
      case DayOptimizerMenuItemKeys.INITIATE_PROTOCOL_TCM:
      case DayOptimizerMenuItemKeys.INITIATE_PROTOCOL_CCM:
      case DayOptimizerMenuItemKeys.MARK_AS_COMPLETE:
      case DayOptimizerMenuItemKeys.SEND_EDUCATION:
      case DayOptimizerMenuItemKeys.ADD_TO_TODAY:
      case DayOptimizerMenuItemKeys.MOVE_TO_NEXT_DAY:
        onMenuItemActionPerformed(action, record, popover);
        break;
      case RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER:
      case RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP:
      case RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER:
      case CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT:
      case DayOptimizerBulkActions.TOOGLE_MEMBER_SELECTION:
      case DayOptimizerBulkActions.SELECT_ALL_MEMBERS:
      case DayOptimizerBulkActions.DESELECT_ALL_MEMBERS:
      case DayOptimizerBulkActions.RUN_AUTOMATION:
      case DayOptimizerBulkActions.MOVE_TO_NEXT_DAY:
      case RIGHT_SIDE_CONTAINER_CODE.ASSIGN_CARE_MANAGER_VIEW:
        onDayOptimizerActions(action, record);
        break;
      default:
        onViewAction(action as DayOptimizerViewAction, record);
        break;
    }
  }
  
  const columns = useMemo(
    () =>
      getColumns(
        isPatientDetailViewVisible,
        onActionPerformed,
        isSmallScreen,
        selectedBulkActionMembers,
        columnShuffleDropDownList,
        currentSortingValue,
        currentSortingOrderColumnId,
        filterFlagList,
        selectedDate,
        loadingActions,
        patients.length
      ),
    [
      isPatientDetailViewVisible,
      selectedBulkActionMembers.size,
      columnShuffleDropDownList,
      currentSortingValue,
      currentSortingOrderColumnId,
      loadingActions,
      patients,
      selectedDate,
    ]
  );  

  useEffect(() => {
    let tableBody: HTMLElement | null = null;
    if(tableRef.current) {
      tableBody = (tableRef.current as HTMLElement).querySelector('.ant-table-body');
    }

    const handleScroll = () => {
      if (!tableBody) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBody;
      if ((scrollTop + clientHeight >= scrollHeight - 5) && hasMore) {        
        fetchPatients(formattedDate, false)
      }
    };
  
    tableBody?.addEventListener('scroll', handleScroll);

    return () => {
      tableBody?.removeEventListener('scroll', handleScroll);
    };
  }, [isLoading, hasMore]);

  useEffect(()=> {
    if(tableRef.current && containerHeight && patients.length > 0) {
      const tableHeight =  (tableRef.current as HTMLElement).getBoundingClientRect().height || 0;
      if(tableHeight < tableContainerHeight && hasMore) {
        fetchPatients(formattedDate, false, patients.length)
      }
    }
  }, [patients?.length])

  const LoadingTableSkeleton = () => (
    <Table
      dataSource={[...Array(10)].map((_, index) => ({
        key: `${index}`,
        patientId: '',
        rafScore: 0,
        name: '',
        reason: '',
        actions: [],
        alerts: [], 
        status: '',
        lastVisit: ''
      })) as readonly any[]}
      columns={columns.map(column => ({
        ...column,
        render: () => <Skeleton active paragraph={false} />
      }))}
      pagination={false}
      scroll={{ x: 'max-content' }}
      style={{ padding: 0, backgroundColor: 'white' }}
      size='small'
    />
  );

  const LoadingInitialDOView = () => {
      <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <DayOptimizerLoadingSvg />
          <NativeText fontSize={20} fontWeight={600} color={Colors.FoldPixel.GRAY400} style={{ textAlign: 'center' }}>
            {intl.formatMessage({ id: 'dayOptimizerSettingUp' })}
          </NativeText>
        </div>
  };

  const getTableHeight = () => {
    return containerHeight ? isLoading ? tableContainerHeight - 40 : tableContainerHeight : undefined;
  }

  const showTable = patients?.length > 0;

  return (
    <div
      ref={scrollRef}
      style={{
        height: containerHeight ? `${containerHeight - 55}px` : 'auto',
        overflowY: 'auto',
        ...cssPropertiesAntD.scrollableDiv,
      }}
      id='patientListTable'
    >
      {isLoading && patients?.length === 0 || columnShuffleDropDownList?.length == 0 || isLoadingSkeleton ? (
        <LoadingTableSkeleton />
      ) : patients?.length === 0 && !isLoading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <NoPatientFoundSvg />
          <NativeText
            fontSize={20}
            fontWeight={500}
            color={Colors.FoldPixel.GRAY300}
            style={{textAlign: 'center'}}
          >
            {intl.formatMessage({id: 'noPatientFound'})}
          </NativeText>
        </div>
      ) : (       
       <></>
      )}
      
        <Table
          ref={tableRef}
          className={"day-optimizer-patient-table"}
          dataSource={patients as readonly any[]}
          columns={columns}
          pagination={false}
          style={{ padding: 0, backgroundColor: 'white' , display: showTable? 'block' : 'none'}}
          size="small"
          rowClassName="table-row-dayOptimizer"
          rowKey={(record, index) =>
            `day_optimizer_list_${record?.patientId}_${index}`
          }
          scroll={{
            x: 'max-content',
            y: getTableHeight() // Set table height for scroll
          }}      
          onChange={(pagination, filters, sorter) => onTableChange(sorter, handleSortChange)}     
          footer={
            isLoading && patients?.length > 0
              ? () => (
                  <Stack
                    direction="row"
                    style={patientDashboardStyles.patientLoadMoreView}
                  >
                    <Spinner />
                    <Text style={patientDashboardStyles.patientLoadMoreText}>
                      {intl.formatMessage({id: 'loadingText'})}
                    </Text>
                  </Stack>
                )
              : undefined
          }
      />

      {activeDrawer === DayOptimizerMenuItemKeys.ADD_RELATIVES && (
        <AddContactRelationView
          contactData={selectedPatient?.patient}
          contactId={selectedPatient?.patientId}
          onFormCompleteAction={(actionCode: string) => {
            onMenuItemActionPerformed(
              DayOptimizerMenuItemKeys.ADD_RELATIVES,
              null
            );
          }}
          onRelativeAdded={() => {
            showToast(
              toast,
              intl.formatMessage({id: 'relativeAddedSuccessfully'}),
              ToastType.success,
              1500
            );
          }}
        />
      )}

      {activeDrawer === DayOptimizerMenuItemKeys.ADD_TASK && (
        <AddTaskWrapper
          selectedPatients={selectedPatient}
          activeDrawer={activeDrawer}
          onMenuItemActionPerformed={onMenuItemActionPerformed}
        />
      )}

      {activeDrawer === DayOptimizerMenuItemKeys.CONTACT_TAG_VIEW && (
        <AddTagView
          contactId={parseInt(selectedPatient?.patientId || '')}
          tagType="Contact"
          onFormCompleteAction={(actionCode: string) => {
            onMenuItemActionPerformed(
              DayOptimizerMenuItemKeys.CONTACT_TAG_VIEW,
              null
            );
            if (actionCode === COMMON_ACTION_CODES.ADDED_OR_UPDATED) {
              showToast(
                toast,
                intl.formatMessage({id: 'tagAddedSuccessfully'}),
                ToastType.success,
                1500
              );
            }
          }}
        />
      )}

      {activeDrawer === DayOptimizerMenuItemKeys.SCHEDULE_APPOINTMENT && (
        <AppointmentBooking
          defaultParticipants={[
            {
              label: selectedPatient?.name || '',
              key: selectedPatient?.uuid || '',
              value: selectedPatient?.uuid || '',
              type: ParticipantType.patient,
            },
          ]}
          isVisible={true}
          /* onAppointmentBooked={() => {
            showToast(
              toast,
              'Appointment scheduled successfully',
              ToastType.success,
              1500
            );
          }} */
          onComplete={() => {
            onMenuItemActionPerformed(
              DayOptimizerMenuItemKeys.SCHEDULE_APPOINTMENT,
              null
            );
          }}
          onCancel={() => {
            onMenuItemActionPerformed(
              DayOptimizerMenuItemKeys.SCHEDULE_APPOINTMENT,
              null
            );
          }}
        />
      )}
    {(activeDrawer === DayOptimizerMenuItemKeys.RUN_AUTOMATION && selectedPatient) || (activeDrawer === DayOptimizerBulkActions.RUN_AUTOMATION && selectedBulkActionMembers.size > 0) && 
      <Drawer
        width={width / 3}
        open={true}
        closeIcon={null}
        closable
        onClose={() => {
          onMenuItemActionPerformed(
            DayOptimizerMenuItemKeys.RUN_AUTOMATION,
            null
          );
        }}
        title={<></>}
        maskClosable={true}
      >
        <AutomationWorkflowEmployee
          type="patient"
          initData={{selectedItems: getSelectedItemsForAutomation(activeDrawer, selectedPatient, selectedBulkActionMembers)}}
          onModalClose={() => {
            onMenuItemActionPerformed(
              DayOptimizerMenuItemKeys.RUN_AUTOMATION,
              null
            );
          }}
        />
      </Drawer>
    }

      {activeDrawer === DayOptimizerMenuItemKeys.SEND_ASSESSMENT && (
        <ContactSendForm
          isVisible={activeDrawer === DayOptimizerMenuItemKeys.SEND_ASSESSMENT}
          assignmentData={{
            patientId: selectedPatient?.patientUuid || '',
            contactId: selectedPatient?.uuid || '',
            assignedById: userUUID,
            patientEmail: selectedPatient?.email || '',
            patientFirstName: selectedPatient?.firstName || '',
            patientContactUUID: selectedPatient?.uuid || '',
            contactTypeCode: selectedPatient?.contactType?.code || '',
          }}
          onActionComplete={() => {
            onMenuItemActionPerformed(
              DayOptimizerMenuItemKeys.SEND_ASSESSMENT,
              null
            );
            showToast(
              toast,
              intl.formatMessage({id: 'assessmentSentSuccessfully'}),
              ToastType.success,
              1500
            );
          }}
          onClose={() => {
            onMenuItemActionPerformed(
              DayOptimizerMenuItemKeys.SEND_ASSESSMENT,
              null
            );
          }}
        />
      )}

      {activeDrawer === DayOptimizerMenuItemKeys.EDIT_PATIENT && (
        <AddOrUpdateLead
          singleLeadData={{
            ...selectedPatient,
            id: selectedPatient?.patientId,
          }}
          onFormCompleteAction={(actionCode: string) => {
            if (actionCode === COMMON_ACTION_CODES.ADDED_OR_UPDATED) {
              if (selectedPatient?.uuid) {
                updatePatients([selectedPatient?.uuid]);
              }
              showToast(
                toast,
                intl.formatMessage({id: 'patientDetailsUpdatedSuccessfully'}),
                ToastType.success,
                1500
              );
            }
            onMenuItemActionPerformed(
              DayOptimizerMenuItemKeys.EDIT_PATIENT,
              null
            );
          }}
          personType={selectedPatient?.contactType?.code}
          personTypeUuid={selectedPatient?.contactType?.id}
        />
      )}

      {activeDrawer === DayOptimizerMenuItemKeys.SEND_EDUCATION && (
        <SendEdicationContent 
          typeNodes={typeNodes}
          contactId={selectedPatient?.uuid || ''}
          onClose={() => {
            onMenuItemActionPerformed(
              DayOptimizerMenuItemKeys.SEND_EDUCATION,
              null
            );
          }}
        />
      )}

      {activeDrawer ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER && (
        <CreateSmsConversationDrawer
          isDrawerVisible={true}
          selectedInbox={{} as any}
          onCreateSmsConversationActionPerformed={(
            actionCode: string,
            actionData: any
          ) => {
            if (
              actionCode === SMS_INBOX_ACTION_CODES.DRAWER_CLOSE &&
              !actionData
            ) {
              onActionPerformed(
                RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER,
                null
              );
            } else {
              onActionPerformed(
                RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER,
                actionData?.selectedInboxConversationData?.conversations?.[0]
              );
            }
          }}
          selectedPatient={{
            ...selectedPatient,
            id: parseInt(selectedPatient?.patientId || ''),
          }}
        />
      )}

      {activeDrawer === RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER && selectedConversation && (
        <InstantChatView
          selectedConversation={selectedConversation}
          contactData={{} as any}
          isLoading={false}
          isDrawerVisible={true}
          isInstantChatView={true}
          instantChatFromNotifications={true}
          onActionPerformed={(actionCode: any, actionData: any) => {
            if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
              onActionPerformed(
                RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER,
                null
              );
            } 
          }}
        />
      )}

      {activeDrawer ===
        CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT &&
        selectedPatient?.uuid &&
        selectedPatient?.patient?.patientId && (
          <InstantChat
            contactData={{
              ...selectedPatient,
              id: parseInt(selectedPatient?.patientId || ''),
            }}
            contactUuid={selectedPatient?.uuid}
            isDrawerVisible={
              activeDrawer ===
              CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT
            }
            onActionPerformed={(actionCode: string) => {
              if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
                onActionPerformed(
                  CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT,
                  null
                );
              }
            }}
          />
        )
      }

      {activeDrawer === RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP && (
        <EmailDrawerCommonV2
          isOpen={true}
          onClose={() => {
            onActionPerformed(
              RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP,
              null
            );
          }}
          onEmailSent={({msgData}) => {
            const conversationId = msgData?.conversationId;
            onActionPerformed(
              RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER,
              conversationId
            );
          }}
          contactIds={selectedPatient?.patientId ? [parseInt(selectedPatient?.patientId)] : []}
        />
      )}

      {activeDrawer ===
        RIGHT_SIDE_CONTAINER_CODE.ASSIGN_CARE_MANAGER_VIEW && (
          createPortal(
            <BulkAssignCareManagerView
              fetchContactCareTeams={true}
              ignoreBroadcast={true}
              selectedContacts={(() => {
                const selectedContacts = Array.from(selectedBulkActionMembers.values());
                const requiredData = selectedContacts.map((contact) => {
                  return {
                    ...contact,
                    contactData: contact,
                  }
                }) as any;
                return requiredData;
              })()}
              visible={true}
              onClose={() => {
                onActionPerformed(RIGHT_SIDE_CONTAINER_CODE.ASSIGN_CARE_MANAGER_VIEW, DayOptimizerActionStatus.CLOSE);
              }}
              onSuccess={(selectedCareManager) => {
                onActionPerformed(RIGHT_SIDE_CONTAINER_CODE.ASSIGN_CARE_MANAGER_VIEW, {
                  selectedCareManager,
                  actionStatus: DayOptimizerActionStatus.SUCCESS
                });
              }}
            />,
            containerRef2.current
          )
        )
      }

      {createPortal(
        <BulkActionWrapper
          onActionPerformed={onActionPerformed}
          selectedBulkActionMembers={selectedBulkActionMembers}
          selectedDate={selectedDate}
        />,
        containerRef.current
      )}

      {/* {!hasMore && patients.length > 0 && (
        <p style={{ textAlign: 'center' }}>
          <b>You have seen it all</b>
        </p>
      )} */}
    </div>
  );
};

export default PatientListingTable;
