import React from "react";
import { Path, Svg } from "react-native-svg";
import { ICommonSvgProps } from "./interfaces";

const ChevronDownSvg = (props: ICommonSvgProps)=> {
  return (
    <Svg
      width={props?.size || '7'}
      height={props?.size || '6'}
      fill="none"
      viewBox="0 0 7 6"
    >
      <Path
        fill={props?.customStrokeColor || '#6F7A90'}
        stroke={props?.customStrokeColor || '#6F7A90'}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.9 4l2-2h-4l2 2z"
      ></Path>
    </Svg>
  );
}

export default ChevronDownSvg;
