import React from 'react';
import {useParams} from 'react-router-dom';
import {ICareManagementView} from './interface';
import {ContactCareProgramView} from '../ContactCareProgram';
import CareManagementView from './CareManagementView';
import ErrorBoundary from '../../common/ErrorBoundry/ErrorBoundry';
import EmptyStateIconSvg from '../../common/Svg/EmptyStateIconSvg';
import {View} from 'react-native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import {Icon, Text} from 'native-base';
import {Colors} from '../../../styles';
import {Button} from 'antd';
import NoDataFoundWithCustomIcon from '../../common/NoDataFound/NoDataFoundWithCustomIcon';
import Ionicons from 'react-native-vector-icons/Ionicons';


const CareManagement = (props: ICareManagementView) => {
  const {careProgramId, careProgramStepId} = useParams();
  const {accountLocationUuid, contactName, personData} = props;

  if (careProgramId) {
    return (
      <ErrorBoundary
        fallback={
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: Colors.Custom.White,
              margin: 20,
              borderRadius: 10,
              height: '60%',
            }}
          >
            <NoDataFoundWithCustomIcon
              iconSize={400}
              iconContainerSize={180}
              size={112}
              displayString="Something went wrong"
              icon={
                <FeatherIcon
                  name="alert-triangle"
                  size={60}
                  color={Colors.FoldPixel.GRAY300}
                />
              }
              customActionElement={
                <Button
                  type="text"
                  onClick={() => {
                    window.location.reload();
                  }}
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <Icon
                    onPress={() => {
                      window.location.reload();
                    }}
                    as={Ionicons}
                    name={'reload'}
                    color={Colors.FoldPixel.PRIMARY300}
                    mx={1}
                    size={4}
                  />
                  <Text color={Colors.FoldPixel.PRIMARY300}>Reload</Text>
                </Button>
              }
            />
          </View>
        }
      >
        <ContactCareProgramView 
          contactCareProgramId={careProgramId} 
          accountLocationUuid={accountLocationUuid}
          contactName={contactName}
          personData={personData}
          careProgramStepId={careProgramStepId}
        />
      </ErrorBoundary>
    );
  }

  return <CareManagementView {...props} />;
};

export default CareManagement;
