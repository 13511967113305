import {useCallback} from 'react';
import {useSelectedStepInCareProgram} from './useSelectedStepInCareProgram';
import {CARE_PROGRAM_STEP_TYPE} from '../../../../../../common/MemebersView/constant';
import {useContactCareProgramContext} from '../../../ContactCareProgram.context';
import {ContactCareProgramReducerAction} from '../../../reducer';

export const useCareProgramAdditionalAttribute = () => {
  const {dispatch, refetchMasterData} = useContactCareProgramContext();
  const {selectedStep} = useSelectedStepInCareProgram();
  const careProgramStepTypeCode = selectedStep?.careProgramStepType?.code;

  const handleUpdateCareProgram = useCallback(
    async (actionCode?: string, actionData?: any) => {
      switch (actionCode) {
        case ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_STEP_ADDITIONAL_ATTRIBUTES:
          handleUpdateCareProgramStep(actionCode || '', actionData);
          break;
        case ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_COMPLEXITY:
          actionData && dispatch?.({
            type: actionCode as ContactCareProgramReducerAction,
            payload: actionData,
          });
          break;
      }
    },
    [careProgramStepTypeCode]
  );

  const handleUpdateCareProgramStep = useCallback((actionCode: string, actionData: any) => {
    switch (careProgramStepTypeCode) {
      case CARE_PROGRAM_STEP_TYPE.ANNUAL_VISIT:
      case CARE_PROGRAM_STEP_TYPE.APPOINTMENT:
      case CARE_PROGRAM_STEP_TYPE.CARE_MANAGER_APPOINTMENT:
        dispatch?.({
          type: actionCode as ContactCareProgramReducerAction,
          payload: {
            additionalAttribute: actionData,
            subjectTypeCode: 'CARE_PROGRAM_STEP',
          },
        });
        break;
    }
  }, [careProgramStepTypeCode]);

  return {
    handleUpdateCareProgram,
  };
};
