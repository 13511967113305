import React, {useCallback, useContext, useEffect, useReducer} from 'react';
import {
  ContactCareProgramReducer,
  contactCareProgramReducer,
  ContactCareProgramReducerAction,
} from './reducer';
import {CareProgramStatusAction} from './hooks/useCareProgramStatusAction';
import useCareProgramStepStatus from './hooks/useCareProgramStepStatus';
import useCareProgramStatusAction from './hooks/useCareProgramStatusAction';
import {useLazyQuery} from '@apollo/client';
import {GET_CONTACT_CARE_PROGRAM_DETAILS} from '../../../../services/ContactCareProgram/ContactCareProgram';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {
  ContactCareProgramComponentStatus,
  IContactCareProgram,
  IContactCareProgramState,
  IFormScore,
} from '../interface';
import {
  getFormResponseProgressByAssessmentLogs,
  getInitSelectedStepAndCollapseIds,
  groupStepsNested,
} from './ContactCareProgramView.utils';
import {GET_MULTIPLE_FORM_RESPONSE_BY_FORM_LOG_IDS} from '../../../../services/Forms/FormsQueries';
import useFormOptions from './hooks/useFormOptions';
import {usePersonOmniViewContext} from '../../../PersonOmniView/PersonOmniView.context';
import {useCustomToast} from '../../../Toast/ToastProvider';
import {ToastType} from '../../../../utils/commonViewUtils';
import { GET_USERS_BY_RESOURCE_CODES_V2 } from '../../../../services/User/UserQueries';
import { USER_ROLE_CODES } from '../../../../constants/MlovConst';
import { USER_ACCESS_PERMISSION } from '../../UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';
import { getBooleanFeatureFlag,  } from '../../../../utils/commonUtils';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';

const useContactCareProgram = (props: {contactCareProgramId: string, careProgramStepId?: string}) => {
  // Constants
  const {formattedData} = usePersonOmniViewContext();
  const {contactCareProgramId, careProgramStepId} = props;
  const commonContextData = useContext(CommonDataContext);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(commonContextData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const [componentState, dispatch] = useReducer<ContactCareProgramReducer>(
    contactCareProgramReducer,
    {
      contactCareProgramDetails: undefined,
      status: undefined,
      selectedStepId: undefined,
      activeStepId: undefined,
      sideBarExpandedStepKeysSet: new Set<string>(),
      groupedSteps: [],
      stepProgressMap: new Map<string, number>(),
      userMap: new Map<string, string>(),
      formScoreMap: new Map<string, IFormScore[]>(),
    }
  );

  const {careProgramStepStatus} = useCareProgramStepStatus();
  const {handleAction} = useCareProgramStatusAction();
  const toast = useCustomToast();

  const handleOnError = () => {
    toast({
      message: 'Failed to fetch contact care program details',
      toastType: ToastType.error,
    });
    dispatch({
      type: ContactCareProgramReducerAction.SET_STATUS,
      payload: ContactCareProgramComponentStatus.ERROR,
    });
  };

  const [getUsers] = useLazyQuery(
    GET_USERS_BY_RESOURCE_CODES_V2,
    {
      fetchPolicy: 'no-cache',
    }
  );


  const refetchContactCareProgramDetails = async () => {
    await fetchInitialData(true);
  }

  const [getContactCareProgramDetails, {
    loading: isLoadingContactCareProgramDetails,
    refetch
  }] = useLazyQuery(
    GET_CONTACT_CARE_PROGRAM_DETAILS,
    {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },

      onError: handleOnError,
    }
  );

  const [getFormResponsesQuery] = useLazyQuery(
    GET_MULTIPLE_FORM_RESPONSE_BY_FORM_LOG_IDS,
    {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  const getFormResponses = async (formLogIds: string[]) => {
    const formResponses = await getFormResponsesQuery({
      variables: {
        formLogId: formLogIds,
      },
    });
    return formResponses?.data;
  };

  const onUpdateCareProgramStatus = async (
    action: CareProgramStatusAction,
    params: {
      careProgramId: string;
      contactId: string;
    }
  ) => {
    const newStatusId = await handleAction(action, params);
    if (newStatusId) {
      dispatch({
        type: ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_STATUS,
        payload: newStatusId,
      });
    }
  };

  const processResponse = async (careProgramDetail: IContactCareProgram, isInlineRefetch = false) => {
    try {
      const [formProgress, usersResponse] = await Promise.all([
        getFormResponseProgressByAssessmentLogs(
          careProgramDetail?.assessmentLogs || [],
          getFormResponses
        ),
        isInlineRefetch ? Promise.resolve() : 
        getUsers({
          variables: {
            params: {
              userRoleCodesNotIn: [
                USER_ROLE_CODES.WORFLOW_USER,
                USER_ROLE_CODES.CUSTOMER_SUCCESS,
                USER_ROLE_CODES.EMPLOYER,
                USER_ROLE_CODES.WORKFLOW,
              ],
              isActive: true,
              ...(isMultiTenancyEnabled && {
                permittedFor: {
                  allOf: [
                    {
                      accountLocationId: formattedData?.accountLocationUuid,
                      resourceCode: `${USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}/${MAIN_MENU_CODES.PATIENT_DETAILS}`,
                    },
                  ],
                },
              }),
            },
          },
          fetchPolicy: 'no-cache',
        }),
      ]);

      // Process users data
      const userMap = new Map();
      usersResponse?.data?.searchUsers?.users?.forEach((user: any) => {
        userMap.set(user.uuid, user.name);
      });

      // Group the steps
      const groupedSteps = groupStepsNested(
        careProgramDetail?.contactCareProgramSteps || []
      );

      // Get the initial selected step and the expanded step ids
      const {initSelectedStepId, expandStepIds} = isInlineRefetch
        ? {initSelectedStepId: undefined, expandStepIds: undefined}
        : getInitSelectedStepAndCollapseIds(
            groupedSteps,
            careProgramDetail?.stepsLog || [],
            [careProgramStepStatus.in_progress, careProgramStepStatus.to_do],
            careProgramStepId
          );

      // Get the status code
      const statusCode = careProgramDetail
        ? ContactCareProgramComponentStatus.SUCCESS
        : ContactCareProgramComponentStatus.EMPTY;

      
      const payload = isInlineRefetch ? {
        contactCareProgramDetails: careProgramDetail,
        groupedSteps,
        status: statusCode,
      } : {
        contactCareProgramDetails: careProgramDetail,
        groupedSteps,
        status: statusCode,
        selectedStepId: initSelectedStepId,
        sideBarExpandedStepKeysSet: new Set(expandStepIds),
        stepProgressMap: formProgress,
        userMap,
      };

      // Set all the data at once
      dispatch({
        type: ContactCareProgramReducerAction.SET_INIT_DATA,
        payload: payload as Partial<IContactCareProgramState>,
      });
    } catch (error) {
      handleOnError();
    }
  };

  // Side Effects
  useEffect(() => {
    fetchInitialData();
  }, [contactCareProgramId]);
 
  const fetchInitialData = async (isInlineRefetch = false) => {
    try {
      if (!isInlineRefetch) {
        dispatch({
          type: ContactCareProgramReducerAction.SET_STATUS,
          payload: ContactCareProgramComponentStatus.LOADING,
        });
      }
      const response = await getContactCareProgramDetails({
        variables: {
          id: contactCareProgramId,
        },
        fetchPolicy: 'no-cache',
      });
      await processResponse(response?.data?.contactCarePrograms?.[0] as IContactCareProgram, isInlineRefetch);
    } catch (error) {
      handleOnError();
    }
  };

  const handleStepClick = useCallback(
    (stepId: string) => {
      dispatch({
        type: ContactCareProgramReducerAction.SET_SELECTED_STEP_ID,
        payload: stepId,
      });
    },
    [dispatch]
  );

  const handleHeaderAction = useCallback(
    (action: ContactCareProgramReducerAction, payload: string) => {
      dispatch({
        type: action,
        payload: payload,
      });
    },
    [dispatch]
  );

  return {
    componentState,
    onUpdateCareProgramStatus,
    handleStepClick,
    handleHeaderAction,
    dispatch,
    isLoadingContactCareProgramDetails,
    refetchContactCareProgramDetails,
  };
};

export default useContactCareProgram;
