import {Colors} from '../../../styles';

const ArrowRightDiagonalNewSvg = (props?: {color?: string}) => {
  const stroke = props?.color || '#6F7A90';
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 12L12 4M12 4H6M12 4V10"
        stroke={stroke}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ArrowRightDiagonalNewSvg;
