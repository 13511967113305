import React, {useEffect, useState, useCallback, useRef} from 'react';
import {
  ScrollView,
  Pressable,
  HStack,
  VStack,
  Text,
  Input,
  View,
  Spinner,
} from 'native-base';
import AlphabetAvatar from '../../../Avatar/AlphabetAvatar';
import {Colors} from '../../../../../styles';
import {UserSelectionDropDownProps} from '../interfaces';
import RoleDataListView from '../../../../RightSideContainer/TeamInbox/Conversations/ConversationActionView/RoleDataListView';
import { useIntl } from 'react-intl';

const UserSelectionDropDown: React.FC<UserSelectionDropDownProps> = ({
  users,
  selectedProvider,
  selectedProviderId,
  handleSelect,
  fetchUsers,
  loading,
  setShowDropdown,
}) => {
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const intl = useIntl();
  const inputRef = useRef<{focus: () => void}>(null);
  const setTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (inputRef?.current) {
      setTimeoutRef.current = setTimeout(() => inputRef.current?.focus(), 100);
    }

    return () => {
      if (setTimeoutRef.current) {
        clearTimeout(setTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  useEffect(() => {
    fetchUsers(debouncedSearchText);
  }, [debouncedSearchText]);

  return (
    <>
      <Input
        ref={inputRef}
        placeholder="Search"
        onChangeText={(text) => setSearchText(text)}
        variant="outline"
        marginBottom={3}
        borderColor={Colors.FoldPixel.GRAY250}
        borderWidth={0.5}
        width={'100%'}
        minWidth={'224px'}
        placeholderTextColor={Colors.FoldPixel.GRAY250}
      />
      <Text
        color={Colors.FoldPixel.GRAY300}
        fontWeight={500}
      >
        {intl.formatMessage({ id: 'switchTo' })}
      </Text>
      {loading ? (
        <Spinner size="sm" />
      ) : (
        <ScrollView mt={3} gap={4} maxHeight={200}>
          {users?.map((provider: any) => {
            const isSelected =
              (selectedProvider?.uuid || selectedProviderId) === provider?.uuid;
            const displayRoles =
              provider?.userRoles?.map((role: any) => ({
                userRole: {
                  userRole: {
                    code: role?.userRole?.customRoleCode,
                    value: role?.userRole?.roleName,
                  },
                },
              })) || [];

            return (
              <Pressable
                key={provider?.id}
                onPress={() => {
                  handleSelect(provider);
                  setShowDropdown && setShowDropdown(false);
                }}
                marginBottom={2}
              >
                <HStack
                  style={{
                    alignItems: 'center',
                    paddingHorizontal: 3,
                    backgroundColor: isSelected ? Colors.Custom.insuranceBgColor : Colors.Custom.ContainerBGColor,
                    borderColor: isSelected ? Colors.Custom.Primary200 : Colors.Custom.gray150,
                    borderWidth: isSelected ? 0.5 : 0,
                    borderRadius: 4,
                  }}
                  height={'48px'}
                  gap={8}
                  minWidth={'224px'}
                  justifyContent="space-between"
                >
                  <HStack space={4} alignItems={'center'}>
                    <AlphabetAvatar
                      size={10}
                      name={provider.name}
                      style={{
                        alignItems: 'center',
                        borderRadius: 8,
                        BorderColor: '#FBCEB7',
                        borderWidth: 1,
                      }}
                      textStyle={{fontSize: 12, fontWeight: 500}}
                      disableTop
                      userType="USER"
                    />
                    <VStack gap={1}>
                      <Text
                        fontWeight={400}
                        fontSize={14}
                        color={'#3A485F'}
                        maxW={200}
                        isTruncated
                      >
                        {provider.name}
                      </Text>
                      <View width={'auto'}>
                        <RoleDataListView
                          userRoles={displayRoles}
                          isPopupView={true}
                        />
                      </View>
                    </VStack>
                  </HStack>
                </HStack>
              </Pressable>
            );
          })}
        </ScrollView>
      )}
    </>
  );
};

export default UserSelectionDropDown;
