import {useApolloClient} from '@apollo/client';
import {LeadQueries} from '../../services';
import {useCallback} from 'react';
import {getTenantForDemo} from '../RightSideContainer/Analytics/EmployerAnalytics/tempDataFile';
import {salesDataService} from '../../services/SalesDataService';
import {isLeadGroupsPage, shuffleArray} from '../../utils/commonUtils';
import {getCampaignSegmentRules} from '../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {getContactTypeId} from '../../utils/mlovUtils';

const isDemoAccount = getTenantForDemo();

const getSexAtBirthValueAndOperatorFromRule = (
  rule: any
):
  | {
      value: string;
      operator: string;
    }
  | undefined => {
  const conditionArray =
    rule?.rule?.conditions?.all || rule?.rule?.conditions?.any || [];
  const sexAtBirthRule = conditionArray.find(
    (condition: any) => condition.fact === 'sexAtBirth'
  );

  if (!sexAtBirthRule) {
    return undefined;
  }

  return {
    value: sexAtBirthRule.value,
    operator: sexAtBirthRule.operator,
  };
};

const getGenderIdentityValueAndOperatorFromRule = (
  rule: any
):
  | {
      value: string;
      operator: string;
    }
  | undefined => {
  const conditionArray =
    rule?.rule?.conditions?.all || rule?.rule?.conditions?.any || [];
  const genderIdentityRule = conditionArray.find(
    (condition: any) => condition.fact === 'genderIdentity'
  );

  if (!genderIdentityRule) {
    return undefined;
  }

  return {
    value: genderIdentityRule.value,
    operator: genderIdentityRule.operator,
  };
};

const getTagsValueAndOperatorFromRule = (
  rule: any
):
  | {
      value: boolean;
      operator: string;
      tags: string[];
    }
  | undefined => {
  const conditionArray =
    rule?.rule?.conditions?.all || rule?.rule?.conditions?.any || [];
  const tagsRule = conditionArray.find(
    (condition: any) => condition.fact === 'hasTags'
  );

  if (!tagsRule) {
    return undefined;
  }

  return {
    value: tagsRule.value,
    operator: tagsRule.operator,
    tags: tagsRule.params.tags,
  };
};

const getWhereClauseFromRule = (rule: any): any => {
  const where: Record<string, any> = {};
  const person: Record<string, any> = {};
  const contactTagging: Record<string, any> = {};
  const sexAtBirthValueAndOperator =
    getSexAtBirthValueAndOperatorFromRule(rule);
  const genderIdentityValueAndOperator =
    getGenderIdentityValueAndOperatorFromRule(rule);
  const tagsValueAndOperator = getTagsValueAndOperatorFromRule(rule);
  if (sexAtBirthValueAndOperator?.value) {
    const operator = sexAtBirthValueAndOperator.operator;
    const value = sexAtBirthValueAndOperator.value;
    const clause = operator === 'equal' ? '_eq' : '_neq';
    person.sexAtBirth = {
      code: {
        [clause]: value,
      },
    };
  }
  if (genderIdentityValueAndOperator?.value) {
    const operator = genderIdentityValueAndOperator.operator;
    const value = genderIdentityValueAndOperator.value;
    const clause = operator === 'equal' ? '_eq' : '_neq';
    person.gender = {
      code: {
        [clause]: value,
      },
    };
  }

  if (tagsValueAndOperator?.tags && tagsValueAndOperator.tags.length > 0) {
    const clause = tagsValueAndOperator.value ? '_in' : '_nin';
    contactTagging.labels = {
      title: {
        [clause]: tagsValueAndOperator.tags,
      },
    };
  }
  if (Object.keys(person).length > 0) {
    where.person = person;
  }
  if (Object.keys(contactTagging).length > 0) {
    where.contactTagging = contactTagging;
  }
  return where;
};

export const useSalesDataService = () => {
  const customerContactTypeId = getContactTypeId('CUSTOMER');
  const client = useApolloClient();

  const getDemoContactIds = useCallback(
    async (ruleId: string): Promise<number[]> => {
      if (!isDemoAccount || !ruleId || isLeadGroupsPage()) {
        return [];
      }
      const data = await salesDataService.getDemoRulesDataByRuleId(ruleId);
      const contactIds = data?.contactIds || [];
      if (contactIds.length > 0) {
        return contactIds;
      }
      const rule = await getCampaignSegmentRules(ruleId);
      if (rule.type === 'STATIC') {
        return [];
      }
      const {contactIds: customContactIds} = await setDemoContactIds(rule);
      return customContactIds;
    },
    []
  );

  const setDemoContactIds = useCallback(async (rule: any) => {
    const ruleId = rule.id;
    const {activePatients, inactivePatients} =
      await salesDataService.getActiveAndInactiveNumberByRuleId(ruleId);
    const where = getWhereClauseFromRule(rule);
    where.contactType = {
      typeId: {
        _eq: customerContactTypeId,
      },
    };
    const activeContactResponse = await client.query({
      query: LeadQueries.GetContactsByConditions,
      variables: {
        limit: activePatients,
        offset: 0,
        where,
      },
    });
    const inactiveContactResponse = await client.query({
      query: LeadQueries.GetContactsByConditions,
      variables: {
        limit: inactivePatients,
        offset: 0,
        where,
      },
    });
    const activeContactIds: number[] =
      activeContactResponse?.data?.contacts?.map(
        (contact: any) => contact.id
      ) || [];
    const inactiveContactIds: number[] =
      inactiveContactResponse?.data?.contacts?.map(
        (contact: any) => contact.id
      ) || [];
    const contactIds = shuffleArray([
      ...activeContactIds,
      ...inactiveContactIds,
    ]);
    await salesDataService.setDemoRulesDataByRuleId(ruleId, {
      activePatients: activePatients,
      inactivePatients: inactivePatients,
      contactIds,
      ruleId,
    });
    return {
      activePatients: activePatients,
      inactivePatients: inactivePatients,
      contactIds: contactIds,
    };
  }, []);

  return {
    getDemoContactIds,
  };
};
