import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent} from '@foldhealth/formio-react';
import {Formio} from '@foldhealth/formiojs';
import CustomWrapper from '../CustomWrapper/CustomWrapper';
import KeyField from '../../EditFormFields/KeyField';
import ConditionalComponents from '../../EditFormFields/ConditionalFields';
import CustomClassComponent from '../CustomWrapper/CustomClassComponent';
import {CapabilityResource} from '../CustomWrapper/CustomComponentHelper';
import AddPatientInformationVerification from './AddPatientIntakeVerification/AddPatientInformationVerification';

export default class PatientInformationVerification extends CustomClassComponent {
  static get builderInfo() {
    return {
      title: 'Patient Information Verification',
      icon: 'filetext1',
      group: 'advanced',
      documentation: '',
      schema: PatientInformationVerification.schema(),
    };
  }

  labelIsHidden() {
    return this.options.renderMode === 'html';
  }

  static schema() {
    return ReactComponent.schema({
      type: 'patientInformationVerification',
      label: 'Patient Information Verification',
      key: 'patientInformationVerification',
    });
  }

  attachReact(element: any) {
    return ReactDOM.render(
      <CustomWrapper
        formOptionData={this.options}
        capabilityList={[CapabilityResource.patient]}
      >
        <AddPatientInformationVerification
          options={this.options}
          disabled={this.disabled}
          component={this.component}
          validateRef={this.validateRef}
          onChange={this.updateValue}
        />
      </CustomWrapper>,
      element
    );
  }

  static editForm = function () {
    return {
      key: 'display',
      components: [
        {
          type: 'oldtextfield',
          key: 'label',
          label: 'Label',
          input: true,
          validate: {
            required: true,
          },
        },
        ...ConditionalComponents,
        ...KeyField
      ],
    };
  };
}

Formio.Components.addComponent(
  'patientInformationVerification',
  PatientInformationVerification
);
